import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ApiUtils } from './api-utils';
import { FilterCriterion } from '@/app/model/search/filter-criterion';
import { Sorting } from '@/app/model/search/sorting';
import { Page } from '@/app/model/pagination/page';
import { plainToClassFromExist } from 'class-transformer';
import { LOCALE_ID, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Collaborator } from '@/app/model/collaborator/collaborator';

@Injectable({
  providedIn: 'root',
})
export class CollaboratorApiService {
  buildPath(): string {
    return ApiUtils.BASE_PATH + 'collaborators/';
  }

  constructor(
    private http: HttpClient,
    @Inject(LOCALE_ID) private locale: string
  ) {}

  getCollaborators(
    page: number,
    size: number,
    filterCriteria: Array<FilterCriterion>,
    sorting: Sorting
  ): Observable<Page<Collaborator>> {
    const url = this.buildPath();
    let queryParams = new HttpParams()
      .set('page', page.toString())
      .set('size', size.toString())
      .set('sortDirection', sorting.direction)
      .set('sortedBy', sorting.sortedBy);
    filterCriteria.forEach(
      (filterCriterion: FilterCriterion) =>
        (queryParams = queryParams.set(
          filterCriterion.key,
          filterCriterion.value
        ))
    );
    const httpOptions = {
      headers: { 'Content-Type': 'application/json' },
      params: queryParams,
    };
    return this.http
      .get<Page<Collaborator>>(url, httpOptions)
      .pipe(
        map((response) =>
          plainToClassFromExist(new Page<Collaborator>(Collaborator), response)
        )
      );
  }
}
