import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ApiUtils } from './api-utils';
import { FilterCriterion } from '@/app/model/search/filter-criterion';
import { Sorting } from '@/app/model/search/sorting';
import { Page } from '@/app/model/pagination/page';
import { GuestUser } from '@/app/model/user/user';
import { plainToClassFromExist } from 'class-transformer';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class GuestUserApiService {
  buildPath(endPoint: string): string {
    return ApiUtils.BASE_PATH + 'guest-users/' + endPoint;
  }

  constructor(private http: HttpClient) {}

  getUsers(
    page: number,
    filterCriteria: Array<FilterCriterion>,
    sorting: Sorting
  ): Observable<Page<GuestUser> | any> {
    const url = this.buildPath('');
    let queryParams = new HttpParams()
      .set('page', page.toString())
      .set('direction', sorting.direction)
      .set('sortedBy', sorting.sortedBy);
    filterCriteria.forEach(
      (filterCriterion: FilterCriterion) =>
        (queryParams = queryParams.set(
          filterCriterion.key,
          filterCriterion.value
        ))
    );
    const httpOptions = {
      headers: { 'Content-Type': 'application/json' },
      params: queryParams,
    };
    return this.http
      .get<Page<GuestUser>>(url, httpOptions)
      .pipe(
        map((response) =>
          plainToClassFromExist(new Page<GuestUser>(GuestUser), response)
        )
      );
  }

  adminDeleteGuestUser(guestUserId: number): Promise<any> {
    return this.http
      .delete(this.buildPath(guestUserId.toString()), ApiUtils.HTTP_OPTIONS)
      .toPromise();
  }

  async getDataToExport(pageNumber: number): Promise<Page<GuestUser>> {
    const url = this.buildPath('');
    const queryParams = new HttpParams()
      .set('size', '1000')
      .set('page', pageNumber.toString());
    const httpOptions = {
      headers: { 'Content-Type': 'application/json' },
      params: queryParams,
    };
    return this.http
      .get<Page<GuestUser>>(url, httpOptions)
      .pipe(
        map((response) =>
          plainToClassFromExist(new Page<GuestUser>(GuestUser), response)
        )
      )
      .toPromise();
  }
}
