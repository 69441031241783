import { Injectable } from '@angular/core';
import { TranslationService } from 'src/app/services/translationService/translation.service';
import { ValidationErrors } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class ValidationService {
  constructor(private _translationService: TranslationService) {}

  getValidatorErrorMessage(
    validatorName: string,
    validatorValue?: ValidationErrors | null
  ) {
    const config = {
      required: this.getLabelValue('interface_required'),
      email: this.getLabelValue('interface_invalid_email'),
      whitespace: this.getLabelValue('interface_not_only_space'),
      minlength: this.getLabelValue(
        'interface_min_length',
        new Map().set('$1', validatorValue?.requiredLength)
      ),
      maxlength: this.getLabelValue(
        'interface_max_length',
        new Map().set('$1', validatorValue?.requiredLength)
      ),
      mustMatch: this.getLabelValue('interface_passwords_must_match'),
      min: this.getLabelValue(
        'interface_larger_than_or_equal',
        new Map().set('$1', validatorValue?.min)
      ),
      max: this.getLabelValue(
        'interface_less_than_or_equal',
        new Map().set('$1', validatorValue?.max)
      ),
      equals: this.getLabelValue(
        'interface_equals',
        new Map()
          .set('$1', validatorValue?.actual)
          .set('$2', validatorValue?.expected)
      ),
    };

    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return config[validatorName];
  }

  getLabelValue(label: string, params?: Map<string, any>): string {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    let i18n: string =
      this._translationService.getTranslationValueForLabel(label);

    if (params != undefined) {
      params.forEach(
        (value: any, key: string) => (i18n = i18n.replace(key, value))
      );
    }

    return i18n;
  }
}
