<form [formGroup]="newPasswordForm">
  <div class="form-group">
    <label for="newPassword">{{
      "interface_new_password" | translationPipe
    }}</label>
    <input
      id="newPassword"
      class="form-control"
      name="newPassword"
      type="password"
      formControlName="newPassword"
      autocomplete="new-password"
    />

    <label
      *ngFor="let ph of passwordhelpers"
      class="col"
      [ngClass]="
        passwordFieldHasError('required') || passwordFieldHasError(ph.errorName)
          ? 'form-invalid-message'
          : 'form-valid-message'
      "
    >
      <i
        class="fas"
        [ngClass]="
          passwordFieldHasError('required') ||
          passwordFieldHasError(ph.errorName)
            ? 'fa-ban'
            : 'fa-check-circle'
        "
      ></i>
      {{ ph.label | translationPipe }}
    </label>
  </div>
  <div class="form-group">
    <label for="confirmNewPassword">{{
      "interface_password_confirm" | translationPipe
    }}</label>
    <input
      id="confirmNewPassword"
      class="form-control"
      name="confirmNewPassword"
      type="password"
      formControlName="confirmNewPassword"
    />
    <validation-messages
      [control]="newPasswordForm.get('confirmNewPassword')"
      [touch]="false"
      class="form-invalid-message"
    >
    </validation-messages>
  </div>
</form>
