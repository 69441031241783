import { getEnumKeys } from '@/app/utils/enum-utils';

export interface Authority {
  role: Role;
  label: string;
  priority: number;
}

export enum Role {
  // eslint-disable-next-line @typescript-eslint/indent
  USER = 'ROLE_USER',
  ADMIN = 'ROLE_ADMIN',
  SUPER_ADMIN = 'ROLE_SUPER_ADMIN',
  GUEST_USER = 'ROLE_GUEST_USER',
}

export function isRole(key: string): key is keyof typeof Role {
  return getEnumKeys(Role).indexOf(key) !== -1;
}

export const authorities: Authority[] = [
  { role: Role.USER, label: 'interface_role_user', priority: 1 },
  { role: Role.ADMIN, label: 'interface_role_admin', priority: 2 },
  { role: Role.SUPER_ADMIN, label: 'interface_role_super_admin', priority: 3 },
  { role: Role.GUEST_USER, label: 'interface_role_guest', priority: 4 },
];
