<div *ngIf="isChallenge(Challenge.PASSWORD_VERIFIER)">
  <div id="loginBox">
    <form
      [formGroup]="loginForm"
      id="loginForm"
      (ngSubmit)="onSubmit(loginForm.value)"
    >
      <div class="form-group">
        <label for="email">{{ "interface_email" | translationPipe }}</label>
        <input
          id="email"
          class="form-control"
          name="email"
          type="text"
          formControlName="email"
          [disableControl]="loading"
        />
      </div>
      <div class="form-group">
        <label for="password">{{
          "interface_password" | translationPipe
        }}</label>
        <input
          id="password"
          class="form-control"
          name="password"
          type="password"
          formControlName="password"
          [disableControl]="loading"
        />
      </div>

      <button
        id="login"
        type="submit"
        class="btn btn-primary login-btn"
        [disabled]="!loginForm.valid || loading"
      >
        <span *ngIf="!loading">{{ "interface_login" | translationPipe }}</span>
        <spinner *ngIf="loading"></spinner>
      </button>
      <div class="form-text text-center errorMsg">
        {{ errorMessage | translationPipe }}
      </div>
      <p class="forgot-password" (click)="forgottenPw()">
        <em class="fas fa-key"></em>
        <a>{{ "interface_forgot_password" | translationPipe }}</a>
      </p>
    </form>
  </div>
  <app-email-form-modal
    [title]="'interface_forgotten_password_title' | translationPipe"
    (answer)="confirmForgottenPw($event)"
  >
  </app-email-form-modal>
</div>

<div *ngIf="isChallenge(Challenge.NEW_PASSWORD_REQUIRED)">
  <form
    [formGroup]="newPasswordForm"
    id="newPassswordRequiredForm"
    (ngSubmit)="
      onSubmitNewPasswordRequired(newPasswordForm.value.password.newPassword)
    "
  >
    <app-new-password-validation
      (formReady)="formInitialized('password', $event)"
    ></app-new-password-validation>
    <div class="form-text text-center errorMsg">{{ errorMessage }}</div>
    <button
      type="submit"
      class="btn btn-primary login-btn mb-3"
      [disabled]="!newPasswordForm.valid"
    >
      {{ "interface_confirm_change_password" | translationPipe }}
    </button>
  </form>
</div>
