/**
 * Model class for a "pagination bar".
 *
 * Typical pagination bar (in ASCII art): `<< < 1 2 3 4 5 > >>`
 */
export class Pager {
  totalItems: number;
  totalFilteredItems: number;
  currentPage: number;
  pageSize: number;
  totalPages: number;
  startPage: number;
  endPage: number;
  startIndex: number;
  endIndex: number;
  pages: Array<number>;

  constructor(
    totalItems: number,
    totalFilteredItems: number,
    currentPage: number,
    pageSize: number,
    totalPages: number,
    startPage: number,
    endPage: number,
    startIndex: number,
    endIndex: number,
    pages: Array<number>
  ) {
    this.totalItems = totalItems;
    this.totalFilteredItems = totalFilteredItems;
    this.currentPage = currentPage;
    this.pageSize = pageSize;
    this.totalPages = totalPages;
    this.startPage = startPage;
    this.endPage = endPage;
    this.startIndex = startIndex;
    this.endIndex = endIndex;
    this.pages = pages;
  }

  static getPager(
    totalItems: number,
    currentPage: number,
    pageSize: number,
    totalFilteredItems?: number
  ): Pager {
    // calculate total pages
    if (totalFilteredItems == null) {
      totalFilteredItems = totalItems;
    }

    const totalPages: number = Math.ceil(totalFilteredItems / pageSize);

    let startPage: number;
    let endPage: number;
    if (totalPages <= 10) {
      // less than 10 total pages so show all
      startPage = 1;
      endPage = totalPages;
    } else {
      // more than 10 total pages so calculate start and end pages
      if (currentPage <= 6) {
        startPage = 1;
        endPage = 10;
      } else if (currentPage + 4 >= totalPages) {
        startPage = totalPages - 9;
        endPage = totalPages;
      } else {
        startPage = currentPage - 5;
        endPage = currentPage + 4;
      }
    }

    // calculate start and end item indexes
    const startIndex: number = (currentPage - 1) * pageSize;
    const endIndex: number = Math.min(
      startIndex + pageSize - 1,
      totalFilteredItems - 1
    );

    // create an array of pages to ng-repeat in the pager control
    const pages: Array<number> = [];
    for (let i = startPage; i < endPage + 1; i++) {
      pages.push(i);
    }

    // return object with all pager properties required by the view
    return new Pager(
      totalItems,
      totalFilteredItems,
      currentPage,
      pageSize,
      totalPages,
      startPage,
      endPage,
      startIndex,
      endIndex,
      pages
    );
  }
}
