<ng-template #publishedscenarioeditionmodal let-modal>
  <div class="modal-content">
    <div class="modal-header modal-spacing">
      <div class="modal-title">
        {{ "interface_edit_publishedScenario" | translationPipe }}
      </div>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        aria-label="Close"
        (click)="close()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <form
      [formGroup]="checkoutForm"
      id="loginForm"
      (ngSubmit)="onSubmit(checkoutForm.value)"
    >
      <div class="modal-body break-word modal-spacing">
        <div class="form-group">
          <label for="name"> {{ "interface_name" | translationPipe }}</label>
          <input
            id="name"
            class="form-control"
            name="login"
            type="text"
            formControlName="name"
          />
          <validation-messages
            [control]="checkoutForm.get('name')"
            class="form-invalid-message"
          >
          </validation-messages>
        </div>
        <div class="form-group">
          <label for="authority">{{
            "interface_status" | translationPipe
          }}</label>
          <select class="custom-select form-control" formControlName="status">
            <option *ngFor="let status of availableStatus" [value]="status">
              {{ status.toString() | translationPipe }}
            </option>
          </select>
          <validation-messages
            [control]="checkoutForm.get('status')"
            class="form-invalid-message"
          >
          </validation-messages>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-cancel" (click)="close()">
          {{ "interface_cancel" | translationPipe }}
        </button>
        <button
          type="submit"
          class="btn btn-primary login-btn"
          [disabled]="isBusy || !checkoutForm.valid"
        >
          <span> {{ "interface_save" | translationPipe }} </span>
        </button>
      </div>
    </form>
  </div>
</ng-template>
