import { MatchingRuleEffect } from './matching-rule-effect.enum';
import { Feature } from './feature';

export class ActivationRule {
  private _parts: Array<string>;
  _matchingRuleEffect: MatchingRuleEffect = MatchingRuleEffect.DENY_FEATURE;
  // @ts-ignore
  _denyReason: string;

  static allowFeature(_parts: Array<string>) {
    const _matchingRuleEffectAllowFeature = MatchingRuleEffect.ALLOW_FEATURE;
    return new ActivationRule(_matchingRuleEffectAllowFeature, _parts);
  }

  static allowInCascadeFeature(_parts: Array<string>) {
    const _matchingRuleEffectAllowCascade =
      MatchingRuleEffect.ALLOW_IN_CASCADE_FEATURE;
    return new ActivationRule(_matchingRuleEffectAllowCascade, _parts);
  }

  static denyFeature(_parts: Array<string>, _denyReason: string) {
    const _matchingRuleEffectDeny = MatchingRuleEffect.DENY_FEATURE;
    return new ActivationRule(_matchingRuleEffectDeny, _parts, _denyReason);
  }

  constructor(
    _matchingRuleEffect: MatchingRuleEffect,
    _parts: Array<string>,
    _denyReason?: string
  ) {
    this._matchingRuleEffect = _matchingRuleEffect;
    this._parts = _parts;
    if (_denyReason != null) this._denyReason = _denyReason;
  }

  perfectMatch(f: Feature): boolean {
    return this._parts.sort().toString() == f._parts.sort().toString();
  }

  cascadeMatch(f: Feature): boolean {
    for (let i = 0; i < this._parts.length; i++) {
      if (this._parts[i] != '*') {
        if (f._parts.length <= i || f._parts[i] != this._parts[i]) return false;
      }
    }
    return true;
  }

  perfectOrCascadeMatch(f: Feature): boolean {
    return this.perfectMatch(f) || this.cascadeMatch(f);
  }
}
