import { Injectable } from '@angular/core';
import { CompanyApiService } from '@/app/api/company-api.service';
import { Observable } from 'rxjs';
import { Company } from '@/app/model/data/company';
import { FilterCriterion } from '@/app/model/search/filter-criterion';
import { SortingDirection } from '@/app/model/search/sorting';
import { Page } from '@/app/model/pagination/page';
import { map } from 'rxjs/operators';
import { TypedSorting } from '@/app/model/search/typed-sorting';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  constructor(private companyApi: CompanyApiService) {}

  async createCompany(company: Company) {
    await this.companyApi.createCompany(company);
  }

  async editCompany(company: Company) {
    await this.companyApi.editCompany(company);
  }

  getCompanies(
    page: number,
    filterCriteria: Array<FilterCriterion>,
    sorting: TypedSorting<keyof Company>
  ): Observable<Page<Company>> {
    return this.companyApi.getCompanies(page, filterCriteria, sorting);
  }

  searchCompanyByName(search: string): Observable<Array<Company>> {
    return this.companyApi
      .getCompanies(
        0,
        [new FilterCriterion('name', search)],
        new TypedSorting<keyof Company>(SortingDirection.ASC, 'name')
      )
      .pipe(map((response) => response.content));
  }
}
