import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ResultsDisplayService {
  numberOfCharDisplayed = 80;
  heightOfIndicatorChart = 22;
  heightOfPackChart = 14;
  heightOfLCAChart = 17;
  heightOfComponentChart = 17;

  computeDisplayVariables(scrWidth: number, scrHeight: number) {
    if ((scrWidth <= 1530 && scrWidth > 1400) || scrHeight <= 740) {
      this.numberOfCharDisplayed = 40;
    } else if (scrWidth <= 1400 && scrWidth > 1244) {
      this.numberOfCharDisplayed = 30;
    } else if (
      (scrWidth <= 1244 && scrWidth > 1054 && scrHeight <= 660) ||
      (scrWidth <= 1244 && scrWidth > 1054) ||
      scrHeight <= 660
    ) {
      this.numberOfCharDisplayed = 25;
    } else if (scrWidth <= 1054 && scrWidth > 993) {
      this.numberOfCharDisplayed = 25;
    } else if (
      (scrWidth <= 993 && scrWidth > 582 && scrHeight <= 495) ||
      (scrWidth <= 993 && scrWidth > 582) ||
      scrHeight <= 495
    ) {
      this.numberOfCharDisplayed = 40;
    } else if (
      (scrWidth <= 582 && scrHeight <= 450) ||
      scrWidth <= 582 ||
      scrHeight <= 450
    ) {
      this.numberOfCharDisplayed = 40;
      this.heightOfPackChart = 26;
      this.heightOfLCAChart = 30;
      this.heightOfIndicatorChart = 41;
    } else {
      this.numberOfCharDisplayed = 80;
    }

    /*if (this.scrHeight <= 740)
        {
            this.heightOfIndicatorChart = 22;
            this.heightOfLCAChart = 16;
        }*/
  }
}
