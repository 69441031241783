<ng-template #termsofusemodal let-modal class="terms-of-use-modal">
  <div class="modal-content">
    <div class="modal-header modal-spacing">
      <div class="modal-title">
        <h1>Spice Terms and Conditions of Use</h1>
        <p>Effective as of September 1st 2020</p>
      </div>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        aria-label="Close"
        (click)="close()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body modal-spacing">
      <p>
        <b
          >PLEASE READ THESE TERMS AND CONDITIONS OF USE CAREFULLY, THAT GOVERN
          YOUR USE OF THE SPICE WEBTOOL</b
        >
      </p>

      <p>
        The SPICE webtool (hereafter the “Service” as defined in section 1),
        accessible at www.open-spice.com was created by the Sustainable
        Packaging Initiative for Cosmetics (hereafter “SPICE” or “SPICE
        Initiative”), and is published by QUANTIS, a company incorporated under
        the laws of France, registered under the number 513 228 734 with its
        registered office at 3 rue de Pondichery 75015 Paris, represented by
        Dimitri CAUDRELIER, acting in his capacity of Managing Director, with a
        share capital of €50 000, VAT n° FR73513228734, tel.: +33 9 63 23 04 67,
        email: info.france@quantis-intl.com.
      </p>

      <p>
        This service is hosted by Amazon Web Services EMEA SARL, a company
        incorporated under the laws of Luxembourg, registered under the number
        B186284 with its registered office at 38 Avenue John F. Kennedy, L-1855
        Luxembourg, https://aws.amazon.com/.
      </p>

      <p>
        By accessing or otherwise using this service, the User agrees to comply
        with and be bound by the following terms of use (the “Terms”) which
        constitute a binding and legally enforceable agreement. If the User does
        not understand and agree to these Terms, the User shall not access, use
        or register on the Service.
      </p>

      <p>
        If the User accepts these Terms on behalf of a third party, this
        acceptance will warrant that the User has the full authority to bind the
        licensee to these Terms and will be binding on that third party. If the
        User does not have such authority, do not access or otherwise use this
        service.
      </p>

      <br />
      <h2>1. Definitions</h2>
      <br />
      <p>
        Words beginning with a capital letter shall have the specific meaning
        set forth in this article. The terms can be used in singular or plural
        form depending on the context.
      </p>

      <p>
        <b>“Authorized Purposes”</b> means the purposes of use of the Service
        authorized under these Terms, which are strictly limited to
        environmental footprint calculation, for research, development and
        commercial purposes, regarding packaging of products of the cosmetic
        sector.
      </p>

      <p>
        <b>“Result”</b> means the environmental footprint calculation provided
        by the Service on the packaging submitted by the User, based on the
        information provided by the User and the SPICE Methodology.
      </p>

      <p>
        <b>“Service”</b> means the software-as-a-service tool, in its publicly
        accessible version, aiming at evaluating the environmental footprint of
        cosmetics packaging, in line with the SPICE Methodology.
      </p>

      <p>
        <b>“SPICE Methodology”</b> means the harmonized methodology used to
        quantify the environmental footprint of packaging products throughout
        their value chain, and based on the methodologies developed by the
        members of the SPICE initiative, as available on the SPICE website.
      </p>

      <p>
        <b>“User”</b> means the natural person accessing and using the website,
        mainly by registering on the Service and using its features.
      </p>
      <br />
      <h2>2. Use of the Service</h2>
      <br />
      <h3>2.1. Registration and account</h3>
      <br />
      <p>
        The User has to register to access and use the Service, by providing
        his/her name, first name, company, position and email address.
      </p>

      <p>
        By registering, the User agrees that the information he/she provides is
        true, accurate, current and complete in all respects. The User also
        undertakes to notify SPICE of any change in such information.
      </p>

      <p>
        Registration on the Service is restricted to Users authorized by their
        employer/ business entity and the Company reserves the right to deny the
        creation of an account to any other person.
      </p>

      <p>
        Once registered, the User must take reasonable security measures to
        protect the confidentiality of his/her password. The User is solely
        responsible for the activity that occurs through his/her account.
      </p>

      <p>
        Data entered by the User for environmental footprint calculation, as
        well as the Result, are not registered and stored by the Service. The
        User is solely responsible for saving the data entered into the Service,
        the Result and any other information.
      </p>
      <br />
      <h3>2.2. Purposes of the Service</h3>
      <br />
      <p>
        The Service must only be used for the Authorized Purposes. The Service
        is not designed for any other use or purpose and must not be used for
        any product other than packaging in the specific cosmetic sector. The
        Service is not intended for use by consumers or non-professional
        individuals or organisms.
      </p>

      <p>
        The User understands that the Service is limited to automated
        calculation of the environmental footprint and that the Result is
        exclusively based on the information provided by the User. The User is
        solely responsible for the accuracy, completeness and quality of such
        information. The Service does not qualify as a certification or
        labelling scheme by SPICE.
      </p>

      <br />
      <h3>2.3. Obligations of the User</h3>
      <br />
      <p>
        The User undertakes not to interrupt or attempt to interrupt the
        operation of the Service in any way including through the use of any
        hardware or software intended to damage or interfere with the proper
        working of the Service or to intercept any data transmitted via the
        Service.
      </p>

      <p>
        The User may not use the Service to upload or otherwise publish any
        content that (i) breaches confidentiality undertakings or contains
        classified information; (ii) infringes any rights of third parties,
        including without limitation their intellectual property rights; (iii)
        breaches any applicable local or international law or regulation; (iv)
        is fraudulent or has any fraudulent purpose or effect; (v) contains
        among others things and without limitation viruses, Trojan horses,
        worms, time-bombs, keystroke loggers, spyware, adware or any other
        harmful programs or similar computer code designed to adversely affect
        the operation of the Service or any computer software or hardware; (vi)
        acts contrary to SPICE interests, in particular by modifying, damaging,
        or overloading the Service; (vii) is inconsistent with reasonable use or
        with the purpose of the Service.
      </p>

      <br />
      <h2>3. Intellectual Property</h2>
      <br />
      <h3>3.1. Right to access and use</h3>
      <br />
      <p>
        Subject to User’s compliance with these Terms, SPICE hereby grants to
        User a non-exclusive, non-sublicensable and non-transferable right to
        use and access the Service, for the Authorized Purposes only.
      </p>
      <br />
      <h3>3.2. License Restrictions</h3>
      <br />
      <p>
        Except as expressly permitted under these Terms or under applicable law,
        the User will not, nor will it permit or otherwise authorize anyone or
        any entity to (a) copy, reverse assemble, reverse compile, decompile,
        disassemble or reverse engineer or attempt to extract or otherwise
        derive the source code or any methods, algorithms, procedures, from the
        Service, (b) extract, reuse, reproduce, represent, download or save,
        directly or indirectly, on and by any means whatsoever, including
        systematically and repeatedly, quantitative or qualitative substantial
        part or all of the database of the Service (c) use.or modify, adapt,
        alter, translate, or create derivative works from the Service; (d)
        license, sublicense, sell, resell, rent, lease, transfer, assign,
        distribute, time share, offer in a third party service, or otherwise
        make the Service or any of its components available to any third party;
        (e) merge the Service with other software and/or use the Service as a
        component or basis for services of any type designed for commercial or
        non-commercial purposes, or (f) otherwise copy the Service.
      </p>
      <br />
      <h3>3.3. Retention of Rights</h3>
      <br />
      <p>
        This Service and all content on the SPICE website including, without
        limitation, text, database, designs, graphics, logos, icons, images,
        films, audio clips, downloads, interfaces, code and software is owned by
        or licensed to SPICE and is protected by intellectual property rights,
        copyright, trademark, database producer’s right and other applicable
        laws. Access and use of the Service does not entitle the User to any
        intellectual property rights or any other rights in the Service, other
        than the limited rights of access and use granted to the User pursuant
        to these Terms.
      </p>

      <p>
        All trademarks, logos, service marks, trade names or domain names
        displayed on this Service are registered and/or unregistered trademarks
        of SPICE, its respective licensors or content providers, or other third
        parties. All of these trademarks, logos and service marks are the
        property of their respective owners. Nothing on this Service shall be
        construed as granting any license or right to use any trademark, logo or
        service mark displayed on the Service without the owner’s prior written
        permission.
      </p>

      <p>
        To the extent not expressly licensed to the User herein, SPICE and its
        respective licensors or content providers, where applicable, reserve and
        retain all right, title and interest in and to all SPICE materials. User
        shall not use, register nor attempt to register, any marks, trade names,
        logos or domain names, that may cause confusion with SPICE’s trademarks
        and logo. User will not register, nor attempt to register, any patent or
        copyright that incorporates any SPICE materials.
      </p>

      <br />
      <h2>4. Publication</h2>
      <br />
      <p>
        Subject to User’s compliance with these Terms, the User is authorized to
        publish and/or disclose the Result, provided that any publication by the
        User or by any third party on the Result or on the Service shall comply
        with the following rules: (i) express reference to www.open-spice.com;
        (ii) no reference to any of the member or founder of the SPICE
        initiative, without the prior written consent of such member; (iii) no
        use of any kind of the SPICE trademark or logo; (iv) no false or
        misleading information regarding the Result, the SPICE Methodology, the
        SPICE initiative, its members or founders, as well as their scope and
        purpose; (v) the Result must not be used for any comparative assertion,
        claim or representation regarding any product, whether or not it has
        been subject to evaluation on the Service; (vi) the publication should
        not contain anything which could be harmful for any member or founder of
        the SPICE Initiative or for SPICE itself.
      </p>
      <br />
      <h2>5. Data</h2>
      <br />
      <h3>5.1. SPICE Data</h3>
      <br />
      <p>
        As part of the Service, SPICE generates, collects and process certain
        data regarding use of the Service by the Users, including data entered
        by the User into the Service.
      </p>

      <p>
        Data entered by the User for environmental footprint calculation, as
        well as the Result, are not registered and stored by the Service, and
        thus are not disclosed to SPICE members or founders.
      </p>

      <p>
        Other data regarding use of the Service, such as User’s information,
        statistics on the use of the Service by the User, etc., may be disclosed
        and used by SPICE, including its members and founders, for their own
        purposes.
      </p>
      <br />
      <h3>5.2. Personal Data</h3>
      <br />
      <p>
        SPICE and the User undertake to comply with the application regulation
        regarding personal data processing, resulting in particular from
        Regulation 2016/679/EU of 27 April 2016, that apply to them, in their
        respective capacity as independent data controllers of the personal data
        that they exchange in the course of the Service, specifically the
        business contacts information.
      </p>

      <p>
        SPICE and the User undertake in particular to inform the data subjects,
        to respect their rights and to take all sufficient technical and
        organizational safeguards in order to preserve the security, the
        integrity and the confidentiality of the personal data.
      </p>

      <p>
        SPICE processes personal data about the User (identity, contact details,
        entity, technical and browsing data, and statistics on the use of the
        Service), on the basis of its legitimate interest and for performance of
        the Service in accordance with these Terms, for the purposes of
        providing the Service to the User, managing the account, monitor the use
        of the Service by the User and provide the User with information on the
        Service and newsletters.
      </p>

      <p>
        User’s personal data are accessible by Quantis’ personnel as well as
        service providers, on a need-to-know basis, for the purpose of providing
        and monitoring the Service. User’s personal data may also be shared with
        SPICE members and founders, for the purpose of reporting on the use of
        the Service.
      </p>

      <p>
        User’s personal data is not transferred to countries located outside of
        the European Union. If such transfer were to occur, and in the event
        that the recipient country does not ensure a level of data protection
        equivalent to that of the European Union, SPICE undertakes to take all
        appropriate guarantees, either on the basis of an adequacy decision or,
        in the absence of such a decision, on the basis of appropriate
        guarantees, such as binding corporate rules or standard data protection
        clauses.
      </p>

      <p>
        SPICE will only retain User’s personal data for as long as necessary to
        fulfil the purposes for which such data were collected, including for
        the purposes of satisfying any legal, accounting, or reporting
        requirements. As a rule, User’s personal data is retained until the
        removal of the User’s account from the Service. Specifically, for
        purposes of electronic marketing, SPICE may keep the User’s contact
        details for a maximum period of three years after the User’s latest use
        of the Service.
      </p>

      <p>
        In accordance with the applicable regulation, the User has the right to
        access, rectify, delete, if any limit and ask for the portability of
        his/her personal data as well as a right to object to the processing.
        The User may also define guidelines for the use of his/her personal data
        in the event of death. These rights can be exercised directly with
        SPICE, by using the form accessible at: [URL]. The User also has the
        right to lodge a complaint at the relevant supervisory data protection
        authority.
      </p>
      <br />
      <h2>6. Warranties</h2>
      <br />
      <p>
        The Service is provided “as is”. To the maximum extent permitted by law,
        SPICE expressly disclaims any guarantees, warranties, conditions or
        other terms of any kind, including, without limitation, any warranty as
        to accuracy, timeliness, completeness, results, performance, freedom
        from errors, bugs, virus or performance interruption, title,
        non-infringement, satisfactory quality, quality of information, quiet
        enjoyment, merchantability and fitness for a particular purpose.
      </p>
      <br />
      <h2>7. Liability</h2>
      <br />
      <p>
        SPICE and its members or cofounders, including their respective
        officers, directors, employees, shareholders or agents, as well as
        affiliates, licensors, contractors and suppliers, exclude all liability
        and responsibility for any amount or kind of loss or damage that may
        result to the User or a third party (including any direct or indirect
        damage, any loss of income, savings, profits revenues, data, goodwill,
        or reputation, inaccuracy of any data, cost of procurement of substitute
        goods, services or software, howsoever arising, in connection with (i)
        the Service, including the SPICE Initiative and the Result, (ii) the
        use, inability to use the Service or the Result (iii) any websites
        linked to this Service or the material on such linked websites.
      </p>

      <p>
        The sole and exclusive remedy of the User for any of the foregoing or
        any dispute with SPICE and its members or cofounders, including their
        respective officers, directors, employees, shareholders or agents, as
        well as affiliates, licensors, contractors and suppliers, is to
        discontinue his/her use of the Service.
      </p>
      <br />
      <h2>8. Suspension and interruption of the Service</h2>
      <br />
      <p>
        SPICE reserves the right at any time and/or from time to time, (i) to
        restrict or terminate the User’s access to, or use of, the Service (or
        any part thereof), and (ii) to modify, suspend or discontinue,
        temporarily or permanently the Service (or any part thereof), for any
        reason and with or without notice. SPICE shall not be liable to the User
        or any third party for such restriction, termination, modification,
        suspension or discontinuance of the Service.
      </p>
      <br />
      <h2>9. General</h2>
      <br />
      <h3>9.1. Entire agreement</h3>
      <br />
      <p>
        These Terms and any other legal notices published by SPICE on the
        Service shall constitute the entire agreement and understanding between
        the User and SPICE concerning the use of the Service.
      </p>
      <br />
      <h3>9.2. Severability</h3>
      <br />
      <p>
        If any provision of these Terms is held to be invalid, illegal or
        unenforceable in any respect by a court of competent jurisdiction, it
        shall not affect the validity of the remaining provisions of these
        Terms, which shall remain in full force and effect and enforceable.
      </p>
      <br />
      <h3>9.3. Waiver</h3>
      <br />
      <p>
        No waiver of any term of these Terms shall be deemed a further or
        continuing waiver of such term or any other term, and SPICE failure to
        assert any right or provision under these Terms, as well as any act,
        delay, omission or acquiescence, shall not constitute a waiver of such
        right or provision.
      </p>
      <br />
      <h3>9.4. Modification</h3>
      <br />
      <p>
        SPICE reserves the right, in its sole discretion, to change these Terms
        at any time by posting revised terms on the Service. The User shall
        check the date of these Terms (which appears at the top of this page)
        and review any changes since the last version. The continued use of this
        Service by the User following the posting of changes to these Terms
        other policies means the User accepts the changes.
      </p>
      <br />
      <h3>9.5. Governing Law – Dispute Resolution</h3>
      <br />
      <p>
        These Terms shall be governed by and construed in accordance with French
        law.
      </p>

      <p>
        SPICE and the User undertake to make all possible effort to resolve
        amicably any disputes arising from the interpretation, performance or
        termination of these Terms.
      </p>

      <p>
        If a settlement cannot be reached within three (3) months of the date of
        the initial notification related to the dispute, disputes arising under
        or in relation to these Terms, shall be subject to the exclusive
        jurisdiction of the competent courts of Paris (France).
      </p>
      <br />
      <h2>10. How to contact SPICE</h2>
      <br />
      <p>
        For any questions or comments about these Terms or the Service, the User
        can contact SPICE by using the form accessible at
        <a href="https://open-spice.com/contact/"
          >https://open-spice.com/contact/</a
        >.
      </p>
    </div>
  </div>
</ng-template>
