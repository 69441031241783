<div
  *ngIf="translationService.translationsLoaded | async"
  class="container-fluid background-colored"
  (window:resize)="sidebarService.onResize($event)"
>
  <div class="row full-height">
    <div id="sidebar" class="sidebar col-lg-3 min-vh-100 position-fixed">
      <sidebar-component [collapsible]="false"></sidebar-component>
    </div>
    <div id="sidebarCollapsible" class="sidebar min-vh-100">
      <sidebar-component [collapsible]="true"></sidebar-component>
    </div>
    <div id="overlay" (click)="sidebarService.closeSidebar()"></div>

    <div id="projectMain" class="col-lg-9 offset-lg-3 project-main">
      <button
        id="openCollapsibleSidebar"
        class="btn-color"
        (click)="sidebarService.openPackDefSidebar()"
      >
        <em class="fas fa-bars"></em>
      </button>
      <div class="col-md-10 project-container">
        <div class="back">
          <button class="btn btn-secondary back-btn" (click)="goBack()">
            <em class="fas fa-arrow-left"></em
            >{{ "interface_back" | translationPipe }}
          </button>
        </div>
        <div class="project-title">
          <span class="project-edit">
            <span class="project-name">SPICE Recycled Content Appendix</span>
          </span>
        </div>
        <div class="explanation-container">
          <iframe
            src="assets/docs/SPICE_Recycled-content-appendix_v1_202204.pdf"
            width="100%"
            height="700"
          ></iframe>
        </div>
      </div>
    </div>
  </div>
</div>
