import { Injectable } from '@angular/core';
import { DownloadService } from './download-service';

@Injectable({
  providedIn: 'root',
})
export class CsvExportService {
  static exportToCsv(filename: string, rows: Record<string, any>[]) {
    if (!rows || !rows.length) {
      return;
    }
    const separator = ',';
    const keys = Object.keys(rows[0]).filter((key) => key !== 'id');
    const csvData =
      keys.join(separator) +
      '\n' +
      rows
        .map((row: Record<string, any>) => {
          return keys
            .map((k) => {
              let cell: any;
              if (row[k] === null || row[k] === undefined) cell = '';
              else {
                cell = row[k] as string;
              }
              if (cell instanceof Date) cell = cell.toLocaleString();
              else if (cell instanceof String) cell = cell.replace(/"/g, '""');
              if (cell instanceof String && cell.search(/("|,|\n)/g) >= 0) {
                cell = `"${cell.toString()}"`;
              }
              return cell as string;
            })
            .join(separator);
        })
        .join('\n');

    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    DownloadService.download(filename, blob);
  }
}
