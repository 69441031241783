<ng-template #emailformmodal let-modal>
  <div class="modal-content">
    <div class="modal-header modal-spacing">
      <div class="modal-title">{{ title }}</div>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        aria-label="Close"
        (click)="abort()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div *ngIf="this.state == State.FORGOT_PASSWORD">
      <email-form-forgot-password (answer)="onSubmitForgotPassword($event)">
      </email-form-forgot-password>
    </div>
    <div *ngIf="this.state == State.FORGOT_PASSWORD_SUBMIT">
      <email-form-forgot-password-validation
        [email]="email"
        (success)="onSuccess($event)"
      >
      </email-form-forgot-password-validation>
    </div>
    <div *ngIf="this.state == State.SUCCESS">
      <div class="modal-body break-word modal-spacing">
        {{ "interface_change_password_success" | translationPipe }}
      </div>
      <div class="modal-footer modal-footer-center">
        <button type="button" class="btn btn-primary" (click)="close()">
          {{ "interface_ok" | translationPipe }}
        </button>
      </div>
    </div>
  </div>
</ng-template>
