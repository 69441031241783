import {
  Component,
  Inject,
  LOCALE_ID,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Location } from '@angular/common';
import {
  NgbModal,
  NgbModalOptions,
  NgbModalRef,
} from '@ng-bootstrap/ng-bootstrap';
import { TranslationService } from '@/app/services/translationService/translation.service';

@Component({
  selector: 'app-changelog-modal',
  templateUrl: './changelog-modal.component.html',
  styleUrls: ['./changelog-modal.component.css'],
})
export class ChangelogModalComponent {
  readonly MODAL_OPTIONS: NgbModalOptions = { size: 'lg' };
  modal: NgbModalRef | undefined;

  @ViewChild('changelogModal')
  templateModal!: TemplateRef<any>;

  constructor(
    public translationService: TranslationService,
    private modalService: NgbModal,
    private location: Location,
    @Inject(LOCALE_ID) private locale: string
  ) {}

  open() {
    this.modal = this.modalService.open(this.templateModal, this.MODAL_OPTIONS);
  }

  close() {
    this.modal?.close();
  }
}
