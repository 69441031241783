import { Company } from '@/app/model/data/company';

abstract class AbstractUser {
  id!: number;
  firstname!: string;
  lastname!: string;
  email!: string;
  //TO REMOVE
  locale!: number;

  get displayedName(): string {
    return this.firstname + ' ' + this.lastname;
  }
}

class GuestUser extends AbstractUser {
  organization!: string;
}

class User extends AbstractUser {
  static readonly LENGTH_2: number = 2;
  static readonly LENGTH_4: number = 4;
  static readonly LENGTH_8: number = 8;
  static readonly LENGTH_50: number = 50;
  static readonly LENGTH_160: number = 160;
  static readonly LENGTH_255: number = 255;

  brand!: string;
  description!: string;
  enabled = true;

  company!: Company;
  authorities!: string[];

  get fullnameWithCompanyName(): string {
    return (
      this.firstname + ' ' + this.lastname + ' (' + this.company.name + ')'
    );
  }
}

enum UserStatus {
  // eslint-disable-next-line @typescript-eslint/indent
  CONFIRMED = 'CONFIRMED',
  FORCE_CHANGE_PASSWORD = 'FORCE_CHANGE_PASSWORD',
}

export { User, GuestUser, UserStatus };
