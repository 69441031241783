import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  Output,
  EventEmitter,
  ViewChild,
  HostListener,
} from '@angular/core';
import { FinishingProcess } from '@/app/model/packaging-model/finishing-process';
import { FinishingProcessType } from '@/app/model/data/finishing-process-type';
import { FinishingProcessService } from '@/app/services/finishing-process.service';
import { ProjectService } from '@/app/services/project.service';
import {
  UntypedFormGroup,
  Validators,
  UntypedFormBuilder,
} from '@angular/forms';
import { FormUtils } from '@/app/utils/form-utils';
import { PkgComponent } from '@/app/model/packaging-model/component/pkg-component';
import { ConfirmModalComponent } from '@/app/pages/confirm-modal/confirm-modal.component';
import assert from 'assert';
import { CustomValidators } from '@/app/utils/custom-validators';

@Component({
  selector: 'app-finishing-process-row, [app-finishing-process-row]',
  templateUrl: './finishing-process-row.component.html',
  styleUrls: ['./finishing-process-row.component.css'],
})
export class FinishingProcessRowComponent implements OnInit, OnDestroy {
  // @ts-ignore
  @Input() processOriginal: FinishingProcess;
  // @ts-ignore
  @Input() componentOriginal: PkgComponent;
  // @ts-ignore
  @Input() finishingProcessTypes: Array<FinishingProcessType>;

  @Output() changeMade = new EventEmitter<FinishingProcess>();

  @ViewChild(ConfirmModalComponent)
  // @ts-ignore
  private confirmModalComponent: ConfirmModalComponent;

  // @ts-ignore
  saveTimer: NodeJS.Timer;
  // @ts-ignore
  processLocal: FinishingProcess;
  // @ts-ignore
  typeForm: UntypedFormGroup;
  // @ts-ignore
  surfaceForm: UntypedFormGroup;
  // @ts-ignore
  forms: Array<UntypedFormGroup>;
  get isReadOnly(): boolean {
    return this._projectService.isReadOnly;
  }

  @HostListener('window:beforeunload', ['$event'])
  onQuit() {
    this.stopSaveTimer();
    this.saveAll();
  }

  constructor(
    private _finishingProcessService: FinishingProcessService,
    private _projectService: ProjectService,
    private formBuilder: UntypedFormBuilder
  ) {}

  ngOnInit() {
    this.startSaveTimer();
    this.processLocal = FinishingProcess.copy(this.processOriginal);
    this.initForms();
    this.updateReadOnlyForms();
  }

  updateReadOnlyForms() {
    this.forms.forEach((form) => {
      if (this.isReadOnly) {
        form.disable();
      } else {
        form.enable();
      }
    });
  }

  ngOnDestroy() {
    this.onQuit();
  }

  startSaveTimer() {
    if (this.saveTimer == null)
      this.saveTimer = setInterval((_) => this.saveAll(), 5000);
  }

  stopSaveTimer(): void {
    if (this.saveTimer != null) {
      clearInterval(this.saveTimer);
      // @ts-ignore
      this.saveTimer = null;
    }
  }

  saveAll(): void {
    if (
      this.processLocal != null &&
      this.processLocal.inputDataHaveChanged(this.processOriginal)
    ) {
      this.changeMade.emit(this.processLocal);
    }
  }

  deleteProcess(): void {
    this.confirmModalComponent.open();
  }

  async confirmOrAbortDeletion(answer: boolean): Promise<void> {
    this.confirmModalComponent.close();
    if (answer)
      await this._finishingProcessService.deleteFinishingProcess(
        this.componentOriginal,
        this.processLocal
      );
  }

  initForms(): void {
    this.typeForm = this.formBuilder.group({
      finishingProcessType: [
        this.processLocal.finishingProcessType.id,
        Validators.required,
      ],
    });
    this.surfaceForm = this.formBuilder.group({
      surface: [
        this.processLocal.surface,
        Validators.compose([
          Validators.required,
          CustomValidators.nonZeroDecimalValidator.compose,
        ]),
      ],
    });
    FormUtils.updateModelFromFieldValue<number>(
      this.typeForm.controls['finishingProcessType'],
      (v) => this.updateProcessLocalFinishing(v)
    );
    FormUtils.updateModelFromFieldValue<number>(
      this.surfaceForm.controls['surface'],
      (v) => this.updateProcessLocalSurface(v)
    );
    this.forms = [this.typeForm, this.surfaceForm];
  }

  getHelpBoxTranslation() {
    return this._finishingProcessService.finishingProcessHelp.get(
      this.processLocal.finishingProcessType.label
    );
  }

  updateProcessLocalFinishing(v: number) {
    const finishingProcess = this.finishingProcessTypes.find((s) => s.id == v);
    assert(finishingProcess !== undefined);
    this.processLocal.finishingProcessType = finishingProcess;
    this.changeMade.emit(this.processLocal);
  }

  updateProcessLocalSurface(v: number) {
    this.processLocal.surface = v;
    this.changeMade.emit(this.processLocal);
  }

  protected readonly CustomValidators = CustomValidators;
}
