import { Injectable } from '@angular/core';
import { TranslationApiService } from 'src/app/api/translation/translation-api.service';
import { LoginService } from 'src/app/services/login/login.service';
import { UsualFeaturesAndRules } from '@/app/services/featureService/usual-features-and-rules';
import { ActivationRule } from '@/app/services/featureService/activation-rule';
import { Feature } from '@/app/services/featureService/feature';
import { FeatureService } from '@/app/services/featureService/feature.service';

@Injectable({
  providedIn: 'root',
})
export class TranslationService {
  private static TRANSLATION_FEATURE_PARTS: Array<string> = [
    UsualFeaturesAndRules.ONLINE_FEATURE_PART,
    'translation',
  ];

  public static TRANSLATION_FEATURE: Feature = new Feature(
    TranslationService.TRANSLATION_FEATURE_PARTS
  );

  private static TRANSLATION_FEATURE_RULE: ActivationRule =
    ActivationRule.allowInCascadeFeature(
      TranslationService.TRANSLATION_FEATURE_PARTS
    );

  allTranslations: any;
  // @ts-ignore
  translationsLoaded: Promise<boolean>;

  constructor(
    private _translationApi: TranslationApiService,
    private _loginService: LoginService,
    private _featureService: FeatureService
  ) {
    this._featureService.declareFeature(TranslationService.TRANSLATION_FEATURE);

    if (_featureService.isActive(UsualFeaturesAndRules.ONLINE_FEATURE)) {
      this.loadAllTranslationsForLocaleId(0).catch((err) => console.error(err));
    }
  }

  async getUser() {
    while (this._loginService.currentUser == null) {
      await new Promise((resolve) => setTimeout(resolve, 200));
    }
    return this._loginService.currentUser;
  }

  async loadAllTranslationsForUser() {
    const user = await this.getUser();
    const localeId: number = user.locale;
    this.allTranslations =
      await this._translationApi.getAllTranslationsForLocaleId(localeId);
    this.translationsLoaded = Promise.resolve(true);
  }

  private async loadAllTranslationsForLocaleId(localeId: number) {
    this.allTranslations =
      await this._translationApi.getAllTranslationsForLocaleId(localeId);
    this.translationsLoaded = Promise.resolve(true);
    this._featureService.declareRule(
      TranslationService.TRANSLATION_FEATURE_RULE
    );
  }

  getTranslationValueForLabel(label: string): string {
    if (this.allTranslations == null) {
      return label;
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
      return this.allTranslations[label];
    }
  }
}
