import { PublishedScenario } from '@/app/model/packaging-model/published-scenario';
import { PublishedScenarioStatus } from '@/app/model/packaging-model/published-scenario_status';
import { Page } from '@/app/model/pagination/page';
import { FilterCriterion } from '@/app/model/search/filter-criterion';
import { Sorting, SortingDirection } from '@/app/model/search/sorting';
import { User } from '@/app/model/user/user';
import { LoginService } from '@/app/services/login/login.service';
import { ProjectService } from '@/app/services/project.service';
import { TranslationService } from '@/app/services/translationService/translation.service';
import { Pager } from '@/app/utils/pager';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';
import { EditPublishedScenarioModalComponent } from './edit-published-scenario-modal/edit-published-scenario-modal.component';

@Component({
  selector: 'app-scenario-library-management',
  templateUrl: './scenario-library-management.component.html',
  styleUrls: ['./scenario-library-management.component.css'],
})
export class ScenarioLibraryManagementComponent implements OnInit {
  @ViewChild(EditPublishedScenarioModalComponent)
  private editPublishedScenarioComponent!: EditPublishedScenarioModalComponent;

  @ViewChild(ConfirmModalComponent)
  private confirmModal!: ConfirmModalComponent;

  // @ts-ignore
  publishedScenarios: Array<PublishedScenario> = [];

  currentPublishedScenario: PublishedScenario | undefined = undefined;

  // @ts-ignore
  page: number;
  // @ts-ignore
  nameFilter: string;
  // @ts-ignore
  sorting: Sorting;
  // @ts-ignore
  pager: Pager;

  constructor(
    public projectService: ProjectService,
    private loginService: LoginService,
    private router: Router,
    public translationService: TranslationService
  ) {}

  async ngOnInit() {
    const userPromise: Promise<User> = this.waitForUser();
    const user: User = await userPromise;

    if (!this.loginService.isAdminOrSuperAdmin()) {
      // TODO check for eventually missing await
      this.router
        .navigate(['/error', 'not-found'])
        .catch((err) => console.error(err));
    } else {
      this.resetPagination();
      this.launchSearch();
      this.setPage(1);
    }
  }

  async waitForUser(): Promise<User> {
    while (this.loginService.currentUser == null) {
      await new Promise((resolve) => setTimeout(resolve, 100));
    }
    return this.loginService.currentUser;
  }

  resetPagination() {
    this.page = 0;
    this.sorting = new Sorting(SortingDirection.DESC, 'exportedAt');
    this.nameFilter = '';
  }

  sortingDescIsDisplayed(column: string) {
    return this.sorting.equals(new Sorting(SortingDirection.ASC, column));
  }

  sort(direction: string, column: string) {
    // @ts-ignore
    this.sorting = new Sorting(SortingDirection[direction], column);
    this.launchSearch();
  }

  setPage(page: number) {
    this.page = page - 1;
    this.launchSearch();
  }

  launchSearch() {
    const filterCriteria: Array<FilterCriterion> = [];
    if (this.nameFilter != '') {
      filterCriteria.push(
        new FilterCriterion('searchContent', this.nameFilter)
      );
    }
    const allStatus = Array.from([
      PublishedScenarioStatus[PublishedScenarioStatus.APPROVED],
      PublishedScenarioStatus[PublishedScenarioStatus.PENDING],
    ]);
    this.projectService
      .getScenarioLibrary(this.page, filterCriteria, this.sorting, allStatus)
      .subscribe((page: Page<PublishedScenario>) => {
        this.publishedScenarios = page.content;
        this.pager = Pager.getPager(
          page.totalElements,
          page.number + 1,
          page.size
        );
      });
  }

  openModalToEditPublishedScenario(publishedScenario: PublishedScenario) {
    this.currentPublishedScenario = publishedScenario;
    this.editPublishedScenarioComponent?.open();
  }

  deletePublishedScenario(publishedScenario: PublishedScenario) {
    this.currentPublishedScenario = publishedScenario;
    this.confirmModal.open();
  }

  async confirmOrAbortDeletion(answer: boolean): Promise<void> {
    this.confirmModal.close();
    if (answer && this.currentPublishedScenario != undefined) {
      await this.projectService.deletePublishedScenario(
        this.currentPublishedScenario
      );
      this.publishedScenarios = this.publishedScenarios.filter(
        (ps) => ps.id != this.currentPublishedScenario?.id
      );
    }
  }

  getPublishedScenarioArgs(): Map<string, string> {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return new Map()
      .set('$1', this.currentPublishedScenario?.name)
      .set(
        '$2',
        this.currentPublishedScenario?.exportedBy?.displayedName ??
          '(deleted user)'
      );
  }
}
