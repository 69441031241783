import { Injectable } from '@angular/core';

import { Scenario } from '../model/packaging-model/scenario';
import { PkgComponent } from '../model/packaging-model/component/pkg-component';
import { PkgComponentApiService } from '../api/pkg-component-api.service';
import { ComponentLevel } from '../model/packaging-model/component/component-level.enum';
import { ProjectService } from '@/app/services/project.service';
import { Router } from '@angular/router';
import { GuestService } from '@/app/services/guest.service';
import { GlobalService } from '@/app/services/global.service';
import { TranslationService } from '@/app/services/translationService/translation.service';
import assert from 'assert';

@Injectable({
  providedIn: 'root',
})
export class PkgComponentService {
  // @ts-ignore
  selectedPkgComponentId: number;
  // @ts-ignore
  selectedPkgComponent: PkgComponent;

  constructor(
    private router: Router,
    private projectservice: ProjectService,
    private pkgComponentApi: PkgComponentApiService,
    private globalService: GlobalService,
    private translationService: TranslationService,
    private guestService: GuestService
  ) {}

  selectPkgComponent(pkgComponentId: number) {
    // @ts-ignore
    const pkgComponent: PkgComponent =
      this.projectservice.scenarioPkgComponents.find(
        (c: PkgComponent) => c.id == pkgComponentId
      );

    if (pkgComponent != null) {
      this.selectedPkgComponentId = pkgComponentId;
      // @ts-ignore
      this.selectedPkgComponent =
        this.projectservice.scenarioPkgComponents.find(
          (p: PkgComponent) => p.id == this.selectedPkgComponentId
        );
    } else {
      void this.router.navigateByUrl('');
      throw 'Component is not in current scenario';
    }
  }

  resetSelectedPkgComponent(): void {
    // @ts-ignore
    this.selectedPkgComponent = null;
  }

  async createPackagingComponent(
    scenario: Scenario,
    componentLevel: ComponentLevel
  ): Promise<PkgComponent> {
    this.projectservice.selectedScenario = scenario;
    let pkgcomponent: PkgComponent = new PkgComponent();
    pkgcomponent.componentLevel = componentLevel;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    pkgcomponent.name = this.translationService.getTranslationValueForLabel(
      'interface_default_component_name'
    );
    // @ts-ignore
    pkgcomponent.productionCountry = this.globalService
      .getCountries()
      .find((c) => c.label == 'cFRA');
    pkgcomponent.numberOfThisComponentInPackaging = 1;

    if (this.guestService.isUserGuest()) {
      pkgcomponent = this.guestService.createPackagingComponent(
        pkgcomponent,
        scenario,
        componentLevel
      );
    } else {
      let packagingId;
      switch (componentLevel) {
        case ComponentLevel.PRIMARY:
          packagingId = scenario.firstPrimaryPackaging?.id;
          assert(packagingId !== undefined);
          pkgcomponent = await this.pkgComponentApi.createPkgComponent(
            packagingId,
            pkgcomponent
          );
          break;
        case ComponentLevel.SECONDARY:
          packagingId = scenario.firstSecondaryPackaging?.id;
          assert(packagingId !== undefined);
          pkgcomponent = await this.pkgComponentApi.createPkgComponent(
            packagingId,
            pkgcomponent
          );
          break;
        case ComponentLevel.TERTIARY_BOX:
          packagingId = scenario.firstTertiaryPackagingBox?.id;
          assert(packagingId !== undefined);
          pkgcomponent = await this.pkgComponentApi.createPkgComponent(
            packagingId,
            pkgcomponent
          );
          break;
        case ComponentLevel.TERTIARY_PALLETIZATION:
          packagingId = scenario.firstTertiaryPackagingPalletization?.id;
          assert(packagingId !== undefined);
          pkgcomponent = await this.pkgComponentApi.createPkgComponent(
            packagingId,
            pkgcomponent
          );
          break;
      }
    }
    scenario.addComponentToLevel(pkgcomponent, componentLevel);
    this.projectservice.saveProjectLocallyIfGuest();
    this.selectPkgComponent(pkgcomponent.id);
    return pkgcomponent;
  }

  async updatePkgComponent(
    updatedComponent: PkgComponent,
    componentOriginal: PkgComponent
  ): Promise<void> {
    if (this.guestService.isUserGuest()) {
      componentOriginal.merge(updatedComponent);
      this.projectservice.saveProjectLocallyIfGuest();
    } else {
      if (this.isComponentInSelectedScenario(componentOriginal.id)) {
        const response: PkgComponent =
          await this.pkgComponentApi.updatePkgComponent(
            componentOriginal.id,
            updatedComponent
          );
        componentOriginal.merge(response);
      }
    }
  }

  isComponentInSelectedScenario(id: number): boolean {
    return (
      this.projectservice.selectedScenario.components.find((c) => c.id == id) !=
      null
    );
  }

  async deletePkgComponent(pkgcomponent: PkgComponent): Promise<void> {
    await this.pkgComponentApi.deletePkgComponent(pkgcomponent.id);
  }

  removeComponentFromSelectedScenario(component: PkgComponent) {
    switch (component.componentLevel) {
      case ComponentLevel.PRIMARY:
        if (
          this.projectservice.selectedScenario.firstPrimaryPackaging !==
          undefined
        )
          this.projectservice.selectedScenario.firstPrimaryPackaging.components =
            this.projectservice.selectedScenario.firstPrimaryPackaging.components.filter(
              (c: PkgComponent) => c.id != component.id
            );
        break;
      case ComponentLevel.SECONDARY:
        if (
          this.projectservice.selectedScenario.firstSecondaryPackaging !==
          undefined
        )
          this.projectservice.selectedScenario.firstSecondaryPackaging.components =
            this.projectservice.selectedScenario.firstSecondaryPackaging.components.filter(
              (c: PkgComponent) => c.id != component.id
            );
        break;
      case ComponentLevel.TERTIARY_BOX:
        if (
          this.projectservice.selectedScenario.firstTertiaryPackagingBox !==
          undefined
        )
          this.projectservice.selectedScenario.firstTertiaryPackagingBox.components =
            this.projectservice.selectedScenario.firstTertiaryPackagingBox.components.filter(
              (c: PkgComponent) => c.id != component.id
            );
        break;
      case ComponentLevel.TERTIARY_PALLETIZATION:
        if (
          this.projectservice.selectedScenario
            .firstTertiaryPackagingPalletization !== undefined
        )
          this.projectservice.selectedScenario.firstTertiaryPackagingPalletization.components =
            this.projectservice.selectedScenario.firstTertiaryPackagingPalletization.components.filter(
              (c: PkgComponent) => c.id != component.id
            );
        break;
    }
    this.projectservice.saveProjectLocallyIfGuest();
  }
}
