<em
  *ngIf="isSortingAsc(sortedBy)"
  class="fas fa-sort-down ml-1"
  (click)="onSort(SortingDirection.ASC, sortedBy)"
></em>
<em
  *ngIf="isSortingDesc(sortedBy)"
  class="fas fa-sort-up ml-1"
  (click)="onSort(SortingDirection.DESC, sortedBy)"
></em>
<em
  *ngIf="isSortingNone(sortedBy)"
  class="fa fa-sort ml-1 pointer"
  (click)="onSort(SortingDirection.ASC, sortedBy)"
></em>
