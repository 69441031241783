import { SingleUseProject } from '@/app/model/packaging-model/project/single-use-project';
import { PublishedScenario } from '@/app/model/packaging-model/published-scenario';
import { PublishedScenarioStatus } from '@/app/model/packaging-model/published-scenario_status';
import { Page } from '@/app/model/pagination/page';
import { FilterCriterion } from '@/app/model/search/filter-criterion';
import { Sorting, SortingDirection } from '@/app/model/search/sorting';
import { User } from '@/app/model/user/user';
import { LoginService } from '@/app/services/login/login.service';
import { ProjectService } from '@/app/services/project.service';
import { TranslationService } from '@/app/services/translationService/translation.service';
import { Pager } from '@/app/utils/pager';
import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import {
  NgbModal,
  NgbModalOptions,
  NgbModalRef,
} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-scenario-library',
  templateUrl: './scenario-library.component.html',
  styleUrls: ['./scenario-library.component.css'],
})
export class ScenarioLibraryComponent {
  private readonly MODAL_OPTIONS: NgbModalOptions = {
    size: 'xl',
    backdrop: 'static',
  };

  @Input() project!: SingleUseProject;
  // @ts-ignore
  publishedScenarios: Array<PublishedScenario> = [];

  @ViewChild('importscenariomodal')
  private readonly templateModal!: TemplateRef<any>;
  private modal: NgbModalRef | undefined;

  // @ts-ignore
  page: number;
  // @ts-ignore
  nameFilter: string;
  // @ts-ignore
  sorting: Sorting;
  // @ts-ignore
  pager: Pager;

  selectedPublishedScenarios: PublishedScenario[] = [];

  allSelected = false;
  someSelected = false;

  constructor(
    public projectService: ProjectService,
    private loginService: LoginService,
    private router: Router,
    public translationService: TranslationService,
    private modalService: NgbModal
  ) {}

  open(): void {
    this.resetDialog();
    this.modal = this.modalService.open(this.templateModal, this.MODAL_OPTIONS);
  }

  private resetDialog(): void {
    this.resetPagination();
    this.setPage(1);
    this.selectedPublishedScenarios = [];
    this.allSelected = false;
    this.someSelected = false;
  }

  private close(): void {
    this.modal?.close();
  }

  // region SELECT

  onSelectAll(event: Event): void {
    const checked = (event.target as unknown as { checked: boolean }).checked;
    if (checked)
      this.publishedScenarios.forEach((scenario: PublishedScenario) => {
        if (!this.scenarioIsAlreadySelected(scenario.id)) {
          this.selectedPublishedScenarios.push(scenario);
        }
      });
    else {
      this.selectedPublishedScenarios = [];
    }
    this.updateSelectAllCheckbox();
  }

  scenarioIsAlreadySelected(scenarioId: number) {
    let res = false;
    this.selectedPublishedScenarios.forEach((s: PublishedScenario) => {
      if (s.id == scenarioId) res = true;
    });
    return res;
  }

  onSelect(scenario: PublishedScenario): void {
    if (this.scenarioIsAlreadySelected(scenario.id)) {
      let index = 0;
      this.selectedPublishedScenarios.forEach((s) => {
        if (s.id === scenario.id)
          index = this.selectedPublishedScenarios.indexOf(s);
      });
      this.selectedPublishedScenarios.splice(index, 1);
    } else {
      this.selectedPublishedScenarios.push(scenario);
    }
    this.updateSelectAllCheckbox();
  }

  private updateSelectAllCheckbox() {
    const selectedCount = this.selectedPublishedScenarios.length;
    this.someSelected =
      selectedCount > 0 && selectedCount < this.publishedScenarios.length;
    this.allSelected = selectedCount === this.publishedScenarios.length;
  }

  // endregion

  async waitForUser(): Promise<User> {
    while (this.loginService.currentUser == null) {
      await new Promise((resolve) => setTimeout(resolve, 100));
    }
    return this.loginService.currentUser;
  }

  resetPagination() {
    this.page = 0;
    this.sorting = new Sorting(SortingDirection.ASC, 'name');
    this.nameFilter = '';
  }

  sortingDescIsDisplayed(column: string) {
    return this.sorting.equals(new Sorting(SortingDirection.ASC, column));
  }

  sort(direction: string, column: string) {
    // @ts-ignore
    this.sorting = new Sorting(SortingDirection[direction], column);
    this.launchSearch();
  }

  setPage(page: number) {
    this.page = page - 1;
    this.launchSearch();
  }

  launchSearch() {
    const filterCriteria: Array<FilterCriterion> = [];
    const statuses: Array<string> = [];
    if (this.nameFilter != '') {
      filterCriteria.push(
        new FilterCriterion('searchContent', this.nameFilter)
      );
    }

    statuses.push(PublishedScenarioStatus[PublishedScenarioStatus.APPROVED]);

    if (this.loginService.isAdminOrSuperAdmin()) {
      statuses.push(PublishedScenarioStatus[PublishedScenarioStatus.PENDING]);
    }

    this.projectService
      .getScenarioLibrary(this.page, filterCriteria, this.sorting, statuses)
      .subscribe((page: Page<PublishedScenario>) => {
        this.publishedScenarios = page.content;
        this.pager = Pager.getPager(
          page.totalElements,
          page.number + 1,
          page.size
        );
      });
  }

  onCancel(): void {
    this.close();
    this.resetDialog();
  }

  async onSave(): Promise<void> {
    for (const publishedScenario of this.selectedPublishedScenarios) {
      await this.projectService.copyFromLibrary(
        publishedScenario.id,
        this.project.id
      );
    }
    this.onCancel();
  }

  getStringFromStatus(status: number) {
    return PublishedScenarioStatus[status];
  }
}
