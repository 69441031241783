<div id="GuestAccessBox">
  <div *ngIf="isState(State.INIT)">
    <form
      [formGroup]="checkoutForm"
      id="loginForm"
      (ngSubmit)="onSubmitGuestUser(checkoutForm.value)"
    >
      <div class="smaller-font">
        <span
          [innerHTML]="'interface_about_tool_lite_1' | translationPipe"
        ></span>
        <a
          class="contact-link link"
          target="_blank"
          href="https://open-spice.com/contact/"
          >{{ "interface_about_tool_lite_2_underline" | translationPipe }}</a
        >
      </div>

      <p class="link text-right d-block">
        <a (click)="onHaveAlreadyValidationCode()">{{
          "interface_have_already_validation_code" | translationPipe
        }}</a>
      </p>

      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label for="firstname">
              {{ "interface_first_name" | translationPipe }}</label
            >
            <input
              id="firstname"
              class="form-control"
              name="firstname"
              type="text"
              formControlName="firstname"
              minlength="{{ CustomValidators.userNameValidator.minLength }}"
              maxlength="{{ CustomValidators.userNameValidator.maxLength }}"
            />
            <validation-messages
              [control]="checkoutForm.get('firstname')"
              class="form-invalid-message"
            >
            </validation-messages>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="lastname">{{
              "interface_last_name" | translationPipe
            }}</label>
            <input
              id="lastname"
              class="form-control"
              name="lastname"
              type="text"
              formControlName="lastname"
              minlength="{{ CustomValidators.userNameValidator.minLength }}"
              maxlength="{{ CustomValidators.userNameValidator.maxLength }}"
            />
            <validation-messages
              [control]="checkoutForm.get('lastname')"
              class="form-invalid-message"
            >
            </validation-messages>
          </div>
        </div>
      </div>
      <div class="form-group">
        <label for="organization"
          >{{ "interface_organization" | translationPipe }}
        </label>
        <input
          id="organization"
          class="form-control"
          name="organization"
          type="text"
          formControlName="organization"
          maxlength="{{ boundaries.organization.maxLength }}"
        />
        <validation-messages
          [control]="checkoutForm.get('organization')"
          class="form-invalid-message"
        >
        </validation-messages>
      </div>
      <div class="form-group">
        <label for="email">{{ "interface_email" | translationPipe }}</label>
        <input
          id="email"
          class="form-control"
          name="email"
          type="text"
          formControlName="email"
          minlength="{{ CustomValidators.emailValidator.minLength }}"
          maxlength="{{ CustomValidators.emailValidator.maxLength }}"
        />
        <validation-messages
          *ngIf="!emailAlreadyTaken"
          [control]="checkoutForm.get('email')"
          class="form-invalid-message"
        >
        </validation-messages>
        <div
          *ngIf="emailAlreadyTaken"
          class="form-invalid-message"
          style="padding-top: 4px; padding-left: 2px"
        >
          {{
            "interface_create_user_given_email_already_exists" | translationPipe
          }}
        </div>
      </div>
      <app-new-password-validation
        (formReady)="formInitialized('password', $event)"
      >
      </app-new-password-validation>
      <div class="form-group">
        <p class="link-cb">
          <input
            type="checkbox"
            name="acceptedTermsOfUse"
            formControlName="acceptedTermsOfUse"
            required
          />
          {{ "interface_agree_terms" | translationPipe }}
          <a (click)="openTermsOfUse()" class="link">{{
            "interface_terms_of_use" | translationPipe
          }}</a>
        </p>
        <validation-messages
          [control]="checkoutForm.get('acceptedTermsOfUse')"
          class="form-invalid-message"
        >
        </validation-messages>
      </div>

      <div class="form-text text-center errorMsg">{{ errorMessage }}</div>

      <div class="button-container">
        <button
          type="submit"
          class="btn btn-secondary guest-user-account-btn"
          [disabled]="!checkoutForm.valid"
        >
          {{ "interface_request_guest_account" | translationPipe }}
        </button>
      </div>
    </form>
  </div>

  <div *ngIf="isState(State.VERIFICATION_CODE)">
    <div>
      {{ emailSentBodyMessage() }}
    </div>
    <form
      [formGroup]="validateCodeForm"
      id="validateCodeForm"
      (ngSubmit)="onSubmitCode(validateCodeForm.value.code)"
    >
      <div class="form-group">
        <label for="code"
          >{{ "interface_activation_code" | translationPipe }}
        </label>
        <input
          id="code"
          class="form-control"
          name="code"
          type="text"
          formControlName="code"
        />
        <validation-messages
          [control]="validateCodeForm.get('code')"
          class="form-invalid-message"
        >
        </validation-messages>
      </div>

      <div class="form-text text-center errorMsg">{{ errorMessage }}</div>

      <div class="button-container">
        <button
          type="submit"
          class="btn btn-secondary guest-user-account-btn"
          [disabled]="!validateCodeForm.valid"
        >
          {{ "interface_validate_verification_code" | translationPipe }}
        </button>
      </div>
    </form>
  </div>
  <div *ngIf="isState(State.EMAIL_CODE_PROVIDED)">
    <form
      [formGroup]="haveAlreadyCodeFrom"
      id="haveAlreadyCodeFrom"
      (ngSubmit)="onSubmitHaveAlreadyCode(haveAlreadyCodeFrom.value)"
    >
      <div class="form-group">
        <label for="email">{{ "interface_email" | translationPipe }}</label>
        <input
          id="email"
          class="form-control"
          name="email"
          type="text"
          formControlName="email"
          minlength="{{ CustomValidators.emailValidator.minLength }}"
          maxlength="{{ CustomValidators.emailValidator.maxLength }}"
        />
        <validation-messages
          [control]="haveAlreadyCodeFrom.get('email')"
          class="form-invalid-message"
        >
        </validation-messages>
      </div>

      <div class="form-group">
        <label for="code"
          >{{ "interface_activation_code" | translationPipe }}
        </label>
        <input
          id="code"
          class="form-control"
          name="code"
          type="text"
          formControlName="code"
        />
        <validation-messages
          [control]="haveAlreadyCodeFrom.get('code')"
          class="form-invalid-message"
        >
        </validation-messages>
      </div>

      <div class="form-text text-center errorMsg">{{ errorMessage }}</div>

      <div class="button-container">
        <button
          type="submit"
          class="btn btn-secondary guest-user-account-btn"
          [disabled]="!haveAlreadyCodeFrom.valid"
        >
          {{ "interface_validate_verification_code" | translationPipe }}
        </button>
      </div>
    </form>
  </div>
  <div *ngIf="isState(State.SUCCESS)">
    {{ "interface_sign_up_success" | translationPipe }}
  </div>
</div>

<app-terms-of-use-modal></app-terms-of-use-modal>
