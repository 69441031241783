import { FinishingProcessType } from '../data/finishing-process-type';
import { Type } from 'class-transformer';

export class FinishingProcess {
  id!: number;
  surface!: number;

  @Type(() => FinishingProcessType)
  finishingProcessType!: FinishingProcessType;

  static copy(finishingProcess: FinishingProcess): FinishingProcess {
    const res: FinishingProcess = new FinishingProcess();
    res.id = finishingProcess.id;
    res.mergeAll(finishingProcess);
    return res;
  }

  mergeAll(updated: FinishingProcess | undefined) {
    if (updated != undefined) {
      this.surface = updated.surface;
      this.finishingProcessType = updated.finishingProcessType;
    }
  }

  public inputDataHaveChanged(original: FinishingProcess): boolean {
    return (
      this.surface != original.surface ||
      this.finishingProcessType.id != original.finishingProcessType.id
    );
  }
}
