<ng-container *ngIf="this.translationService.translationsLoaded | async">
  <div *ngIf="this.componentReady" class="col">
    <div class="user-management">
      <div class="top-bar">
        <div class="search-box">
          <input
            type="text"
            class="search-input"
            placeholder="{{ 'interface_search' | translationPipe }} "
            [formControl]="search"
          />
          <em class="fas fa-search mar-right"> </em>
        </div>
        <div class="export-btn">
          <button class="btn btn-secondary add-comp" (click)="exportList()">
            <em class="far fa-file-excel"></em>
            {{ "interface_export_excel_btn" | translationPipe }}
          </button>
        </div>
      </div>
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">
                {{ "interface_firstname" | translationPipe }}
                <app-sort-toggle
                  [sorting]="sorting"
                  sortedBy="firstname"
                  (toggle)="launchSearch()"
                ></app-sort-toggle>
              </th>
              <th scope="col">
                {{ "interface_lastname" | translationPipe }}
                <app-sort-toggle
                  [sorting]="sorting"
                  sortedBy="lastname"
                  (toggle)="launchSearch()"
                ></app-sort-toggle>
              </th>
              <th scope="col">
                {{ "interface_email" | translationPipe }}
                <app-sort-toggle
                  [sorting]="sorting"
                  sortedBy="email"
                  (toggle)="launchSearch()"
                ></app-sort-toggle>
              </th>
              <th scope="col">
                {{ "interface_organization" | translationPipe }}
                <app-sort-toggle
                  [sorting]="sorting"
                  sortedBy="organization"
                  (toggle)="launchSearch()"
                ></app-sort-toggle>
              </th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let user of users">
              <td>
                {{ user.firstname }}
              </td>
              <td>
                {{ user.lastname }}
              </td>
              <td>
                {{ user.email }}
              </td>
              <td>
                {{ user.organization }}
              </td>
              <td class="actions">
                <em
                  class="fas fa-trash"
                  (click)="openModalToDeleteGuestUser(user)"
                ></em>
              </td>
            </tr>
            <tr *ngIf="users.length == 0">
              <td colspan="5" class="text-center">
                {{ "interface_guest_user_not_found" | translationPipe }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div *ngIf="users.length > 0" class="paginationContainer">
        <app-pagination-bar
          [pager]="pager"
          (selectedPage)="setPage($event)"
        ></app-pagination-bar>
      </div>
    </div>
  </div>

  <app-confirm-modal
    [id]="'guest-user-delete'"
    [title]="'interface_guest_user_delete_title' | translationPipe"
    [content]="
      'interface_guest_user_delete_body' | translationPipe: getUserFullname()
    "
    (answer)="confirmDeleteUser($event)"
  >
  </app-confirm-modal>
</ng-container>
