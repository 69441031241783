import {
  Component,
  ViewChild,
  TemplateRef,
  Output,
  EventEmitter,
  Input,
  OnChanges,
} from '@angular/core';
import {
  NgbModal,
  ModalDismissReasons,
  NgbModalRef,
} from '@ng-bootstrap/ng-bootstrap';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Company } from '@/app/model/data/company';
import { CompanyService } from '@/app/services/company.service';
import { CustomValidators } from '@/app/utils/custom-validators';

@Component({
  selector: 'app-create-company-modal',
  templateUrl: './create-company-modal.component.html',
  styleUrls: ['./create-company-modal.component.css'],
})
export class CreateCompanyModalComponent implements OnChanges {
  @Input() currentCompany: Company | null = null;
  @Input() addCompany = false;
  @Output() changeMade = new EventEmitter<any>();

  modal: NgbModalRef | undefined;
  checkoutForm: UntypedFormGroup;
  // @ts-ignore
  isBusy: boolean;

  // @ts-ignore
  @ViewChild('companycreationmodal') templateModal: TemplateRef<any>;

  constructor(
    private modalService: NgbModal,
    private formBuilder: UntypedFormBuilder,
    private companyService: CompanyService
  ) {
    this.checkoutForm = this.formBuilder.group({
      name: [
        // @ts-ignore
        { value: '', disabled: this.isBusy },
        Validators.compose([
          Validators.required,
          Validators.maxLength(255),
          CustomValidators.notSpaceValidator(),
        ]),
      ],
      enabled: [
        // @ts-ignore
        { value: '', disabled: this.isBusy },
        Validators.compose([]),
      ],
    });
  }

  ngOnChanges() {
    this.checkoutForm.reset();
    if (!this.addCompany && this.currentCompany) {
      this.checkoutForm.controls['name'].setValue(this.currentCompany.name);
      this.checkoutForm.controls['enabled'].setValue(
        this.currentCompany.enabled
      );
    }
  }

  // @ts-ignore
  async onSubmit(customerData) {
    this.isBusy = true;
    if (customerData != null && this.addCompany) {
      const c: Company = new Company();
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
      c.name = customerData.name;
      if (!this.addCompany) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
        c.enabled = customerData.enabled;
      } else {
        c.enabled = true;
      }

      await this.companyService.createCompany(c);
    } else if (!this.addCompany && this.currentCompany != null) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
      this.currentCompany.name = customerData.name;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
      this.currentCompany.enabled = customerData.enabled;
      await this.companyService.editCompany(this.currentCompany);
    }
    this.checkoutForm.reset();
    this.isBusy = false;
    this.changeMade.emit();
    this.modal?.close();
  }

  open() {
    this.modal = this.modalService.open(this.templateModal);
    this.modal.result.then(null, (reason: any) => {
      this.resetAfterDismissal(reason);
    });
  }

  close() {
    this.resetFields();
    this.modal?.close();
  }

  resetAfterDismissal(reason: any) {
    if (
      reason === ModalDismissReasons.ESC ||
      reason === ModalDismissReasons.BACKDROP_CLICK
    ) {
      this.resetFields();
    }
  }

  resetFields() {
    this.checkoutForm.reset();
    if (this.currentCompany) {
      this.checkoutForm.controls['name'].setValue(this.currentCompany.name);
    }
  }
}
