import { Component } from '@angular/core';
import { UsualFeaturesAndRules } from './services/featureService/usual-features-and-rules';
import { FeatureService } from './services/featureService/feature.service';
import { environment } from '@/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  readonly environment = environment;

  constructor(private _featureService: FeatureService) {
    UsualFeaturesAndRules.registerUsualFeaturesAndRules(_featureService);
  }
}
