import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectService } from '@/app/services/project.service';
import { GuestService } from '@/app/services/guest.service';
import { TranslationService } from '@/app/services/translationService/translation.service';
import { SidebarService } from '@/app/services/sidebarService/sidebar.service';
import { OpenProjectModalComponent } from '@/app/pages/landing/open-project-modal/open-project-modal.component';
import { Project } from '@/app/model/packaging-model/project/project';

@Component({
  selector: 'app-projects-selection',
  templateUrl: './projects-selection.component.html',
  styleUrls: ['./projects-selection.component.css'],
})
export class ProjectsSelectionComponent implements OnInit {
  @ViewChild(OpenProjectModalComponent)
  // @ts-ignore
  private openProjectModalComponent: OpenProjectModalComponent;

  isPackDefSelected = true;

  // @ts-ignore
  isSelectingFirst: boolean;

  firstProject: any = null;
  secondProject: any = null;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private projectService: ProjectService,
    public guestService: GuestService,
    public translationService: TranslationService,
    public sidebarService: SidebarService,
    private _translationService: TranslationService
  ) {}

  ngOnInit() {
    if (this.guestService.isUserGuest())
      void this.router.navigate(['/'], { relativeTo: this.route });

    if (this.projectService.firstSelectedProject != null)
      this.firstProject = this.projectService.firstSelectedProject;
    if (this.projectService.secondSelectedProject != null)
      this.secondProject = this.projectService.secondSelectedProject;
  }

  unableToAssess(): boolean {
    return (
      this.firstProject == null ||
      this.secondProject == null ||
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      this.firstProject.id == this.secondProject.id
    );
  }

  isAssess(): boolean {
    return this.router.url.includes('assess');
  }

  openModalToChooseFirst() {
    this.isSelectingFirst = true;
    this.openProjectModalComponent.open();
  }

  openModalToChooseSecond() {
    this.isSelectingFirst = false;
    this.openProjectModalComponent.open();
  }

  selectProject(project: Project) {
    this.openProjectModalComponent.close();
    if (this.isSelectingFirst) {
      this.firstProject = project;
      // @ts-ignore
      this.projectService.firstSelectedProject = project;
    } else {
      this.secondProject = project;
      // @ts-ignore
      this.projectService.secondSelectedProject = project;
    }
  }

  firstName(): string {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.firstProject
      ? // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        this.firstProject.name
      : this._translationService.getTranslationValueForLabel(
          'interface_select_file'
        );
  }

  secondName(): string {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.secondProject
      ? // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        this.secondProject.name
      : this._translationService.getTranslationValueForLabel(
          'interface_select_file'
        );
  }

  async goToAssess() {
    await this.projectService.setProjectsForComparison(
      this.firstProject,
      this.secondProject
    );
    if (!this.unableToAssess()) {
      void this.router.navigate(['../assess'], { relativeTo: this.route });
      this.isPackDefSelected = false;
    }
  }
}
