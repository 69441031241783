export const environment = {
  name: 'production',
  apiUrl: 'https://api.tool.open-spice.com',
  whitelistedDomains: ['api.tool.open-spice.com'],
  production: true,
  enableSentry: true,
};

export const awsconfig = {
  aws_project_region: 'eu-west-1',
  userPoolId: 'eu-west-1_sOy316lUr',
  userPoolWebClientId: '5n21q9ersg0ccvg9mv07vaj3m9',
};
