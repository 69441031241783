<td class="drop-down finishing-process-type">
  <form [formGroup]="typeForm">
    <div class="mass-container">
      <select
        class="custom-select form-control conv-process-select"
        formControlName="finishingProcessType"
      >
        <option
          *ngFor="let type of finishingProcessTypes"
          [selected]="type.id == processOriginal.finishingProcessType.id"
          [value]="type.id"
        >
          {{ type.valueIntl }}
        </option>
      </select>
      <span
        *ngIf="
          processLocal != null &&
          processLocal.finishingProcessType != null &&
          processLocal.finishingProcessType.label != 'noprocess'
        "
        matTooltip="{{ getHelpBoxTranslation() | translationPipe }} "
        matTooltipPosition="above"
        matTooltipClass="custom-tooltip"
      >
        <em class="fas fa-question-circle"></em>
      </span>
    </div>
  </form>
</td>

<td class="no-pad-right">
  <form [formGroup]="surfaceForm">
    <div class="row">
      <div class="col-9 col-md-9 number-input surface">
        <input
          class="form-control surface-input"
          formControlName="surface"
          type="number"
          [value]="processOriginal.surface"
          min="{{ CustomValidators.nonZeroDecimalValidator.min }}"
        />
      </div>
      <div class="col-3 col-md-3 unit-padding">
        <span class="pl"> cm<sup>2</sup> </span>
      </div>
    </div>
    <validation-messages
      [control]="surfaceForm.get('surface')"
      class="form-invalid-message"
    >
    </validation-messages>
  </form>
</td>

<td class="icons trash-padding">
  <span
    matTooltip="{{ 'interface_delete' | translationPipe }} "
    matTooltipPosition="above"
    matTooltipClass="custom-tooltip"
  >
    <em class="fas fa-trash" *ngIf="!isReadOnly" (click)="deleteProcess()"></em>
  </span>
</td>

<app-confirm-modal
  [title]="'interface_finishing_process_deletion_title' | translationPipe"
  [content]="'interface_finishing_process_deletion_body' | translationPipe"
  (answer)="confirmOrAbortDeletion($event)"
>
</app-confirm-modal>
