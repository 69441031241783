import { PackagingAssociation } from './packaging-association';
import { Packaging } from './packaging';
import { Type } from 'class-transformer';

export class SecondaryPackaging extends Packaging {
  @Type(() => PackagingAssociation)
  containerPackagingAssociations!: Array<PackagingAssociation>;
  @Type(() => PackagingAssociation)
  contentPackagingAssociations!: Array<PackagingAssociation>;

  get associationWithPrimary(): PackagingAssociation | undefined {
    return this.containerPackagingAssociations[0];
  }

  constructor() {
    super();
  }

  copy(): SecondaryPackaging {
    const copy: SecondaryPackaging = new SecondaryPackaging();
    copy.id = this.id;
    copy.type = this.type;
    copy.isRecyclable = this.isRecyclable;
    return copy;
  }

  editableFieldsHaveChanged(original: SecondaryPackaging): boolean {
    return this.isRecyclable != original.isRecyclable;
  }

  mergeEditableFields(pack: SecondaryPackaging): void {
    this.isRecyclable = pack.isRecyclable;
  }
}
