<div
  *ngIf="this.translationService.translationsLoaded | async"
  class="container-fluid background-img-landing"
  (window:resize)="sidebarService.onResize($event)"
>
  <div class="row full-height">
    <div id="sidebar" class="sidebar col-lg-3 min-vh-100 position-fixed">
      <sidebar-component [collapsible]="false"></sidebar-component>
    </div>
    <div id="sidebarCollapsible" class="sidebar min-vh-100">
      <sidebar-component [collapsible]="true"></sidebar-component>
    </div>
    <div id="overlay" (click)="sidebarService.closeSidebar()"></div>
    <div id="projectMain" class="col-lg-9 offset-lg-3 main-section">
      <div class="row banner">
        <div class="col-md-12">
          <button
            id="openCollapsibleSidebar"
            (click)="sidebarService.openPackDefSidebar()"
          >
            <em class="fas fa-bars"></em>
          </button>
          <img src="assets/sidebar_spiceLogo.svg" class="header-spice" />
          <p class="spice-text-helvetica main-title-text">
            {{ "interface_landing_spice_subtitle" | translationPipe }}
          </p>
        </div>
      </div>
      <div
        class="row below-banner"
        [class.below-banner-guest]="guestService.isUserGuest()"
      >
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
