import { Component, TemplateRef } from '@angular/core';

import { ToastService } from '@/app/services/toast.service';

@Component({
  selector: 'app-toasts',
  template: `
    <ngb-toast
      *ngFor="let toast of toastService.toasts"
      [class]="toast.classname"
      [autohide]="true"
      [header]="toast.header"
      [delay]="toast.delay || 5000"
      (hidden)="toastService.remove(toast)"
    >
      <ng-template [ngIf]="isTemplate(toast)" [ngIfElse]="text">
        <ng-template [ngTemplateOutlet]="toast.textOrTpl"></ng-template>
      </ng-template>

      <ng-template #text>{{ toast.textOrTpl }}</ng-template>
    </ngb-toast>
  `,
  styleUrls: ['./toast-container.scss'],
})
export class ToastsContainerComponent {
  constructor(public toastService: ToastService) {}

  isTemplate(toast: Toast): boolean {
    return toast.textOrTpl instanceof TemplateRef;
  }
}

export interface Toast {
  header: string;
  textOrTpl: TemplateRef<any> | null;
  delay: number;
  classname: string;
}
