<div class="logo-spice">
  <img
    src="assets/sidebar_spiceLogo.svg"
    class="logo-spice no-outline"
    [routerLink]="['/']"
  />
</div>
<div class="current-user-management">
  <p class="username" (click)="userInfo = !userInfo">
    {{ currentUser }}
    <span class="user-role">{{ displayUserRole() | translationPipe }}</span>
    <em *ngIf="!userInfo" class="fas fa-caret-down"></em>
    <em *ngIf="userInfo" class="fas fa-caret-up"></em>
  </p>
  <div *ngIf="userInfo">
    <ul class="user-info-menu">
      <li
        *ngIf="isUserSuperAdmin()"
        class="username"
        (click)="closeSidebarIfCollapsible()"
        [routerLink]="['/company-management']"
      >
        {{ "interface_landing_company_management" | translationPipe }}
      </li>
      <li
        *ngIf="isUserSuperAdminOrAdmin()"
        class="username"
        (click)="closeSidebarIfCollapsible()"
        [routerLink]="['/user-management']"
      >
        {{ "interface_landing_user_management" | translationPipe }}
      </li>
      <li
        *ngIf="isUserSuperAdminOrAdmin()"
        class="username"
        (click)="closeSidebarIfCollapsible()"
        [routerLink]="['/scenario-library-management']"
      >
        {{ "interface_landing_scenario_library_management" | translationPipe }}
      </li>
      <li
        *ngIf="isUserSuperAdminOrAdmin()"
        class="username"
        (click)="closeSidebarIfCollapsible()"
        [routerLink]="['/all-projects-files']"
      >
        {{ "interface_portfolio_extract" | translationPipe }}
      </li>
      <li class="username" (click)="logout(); closeSidebarIfCollapsible()">
        {{ "interface_logout" | translationPipe }}
      </li>
    </ul>
  </div>
</div>
<div class="documentation">
  <p class="documents" (click)="openDocuments = !openDocuments">
    <em class="fas fa-book-open"></em>
    <span>{{ "interface_documents_btn" | translationPipe }}</span>
    <em *ngIf="!openDocuments" class="fas fa-caret-down"></em>
    <em *ngIf="openDocuments" class="fas fa-caret-up"></em>
  </p>
  <div *ngIf="openDocuments">
    <ul class="user-info-menu">
      <li
        [routerLink]="['/user-guide']"
        routerLinkActive="explanation-link-active"
        class="explanation-link"
      >
        User Guide
      </li>
      <li
        [routerLink]="['/technical-user-guide']"
        routerLinkActive="explanation-link-active"
        class="explanation-link"
      >
        Technical User Guide
      </li>
      <li
        [routerLink]="['/explanations']"
        routerLinkActive="explanation-link-active"
        class="explanation-link"
      >
        Methodology overview
      </li>
      <li
        [routerLink]="['/technical-appendix']"
        routerLinkActive="explanation-link-active"
        class="explanation-link"
      >
        Methodological guide
      </li>
      <li
        [routerLink]="['/recyclability-appendix']"
        routerLinkActive="explanation-link-active"
        class="explanation-link"
      >
        Recyclability Appendix
      </li>
      <li
        [routerLink]="['/recycled-content-appendix']"
        routerLinkActive="explanation-link-active"
        class="explanation-link"
      >
        Recycled Content Appendix
      </li>
    </ul>
  </div>
  <div class="db-version-container">
    <span class="db-version user-role" (click)="openChangelogModal()"
      >{{ "interface_db_version" | translationPipe }}{{ dbVersion }}</span
    >
  </div>
</div>
<app-changelog-modal></app-changelog-modal>
