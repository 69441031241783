/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import {
  Component,
  OnInit,
  ViewChild,
  ViewChildren,
  QueryList,
} from '@angular/core';
import { LoginService } from 'src/app/services/login/login.service';
import { User } from '@/app/model/user/user';
import { ProjectService } from 'src/app/services/project.service';
import { Project } from 'src/app/model/packaging-model/project/project';
import { ProjectAudit } from 'src/app/model/packaging-model/project/project-audit';
import { ConfirmModalComponent } from '../../confirm-modal/confirm-modal.component';
import { OpenProjectModalComponent } from '../open-project-modal/open-project-modal.component';
import { ProjectModalComponent } from '../create-project-modal/project-modal.component';
import { Router } from '@angular/router';
import { TranslationService } from '@/app/services/translationService/translation.service';
import { GuestService } from '@/app/services/guest.service';
import { ProjectLock } from '@/app/model/packaging-model/project/project-lock';
import { ToastService } from '@/app/services/toast.service';
import { TranslationPipe } from '@/app/intl/translation-pipe';
import { SingleUseProject } from '@/app/model/packaging-model/project/single-use-project';
import { ProjectCollaboratorsModalComponent } from '../../projects/share-project-modal/project-collaborators-modal.component';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.css'],
})
export class LandingPageComponent implements OnInit {
  @ViewChild(OpenProjectModalComponent)
  private openProjectModalComponent!: OpenProjectModalComponent;
  @ViewChildren(ConfirmModalComponent)
  private confirmModals!: QueryList<ConfirmModalComponent>;
  @ViewChild(ProjectModalComponent)
  private createProjectModalComponent!: ProjectModalComponent;
  @ViewChild(ProjectCollaboratorsModalComponent)
  private shareProjectModalComponent!: ProjectCollaboratorsModalComponent;

  latestProjects: Array<ProjectAudit> = [];
  selectedProject: Project | undefined;
  isSingleUse: boolean | undefined;

  get currentUser(): User | null {
    return this._loginService.currentUser;
  }

  get isAdmin(): boolean {
    return this._loginService.isAdminOrSuperAdmin();
  }

  get guestUserProject(): SingleUseProject | undefined {
    return this.guestService.guestUserProject;
  }

  constructor(
    private _loginService: LoginService,
    private projectService: ProjectService,
    public guestService: GuestService,
    private router: Router,
    public translationService: TranslationService,
    private toastService: ToastService,
    private translationPipe: TranslationPipe
  ) {}

  ngOnInit(): void {
    if (!this.guestService.isUserGuest()) this.retrieveLatestProjects();
  }

  retrieveLatestProjects(): void {
    this.projectService
      .getLatestProjects()
      .then((projectAudits: ProjectAudit[]) => {
        this.latestProjects = projectAudits;
      })
      .catch((err) => console.error(err));
  }

  openModalToOpenExistingProject(): void {
    this.openProjectModalComponent.open();
  }

  openModalToCreateProject(isSingleUse: boolean): void {
    this.isSingleUse = isSingleUse;
    this.createProjectModalComponent.open();
  }

  openModalToShareProject(selectedProject: Project): void {
    this.selectedProject = selectedProject;
    if (!this.guestService.isUserGuest())
      this.shareProjectModalComponent.open();
  }

  openProjectsSelectionPage(): void {
    void this.router.navigateByUrl('/projects-comparison/definition');
  }

  deleteProject(project: Project): void {
    this.selectedProject = project;
    if (this.guestService.isUserGuest()) this.selectedProject.ownerName = 'Me';
    this.confirmModals.filter((item) => item.id == 'delete-project')[0].open();
  }

  confirmOrAbortDeletion(answer: boolean): void {
    this.confirmModals.filter((item) => item.id == 'delete-project')[0].close();

    if (answer && this.selectedProject) {
      if (this.guestService.isUserGuest())
        this.guestService.deleteStoredProject();
      else {
        this.projectService
          .deleteProject(this.selectedProject)
          .then(() => {
            this.retrieveLatestProjects();
          })
          .catch((err: HttpErrorResponse) => {
            if (err instanceof ProjectLock) {
              this.toastService.show(
                this.translationPipe.transform(
                  'interface_project_lock_project_delete_failure_title'
                ),
                this.translationPipe.transform(
                  'interface_project_lock_project_delete_failure_body',
                  new Map().set('$1', err.user.displayedName)
                ),
                { classname: 'bg-warning' }
              );
            } else if (err.error.type == 'ImpossibleDeletionException') {
              this.toastService.show(
                this.translationPipe.transform('interface_deletion_forbidden'),
                err.error.message,
                { classname: 'bg-warning' }
              );
            }
          });
      }
    }
  }

  duplicateProject(project: Project): void {
    this.selectedProject = project;
    this.confirmModals
      .filter((item) => item.id == 'duplicate-project')[0]
      .open();
  }

  async confirmOrAbortDuplication(answer: boolean): Promise<void> {
    this.confirmModals
      .filter((item) => item.id == 'duplicate-project')[0]
      .close();

    if (answer && this.selectedProject) {
      if (this.selectedProject.isSingleUse) {
        const responseId: number =
          await this.projectService.duplicateSingleUseProject(
            this.selectedProject
          );
        this.goToSingleUseProject(responseId);
      } else {
        const responseId: number =
          await this.projectService.duplicateRechargeProject(
            this.selectedProject
          );
        this.gotToRechargeProject(responseId);
      }
    }
  }

  goToSingleUseProject(projectId: number): void {
    void this.router.navigateByUrl(`/single-use-project/${projectId}`);
  }

  gotToRechargeProject(projectId: number): void {
    void this.router.navigateByUrl(`/recharge-project/${projectId}`);
  }

  openProject(selectedProject: Project): void {
    this.selectedProject = selectedProject;
    if (this.selectedProject.isSingleUse || this.guestService.isUserGuest()) {
      void this.router.navigate([
        '/single-use-project',
        this.selectedProject.id,
      ]);
    } else {
      void this.router.navigate(['/recharge-project', this.selectedProject.id]);
    }
  }
}
