export class TypedSorting<T> {
  direction: SortingDirection;
  sortedBy: T;

  constructor(direction: SortingDirection, sortedBy: T) {
    this.direction = direction;
    this.sortedBy = sortedBy;
  }
}

export enum SortingDirection {
  // eslint-disable-next-line @typescript-eslint/indent
  ASC = 'ASC',
  DESC = 'DESC',
}
