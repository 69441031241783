<ng-form [formGroup]="materialForm">
  <td class="drop-down mat-type">
    <select
      class="custom-select form-control"
      formControlName="materialType"
      (change)="onMaterialChanged()"
    >
      <option *ngFor="let mt of materialTypes" [ngValue]="mt">
        {{ mt.valueIntl }}
      </option>
    </select>
    <validation-messages
      [control]="materialForm.controls.materialType"
      class="form-invalid-message"
    >
    </validation-messages>
  </td>

  <td class="no-pad-right w90">
    <div class="mass-container">
      <input
        class="form-control mass-input"
        formControlName="mass"
        type="number"
        min="{{ CustomValidators.nonZeroDecimalValidator.min }}"
        [value]="materialForm.value.mass"
      />
      <span class="mass-unit">g</span>
    </div>
    <validation-messages
      [control]="materialForm.controls.mass"
      class="form-invalid-message"
    >
    </validation-messages>
  </td>
  <td class="drop-down">
    <div class="mass-container">
      <select
        class="custom-select form-control conv-process-select"
        formControlName="conversionProcess"
      >
        <option class="select-default" [value]="null">
          {{ "interface_select_placeholder" | translationPipe }}
        </option>
        <option
          *ngFor="
            let cp of this.getConversionProcessesFor(
              materialForm.value.materialType!
            )
          "
          [ngValue]="cp"
        >
          {{ cp.valueIntl }}
        </option>
      </select>
      <span
        *ngIf="
          materialForm.value.conversionProcess !== undefined &&
          materialForm.value.conversionProcess !== null &&
          materialForm.value.conversionProcess!.label !== 'noprocess'
        "
        matTooltip="{{ this.getHelpboxTranslation() | translationPipe }} "
        matTooltipPosition="above"
        matTooltipClass="custom-tooltip"
      >
        <em
          *ngIf="getHelpboxTranslation() !== undefined"
          class="fas fa-question-circle"
        ></em>
      </span>
    </div>
    <validation-messages
      [control]="materialForm.controls.conversionProcess"
      class="form-invalid-message"
    >
    </validation-messages>
  </td>
  <td class="no-pad-right w110">
    <div class="row">
      <div class="col-9 col-md-9 number-input">
        <input
          class="form-control"
          type="number"
          formControlName="recycledContent"
          [value]="materialForm.value.recycledContent"
          min="{{ CustomValidators.percentageValidator.min }}"
          max="{{ CustomValidators.percentageValidator.max }}"
        />
      </div>
      <div class="col-3 col-md-3 unit-padding">
        <span>%</span>
      </div>
    </div>
    <validation-messages
      [control]="materialForm.controls.recycledContent"
      class="form-invalid-message"
    >
    </validation-messages>
  </td>
  <td class="icons trash-padding">
    <span
      matTooltip="{{ 'interface_delete' | translationPipe }} "
      matTooltipPosition="above"
      matTooltipClass="custom-tooltip"
    >
      <em
        class="fas fa-trash"
        *ngIf="!isReadOnly"
        (click)="deleteMaterial()"
      ></em>
    </span>
  </td>
</ng-form>
<app-confirm-modal
  [title]="'interface_material_deletion_title' | translationPipe"
  [content]="'interface_material_deletion_body' | translationPipe"
  (answer)="confirmOrAbortDeletion($event)"
>
</app-confirm-modal>
