import { Component, EventEmitter, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { UserService } from '@/app/services/user.service';

@Component({
  selector: 'email-form-forgot-password',
  templateUrl: './email-form-forgot-password.html',
  styleUrls: [],
})
export class EmailFormForgotPasswordComponent {
  @Output() answer = new EventEmitter<string>();

  forgotPasswordForm: UntypedFormGroup;

  errorMessage: string | null = null;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private userService: UserService
  ) {
    this.forgotPasswordForm = this.formBuilder.group({
      email: ['', Validators.compose([Validators.required, Validators.email])],
    });
  }

  onForgotPassword(email: string) {
    this.errorMessage = null;
    this.userService
      .forgotPassword(email)
      .then(() => this.answer.emit(email))
      .catch((err) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        switch (err.code) {
          case 'LimitExceededException':
            this.errorMessage =
              'interface_forgot_password_limit_exceeded_exception';
            break;
          default:
            // eslint-disable-next-line max-len
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
            this.errorMessage = err.message;
            break;
        }
      });
  }
}
