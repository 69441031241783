<ng-template #populatemodal let-modal>
  <div class="modal-content">
    <div class="modal-header modal-spacing">
      <div class="modal-title">Populate</div>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        aria-label="Close"
        (click)="close()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <form [formGroup]="populateForm" id="populate-form" (ngSubmit)="onSubmit()">
      <div class="modal-body break-word modal-spacing">
        <div class="form-group">
          <label for="populate-nb-projects-to-create"
            >Number of projects to create</label
          >
          <input
            id="populate-nb-projects-to-create"
            type="number"
            class="number-input form-control"
            formControlName="nbProjects"
            min="{{ boundaries.nbProjects.min }}"
            max="{{ boundaries.nbProjects.max }}"
          />
          <validation-messages
            [control]="populateForm.get('nbProjects')"
            class="form-invalid-message"
          >
          </validation-messages>
        </div>
        <div class="form-group">
          <label for="populate-nb-scenarios-to-create"
            >Number of scenarios to create</label
          >
          <input
            id="populate-nb-scenarios-to-create"
            type="number"
            class="number-input form-control"
            formControlName="nbScenarios"
            min="{{ boundaries.nbScenarios.min }}"
            max="{{ boundaries.nbScenarios.max }}"
          />
          <validation-messages
            [control]="populateForm.get('nbScenarios')"
            class="form-invalid-message"
          >
          </validation-messages>
        </div>
        <div class="form-group">
          <label for="populate-nb-primary-packaging-components-to-create"
            >Number of primary packaging components to create</label
          >
          <input
            id="populate-nb-primary-packaging-components-to-create"
            type="number"
            class="number-input form-control"
            formControlName="nbPrimaryPackagingComponents"
            min="{{ boundaries.nbComponents.min }}"
            max="{{ boundaries.nbComponents.max }}"
          />
          <validation-messages
            [control]="populateForm.get('nbPrimaryPackagingComponents')"
            class="form-invalid-message"
          >
          </validation-messages>
        </div>
        <div class="form-group">
          <label for="populate-nb-secondary-packaging-components-to-create"
            >Number of secondary packaging components to create</label
          >
          <input
            id="populate-nb-secondary-packaging-components-to-create"
            type="number"
            class="number-input form-control"
            formControlName="nbSecondaryPackagingComponents"
            min="{{ boundaries.nbComponents.min }}"
            max="{{ boundaries.nbComponents.max }}"
          />
          <validation-messages
            [control]="populateForm.get('nbSecondaryPackagingComponents')"
            class="form-invalid-message"
          >
          </validation-messages>
        </div>
        <div class="form-group">
          <label for="populate-nb-tertiary-box-packaging-components-to-create"
            >Number of tertiary box packaging components to create</label
          >
          <input
            id="populate-nb-tertiary-box-packaging-components-to-create"
            type="number"
            class="number-input form-control"
            formControlName="nbTertiaryBoxPackagingComponents"
            min="{{ boundaries.nbComponents.min }}"
            max="{{ boundaries.nbComponents.max }}"
          />
          <validation-messages
            [control]="populateForm.get('nbTertiaryBoxPackagingComponents')"
            class="form-invalid-message"
          >
          </validation-messages>
        </div>
        <div class="form-group">
          <label
            for="populate-nb-tertiary-palletization-packaging-components-to-create"
            >Number of tertiary palletization packaging components to
            create</label
          >
          <input
            id="populate-nb-tertiary-palletization-packaging-components-to-create"
            type="number"
            class="number-input form-control"
            formControlName="nbTertiaryPalletizationPackagingComponents"
            min="{{ boundaries.nbComponents.min }}"
            max="{{ boundaries.nbComponents.max }}"
          />
          <validation-messages
            [control]="
              populateForm.get('nbTertiaryPalletizationPackagingComponents')
            "
            class="form-invalid-message"
          >
          </validation-messages>
        </div>
        <div class="form-group">
          <label id="populate-reset-database-before-populate" class="switch">
            <input
              type="checkbox"
              formControlName="resetDatabaseBeforePopulate"
            />
            <div class="slider round"></div>
          </label>
          <label class="mini-label inline"
            >Reset database before populate</label
          >
          <div
            class="warning"
            *ngIf="
              populateForm.get('resetDatabaseBeforePopulate')?.value === true
            "
          >
            <i class="fas fa-exclamation-circle"></i>
            Caution, this will delete all projects for all users!
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-cancel" (click)="close()">
          Cancel
        </button>
        <button
          type="submit"
          id="populate-populate"
          class="btn btn-primary login-btn"
          [disabled]="isBusy || !populateForm.valid"
        >
          Populate
        </button>
      </div>
    </form>
  </div>
</ng-template>
