import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { UserService } from '@/app/services/user.service';
import { TranslationPipe } from '@/app/intl/translation-pipe';
import EmailUtils from '@/app/utils/email-utils';

@Component({
  selector: 'email-form-forgot-password-validation',
  templateUrl: './email-form-forgot-password-validation.html',
  styleUrls: [],
})
export class EmailFormForgotPasswordValidationComponent {
  // @ts-ignore
  @Input() email: string;

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() success = new EventEmitter<boolean>();

  forgotPasswordConfirmForm: UntypedFormGroup;

  // @ts-ignore
  errorMessage: string;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private userService: UserService,
    private translationPipe: TranslationPipe
  ) {
    this.forgotPasswordConfirmForm = this.formBuilder.group({
      code: ['', Validators.required],
    });
  }

  formInitialized(name: string, form: UntypedFormGroup) {
    this.forgotPasswordConfirmForm.setControl(name, form);
  }

  // @ts-ignore
  onForgotPasswordSubmit(data) {
    // @ts-ignore
    this.errorMessage = null;
    this.userService
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      .forgotPasswordSubmit(this.email, data.code, data.newpassword.newPassword)
      .then(() => this.success.emit(true))
      .catch((err) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        switch (err.code) {
          case 'InvalidParameterException':
            this.errorMessage = 'interface_password_parameter_exception';
            break;
          case 'CodeMismatchException':
            this.errorMessage = 'interface_code_mismatch_exception';
            break;
          default:
            // eslint-disable-next-line max-len
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment
            this.errorMessage = err.message;
            break;
        }
      });
  }

  emailSentBodyMessage() {
    return this.translationPipe.transform(
      'interface_email_sent_body',
      new Map().set('$1', EmailUtils.obfuscateEmailAddress(this.email))
    );
  }
}
