import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiUtils } from './api-utils';
import { Bundle } from '../model/data/bundle';
import { map } from 'rxjs/operators';
import { plainToClass } from 'class-transformer';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GlobalApi {
  constructor(private http: HttpClient) {}

  buildPath(endPoint: string): string {
    return ApiUtils.BASE_PATH + 'global/' + endPoint;
  }

  getBundle(): Observable<Bundle> {
    return this.http
      .get<Bundle>(this.buildPath('bundle'), ApiUtils.HTTP_OPTIONS)
      .pipe(
        map((response) => {
          return plainToClass(Bundle, response);
        })
      );
  }
}
