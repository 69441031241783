<div class="modal-body break-word modal-spacing">
  <form
    [formGroup]="forgotPasswordForm"
    id="emailFormForgotPassword"
    (ngSubmit)="onForgotPassword(forgotPasswordForm.value.email)"
    autocomplete="off"
  >
    <div class="form-group">
      <label for="label"
        >{{'interface_forgotten_password_body' | translationPipe}}</label
      >
      <input
        id="email"
        class="form-control"
        name="email"
        type="email"
        formControlName="email"
      />
      <validation-messages
        [control]="forgotPasswordForm.get('email')"
        class="form-invalid-message"
      >
      </validation-messages>
    </div>
  </form>
  <div *ngIf="errorMessage != null" class="text-center errorMsg form-text mb-3">
    {{errorMessage | translationPipe}}
  </div>
</div>

<div class="modal-footer modal-footer-center">
  <button
    type="submit"
    (click)="onForgotPassword(forgotPasswordForm.value.email)"
    class="btn btn-primary login-btn"
    [disabled]="forgotPasswordForm.invalid"
  >
    {{'interface_send_email' | translationPipe }}
  </button>
</div>
