<div class="general-info-container" *ngIf="globalService.listsReady">
  <form
    [formGroup]="scenarioform"
    id="scenarioform"
    (keydown.enter)="$event.preventDefault()"
  >
    <div class="general-info-block">
      <div class="row">
        <div class="col-md-12">
          <h4 class="box-title">
            {{ scenarioOriginal.name }}
            <div
              *ngIf="!guestService.isUserGuest()"
              class="scenario-actions actions align-right"
            >
              <em
                *ngIf="
                  scenarioLocal.id != project.referenceScenarioId && !isReadOnly
                "
                class="fas fa-star mr-6"
                title="{{ 'interface_reference' | translationPipe }}"
                (click)="setToReference()"
              ></em>
              <em
                *ngIf="scenarioLocal.id == project.referenceScenarioId"
                class="fas fa-star isReference mr-6"
                title="{{ 'interface_is_reference' | translationPipe }}"
              ></em>

              <span
                *ngIf="!isReadOnly"
                matTooltip="{{
                  'interface_max_scenarios_reached' | translationPipe
                }} "
                matTooltipPosition="above"
                [matTooltipDisabled]="!isScenarioLimitReached()"
                matTooltipClass="custom-tooltip"
              >
                <button
                  [disabled]="isScenarioLimitReached()"
                  (click)="duplicateScenario()"
                >
                  <em
                    class="fas fa-copy copy-scenario mr-6"
                    title="{{ 'interface_duplicate' | translationPipe }}"
                  ></em>
                </button>
              </span>
              <em
                class="fas fa-trash"
                (click)="deleteScenario()"
                *ngIf="
                  scenarioLocal.id != project.referenceScenarioId && !isReadOnly
                "
                title="{{ 'interface_delete' | translationPipe }}"
              ></em>
            </div>
          </h4>
        </div>
      </div>
      <div class="main-actions-container">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="name">{{
                "interface_scenario_name" | translationPipe
              }}</label>
              <input
                id="name"
                class="form-control"
                formControlName="name"
                type="text"
                maxlength="{{ this.boundaries.scenarioName.max }}"
              />
              <validation-messages
                [control]="scenarioform.get('name')"
                class="form-invalid-message"
              >
              </validation-messages>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="productionCountry"
                >{{ "interface_scenario_prod_zone" | translationPipe }}
                <span
                  matTooltip="{{
                    'helpbox_general_info_production_zone' | translationPipe
                  }} {{ 'interface_refer_to_doc_details' | translationPipe }} "
                  matTooltipPosition="above"
                  matTooltipClass="custom-tooltip"
                >
                  <em class="fas fa-question-circle"></em>
                </span>
              </label>
              <select
                class="select-scenario"
                class="custom-select form-control"
                formControlName="productionCountry"
              >
                <option
                  *ngFor="let country of countries"
                  [value]="country.label"
                  [disabled]="country.label === 'zRoW'"
                >
                  {{ country.valueIntl }}
                </option>
              </select>
              <validation-messages
                [control]="scenarioform.get('productionCountry')"
                class="form-invalid-message"
              >
              </validation-messages>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group-sales">
              <label for="salesCountry"
                >{{ "interface_scenario_sales_zone" | translationPipe }}
                <span
                  matTooltip="{{
                    'helpbox_general_info_sales_zone' | translationPipe
                  }} "
                  matTooltipPosition="above"
                  matTooltipClass="custom-tooltip"
                >
                  <em class="fas fa-question-circle"></em>
                </span>
              </label>
              <select
                class="select-scenario"
                class="custom-select form-control"
                formControlName="salesCountry"
              >
                <option
                  *ngFor="let country of countries"
                  [value]="country.label"
                  [disabled]="country.label === 'zRoW'"
                >
                  {{ country.valueIntl }}
                </option>
              </select>
              <validation-messages
                [control]="scenarioform.get('salesCountry')"
                class="form-invalid-message"
              >
              </validation-messages>
            </div>
          </div>
          <em *ngIf="displayCountryInfo">
            {{ "interface_country_info" | translationPipe }}</em
          >
        </div>
      </div>
    </div>
  </form>
  <form
    [formGroup]="primaryPackform"
    id="primaryPackform"
    (keydown.enter)="$event.preventDefault()"
  >
    <div class="general-info-block">
      <h4 class="box-title">
        {{ "interface_scenario_pack_layers" | translationPipe }}
      </h4>
      <div class="main-actions-container">
        <div class="pack-layer-icon">
          <p class="spice-icon">b</p>
        </div>
        <div class="level-tag">
          <p>{{ "interface_scenario_primary_layer" | translationPipe }}</p>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label for="claimedVolume">{{
                "interface_scenario_claimed_volume" | translationPipe
              }}</label>
              <div class="row">
                <div class="col-md-9">
                  <input
                    id="claimedVolume"
                    class="form-control"
                    formControlName="claimedVolume"
                    type="text"
                    min="{{ CustomValidators.nonZeroDecimalValidator.min }}"
                  />
                  <validation-messages
                    [control]="primaryPackform.get('claimedVolume')"
                    class="form-invalid-message"
                  >
                  </validation-messages>
                </div>
                <div class="col-md-3">
                  <label class="mini-label inline">{{
                    "unit_milliliter" | translationPipe
                  }}</label>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="rateOfRestitution"
                >{{ "interface_scenario_rate_restitution" | translationPipe }}
                <span
                  matTooltip="{{ 'helpbox_ror' | translationPipe }} "
                  matTooltipPosition="above"
                  matTooltipClass="custom-tooltip"
                >
                  <em class="fas fa-question-circle"></em> </span
              ></label>
              <div class="row">
                <div class="col-md-9">
                  <input
                    id="rateOfRestitution"
                    class="form-control"
                    formControlName="rateOfRestitution"
                    type="text"
                    min="{{ CustomValidators.percentageNonZeroValidator.min }}"
                    max="{{ CustomValidators.percentageNonZeroValidator.max }}"
                  />
                  <validation-messages
                    [control]="primaryPackform.get('rateOfRestitution')"
                    class="form-invalid-message"
                  >
                  </validation-messages>
                </div>
                <div class="col-md-3">
                  <label class="mini-label inline">{{
                    "unit_percent" | translationPipe
                  }}</label>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4 center">
            <div class="form-group">
              <label class="switch">
                <input
                  type="checkbox"
                  formControlName="primaryPackagingIsRecyclable"
                />
                <div class="slider round"></div>
              </label>
              <label class="mini-label inline"
                >{{
                  "interface_scenario_primary_is_recyclable" | translationPipe
                }}
                <span
                  matTooltip="{{
                    'helpbox_primary_is_recyclable' | translationPipe
                  }} "
                  matTooltipPosition="above"
                  matTooltipClass="custom-tooltip"
                >
                  <em class="fas fa-question-circle"></em>
                </span>
              </label>
            </div>
          </div>
        </div>
        <div
          class="row"
          *ngIf="
            scenarioOriginal.componentsOfLevel(ComponentLevel.PRIMARY).length >
            0
          "
        >
          <div class="col-md-12">
            <div class="sub-tag">
              <p>{{ "interface_packaging_components" | translationPipe }}</p>
            </div>
            <table id="general-info-primary-layer-components" class="table">
              <tbody>
                <tr
                  class="pointer"
                  *ngFor="
                    let component of scenarioOriginal.componentsOfLevel(
                      ComponentLevel.PRIMARY
                    )
                  "
                >
                  <td (click)="selectComponent(component.id)" class="clickable">
                    {{ component.name }}
                  </td>
                  <td class="icons">
                    <span
                      matTooltip="{{ 'interface_delete' | translationPipe }} "
                      matTooltipPosition="above"
                      matTooltipClass="custom-tooltip"
                    >
                      <em
                        *ngIf="!isReadOnly"
                        class="fas fa-trash no-outline"
                        (click)="deleteComponent(component)"
                      ></em>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <span
          *ngIf="!isReadOnly"
          matTooltip="{{
            'interface_max_components_reached' | translationPipe
          }} "
          matTooltipPosition="above"
          [matTooltipDisabled]="!isComponentLimitReached(0)"
          matTooltipClass="custom-tooltip"
        >
          <button
            [disabled]="isComponentLimitReached(0)"
            id="general-info-add-primary-packaging"
            class="btn btn-secondary landing-main-action-button"
            (click)="createComponent(ComponentLevel.PRIMARY)"
          >
            <em class="fas fa-plus"></em>
            {{ "interface_scenario_add_primary_pack" | translationPipe }}
          </button>
        </span>
      </div>
    </div>
  </form>
  <form
    [formGroup]="secondaryPackform"
    id="secondaryPackform"
    (keydown.enter)="$event.preventDefault()"
  >
    <div class="general-info-block">
      <div class="main-actions-container">
        <div class="pack-layer-icon">
          <p class="spice-icon">n</p>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="level-tag">
              <p>
                {{ "interface_scenario_secondary_layer" | translationPipe }}
              </p>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label id="general-info-has-secondary-packaging" class="switch">
                <input
                  type="checkbox"
                  formControlName="hasSecondaryPackaging"
                  (change)="switchHasSecondaryPackaging()"
                />
                <div class="slider round"></div>
              </label>
              <label class="mini-label inline"
                >{{ "interface_scenario_has_secondary_pack" | translationPipe }}
              </label>
            </div>
          </div>
        </div>
        <div class="row" [hidden]="!scenarioOriginal.hasSecondaryPackaging">
          <div class="col-md-6 offset-md-6">
            <div class="form-group">
              <label class="switch">
                <input
                  type="checkbox"
                  formControlName="secondaryPackagingIsRecyclable"
                />
                <div class="slider round"></div>
              </label>
              <label class="mini-label inline"
                >{{
                  "interface_scenario_secondary_is_recyclable" | translationPipe
                }}
                <span
                  matTooltip="{{
                    'helpbox_secondary_is_recyclable' | translationPipe
                  }} "
                  matTooltipPosition="above"
                  matTooltipClass="custom-tooltip"
                >
                  <em class="fas fa-question-circle"></em>
                </span>
              </label>
            </div>
          </div>
        </div>
        <div [hidden]="!scenarioOriginal.hasSecondaryPackaging">
          <div
            class="row"
            *ngIf="
              scenarioOriginal.componentsOfLevel(ComponentLevel.SECONDARY)
                .length > 0
            "
          >
            <div class="col-md-12">
              <div class="sub-tag">
                <p>{{ "interface_packaging_components" | translationPipe }}</p>
              </div>
              <table id="general-info-secondary-layer-components" class="table">
                <tbody>
                  <tr
                    class="pointer"
                    *ngFor="
                      let component of scenarioOriginal.componentsOfLevel(
                        ComponentLevel.SECONDARY
                      )
                    "
                  >
                    <td
                      (click)="selectComponent(component.id)"
                      class="clickable"
                    >
                      {{ component.name }}
                    </td>
                    <td class="icons">
                      <span
                        matTooltip="{{ 'interface_delete' | translationPipe }} "
                        matTooltipPosition="above"
                        matTooltipClass="custom-tooltip"
                      >
                        <em
                          *ngIf="!isReadOnly"
                          class="fas fa-trash no-outline"
                          (click)="deleteComponent(component)"
                        ></em>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <span
            *ngIf="!isReadOnly"
            matTooltip="{{
              'interface_max_components_reached' | translationPipe
            }} "
            matTooltipPosition="above"
            [matTooltipDisabled]="!isComponentLimitReached(1)"
            matTooltipClass="custom-tooltip"
          >
            <button
              [disabled]="isComponentLimitReached(1)"
              id="general-info-add-secondary-packaging"
              class="btn btn-secondary landing-main-action-button"
              (click)="createComponent(ComponentLevel.SECONDARY)"
            >
              <em class="fas fa-plus"></em>
              {{ "interface_scenario_add_secondary_pack" | translationPipe }}
            </button>
          </span>
        </div>
      </div>
    </div>
  </form>
  <div class="general-info-block-super">
    <div class="main-actions-container">
      <div class="row">
        <div class="col-md-6">
          <div class="level-tag">
            <p id="tertiary">
              {{ "interface_scenario_tertiary_all_layer" | translationPipe }}
            </p>
          </div>
        </div>
        <div class="col-md-6">
          <div class="col-md-8 tertiary-menu-container">
            <ul class="tertiary-ul">
              <li
                [class.active]="isDefaultSelected"
                (click)="selectTab('default')"
              >
                {{ "interface_default" | translationPipe }}
              </li>
              <li
                id="general-info-has-tertiary-packaging"
                [class.active]="isSpecifySelected"
                (click)="selectTab('specify')"
              >
                {{ "interface_specify" | translationPipe }}
              </li>
              <li
                [class.active]="isNoTertiarySelected"
                (click)="selectTab('noTertiary')"
              >
                {{ "interface_no_tertiary" | translationPipe }}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="row" [hidden]="!isDefaultSelected">
        <div class="col-md-6 default-tertiary-pack">
          <span
            >{{ "interface_scenario_default_tertiary_pack" | translationPipe }}
            <a class="link" (click)="goToDefaultDataPDF()"
              >Methodological guide</a
            ></span
          >
        </div>
      </div>
    </div>
    <form
      [formGroup]="tertiaryBoxPackform"
      id="tertiaryBoxPackform"
      (keydown.enter)="$event.preventDefault()"
    >
      <div class="general-info-block" [hidden]="!isSpecifySelected">
        <div class="main-actions-container">
          <div class="pack-layer-icon">
            <p class="spice-icon">q</p>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="level-tag">
                <p id="tertiary">
                  {{ "interface_scenario_tertiary_layer" | translationPipe }}
                </p>
              </div>
            </div>
            <div class="col-md-6"></div>
          </div>
          <div class="row" [hidden]="!isSpecifySelected">
            <div class="col-md-12">
              <div class="form-group">
                <label
                  *ngIf="scenarioOriginal.hasSecondaryPackaging"
                  for="numberOfSecondaryPerTertiaryBox"
                  >{{
                    "interface_scenario_nb_secondary_per_tertiary_box"
                      | translationPipe
                  }}</label
                >
                <label
                  *ngIf="!scenarioOriginal.hasSecondaryPackaging"
                  for="numberOfSecondaryPerTertiaryBox"
                  >{{
                    "interface_scenario_nb_primary_per_tertiary_box"
                      | translationPipe
                  }}</label
                >
                <div class="row">
                  <div class="col-md-3">
                    <input
                      id="numberOfSecondaryPerTertiaryBox"
                      class="form-control"
                      formControlName="numberOfSecondaryPerTertiaryBox"
                      type="number"
                      min="{{ boundaries.boxes.min }}"
                      max="{{ boundaries.boxes.max }}"
                    />
                    <validation-messages
                      [control]="
                        tertiaryBoxPackform.get(
                          'numberOfSecondaryPerTertiaryBox'
                        )
                      "
                      class="form-invalid-message"
                    >
                    </validation-messages>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div [hidden]="!isSpecifySelected">
            <div
              class="row"
              *ngIf="
                scenarioOriginal.componentsOfLevel(ComponentLevel.TERTIARY_BOX)
                  .length > 0
              "
            >
              <div class="col-md-12">
                <div class="sub-tag">
                  <p>
                    {{ "interface_packaging_components" | translationPipe }}
                  </p>
                </div>
                <table
                  id="general-info-tertiary-box-layer-components"
                  class="table"
                >
                  <tbody>
                    <tr
                      class="pointer"
                      *ngFor="
                        let component of scenarioOriginal.componentsOfLevel(
                          ComponentLevel.TERTIARY_BOX
                        )
                      "
                    >
                      <td
                        (click)="selectComponent(component.id)"
                        class="clickable"
                      >
                        {{ component.name }}
                      </td>
                      <td class="icons">
                        <span
                          matTooltip="{{
                            'interface_delete' | translationPipe
                          }} "
                          matTooltipPosition="above"
                          matTooltipClass="custom-tooltip"
                        >
                          <em
                            class="fas fa-trash no-outline"
                            *ngIf="!isReadOnly"
                            (click)="deleteComponent(component)"
                          ></em>
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <span
              *ngIf="!isReadOnly"
              matTooltip="{{
                'interface_max_components_reached' | translationPipe
              }} "
              matTooltipPosition="above"
              [matTooltipDisabled]="!isComponentLimitReached(2)"
              matTooltipClass="custom-tooltip"
            >
              <button
                [disabled]="isComponentLimitReached(2)"
                id="general-info-add-tertiary-box-packaging"
                class="btn btn-secondary landing-main-action-button"
                (click)="createComponent(ComponentLevel.TERTIARY_BOX)"
              >
                <em class="fas fa-plus"></em>
                {{ "interface_scenario_add_tertiary_pack" | translationPipe }}
              </button>
            </span>
          </div>
        </div>
      </div>
    </form>
    <form
      [formGroup]="tertiaryPalletizationPackform"
      id="tertiaryPalletizationPackform"
      (keydown.enter)="$event.preventDefault()"
    >
      <div class="general-info-block" [hidden]="!isSpecifySelected">
        <div class="main-actions-container">
          <div class="pack-layer-icon">
            <p class="spice-icon">r</p>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="level-tag">
                <p id="tertiary-palletization">
                  {{
                    "interface_scenario_tertiary_palletization_layer"
                      | translationPipe
                  }}
                </p>
              </div>
            </div>
            <div class="col-md-6"></div>
          </div>
          <div class="row" [hidden]="!isSpecifySelected">
            <div class="col-md-12">
              <div class="form-group">
                <label for="nbTertiaryPerTertiaryPallet">{{
                  "interface_scenario_nb_tertiary_box_per_tertiary_pallet"
                    | translationPipe
                }}</label>
                <div class="row">
                  <div class="col-md-3">
                    <input
                      id="nbTertiaryPerTertiaryPallet"
                      class="form-control"
                      formControlName="numberOfTertiaryBoxPerPallet"
                      type="number"
                      min="{{ boundaries.boxes.min }}"
                      max="{{ boundaries.boxes.max }}"
                    />
                    <validation-messages
                      [control]="
                        tertiaryPalletizationPackform.get(
                          'numberOfTertiaryBoxPerPallet'
                        )
                      "
                      class="form-invalid-message"
                    >
                    </validation-messages>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div [hidden]="!isSpecifySelected">
            <div
              class="row"
              *ngIf="
                scenarioOriginal.componentsOfLevel(
                  ComponentLevel.TERTIARY_PALLETIZATION
                ).length > 0
              "
            >
              <div class="col-md-12">
                <div class="sub-tag">
                  <p>
                    {{ "interface_packaging_components" | translationPipe }}
                  </p>
                </div>
                <table
                  id="general-info-tertiary-palletization-layer-components"
                  class="table"
                >
                  <tbody>
                    <tr
                      class="pointer"
                      *ngFor="
                        let component of scenarioOriginal.componentsOfLevel(
                          ComponentLevel.TERTIARY_PALLETIZATION
                        )
                      "
                    >
                      <td
                        (click)="selectComponent(component.id)"
                        class="clickable"
                      >
                        {{ component.name }}
                      </td>
                      <td class="icons">
                        <span
                          matTooltip="{{
                            'interface_delete' | translationPipe
                          }} "
                          matTooltipPosition="above"
                          matTooltipClass="custom-tooltip"
                        >
                          <em
                            class="fas fa-trash no-outline"
                            *ngIf="!isReadOnly"
                            (click)="deleteComponent(component)"
                          ></em>
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <span
              *ngIf="!isReadOnly"
              matTooltip="{{
                'interface_max_components_reached' | translationPipe
              }} "
              matTooltipPosition="above"
              [matTooltipDisabled]="!isComponentLimitReached(3)"
              matTooltipClass="custom-tooltip"
            >
              <button
                [disabled]="isComponentLimitReached(3)"
                id="general-info-add-tertiary-palletization-packaging"
                class="btn btn-secondary landing-main-action-button"
                (click)="createComponent(ComponentLevel.TERTIARY_PALLETIZATION)"
              >
                <em class="fas fa-plus"></em>
                {{
                  "interface_scenario_add_tertiary_palletization_pack"
                    | translationPipe
                }}
              </button>
            </span>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>

<app-confirm-modal
  [id]="'gen-delete-scenario'"
  [title]="'interface_scenario_deletion_title' | translationPipe"
  [content]="
    ('interface_scenario_deletion_body' | translationPipe) +
    this.scenarioLocal.name +
    ' ?'
  "
  (answer)="confirmOrAbortScenarioDeletion($event)"
>
</app-confirm-modal>

<app-confirm-modal
  [id]="'gen-delete-component'"
  [title]="'interface_component_deletion_title' | translationPipe"
  [content]="
    ('interface_component_deletion_body' | translationPipe) +
    this.componentToBeDeleted?.name +
    ' ?'
  "
  (answer)="confirmOrAbortComponentDeletion($event)"
>
</app-confirm-modal>

<app-confirm-modal
  [id]="'reference-scenario'"
  [title]="'interface_scenario_reference_title' | translationPipe"
  [content]="
    ('interface_scenario_reference_body' | translationPipe) +
    this.scenarioLocal.name +
    ' ?'
  "
  (answer)="confirmOrAbortReferencingScenario($event)"
>
</app-confirm-modal>
