<div class="project-comparison-info-container">
  <div class="warning-container-comparison-selection">
    <em class="fas fa-info-circle"></em
    >{{ "interface_compare_warning" | translationPipe }}
  </div>
  <div class="warning-container-comparison-selection">
    <em class="fas fa-info-circle"></em
    >{{ "interface_communication_warning" | translationPipe }}
    <a
      class="link"
      target="_blank"
      href="https://open-spice.com/claims-guidelines/"
      >{{ "interface_communication_warning_guidelines" | translationPipe }}</a
    >
  </div>
  <div class="main-actions-container project-block">
    <div class="row">
      <div class="col-md-6">
        <b> {{ "interface_first_project_selection" | translationPipe }}</b>
        <button
          id="projects-selection-select-project-one"
          type="button"
          class="filename"
          (click)="openModalToChooseFirst()"
        >
          <div class="row align-items-center">
            <div class="col-md-10">
              <p class="align-left">{{ firstName() }}</p>
            </div>
            <div class="col-md-2">
              <em
                class="fas fa-folder-open open-icon"
                title="{{ 'interface_select_file' | translationPipe }}"
              ></em>
            </div>
          </div>
        </button>
      </div>
      <div class="col-md-6">
        <b> {{ "interface_second_project_selection" | translationPipe }}</b>
        <button
          id="projects-selection-select-project-two"
          type="button"
          class="filename"
          (click)="openModalToChooseSecond()"
        >
          <div class="row align-items-center">
            <div class="col-md-10">
              <p class="align-left">{{ secondName() }}</p>
            </div>
            <div class="col-md-2">
              <em
                class="fas fa-folder-open open-icon"
                title="{{ 'interface_select_file' | translationPipe }}"
              ></em>
            </div>
          </div>
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="btn-container">
          <span
            matTooltip="{{
              'interface_no_project_or_project_twice' | translationPipe
            }} "
            matTooltipPosition="above"
            [matTooltipDisabled]="!unableToAssess()"
            matTooltipClass="custom-tooltip"
          >
            <button
              id="projects-selection-compare"
              class="btn btn-secondary compare-btn"
              [disabled]="unableToAssess()"
              (click)="goToAssess()"
            >
              {{ "interface_compare" | translationPipe }}
            </button>
          </span>
        </div>
      </div>
    </div>
  </div>
</div>

<app-open-project-modal
  [isComparisonSelection]="true"
  (answer)="selectProject($event)"
></app-open-project-modal>
