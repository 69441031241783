/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import {
  NgbModal,
  NgbModalOptions,
  NgbModalRef,
} from '@ng-bootstrap/ng-bootstrap';
import { ConversionProcess } from '@/app/model/data/conversion-process';
import { MaterialType } from '@/app/model/data/material-type';
import { MaterialService } from 'src/app/services/material.service';
import { PkgComponent } from '@/app/model/packaging-model/component/pkg-component';
import { Material } from '@/app/model/packaging-model/material';
import { plainToClass } from 'class-transformer';
import { CustomValidators } from '@/app/utils/custom-validators';

@Component({
  selector: 'create-material-modal',
  templateUrl: './create-material-modal.component.html',
  styleUrls: ['./create-material-modal.component.css'],
})
export class CreateMaterialModalComponent {
  modal: NgbModalRef | undefined;
  @Input() componentOriginal!: PkgComponent;
  @Input() materialTypes: Array<MaterialType> = [];
  @Input() conversionProcessesPerMaterialTypeGroup!: Map<
    number,
    Array<ConversionProcess>
  >;
  conversionProcesses: Array<ConversionProcess> = [];

  @ViewChild('materialcreationmodal') templateModal!: TemplateRef<any>;

  checkoutForm = this.fb.group({
    materialType: [new MaterialType(), Validators.required],
    conversionProcess: [new ConversionProcess(), Validators.required],
    mass: [
      0,
      Validators.compose([
        Validators.required,
        CustomValidators.nonZeroDecimalValidator.compose,
      ]),
    ],
    recycledContent: [
      0,
      Validators.compose([CustomValidators.percentageValidator.compose]),
    ],
  });

  private readonly MODAL_OPTIONS: NgbModalOptions = {
    centered: true,
  };

  constructor(
    private modalService: NgbModal,
    private fb: FormBuilder,
    private materialService: MaterialService
  ) {}

  onSubmit(): void {
    const recycledContent = this.checkoutForm.value.recycledContent!;
    this.checkoutForm.controls.recycledContent.patchValue(
      recycledContent / 100
    );
    this.checkoutForm.disable();
    if (this.checkoutForm.value != null) {
      // TODO check for eventually missing await
      this.materialService
        .createMaterial(
          this.componentOriginal,
          plainToClass(Material, this.checkoutForm.value)
        )
        .catch((err) => console.error(err));
    }
    this.checkoutForm.enable();
    this.modal?.close();
  }

  open(): void {
    this.checkoutForm.reset();
    this.conversionProcesses = [];
    this.modal = this.modalService.open(this.templateModal, this.MODAL_OPTIONS);
  }

  close(): void {
    this.checkoutForm.reset();
    this.modal?.close();
  }

  setConversionProcesses(): void {
    const materialTypeSelected = this.checkoutForm.controls.materialType.value!;

    this.conversionProcesses = this.conversionProcessesPerMaterialTypeGroup.get(
      materialTypeSelected.materialTypeGroup.id
    )!;
    this.checkoutForm.controls.conversionProcess.reset();
    this.checkoutForm.controls.conversionProcess.markAsPristine();
    this.checkoutForm.controls.conversionProcess.markAsUntouched();
  }

  getHelpBoxTranslation(): string | undefined {
    return this.materialService.getConversionHelpbox.get(
      this.checkoutForm.controls.conversionProcess.value!.label
    );
  }

  protected readonly CustomValidators = CustomValidators;
}
