import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'humanFileSize' })
export class HumanFileSizePipe implements PipeTransform {
  transform(bytes: number | undefined): string {
    if (bytes == undefined) {
      return '-';
    }
    const i = bytes == 0 ? 0 : Math.floor(Math.log(bytes) / Math.log(1024));
    return (
      (bytes / Math.pow(1024, i)).toFixed(2) +
      ' ' +
      ['B', 'kB', 'MB', 'GB', 'TB'][i]
    );
  }
}
