<div
  *ngIf="translationService.translationsLoaded | async"
  class="container-fluid background-colored"
  (window:resize)="sidebarService.onResize($event)"
>
  <div
    class="row full-height"
    *ngIf="singleUseProject != null || rechargeOriginal != null"
  >
    <div
      [hidden]="!isSingleUse && isPackDefSelected"
      id="sidebar"
      class="sidebar col-lg-3 min-vh-100 position-fixed"
    >
      <sidebar-single-use
        [collapsible]="false"
        [projectIsSingleUse]="isSingleUse"
      ></sidebar-single-use>
    </div>
    <div
      [hidden]="!isSingleUse && isPackDefSelected"
      id="sidebarCollapsible"
      class="sidebar min-vh-100"
    >
      <sidebar-single-use
        [collapsible]="true"
        [projectIsSingleUse]="isSingleUse"
      ></sidebar-single-use>
    </div>
    <div
      *ngIf="!isSingleUse && isPackDefSelected"
      id="sidebar"
      class="sidebar col-lg-3 min-vh-100 position-fixed"
    >
      <sidebar-component [collapsible]="false"></sidebar-component>
    </div>
    <div
      *ngIf="!isSingleUse && isPackDefSelected"
      id="sidebarCollapsible"
      class="sidebar min-vh-100"
    >
      <sidebar-component [collapsible]="true"></sidebar-component>
    </div>
    <div
      [hidden]="isPackDefSelected"
      id="sidebarAssess"
      class="sidebar col-lg-3 min-vh-100 position-fixed"
    >
      <sidebar-assess-one-project
        [collapsible]="false"
        [projectIsSingleUse]="isSingleUse"
      >
      </sidebar-assess-one-project>
    </div>
    <div
      [hidden]="isPackDefSelected"
      id="sidebarAssessCollapsible"
      class="sidebar min-vh-100"
    >
      <sidebar-assess-one-project
        [collapsible]="true"
        [projectIsSingleUse]="isSingleUse"
      >
      </sidebar-assess-one-project>
    </div>
    <div id="overlay" (click)="closeSidebar()"></div>

    <div id="projectMain" class="col-lg-9 offset-lg-3 project-main">
      <button
        id="openCollapsibleSidebar"
        class="btn-color"
        (click)="openSidebar()"
      >
        <em class="fas fa-bars"></em>
      </button>
      <div class="col-md-11 project-container">
        <div class="project-title">
          <span class="project-edit">
            <span class="project-name">{{ projectName }}</span>
          </span>
          <div
            ngbDropdown
            #dropdownMenu="ngbDropdown"
            autoClose="outside"
            class="d-inline-block dropdown-icon-only"
          >
            <button class="btn" ngbDropdownToggle>
              <span class="fas fa-ellipsis-v"></span>
            </button>
            <div ngbDropdownMenu aria-labelledby="More actions">
              <button
                ngbDropdownItem
                [disabled]="isReadOnly"
                (click)="openModalToEditProject()"
              >
                <span class="fas fa-pen"></span>Edit
              </button>
              <span
                matTooltip="{{
                  'interface_pro_version_tooltip' | translationPipe
                }} "
                [matTooltipDisabled]="!guestService.isUserGuest()"
                matTooltipPosition="above"
                matTooltipClass="custom-tooltip"
              >
                <button
                  *ngIf="showShareButton"
                  ngbDropdownItem
                  [disabled]="isReadOnly || guestService.isUserGuest()"
                  (click)="openModalToShareProject()"
                >
                  <span class="fas fa-share-alt"></span>Share
                  <span
                    *ngIf="projectCollaboratorsIds.length > 0"
                    class="badge badge-secondary"
                    >{{ projectCollaboratorsIds.length }}</span
                  >
                </button>
              </span>
              <button
                *ngIf="guestService.isUserGuest()"
                ngbDropdownItem
                (click)="deleteStoredProject()"
              >
                <span class="fas fa-trash"></span>Delete
              </button>
              <span
                matTooltip="{{ getExportErrorMessageToDisplay() }}"
                [matTooltipDisabled]="
                  !guestService.isUserGuest() && canAssess()
                "
                matTooltipPosition="above"
                matTooltipClass="custom-tooltip"
              >
                <button
                  ngbDropdownItem
                  [disabled]="
                    exporting || guestService.isUserGuest() || !canAssess()
                  "
                  (click)="exportProject(dropdownMenu)"
                >
                  <span class="fas fa-file-export"></span>
                  <span>
                    Export
                    <spinner
                      *ngIf="exporting"
                      class="export-progress-spinner"
                      size="small"
                      color="blue"
                    ></spinner>
                  </span>
                </button>
              </span>
            </div>
          </div>
          <div
            id="saving"
            class="text-right saving-block"
            [class.invisible]="isReadOnly"
          >
            <em
              class="far fa-save black"
              *ngIf="!animateSaveIcon"
              aria-hidden="true"
            ></em>
            <spinner
              *ngIf="animateSaveIcon"
              [size]="'small'"
              [display]="'inline-block'"
              [color]="'black'"
            >
            </spinner>
            &nbsp; <span>{{ "interface_saving" | translationPipe }}</span>
          </div>
        </div>
        <div *ngIf="isReadOnly" class="read-only-container">
          <div class="header">
            <em class="fas fa-lock"></em>
            {{ "interface_project_read_only_mode_title" | translationPipe }}
          </div>
          <div class="body">
            {{
              "interface_project_read_only_mode_body"
                | translationPipe: getProjectOwnerArgs()
            }}
          </div>
          <div class="retry" *ngIf="!failedFetchCurrentStatusProjectLock">
            <div
              *ngIf="loadingProjectLock"
              class="d-inline-flex align-self-center"
            >
              <div class="mt-auto mb-auto">
                {{
                  "interface_project_lock_load_current_status" | translationPipe
                }}
              </div>
              <spinner class="m-2" [color]="'black'" [size]="'small'"></spinner>
            </div>
            <span *ngIf="!loadingProjectLock">{{
              "interface_project_lock_retry"
                | translationPipe: getRemainingSecondsArgs()
            }}</span>
          </div>
        </div>
        <div class="nav-bar">
          <a
            class="pack-def"
            [class.isSelected]="!this.isAssess()"
            (click)="goToPackagingDefinition()"
            ><em class="fas fa-box"></em>
            {{ "interface_pack_definition" | translationPipe }}</a
          >
          <span
            matTooltip="{{
              'interface_recharge_incomplete' | translationPipe
            }} "
            matTooltipPosition="above"
            [matTooltipDisabled]="canAssess()"
            matTooltipClass="custom-tooltip"
          >
            <a
              id="recharge-project-assess"
              class="assess"
              [class.isSelected]="this.isAssess()"
              (click)="goToAssess()"
              [class.disabled]="!canAssess()"
            >
              <em class="fas fa-chart-pie"></em>
              {{ "interface_assess" | translationPipe }}
            </a>
          </span>
        </div>
        <app-breadcrumb *ngIf="isPackDefSelected"></app-breadcrumb>
        <router-outlet (activate)="setRoutedComponent($event)"></router-outlet>

        <form *ngIf="!isSingleUse && isPackDefSelected">
          <div class="project-info-container">
            <div class="main-actions-container project-block">
              <div class="level-tag">
                <p>{{ "interface_mother_project" | translationPipe }}</p>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <b> {{ "interface_mother_selection" | translationPipe }}</b>
                  <button
                    id="recharge-project-select-mother"
                    type="button"
                    class="filename"
                    (click)="openModalToChooseMother()"
                    [disabled]="isReadOnly"
                  >
                    <div class="row align-items-center">
                      <div class="col-md-10">
                        <p>{{ motherName() }}</p>
                      </div>
                      <div class="col-md-2">
                        <em
                          class="fas fa-folder-open open-icon"
                          title="{{
                            'interface_select_file' | translationPipe
                          }}"
                        ></em>
                      </div>
                    </div>
                  </button>
                </div>
                <div class="col-md-6 align-self-end">
                  <div class="warning-container row align-items-center">
                    <div class="col-md-2">
                      <em class="fas fa-info-circle"></em>
                    </div>
                    <div class="col-md-10">
                      <p>{{ "interface_recharge_note" | translationPipe }}</p>
                      <b *ngIf="this.rechargeProjectLocal.motherProject">
                        {{
                          "interface_recharge_reference_scenario"
                            | translationPipe
                              : referenceScenarioName(
                                  this.rechargeProjectLocal.motherProject
                                )
                        }}
                      </b>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row align-items-center">
                <div class="col-md-6">
                  <p>{{ "interface_number_mother" | translationPipe }}</p>
                </div>
                <div class="col-md-2">
                  <form [formGroup]="motherForm" id="motherForm">
                    <input
                      id="recharge-project-nb-uses-mother"
                      type="number"
                      class="number-input form-control"
                      formControlName="nbUses"
                      step="1"
                      min="{{ this.boundaries.uses.min }}"
                    />
                    <validation-messages
                      [control]="motherForm.get('nbUses')"
                      class="form-invalid-message"
                    >
                    </validation-messages>
                    <div
                      *ngIf="
                        motherFieldHasError(validationHelpers[0].errorName)
                      "
                      class="error-number"
                      [ngClass]="'form-invalid-message'"
                    >
                      {{ validationHelpers[0].label | translationPipe }}
                    </div>
                  </form>
                </div>
                <div class="col-md-2">
                  <p>
                    {{ "interface_times" | translationPipe
                    }}<span class="asterisque">*</span>
                  </p>
                </div>
              </div>
              <div class="warning-container">
                <span class="asterisque"><b>*</b></span>
                <p
                  [innerHTML]="'helpbox_recharge_mother' | translationPipe"
                ></p>
              </div>
            </div>

            <div class="main-actions-container project-block">
              <div class="level-tag">
                <p>{{ "interface_daughter_project" | translationPipe }}</p>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <b> {{ "interface_daughter_selection" | translationPipe }}</b>
                  <button
                    id="recharge-project-select-daughter"
                    type="button"
                    class="filename"
                    (click)="openModalToChooseDaughter()"
                    [disabled]="isReadOnly"
                  >
                    <div class="row align-items-center">
                      <div class="col-md-10">
                        <p>{{ daughterName() }}</p>
                      </div>
                      <div class="col-md-2">
                        <em
                          class="fas fa-folder-open open-icon"
                          title="{{
                            'interface_select_file' | translationPipe
                          }}"
                        ></em>
                      </div>
                    </div>
                  </button>
                </div>
                <div class="col-md-6 align-self-end">
                  <div class="warning-container row align-items-center">
                    <div class="col-md-2">
                      <em class="fas fa-info-circle"></em>
                    </div>
                    <div class="col-md-10">
                      <p>{{ "interface_recharge_note" | translationPipe }}</p>
                      <b *ngIf="this.rechargeProjectLocal.daughterProject">
                        {{
                          "interface_recharge_reference_scenario"
                            | translationPipe
                              : referenceScenarioName(
                                  this.rechargeProjectLocal.daughterProject
                                )
                        }}
                      </b>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row align-items-center">
                <div class="col-md-6">
                  <p>{{ "interface_number_daughter" | translationPipe }}</p>
                </div>
                <div class="col-md-2">
                  <form [formGroup]="daughterForm" id="daughterForm">
                    <input
                      id="recharge-project-nb-uses-daughter"
                      type="number"
                      class="number-input form-control"
                      formControlName="nbUses"
                      step="1"
                      min="{{ this.boundaries.uses.min }}"
                    />
                    <validation-messages
                      [control]="daughterForm.get('nbUses')"
                      class="form-invalid-message"
                    >
                    </validation-messages>
                    <div
                      *ngIf="
                        daughterFieldHasError(validationHelpers[0].errorName)
                      "
                      class="error-number"
                      [ngClass]="'form-invalid-message'"
                    >
                      {{ validationHelpers[0].label | translationPipe }}
                    </div>
                  </form>
                </div>
                <div class="col-md-2">
                  <p>
                    {{ "interface_times" | translationPipe
                    }}<span class="asterisque">*</span>
                  </p>
                </div>
              </div>
              <div class="warning-container">
                <span class="asterisque"><b>*</b></span>
                <p
                  [innerHTML]="'helpbox_recharge_daughter' | translationPipe"
                ></p>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<app-project-modal
  [singleuse]="isSingleUse"
  [createProject]="false"
  [inputProject]="isSingleUse ? this.singleUseProject : this.rechargeOriginal"
>
</app-project-modal>

<app-open-project-modal
  [onlySingleUseSelection]="true"
  (answer)="selectProject($event)"
></app-open-project-modal>

<app-project-collaborators-modal
  [project]="isSingleUse ? this.singleUseProject : this.rechargeOriginal"
>
</app-project-collaborators-modal>

<app-confirm-modal
  [id]="'delete-project'"
  [title]="'interface_project_deletion_title' | translationPipe"
  [content]="
    ('interface_project_deletion_body_1' | translationPipe) +
    this.singleUseProject?.name +
    ('interface_project_deletion_body_2' | translationPipe) +
    this.singleUseProject?.ownerName +
    ' ?'
  "
  (answer)="confirmOrAbortDeletion($event)"
>
</app-confirm-modal>
