<div class="modal-body break-word modal-spacing">
  <div>{{emailSentBodyMessage()}}</div>
  <form
    [formGroup]="forgotPasswordConfirmForm"
    id="emailFormForgotPasswordValidation"
    (ngSubmit)="onForgotPasswordSubmit(forgotPasswordConfirmForm.value)"
  >
    <div class="form-group mt-4">
      <label for="label"
        >{{'interface_activation_code' | translationPipe}}</label
      >
      <input
        id="code"
        class="form-control"
        name="code"
        type="text"
        formControlName="code"
      />
      <validation-messages
        [control]="forgotPasswordConfirmForm.get('code')"
        [touch]="false"
        class="form-invalid-message"
      >
      </validation-messages>
    </div>
    <app-new-password-validation
      (formReady)="formInitialized('newpassword', $event)"
    ></app-new-password-validation>
    <div
      *ngIf="errorMessage != null"
      class="text-center errorMsg form-text mb-3"
    >
      {{errorMessage | translationPipe}}
    </div>
  </form>
</div>
<div class="modal-footer modal-footer-center">
  <button
    type="submit"
    class="btn btn-primary confirm-btn"
    [disabled]="forgotPasswordConfirmForm.invalid"
    (click)="onForgotPasswordSubmit(forgotPasswordConfirmForm.value)"
  >
    {{'interface_change_password' | translationPipe }}
  </button>
</div>
