<app-container-sidebar
  *ngIf="this.translationService.translationsLoaded | async"
>
  <div class="col-md-10 col-lg-12 content-container">
    <div class="content-header">
      <app-back-button></app-back-button>
      <h3 class="box-title inline-block">
        {{ "interface_portfolio_extract" | translationPipe }}
      </h3>
      <div class="header-buttons-container">
        <button (click)="this.filesSubject$.next()" class="btn">
          <em class="fas fa-sync"></em>
          {{ "interface_portfolio_refresh" | translationPipe }}
        </button>
        <button (click)="generateAllProjectExport()" class="btn">
          <em class="fas fa-download"></em>
          {{ "interface_portfolio_new_extract" | translationPipe }}
        </button>
      </div>
    </div>
    <div class="main-actions-container table-container">
      <div class="text-container">
        <span>
          {{ "interface_portfolio_refresh_information" | translationPipe }}
        </span>
      </div>
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">
                {{ "interface_file_name" | translationPipe }}
                <app-sort-toggle
                  [sorting]="sorting"
                  sortedBy="fileName"
                  (toggle)="setSortColumn('fileName')"
                ></app-sort-toggle>
              </th>
              <th scope="col">
                {{ "interface_created_by" | translationPipe }}
                <app-sort-toggle
                  [sorting]="sorting"
                  sortedBy="createdBy"
                  (toggle)="setSortColumn('createdBy')"
                ></app-sort-toggle>
              </th>
              <th scope="col">
                {{ "interface_status" | translationPipe }}
                <app-sort-toggle
                  [sorting]="sorting"
                  sortedBy="status"
                  (toggle)="setSortColumn('status')"
                ></app-sort-toggle>
              </th>
              <th scope="col">
                {{ "interface_file_size" | translationPipe }}
                <app-sort-toggle
                  [sorting]="sorting"
                  sortedBy="fileSize"
                  (toggle)="setSortColumn('fileSize')"
                ></app-sort-toggle>
              </th>
              <th scope="col">
                {{ "interface_start_time" | translationPipe }}
                <app-sort-toggle
                  [sorting]="sorting"
                  sortedBy="createdDate"
                  (toggle)="setSortColumn('createdDate')"
                ></app-sort-toggle>
              </th>
              <th scope="col">
                {{ "interface_finished_time" | translationPipe }}
                <app-sort-toggle
                  [sorting]="sorting"
                  sortedBy="uploadedDate"
                  (toggle)="setSortColumn('uploadedDate')"
                ></app-sort-toggle>
              </th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let file of files$ | async">
              <td>
                {{ file.fileName }}
              </td>
              <td>
                {{ file.createdBy }}
              </td>
              <td>
                {{ file.status }}
              </td>
              <td>
                {{ file.fileSize | humanFileSize }}
              </td>
              <td>
                {{ file.createdDate | date: DATE_FORMAT }}
              </td>
              <td>
                {{ file.uploadedDate | date: DATE_FORMAT }}
              </td>
              <td>
                <button
                  class="icon-button"
                  [disabled]="file.status !== 'Ready'"
                  (click)="downloadFile(file.fileName)"
                >
                  <em class="fas fa-download"></em>
                </button>
              </td>
            </tr>
            <tr *ngIf="(files$ | async)?.length === 0">
              <td colspan="4" class="text-center">
                {{ "interface_no_data" | translationPipe }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</app-container-sidebar>
