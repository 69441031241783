import { Injectable } from '@angular/core';
import {
  SortingDirection,
  TypedSorting,
} from '@/app/model/search/typed-sorting';

@Injectable({
  providedIn: 'root',
})
export class SortService<T> {
  sortArray(array: Array<T>, typedSorting: TypedSorting<keyof T>): void {
    array.sort(this.getComparator(typedSorting));
  }

  getComparator(typedSorting: TypedSorting<keyof T>) {
    return function (a: T, b: T): number {
      const firstValue = a[typedSorting.sortedBy];
      const secondValue = b[typedSorting.sortedBy];
      const isDescending = typedSorting.direction === SortingDirection.DESC;
      // values are equal so return 0 -- don't need to sort
      if (firstValue === secondValue) return 0;

      // toggle between descending and ascending dependent on the currently applied sort direction
      return firstValue > secondValue
        ? isDescending
          ? -1
          : 1
        : isDescending
        ? 1
        : -1;
    };
  }
}
