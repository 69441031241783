import { Component, ViewChild, TemplateRef, Input } from '@angular/core';
import {
  UntypedFormBuilder,
  Validators,
  UntypedFormGroup,
} from '@angular/forms';
import {
  NgbModal,
  NgbModalOptions,
  NgbModalRef,
} from '@ng-bootstrap/ng-bootstrap';
import { ConversionProcess } from '@/app/model/data/conversion-process';

import { FinishingProcessType } from '@/app/model/data/finishing-process-type';
import { FinishingProcessService } from '@/app/services/finishing-process.service';
import { PkgComponent } from '@/app/model/packaging-model/component/pkg-component';
import { plainToClass } from 'class-transformer';
import { FinishingProcess } from '@/app/model/packaging-model/finishing-process';
import { CustomValidators } from '@/app/utils/custom-validators';

@Component({
  selector: 'create-finishing-process-modal',
  templateUrl: './create-finishing-process-modal.component.html',
  styleUrls: ['./create-finishing-process-modal.component.css'],
})
export class CreateFinishingProcessModalComponent {
  modal: NgbModalRef | undefined;
  createFinProcessForm: UntypedFormGroup;
  // @ts-ignore
  isBusy: boolean;
  // @ts-ignore
  @Input() componentOriginal: PkgComponent;
  // @ts-ignore
  @Input() finishingProcessTypes: Array<FinishingProcessType>;
  // @ts-ignore
  @Input() conversionProcesses: Array<ConversionProcess>;

  // @ts-ignore
  @ViewChild('finishingprocesscreationmodal') templateModal: TemplateRef<any>;

  private readonly MODAL_OPTIONS: NgbModalOptions = {
    centered: true,
  };

  constructor(
    private modalService: NgbModal,
    private formBuilder: UntypedFormBuilder,
    private finishingProcessService: FinishingProcessService
  ) {
    this.createFinProcessForm = this.formBuilder.group({
      finishingProcessType: [
        // @ts-ignore
        { value: '', disabled: this.isBusy },
        Validators.required,
      ],
      surface: [
        // @ts-ignore
        { value: '', disabled: this.isBusy },
        Validators.compose([
          Validators.required,
          CustomValidators.nonZeroDecimalValidator.compose,
        ]),
      ],
    });
  }

  // @ts-ignore
  onSubmit(processData) {
    this.isBusy = true;
    if (processData != null) {
      // TODO check for eventually missing await
      this.finishingProcessService
        .createFinishingProcess(
          this.componentOriginal,
          plainToClass(FinishingProcess, processData)
        )
        .catch((err) => console.error(err));
    }
    this.createFinProcessForm.reset();
    this.isBusy = false;
    this.modal?.close();
  }

  open() {
    this.createFinProcessForm.reset();
    this.modal = this.modalService.open(this.templateModal, this.MODAL_OPTIONS);
  }

  close() {
    this.createFinProcessForm.reset();
    this.modal?.close();
  }

  isPristine(formControlName: string) {
    return this.createFinProcessForm.controls[formControlName].pristine;
  }

  getSelectedProcess() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.createFinProcessForm.controls['finishingProcessType'].value;
  }

  getHelpBoxTranslation() {
    return this.finishingProcessService.finishingProcessHelp.get(
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      this.getSelectedProcess().label
    );
  }

  protected readonly CustomValidators = CustomValidators;
}
