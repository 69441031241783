import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GuestService } from '@/app/services/guest.service';
import { TranslationService } from '@/app/services/translationService/translation.service';
import { SidebarService } from '@/app/services/sidebarService/sidebar.service';
import { OpenProjectModalComponent } from '@/app/pages/landing/open-project-modal/open-project-modal.component';
import { ProjectService } from '@/app/services/project.service';
import { DownloadService } from '@/app/services/download-service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-project-comparison',
  templateUrl: './project-comparison.component.html',
  styleUrls: ['./project-comparison.component.css'],
})
export class ProjectComparisonComponent implements OnInit {
  @ViewChild(OpenProjectModalComponent)
  // @ts-ignore
  private openProjectModalComponent: OpenProjectModalComponent;

  isPackDefSelected = true;
  exporting = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public guestService: GuestService,
    public translationService: TranslationService,
    public sidebarService: SidebarService,
    public projectService: ProjectService
  ) {}

  ngOnInit() {
    if (this.guestService.isUserGuest())
      void this.router.navigate(['/'], { relativeTo: this.route });

    this.route.paramMap.subscribe((params) => {
      this.isPackDefSelected = !this.router.url.includes('assess');
      if (!this.isPackDefSelected)
        void this.router.navigate(['projects-comparison/definition'], {
          replaceUrl: true,
        });
    });
  }

  openSidebar() {
    if (this.isPackDefSelected) this.sidebarService.openPackDefSidebar();
    else this.sidebarService.openAssessSidebar();
  }

  closeSidebar() {
    if (this.isPackDefSelected) this.sidebarService.closeSidebar();
    else this.sidebarService.closeAssessSidebar();
  }

  goToPackagingDefinition() {
    this.isPackDefSelected = true;
    void this.router.navigate(['../definition'], { relativeTo: this.route });
  }

  isAssess(): boolean {
    if (this.router.url.includes('assess')) {
      this.isPackDefSelected = false;
      return true;
    }
    return false;
  }

  goToPrevious() {
    if (this.isAssess()) {
      void this.router.navigate(['definition'], { relativeTo: this.route });
      this.isPackDefSelected = true;
    } else {
      this.projectService.resetSelectedProjects();
      void this.router.navigate(['/'], { relativeTo: this.route });
    }
  }

  exportComparison(): void {
    this.exporting = true;
    this.projectService
      .exportComparison()
      .pipe(take(1))
      .subscribe({
        next: (blob: string) => {
          this.exporting = false;
          const data = new Blob([blob]);
          DownloadService.download('project_comparison_export.xlsx', data);
        },
        error: () => {
          this.exporting = false;
        },
      });
  }
}
