import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Project } from 'src/app/model/packaging-model/project/project';
import {
  NgbModal,
  NgbModalOptions,
  NgbModalRef,
} from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { xor } from 'lodash';
import { CollaboratorService } from '@/app/services/collaborator.service';
import { Collaborator } from '@/app/model/collaborator/collaborator';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { SortingDirection } from '@/app/model/search/sorting';
import { TypedSorting } from '@/app/model/search/typed-sorting';
import { ProjectService } from '@/app/services/project.service';
import { Role } from '@/app/model/user/authority';
import {
  NormalizedSelectableCollaborator,
  SelectableCollaborator,
  NormalizedCollaboratorFields,
  collaboratorNormalizedFieldsMapping,
  collaboratorNormalizedFields,
} from '@/app/pages/projects/share-project-modal/share-project-modal';
import { Pager } from '@/app/utils/pager';
import { Paginator } from '@/app/utils/paginator';

/**
 * Modal dialog to share project with collaborators.
 *
 * Important:
 * - The owner, admin and super admin users are implicit collaborators, which means they should not
 *   be explicitly present in the list of collaborators ids exchanged with the back end
 */
@Component({
  selector: 'app-project-collaborators-modal[project]',
  templateUrl: './project-collaborators-modal.component.html',
  styleUrls: ['./project-collaborators-modal.component.css'],
})
export class ProjectCollaboratorsModalComponent implements OnInit, OnDestroy {
  private readonly MODAL_OPTIONS: NgbModalOptions = {
    size: 'xxl',
    backdrop: 'static',
    centered: true,
  };

  @ViewChild('shareprojectmodal')
  private readonly templateModal!: TemplateRef<any>;
  private modal: NgbModalRef | undefined;

  @Input() project!: Project;

  @Input()
  filterSelectedOnly = false;

  @Input()
  editMode = true;

  collaborators: SelectableCollaborator[] = []; // displayed collaborators in current page
  private collaboratorsCache: NormalizedSelectableCollaborator[] = []; // in-memory cache of all collaborators

  canSave = false;

  allSelected = false;
  someSelected = false;
  someSelectable = true;

  readonly searchInput$: Subject<string> = new Subject();
  searchFilter = '';

  sorting = new TypedSorting<keyof NormalizedCollaboratorFields>(
    SortingDirection.ASC,
    'normalizedFirstname'
  );

  pager: Pager | undefined;
  private page = 1;

  sendEmailNotificationsToCollaborators = true;

  constructor(
    private projectService: ProjectService,
    private modalService: NgbModal,
    private collaboratorService: CollaboratorService
  ) {}

  // region LIFECYCLE

  ngOnInit(): void {
    this.bindSearch();
  }

  ngOnDestroy(): void {
    this.unbindSearch();
  }

  // endregion

  // region OPENING & CLOSING DIALOG

  open(): void {
    this.resetDialog();
    this.loadDataAndRefreshView();
    this.modal = this.modalService.open(this.templateModal, this.MODAL_OPTIONS);
  }

  private resetDialog(): void {
    this.canSave = false;
    this.allSelected = false;
    this.someSelected = false;
    this.searchFilter = '';
    this.sorting = new TypedSorting<keyof NormalizedCollaboratorFields>(
      SortingDirection.ASC,
      'normalizedFirstname'
    );
    this.page = 1;
    this.sendEmailNotificationsToCollaborators = true;
  }

  private loadDataAndRefreshView(): void {
    this.collaboratorService
      .getAllCollaborators()
      .then((collaborators) => {
        this.collaboratorsCache = collaborators
          .map((collaborator) => ({
            ...collaborator,
            selected:
              this.project.collaboratorIds.includes(collaborator.id) ||
              this.isDisabled(collaborator),
          }))
          .map((collaborator) =>
            Paginator.normalizeForFilterAndSort(
              collaborator,
              collaboratorNormalizedFieldsMapping
            )
          );
        this.updateSelectAllCheckbox();
        this.refresh();
      })
      .catch((err) => console.error(err));
  }

  private close(): void {
    this.modal?.close();
  }

  // endregion

  // region SAVE & CANCEL

  onSave(): void {
    this.close();
    const updatedProject = new Project();
    updatedProject.fillFields(this.project);
    updatedProject.collaboratorIds = this.getSelectedCollaboratorsIds();
    this.projectService.updateProjectCollaborators(
      this.project,
      updatedProject,
      this.sendEmailNotificationsToCollaborators
    );
  }

  onCancel(): void {
    this.close();
  }

  // endregion

  // region SELECT

  onSelectAll(event: Event): void {
    const checked = (event.target as unknown as { checked: boolean }).checked;
    this.collaboratorsCache
      .filter((collaborator) => !this.isDisabled(collaborator))
      .forEach((collaborator) => (collaborator.selected = checked));
    this.updateSelectAllCheckbox();
    this.updateCanSave();
    if (this.filterSelectedOnly) this.refresh();
  }

  onSelect(collaborator: SelectableCollaborator): void {
    if (this.isDisabled(collaborator)) return;
    collaborator.selected = !collaborator.selected;
    this.updateSelectAllCheckbox();
    this.updateCanSave();
    if (this.filterSelectedOnly) this.refresh();
  }

  private getSelectedCollaboratorsIds() {
    return this.collaboratorsCache
      .filter((collaborator) => collaborator.selected)
      .filter((collaborator) => !this.isDisabled(collaborator))
      .map((collaborator) => collaborator.id);
  }

  private updateCanSave() {
    const originalCollaboratorsIds = this.project.collaboratorIds;
    const updatedCollaboratorsIds = this.getSelectedCollaboratorsIds();
    this.canSave =
      xor(originalCollaboratorsIds, updatedCollaboratorsIds).length > 0;
  }

  private updateSelectAllCheckbox() {
    const selectedCount = this.collaboratorsCache.filter(
      (collaborator) => collaborator.selected
    ).length;
    this.someSelected =
      selectedCount > 0 && selectedCount < this.collaboratorsCache.length;
    this.allSelected = selectedCount === this.collaboratorsCache.length;
    this.someSelectable = this.collaboratorsCache.some(
      (collaborator) => !this.isDisabled(collaborator)
    );
  }

  // endregion

  // region FILTER, SORT & PAGINATE

  onSearch(val: string | undefined): void {
    this.searchInput$.next(val);
  }

  private bindSearch(): void {
    this.searchInput$
      .pipe(debounceTime(50), distinctUntilChanged())
      .subscribe((_) => this.refresh());
  }

  private unbindSearch(): void {
    this.searchInput$.unsubscribe();
  }

  onToggleShowSelectedOnly(): void {
    this.filterSelectedOnly = !this.filterSelectedOnly;
    this.page = 1;
    this.refresh();
  }

  onToggleSort(): void {
    this.refresh();
  }

  onSetPage(page: number): void {
    this.page = page;
    this.refresh();
  }

  private refresh() {
    const { items, pager, page } = Paginator.filterSortAndPaginate(
      this.collaboratorsCache,
      collaboratorNormalizedFields,
      this.searchFilter,
      this.sorting,
      this.page,
      { filterSelectedOnly: this.filterSelectedOnly }
    );
    this.collaborators = items;
    this.pager = pager;
    this.page = page;
  }

  // endregion

  // region COLLABORATOR

  isOwner(collaborator: Collaborator): boolean {
    return collaborator.id === this.project.ownerId;
  }

  isAdmin(collaborator: Collaborator): boolean {
    return collaborator.authorities.includes(Role.ADMIN);
  }

  isSuperAdmin(collaborator: Collaborator): boolean {
    return collaborator.authorities.includes(Role.SUPER_ADMIN);
  }

  isDisabled(collaborator: Collaborator): boolean {
    return (
      this.isOwner(collaborator) ||
      this.isAdmin(collaborator) ||
      this.isSuperAdmin(collaborator)
    );
  }

  // endregion
}
