/**
 * Get a Map instance representing the given object.
 *
 * The object is assumed to only have values of type T.
 *
 * @param object to get a Map instance from
 * @return a Map instance representing the given object
 */
export function objectToMap<T>(object: { [key: string]: T }): Map<string, T> {
  return Object.entries(object).reduce((map, entry) => {
    const key = entry[0];
    const value = entry[1];
    map.set(key, value);
    return map;
  }, new Map<string, T>());
}
