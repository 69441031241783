<div
  *ngIf="translationService.translationsLoaded | async"
  class="login-container container-fluid"
>
  <div class="row">
    <div class="col-md-10 col-lg-8 center">
      <div class="row">
        <div class="col-md-6">
          <div class="left-side">
            <div class="menu">
              <ul class="menu-ul">
                <li
                  class="menu-li"
                  [class.is-selected]="isLogin"
                  (click)="selectLogin()"
                >
                  {{ "interface_login" | translationPipe }}
                </li>
                <li
                  class="menu-li"
                  [class.is-selected]="!isLogin"
                  (click)="unselectLogin()"
                >
                  {{ "interface_guest_access" | translationPipe }}
                </li>
              </ul>
            </div>
            <div *ngIf="isLogin">
              <div class="login-box-container">
                <h3 class="login-title">
                  {{ "interface_login" | translationPipe }}
                </h3>
                <app-login-box></app-login-box>
              </div>
            </div>
            <div *ngIf="!isLogin">
              <div class="guest-access-container">
                <h3 class="guest-user-title">
                  {{ "interface_guest_title" | translationPipe }}
                </h3>
                <app-guest-access-box
                  (goToLogin)="selectLogin()"
                ></app-guest-access-box>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="video-container">
            <img
              src="assets/logo-bottle.png"
              class="padding-img"
              alt="SPICE Logo"
            />
            <!--iframe width="420" height="315" src="https://www.youtube.com/embed/tgbNymZ7vqY"></iframe-->
          </div>
        </div>
      </div>
      <div class="row bottom-login-info">
        <div class="col-md-6 info-block">
          <div class="left-side-info">
            <h4>{{ "interface_about_tool_title" | translationPipe }}</h4>
            <p>{{ "interface_about_tool_desc" | translationPipe }}</p>
          </div>
        </div>
        <div class="col-md-6 info-block">
          <h4>{{ "interface_contact" | translationPipe }}</h4>
          <a
            class="contact-link"
            [href]="'interface_contact_url' | translationPipe"
            >{{ "interface_contact_url" | translationPipe }}</a
          >
          <div class="contact">
            <em class="fas fa-envelope"></em
            ><a class="contact-link" href="mailto:tool@open-spice.com"
              >tool@open-spice.com</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
