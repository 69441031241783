import { AbstractControl } from '@angular/forms';

export abstract class FormUtils {
  static updateModelFromFieldValue<T>(
    ctrl: AbstractControl,
    fn: (v: T) => void
  ) {
    ctrl.valueChanges.subscribe((v) => {
      // TODO add runtime type check to ensure that v is of type T
      if (ctrl.valid) fn(v);
    });
  }
}
