import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiUtils } from '@/app/api/api-utils';
import { Observable } from 'rxjs';
import { S3FileRecord } from '@/app/services/file.service';
import { plainToClassFromExist } from 'class-transformer';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FileApiService {
  constructor(private http: HttpClient) {}

  getFiles(): Observable<S3FileRecord[]> {
    const url = this.buildPath('project-export-files');
    const httpOptions = {
      headers: { 'Content-Type': 'application/json' },
    };
    return this.http
      .get<S3FileRecord[]>(url, httpOptions)
      .pipe(
        map((response) =>
          plainToClassFromExist(new Array<S3FileRecord>(), response)
        )
      );
  }

  downloadFile(fileName: string): Observable<string> {
    const url = this.buildPath('download');
    const httpOptions = {
      responseType: 'blob' as 'json',
    };
    return this.http.post<string>(url, fileName, httpOptions);
  }

  private buildPath(endPoint: string): string {
    return ApiUtils.BASE_PATH + 'file/' + endPoint;
  }
}
