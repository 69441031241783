import {
  Component,
  Input,
  ViewChildren,
  QueryList,
  ViewChild,
} from '@angular/core';
import { SingleUseProject } from 'src/app/model/packaging-model/project/single-use-project';
import { Scenario } from '@/app/model/packaging-model/scenario';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectService } from '@/app/services/project.service';
import { PkgComponent } from '@/app/model/packaging-model/component/pkg-component';
import { ComponentLevel } from '@/app/model/packaging-model/component/component-level.enum';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';
import { GlobalService } from '@/app/services/global.service';
import { PkgComponentService } from '@/app/services/pkg-component.service';
import { GuestService } from '@/app/services/guest.service';
import { LoadingService } from '@/app/services/loading.service';
import assert from 'assert';
import { ExportScenariosModalComponent } from '../projects/export-scenarios-modal/export-scenarios-modal.component';
import { LoginService } from '@/app/services/login/login.service';
import { ScenarioLibraryComponent } from '../scenario-library/scenario-library.component';

@Component({
  selector: 'sidebar-single-use',
  templateUrl: './sidebar-single-use.component.html',
  styleUrls: ['./sidebar-single-use.component.css'],
})
export class SidebarSingleUseComponent {
  @Input() collapsible!: boolean;
  @Input() projectIsSingleUse!: boolean;
  @ViewChildren(ConfirmModalComponent)
  private confirmModals!: QueryList<ConfirmModalComponent>;

  @ViewChild(ExportScenariosModalComponent)
  private exportScenariosModalComponent!: ExportScenariosModalComponent;

  @ViewChild(ScenarioLibraryComponent)
  private scenarioLibraryComponent!: ScenarioLibraryComponent;

  componentToBeDeleted: PkgComponent;
  scenarioToBeDeleted: Scenario;
  scenarioToBeReferenced: Scenario | undefined;

  get noTertiary(): boolean {
    return this.projectService.noTertiary;
  }

  get isReadOnly(): boolean {
    return this.projectService.isReadOnly;
  }
  get project(): SingleUseProject {
    if (this.guestService.isUserGuest() && this.guestService.guestUserProject) {
      return this.guestService.guestUserProject;
    } else return this.projectService.currentSingleUseProject;
  }
  get selectedScenario(): Scenario {
    return this.projectService.selectedScenario;
  }

  get selectedComponent(): PkgComponent {
    return this.pkgComponentService.selectedPkgComponent;
  }

  get componentLevels(): Array<ComponentLevel> {
    return this.projectService.componentLevels;
  }

  getStringFromLevel(componentLevel: ComponentLevel): string {
    return ProjectService.levelToString.get(componentLevel) ?? '';
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public projectService: ProjectService,
    private pkgComponentService: PkgComponentService,
    public globalService: GlobalService,
    public guestService: GuestService,
    public loadingService: LoadingService,
    private loginService: LoginService
  ) {
    this.scenarioToBeDeleted = projectService.selectedScenario;
    this.componentToBeDeleted = pkgComponentService.selectedPkgComponent;
  }

  get isGeneralInfo() {
    return this.router.url.split('/').find((s) => s == 'general-info') != null;
  }

  userIsOwner(): boolean {
    return this.project.ownerId === this.loginService.currentUser?.id;
  }

  closeCollapsibleSidebar() {
    if (this.collapsible) {
      const event = new Event('closeSidebar');
      window.dispatchEvent(event);
    }
  }

  async createScenario(): Promise<void> {
    const createdScenarioId: number =
      await this.projectService.createScenario();
    this.goToScenario(createdScenarioId);
  }

  goToScenario(scenarioId: number): void {
    void this.router.navigate(['scenario', scenarioId, 'general-info'], {
      relativeTo: this.route,
    });
  }

  deleteScenario(scenario: Scenario): void {
    this.scenarioToBeDeleted = scenario;
    this.confirmModals.filter((item) => item.id == 'delete-scenario')[0].open();
  }

  async confirmOrAbortScenarioDeletion(answer: boolean): Promise<void> {
    this.confirmModals
      .filter((item) => item.id == 'delete-scenario')[0]
      .close();
    if (answer)
      await this.projectService.deleteScenario(this.scenarioToBeDeleted);
    if (this.scenarioToBeDeleted.id == this.projectService.selectedScenarioId)
      this.goToScenario(
        this.projectService.currentSingleUseProject.referenceScenarioId
      );
  }

  async duplicateScenario(scenario: Scenario): Promise<void> {
    const duplicatedScenarioId: number =
      await this.projectService.duplicateScenario(scenario);
    this.goToScenario(duplicatedScenarioId);
  }

  setToReference(scenario: Scenario): void {
    this.scenarioToBeReferenced = scenario;
    this.confirmModals
      .filter((item) => item.id == 'side-reference-scenario')[0]
      .open();
  }

  async confirmOrAbortReferencingScenario(answer: boolean): Promise<void> {
    this.confirmModals
      .filter((item) => item.id == 'side-reference-scenario')[0]
      .close();
    if (answer && this.scenarioToBeReferenced) {
      await this.projectService.setScenarioToReference(
        this.scenarioToBeReferenced.id
      );
    }
  }

  selectComponent(pkgComponentId: number) {
    this.pkgComponentService.selectPkgComponent(pkgComponentId);
    void this.router.navigate(
      ['scenario', this.selectedScenario.id, 'component', pkgComponentId],
      { relativeTo: this.route }
    );
  }

  async createComponent(componentLevel: ComponentLevel, scenario: Scenario) {
    await this.pkgComponentService.createPackagingComponent(
      scenario,
      componentLevel
    );
  }

  createComponentAndNavigate(scenario: Scenario, level: ComponentLevel) {
    void this.createComponent(level, scenario)
      .then((any) => {
        void this.router.navigate(
          [
            'scenario',
            scenario.id,
            'component',
            this.pkgComponentService.selectedPkgComponentId,
          ],
          { relativeTo: this.route }
        );
      })
      .catch((err) => console.error(err));
  }

  isLevelExisting(level: ComponentLevel): boolean {
    let res = false;
    switch (level) {
      case ComponentLevel.PRIMARY:
        res = this.selectedScenario.firstPrimaryPackaging !== undefined;
        break;
      case ComponentLevel.SECONDARY:
        res = this.selectedScenario.hasSecondaryPackaging;
        break;
      case ComponentLevel.TERTIARY_BOX:
        res =
          this.selectedScenario.firstTertiaryPackagingBox !== undefined &&
          !this.noTertiary;
        break;
      case ComponentLevel.TERTIARY_PALLETIZATION:
        res =
          this.selectedScenario.firstTertiaryPackagingPalletization !==
            undefined && !this.noTertiary;
        break;
    }
    return res;
  }

  isScenarioLimitReached(): boolean {
    const scenariosPerProject = this.globalService.limits.get(
      'scenariosPerProject'
    );
    assert(scenariosPerProject !== undefined);
    return this.project.scenarios.length >= scenariosPerProject;
  }

  isComponentLimitReached(scenario: Scenario, level: ComponentLevel) {
    const limit = this.guestService.isUserGuest()
      ? this.globalService.limitsGuestUser.get('componentsPerLevel')
      : this.globalService.limits.get('componentsPerLevel');
    assert(limit !== undefined);
    return scenario.componentsOfLevel(level).length >= limit;
  }

  deleteComponent(component: PkgComponent) {
    this.componentToBeDeleted = component;
    this.confirmModals
      .filter((item) => item.id == 'delete-component')[0]
      .open();
  }

  async confirmOrAbortComponentDeletion(answer: boolean): Promise<void> {
    this.confirmModals
      .filter((item) => item.id == 'delete-component')[0]
      .close();
    if (answer) {
      await this.pkgComponentService.deletePkgComponent(
        this.componentToBeDeleted
      );
      this.pkgComponentService.removeComponentFromSelectedScenario(
        this.componentToBeDeleted
      );
    }
    if (
      this.componentToBeDeleted.id ==
      this.pkgComponentService.selectedPkgComponentId
    )
      this.goToScenario(this.selectedScenario.id);
  }

  exportScenario() {
    if (!this.isReadOnly && !this.guestService.isUserGuest())
      this.exportScenariosModalComponent.open();
  }

  openLibraryModal() {
    if (!this.isReadOnly && !this.guestService.isUserGuest())
      this.scenarioLibraryComponent.open();
  }
}
