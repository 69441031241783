import { User } from './user';
import { Transform } from 'class-transformer';

export class UserComplete extends User {
  status!: string;

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-call
  @Transform((value) => new Date(value.substring(0, 19))) // Format "YYYY-MM-DDThh:mm::ss" (first 19 elements)
  userCreateDate!: Date;

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-call
  @Transform((value) => new Date(value.substring(0, 19))) // Format "YYYY-MM-DDThh:mm::ss" (first 19 elements)
  userLastModifiedDate!: Date;
}
