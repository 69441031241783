<app-container-sidebar
  *ngIf="this.translationService.translationsLoaded | async"
>
  <div class="col-md-10 col-lg-12 content-container">
    <div class="content-header">
      <app-back-button></app-back-button>
      <h3 class="box-title inline-block">
        {{ "interface_manage_companies" | translationPipe }}
      </h3>
    </div>
    <div class="main-actions-container">
      <div class="top-bar">
        <div class="search-box">
          <input
            type="text"
            class="search-input"
            placeholder="{{ 'interface_search' | translationPipe }} "
            [(ngModel)]="this.nameFilter"
            (keyup)="launchSearch()"
          />
          <em class="fas fa-search mar-right"> </em>
        </div>
        <div class="add-company-btn">
          <button
            class="btn btn-secondary add-comp"
            (click)="openModalToCreateCompany(true, null)"
          >
            <em class="fas fa-plus mar-right"></em>
            {{ "interface_organization" | translationPipe }} /
            {{ "interface_company" | translationPipe }}
          </button>
        </div>
      </div>
      <table *ngIf="companies.length > 0" class="table">
        <thead>
          <tr>
            <th scope="col">
              {{ "interface_company" | translationPipe }}
              <app-sort-toggle
                [sorting]="sorting"
                sortedBy="name"
                (toggle)="launchSearch()"
              ></app-sort-toggle>
            </th>
            <th scope="col">
              <div class="parent">
                {{ "interface_status" | translationPipe }}
              </div>
            </th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let company of companies">
            <td class="space-left">
              {{ company.name }}
            </td>
            <td class="space-left" *ngIf="company.enabled">
              <em class="fas fa-check green"></em>
              {{ "interface_enabled" | translationPipe }}
            </td>
            <td class="space-left" *ngIf="!company.enabled">
              <em class="far fa-times-circle errorMsg"></em>
              {{ "interface_disabled" | translationPipe }}
            </td>
            <td class="actions">
              <em
                class="fas fa-pen"
                (click)="openModalToCreateCompany(false, company)"
              ></em>
            </td>
          </tr>
        </tbody>
      </table>
      <div *ngIf="companies.length > 0" class="paginationContainer">
        <app-pagination-bar
          [pager]="pager"
          (selectedPage)="setPage($event)"
        ></app-pagination-bar>
      </div>
    </div>
  </div>
</app-container-sidebar>

<app-create-company-modal
  [addCompany]="this.addCompany"
  [currentCompany]="this.currentCompany"
  (changeMade)="launchSearch()"
>
</app-create-company-modal>
