import { GuestService } from '@/app/services/guest.service';
import { SidebarService } from '@/app/services/sidebarService/sidebar.service';
import { TranslationService } from '@/app/services/translationService/translation.service';
import { Location } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'app-recycled-content-appendix',
  templateUrl: './recycled-content-appendix.component.html',
  styleUrls: ['./recycled-content-appendix.component.css'],
})
export class RecycledContentAppendixComponent {
  constructor(
    public translationService: TranslationService,
    public sidebarService: SidebarService,
    public guestService: GuestService,
    private location: Location
  ) {}

  goBack() {
    this.location.back();
  }

  onLinkClick(anchor: string): void {
    document.getElementById(anchor)!.scrollIntoView();
    // will scroll to 4th h3 element
  }
}
