import { Feature } from './feature';
import { ActivationRule } from './activation-rule';

export class FeatureStatus {
  private _feature: Feature;
  _isActive = true;
  // @ts-ignore
  _inactivityReason: string = null;
  _perfectMatchRules: Array<ActivationRule>;
  _cascadeMatchRules: Array<ActivationRule>;

  constructor(
    _feature: Feature,
    _perfectMatchRules: Array<ActivationRule>,
    _cascadeMatchRules: Array<ActivationRule>
  ) {
    this._feature = _feature;
    this._perfectMatchRules = _perfectMatchRules;
    this._cascadeMatchRules = _cascadeMatchRules;
  }

  get feature(): Feature {
    return this._feature;
  }

  get isActive(): boolean {
    return this._isActive;
  }

  get inactivityReason(): string {
    return this._inactivityReason;
  }
}
