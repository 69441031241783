<ng-container *ngIf="this.translationService.translationsLoaded | async">
  <app-container-sidebar>
    <div class="col-md-10 col-lg-12 content-container">
      <div class="content-header">
        <app-back-button></app-back-button>
        <h3 class="box-title inline-block">
          {{ "interface_manage_users" | translationPipe }}
        </h3>
      </div>
      <div class="main-actions-container">
        <ul *ngIf="isSuperAdmin()" class="nav nav-justified nav-users">
          <li class="nav-item">
            <a
              class="nav-link user-link"
              [routerLink]="['/user-management/member-user']"
              routerLinkActive="router-active"
            >
              {{ "interface_manage_member_user" | translationPipe }}
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link user-link"
              [routerLink]="['/user-management/guest-user']"
              routerLinkActive="router-active"
            >
              {{ "interface_role_guest" | translationPipe }}
            </a>
          </li>
        </ul>
        <router-outlet></router-outlet>
      </div>
    </div>
  </app-container-sidebar>
</ng-container>
