import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SidebarService {
  openPackDefSidebar() {
    // @ts-ignore
    document.getElementById('sidebarCollapsible').classList.add('active');
    // @ts-ignore
    document.getElementById('overlay').classList.add('active');
  }

  openAssessSidebar() {
    // @ts-ignore
    document.getElementById('sidebarAssessCollapsible').classList.add('active');
    // @ts-ignore
    document.getElementById('overlay').classList.add('active');
  }

  onResize(event: UIEvent) {
    const width = (<Window>event.target).innerWidth;
    if (width > 991) {
      if (document.getElementById('sidebarCollapsible') != null)
        this.closeSidebar();
      if (document.getElementById('sidebarAssessCollapsible') != null)
        this.closeAssessSidebar();
    }
  }

  closeSidebar() {
    // @ts-ignore
    document.getElementById('overlay').classList.remove('active');
    // @ts-ignore
    document.getElementById('sidebarCollapsible').classList.remove('active');
  }

  closeAssessSidebar() {
    // @ts-ignore
    document.getElementById('overlay').classList.remove('active');
    // @ts-ignore
    document
      .getElementById('sidebarAssessCollapsible')
      .classList.remove('active');
  }
}
