import { Component } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { TranslationService } from 'src/app/services/translationService/translation.service';
import { CustomValidators } from '@/app/utils/custom-validators';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.css'],
})
export class LoginPageComponent {
  isLogin = true;
  checkoutForm: UntypedFormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder,
    public translationService: TranslationService
  ) {
    this.checkoutForm = this.formBuilder.group({
      firstname: [
        '',
        Validators.compose([
          Validators.required,
          CustomValidators.notSpaceValidator(),
        ]),
      ],
      lastname: [
        '',
        Validators.compose([
          Validators.required,
          CustomValidators.notSpaceValidator(),
        ]),
      ],
      organization: [
        '',
        Validators.compose([
          Validators.required,
          CustomValidators.notSpaceValidator(),
        ]),
      ],
      email: [
        '',
        Validators.compose([
          Validators.required,
          CustomValidators.notSpaceValidator(),
        ]),
      ],
      'terms-of-use': ['', Validators.required],
    });
  }

  selectLogin() {
    this.isLogin = true;
  }

  unselectLogin() {
    this.isLogin = false;
  }

  // @ts-ignore
  onSubmit(customerData) {
    console.log('Nothing submitted');
    console.log(customerData);
  }
}
