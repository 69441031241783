import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ApiUtils } from './api-utils';
import { plainToClass } from 'class-transformer';
import { PkgComponent } from '../model/packaging-model/component/pkg-component';
import { Observable } from 'rxjs';
import { ComponentResult } from '../model/results/component-result';

@Injectable({
  providedIn: 'root',
})
export class PkgComponentApiService {
  buildPath(endPoint: string): string {
    return ApiUtils.BASE_PATH + 'components/' + endPoint;
  }

  constructor(private http: HttpClient) {}

  async createPkgComponent(
    packagingId: number,
    newPkgComponent: PkgComponent
  ): Promise<PkgComponent> {
    const url = this.buildPath(packagingId.toString());
    const response = await this.http
      .post<PkgComponent>(url, newPkgComponent, ApiUtils.HTTP_OPTIONS)
      .toPromise();
    return plainToClass(PkgComponent, response);
  }

  async updatePkgComponent(
    componentId: number,
    updatedComponent: PkgComponent
  ): Promise<PkgComponent> {
    const url = this.buildPath(componentId.toString());
    const response = await this.http
      .put<PkgComponent>(url, updatedComponent, ApiUtils.HTTP_OPTIONS)
      .toPromise();
    return plainToClass(PkgComponent, response);
  }

  async deletePkgComponent(componentId: number): Promise<void> {
    const url = this.buildPath(componentId.toString());
    await this.http
      .delete<PkgComponent>(url, ApiUtils.HTTP_OPTIONS)
      .toPromise();
  }

  assessPkgComponentForIndicator(
    componentId: number,
    indicatorId: number
  ): Observable<ComponentResult> {
    const url = this.buildPath(`${componentId}/assess`);
    const queryParam = new HttpParams().set(
      'indicatorId',
      indicatorId.toString()
    );
    const httpOptions = {
      headers: { 'Content-Type': 'application/json' },
      params: queryParam,
    };
    return this.http.get<ComponentResult>(url, httpOptions);
  }

  assessRechargePkgComponentForIndicator(
    componentId: number,
    indicatorId: number,
    rechargeProjectId: number
  ): Observable<ComponentResult> {
    const url = this.buildPath(
      `${componentId}/assess-recharge/${rechargeProjectId}`
    );
    const queryParam = new HttpParams().set(
      'indicatorId',
      indicatorId.toString()
    );
    const httpOptions = {
      headers: { 'Content-Type': 'application/json' },
      params: queryParam,
    };
    return this.http.get<ComponentResult>(url, httpOptions);
  }

  assessPkgComponentForSingleScore(
    componentId: number
  ): Observable<ComponentResult> {
    const url = this.buildPath(`${componentId}/assess`);
    return this.http.get<ComponentResult>(url);
  }

  assessRechargePkgComponentForSingleScore(
    componentId: number,
    rechargeProjectId: number
  ): Observable<ComponentResult> {
    const url = this.buildPath(
      `${componentId}/assess-recharge/${rechargeProjectId}`
    );
    return this.http.get<ComponentResult>(url);
  }
}
