import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  constructor(private router: Router) {}

  redirectToErrorPage() {
    void this.router.navigate(['/error', 'not-found']);
  }

  redirectToCompanyDisabledPage() {
    void this.router.navigate(['/error', 'company-disabled']);
  }
}
