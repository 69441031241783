<div
  class="general-info-container"
  *ngIf="
    globalService.listsReady &&
    !computing &&
    !errorsWhileComputing &&
    !error500 &&
    incompleteProjects.length == 0
  "
>
  <div class="warning-container" *ngIf="this.projects.length > 0">
    <em class="fas fa-info-circle"></em
    >{{ "interface_result_warning" | translationPipe }}
  </div>
  <div class="general-info-block" [hidden]="this.projects.length == 0">
    <div class="main-actions-container align-left">
      <h4>{{ "interface_assess_packaging_overview" | translationPipe }}</h4>
      <div class="level-tag">
        <p>
          {{ "interface_assess_environmental_single_score" | translationPipe }}
        </p>
      </div>
      <div class="explanations">
        {{ "interface_explanations_single_score" | translationPipe }}
        <a (click)="goToExplanationPage()">{{
          "interface_explanation_this_page" | translationPipe
        }}</a>
      </div>
      <table class="table score-table table-striped">
        <thead>
          <th width="33.33%">
            {{ "interface_error_deleting_project_body_1" | translationPipe }}
          </th>
          <th width="33.33%">
            {{ "interface_assess_score" | translationPipe }} ({{
              "unit_pt_per_ml" | translationPipe
            }})
          </th>
          <th width="33.33%">
            {{ "interface_assess_difference_from_worst" | translationPipe }}
          </th>
        </thead>
        <tbody>
          <tr *ngFor="let project of projects">
            <td>{{ project.name }}</td>
            <td>{{ singleScoreForProject(project) }}</td>
            <td>
              <div
                *ngIf="
                  selectedRechargeOrReferenceScenariosForAssess.length > 1 &&
                  getSingleScoreDifference(project) != 0 &&
                  ((isRecharge(project) &&
                    getScenarioWithMinSingleScore() == project.id) ||
                    (!isRecharge(project) &&
                      getScenarioWithMinSingleScore() ==
                        project.referenceScenarioId))
                "
                class="difference-tag"
              >
                <div class="difference-tag">
                  <p>
                    <em class="fa fa-leaf mright-5"></em
                    >{{ getSingleScoreDifference(project).toFixed(0) }}%
                  </p>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="row scenario-infos">
        <div class="col-md-12">
          <div class="form-group">
            <span class="graph-title">{{
              "interface_assess_kpi_breakdown" | translationPipe
            }}</span>
            <div class="left-align explanations">
              {{ "interface_assess_click_legend" | translationPipe }}
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <div class="graph_container">
              <canvas
                id="{{
                  'packagingKPIChart_' +
                    selectedRechargeOrReferenceScenariosForAssess[0].id
                }}"
              ></canvas>
            </div>
          </div>
        </div>
      </div>

      <div class="level-tag">
        <p>{{ "interface_assess_absolute_results" | translationPipe }}</p>
      </div>
      <div class="explanations">
        {{ "interface_explanations_absolute_results" | translationPipe }}
        <a (click)="goToExplanationPage()">{{
          "interface_explanation_this_page" | translationPipe
        }}</a>
      </div>
      <div class="table-container">
        <table class="table table-hover table-striped">
          <thead>
            <th
              width="15%"
              style="vertical-align: middle"
              (click)="
                scenarioDir === null
                  ? sortPerProjectName('ASC')
                  : scenarioDir === 'ASC'
                  ? sortPerProjectName('DESC')
                  : sortPerProjectName('ASC')
              "
            >
              {{ "interface_scenario" | translationPipe }}
              <em
                class="fas fa-sort-down ml-1 pointer"
                *ngIf="scenarioDir != null && scenarioDir === 'ASC'"
              ></em>
              <em
                class="fas fa-sort-up ml-1 pointer"
                *ngIf="scenarioDir != null && scenarioDir === 'DESC'"
              ></em>
              <em
                class="fa fa-sort ml-1 pointer"
                *ngIf="scenarioDir == null"
              ></em>
            </th>
            <th width="28.33%">
              <ng-select
                class="select-scenarios"
                [items]="$any(indicatorsAsList())"
                bindLabel="value"
                [trackByFn]="trackByFn"
                [multiple]="false"
                [(ngModel)]="firstSelectedIndic"
                (change)="updateSelectedIndicators()"
                [closeOnSelect]="true"
                [clearable]="false"
              >
              </ng-select>

              <em
                class="fas fa-sort-down ml-1 pointer"
                *ngIf="indic1Dir != null && indic1Dir === 'ASC'"
                (click)="sortPerIndicator(firstSelectedIndic, 'DESC')"
              ></em>
              <em
                class="fas fa-sort-up ml-1 pointer"
                *ngIf="indic1Dir != null && indic1Dir === 'DESC'"
                (click)="sortPerIndicator(firstSelectedIndic, 'ASC')"
              ></em>
              <em
                class="fa fa-sort ml-1 pointer"
                *ngIf="indic1Dir == null"
                (click)="sortPerIndicator(firstSelectedIndic, 'ASC')"
              ></em>
            </th>
            <th width="28.33%">
              <ng-select
                class="select-scenarios"
                [items]="$any(indicatorsAsList())"
                bindLabel="value"
                [trackByFn]="trackByFn"
                [multiple]="false"
                [(ngModel)]="secondSelectedIndic"
                (change)="updateSelectedIndicators()"
                [closeOnSelect]="true"
                [clearable]="false"
              >
              </ng-select>
              <em
                class="fas fa-sort-down ml-1 pointer"
                *ngIf="indic2Dir != null && indic2Dir === 'ASC'"
                (click)="sortPerIndicator(secondSelectedIndic, 'DESC')"
              ></em>
              <em
                class="fas fa-sort-up ml-1 pointer"
                *ngIf="indic2Dir != null && indic2Dir === 'DESC'"
                (click)="sortPerIndicator(secondSelectedIndic, 'ASC')"
              ></em>
              <em
                class="fa fa-sort ml-1 pointer"
                *ngIf="indic2Dir == null"
                (click)="sortPerIndicator(secondSelectedIndic, 'ASC')"
              ></em>
            </th>
            <th width="28.33%">
              <ng-select
                class="select-scenarios"
                [items]="$any(indicatorsAsList())"
                bindLabel="value"
                [trackByFn]="trackByFn"
                [multiple]="false"
                [(ngModel)]="thirdSelectedIndic"
                (change)="updateSelectedIndicators()"
                [closeOnSelect]="true"
                [clearable]="false"
              >
              </ng-select>
              <em
                class="fas fa-sort-down ml-1 pointer"
                *ngIf="indic3Dir != null && indic3Dir === 'ASC'"
                (click)="sortPerIndicator(thirdSelectedIndic, 'DESC')"
              ></em>
              <em
                class="fas fa-sort-up ml-1 pointer"
                *ngIf="indic3Dir != null && indic3Dir === 'DESC'"
                (click)="sortPerIndicator(thirdSelectedIndic, 'ASC')"
              ></em>
              <em
                class="fa fa-sort ml-1 pointer"
                *ngIf="indic3Dir == null"
                (click)="sortPerIndicator(thirdSelectedIndic, 'ASC')"
              ></em>
            </th>
          </thead>
          <tbody>
            <ng-template
              ngFor
              let-s
              [ngForOf]="resultsPerIndicators"
              let-i="index"
            >
              <tr>
                <td>
                  {{ resultsPerIndicators[i].project.name }}
                </td>
                <td *ngIf="resultsPerIndicators[i].indicator1 != undefined">
                  {{
                    getFormattedResult(resultsPerIndicators[i].resultIndicator1)
                  }}
                  {{ resultsPerIndicators[i].indicator1.displayUnit }}
                </td>
                <td *ngIf="resultsPerIndicators[i].indicator2 != undefined">
                  {{
                    getFormattedResult(resultsPerIndicators[i].resultIndicator2)
                  }}
                  {{ resultsPerIndicators[i].indicator2.displayUnit }}
                </td>
                <td *ngIf="resultsPerIndicators[i].indicator3 != undefined">
                  {{
                    getFormattedResult(resultsPerIndicators[i].resultIndicator3)
                  }}
                  {{ resultsPerIndicators[i].indicator3.displayUnit }}
                </td>
              </tr>
            </ng-template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div
    class="general-info-block"
    *ngIf="this.selectedRechargeOrReferenceScenariosForAssess.length > 0"
  >
    <div class="main-actions-container align-left">
      <h4>{{ "interface_assess_packaging_deep_dive" | translationPipe }}</h4>
      <div class="flex-indicator-select">
        <p class="p-no-margin-bottom">
          {{ "interface_assess_display_values_for" | translationPipe }} :
        </p>
        <mat-radio-group
          value="score"
          (change)="singleScoreOrIndicatorSelectionChanged()"
          [(ngModel)]="deepDiveRadioGroup"
        >
          <mat-radio-button #ssRadio value="score">{{
            "interface_assess_environmental_single_score" | translationPipe
          }}</mat-radio-button>
          <mat-radio-button value="indicator"
            >{{ "interface_assess_indicator_title" | translationPipe }} :
          </mat-radio-button>
        </mat-radio-group>
        <ng-select
          [disabled]="ssRadio.checked"
          class="indicator-select"
          [items]="$any(indicatorsAsList())"
          bindLabel="value"
          [trackByFn]="trackByFn"
          [multiple]="false"
          [(ngModel)]="deepDiveSelectedIndic"
          (change)="singleScoreOrIndicatorSelectionChanged()"
          [closeOnSelect]="true"
          [clearable]="false"
        ></ng-select>
      </div>
      <div *ngIf="bothProjectsAreRecharge()">
        <div class="row scenario-infos">
          <div class="col-md-12">
            <div class="form-group">
              <span class="graph-title">
                {{ "interface_assess_recharge_breakdown" | translationPipe }}
                ({{
                  this.deepDiveRadioGroup === "score"
                    ? ("interface_assess_environmental_single_score"
                      | translationPipe)
                    : this.deepDiveSelectedIndic.value
                }})
              </span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <div class="graph_container">
                <canvas
                  id="{{
                    'rechargeBreakdownChart_' +
                      selectedRechargeOrReferenceScenariosForAssess[0].id
                  }}"
                ></canvas>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row scenario-infos">
        <div class="col-md-12">
          <div class="form-group">
            <span class="graph-title">
              {{ "interface_assess_packaging_breakdown" | translationPipe }}
              ({{
                this.deepDiveRadioGroup === "score"
                  ? ("interface_assess_environmental_single_score"
                    | translationPipe)
                  : this.deepDiveSelectedIndic.value
              }})
            </span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <div class="graph_container">
              <canvas
                id="{{
                  'packagingBreakdownChart_' +
                    selectedRechargeOrReferenceScenariosForAssess[0].id
                }}"
              ></canvas>
            </div>
          </div>
        </div>
      </div>
      <div class="row scenario-infos">
        <div class="col-md-12">
          <div class="form-group">
            <span class="graph-title">
              {{ "interface_assess_lcaStep_breakdown" | translationPipe }}
              ({{
                this.deepDiveRadioGroup === "score"
                  ? ("interface_assess_environmental_single_score"
                    | translationPipe)
                  : this.deepDiveSelectedIndic.value
              }})
            </span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <div class="graph_container">
              <canvas
                id="{{
                  'packagingLCAStepChart_' +
                    selectedRechargeOrReferenceScenariosForAssess[0].id
                }}"
              ></canvas>
            </div>
          </div>
        </div>
      </div>
      <div class="row scenario-infos">
        <div class="col-md-12">
          <div class="form-group">
            <span class="graph-title">
              {{ "interface_assess_component_breakdown" | translationPipe }}
              ({{
                this.deepDiveRadioGroup === "score"
                  ? ("interface_assess_environmental_single_score"
                    | translationPipe)
                  : this.deepDiveSelectedIndic.value
              }})
            </span>
          </div>
        </div>
      </div>
      <div
        class="row component-graph-container"
        *ngFor="
          let scenario of getScenariosFrom(
            this.selectedRechargeOrReferenceScenariosForAssess
          )
        "
      >
        <div
          class="col-md-12 pointer"
          (click)="hideOrShow(scenario.id.toString())"
        >
          <div class="form-group">
            <span class="graph-sub-title">{{ scenario.name }}</span>
            <i
              class="fas fa-chevron-down float-right"
              *ngIf="!isGraphShown(scenario.id.toString())"
            ></i>
            <i
              class="fas fa-chevron-up float-right"
              *ngIf="isGraphShown(scenario.id.toString())"
            ></i>
          </div>
        </div>
        <div
          class="col-md-12"
          id="{{ this.getCollapseId(scenario.id.toString()) }}"
        >
          <div class="form-group">
            <div class="graph_container">
              <canvas
                id="{{ this.getGraphId(scenario.id.toString()) }}"
              ></canvas>
            </div>
          </div>
        </div>
      </div>
      <div
        class="row component-graph-container"
        *ngFor="
          let recharge of getRechargeFrom(
            this.selectedRechargeOrReferenceScenariosForAssess
          )
        "
      >
        <div
          class="col-md-12 pointer"
          (click)="
            hideOrShow(
              recharge.id.toString() +
                '-' +
                getMotherReferenceProjectId(recharge).toString()
            )
          "
        >
          <div class="form-group">
            <span class="graph-sub-title"
              >MOTHER - {{ getMotherReferenceName(recharge) }}</span
            >
            <i
              class="fas fa-chevron-down float-right"
              *ngIf="
                !isGraphShown(
                  recharge.id.toString() +
                    '-' +
                    getMotherReferenceProjectId(recharge).toString()
                )
              "
            ></i>
            <i
              class="fas fa-chevron-up float-right"
              *ngIf="
                isGraphShown(
                  recharge.id.toString() +
                    '-' +
                    getMotherReferenceProjectId(recharge).toString()
                )
              "
            ></i>
          </div>
        </div>
        <div
          class="col-md-12"
          id="{{
            this.getCollapseId(
              recharge.id.toString() +
                '-' +
                getMotherReferenceProjectId(recharge).toString()
            )
          }}"
        >
          <div class="form-group">
            <div class="graph_container">
              <canvas
                id="{{
                  this.getGraphId(
                    recharge.id.toString() +
                      '-' +
                      getMotherReferenceProjectId(recharge).toString()
                  )
                }}"
              ></canvas>
            </div>
          </div>
        </div>
        <div
          class="col-md-12 pointer"
          (click)="
            hideOrShow(
              recharge.id.toString() +
                '-' +
                getDaughterReferenceProjectId(recharge).toString()
            )
          "
        >
          <div class="form-group">
            <span class="graph-sub-title"
              >DAUGHTER - {{ getDaughterReferenceName(recharge) }}</span
            >
            <i
              class="fas fa-chevron-down float-right"
              *ngIf="
                !isGraphShown(
                  recharge.id.toString() +
                    '-' +
                    getDaughterReferenceProjectId(recharge).toString()
                )
              "
            ></i>
            <i
              class="fas fa-chevron-up float-right"
              *ngIf="
                isGraphShown(
                  recharge.id.toString() +
                    '-' +
                    getDaughterReferenceProjectId(recharge).toString()
                )
              "
            ></i>
          </div>
        </div>
        <div
          class="col-md-12"
          id="{{
            this.getCollapseId(
              recharge.id.toString() +
                '-' +
                getDaughterReferenceProjectId(recharge).toString()
            )
          }}"
        >
          <div class="form-group">
            <div class="graph_container">
              <canvas
                id="{{
                  this.getGraphId(
                    recharge.id.toString() +
                      '-' +
                      getDaughterReferenceProjectId(recharge).toString()
                  )
                }}"
              ></canvas>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="general-info-block"
    *ngIf="this.selectedRechargeOrReferenceScenariosForAssess.length == 0"
  >
    <div class="main-actions-container align-left">
      {{ "interface_assess_select_scenario" | translationPipe }}
    </div>
  </div>
</div>
<div
  *ngIf="computing && this.incompleteProjects.length == 0"
  class="spinner-container"
>
  <div class="general-info-block">
    <div class="main-actions-container">
      <div class="row">
        <div class="col-md-12 computing-message">
          {{ "interface_assess_computing" | translationPipe }}
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 mt-10">
          <spinner *ngIf="computing" [color]="'blue'"></spinner>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="general-info-container" *ngIf="errorsWhileComputing">
  <div class="general-info-block">
    <div class="main-actions-container">
      <div class="row">
        <div class="col-md-12 error-message">
          <i class="fas fa-exclamation-circle red"></i>
          {{ "interface_assess_error_results" | translationPipe }}
        </div>
        <div class="col-md-12">
          <ul>
            <li *ngFor="let error of this.errors" class="list-inside">
              {{ error }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="general-info-container" *ngIf="error500">
  <div class="general-info-block">
    <div class="main-actions-container">
      <div class="row">
        <div class="col-md-12 error-message">
          <i class="fas fa-exclamation-circle red"></i>
          {{ "interface_error500" | translationPipe
          }}{{ "interface_quantis_address" | translationPipe }}.
        </div>
      </div>
    </div>
  </div>
</div>
<div class="general-info-container" *ngIf="this.incompleteProjects.length > 0">
  <div class="general-info-block">
    <div class="main-actions-container">
      <div class="row">
        <div class="col-md-12 error-message">
          <i class="fas fa-exclamation-circle red"></i>
          {{ "interface_assess_error_interface_incomplete" | translationPipe }}
        </div>
        <div class="col-md-12">
          <ul>
            <li *ngFor="let p of incompleteProjects" class="list-inside">
              {{ p.name }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<app-breakdown-by-material-modal></app-breakdown-by-material-modal>
