import { Pipe, PipeTransform } from '@angular/core';
import { TranslationService } from '../services/translationService/translation.service';

@Pipe({ name: 'translationPipe' })
export class TranslationPipe implements PipeTransform {
  constructor(private _translationService: TranslationService) {}

  transform(
    label: string | null | undefined,
    attributes?: Map<string, any>
  ): string {
    if (label === null || label === undefined) {
      return '';
    }

    if (this._translationService.getTranslationValueForLabel(label) == null) {
      return label;
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    let i18n: string =
      this._translationService.getTranslationValueForLabel(label);

    if (attributes != undefined) {
      attributes.forEach(
        (value: any, key: string) => (i18n = i18n.split(key).join(value))
      );
    }

    return i18n;
  }
}
