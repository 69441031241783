class EmailUtils {
  static obfuscateEmailAddress(email: string): string {
    const arr = email.split('@');
    return (
      EmailUtils.obfuscateWord(arr[0]) + '@' + EmailUtils.obfuscateWord(arr[1])
    );
  }

  static obfuscateWord(str: string): string {
    if (str.length <= 2) return str;

    return str[0] + '*'.repeat(str.length - 2) + str.slice(-1);
  }
}

export default EmailUtils;
