import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  TemplateRef,
} from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-email-form-modal',
  templateUrl: './email-form-modal.component.html',
  styleUrls: [],
})
export class EmailFormModalComponent {
  modal: NgbModalRef | undefined;

  // @ts-ignore
  @Input() title: string;
  // @ts-ignore
  @Input() content: string;

  // @ts-ignore
  @ViewChild('emailformmodal') templateModal: TemplateRef<any>;

  @Output() answer = new EventEmitter<string>();

  state: State = State.FORGOT_PASSWORD;
  State = State;

  // @ts-ignore
  email: string;
  // @ts-ignore
  emailSubmit: boolean;

  constructor(private modalService: NgbModal) {
    this.state = State.FORGOT_PASSWORD;
  }

  onSubmitForgotPassword(email: string) {
    this.email = email;
    this.state = State.FORGOT_PASSWORD_SUBMIT;
  }

  // @ts-ignore
  onSuccess($event) {
    this.state = State.SUCCESS;
  }

  abort() {
    this.close();
  }

  open() {
    this.modal = this.modalService.open(this.templateModal);
  }

  close() {
    this.state = State.FORGOT_PASSWORD;
    this.modal?.close();
  }
}

enum State {
  // eslint-disable-next-line @typescript-eslint/indent
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUBMIT,
  SUCCESS,
}
