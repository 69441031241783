import {
  AbstractControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';

export class CustomValidators {
  static patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        // if control is empty return no error
        // @ts-ignore
        return null;
      }

      // test the value of the control against the regexp supplied
      const valid = regex.test(control.value);

      // if true, return no error (no error), else return error passed in the second parameter
      // @ts-ignore
      return valid ? null : error;
    };
  }

  static equals(value: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        // @ts-ignore
        return null;
      }

      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
      return control.value.toLowerCase() !== value.toLowerCase()
        ? {
            equals: {
              expected: value.toLowerCase(),
              // eslint-disable-next-line max-len
              // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-assignment,,@typescript-eslint/no-unsafe-member-access
              actual: control.value.toLowerCase(),
            },
          }
        : null;
    };
  }

  static notSpaceValidator(): ValidatorFn {
    return (
      control: AbstractControl<string, string>
    ): ValidationErrors | null => {
      return control?.value?.length > 0 && control?.value?.trim().length === 0
        ? { whitespace: true }
        : null;
    };
  }

  static passwordMatchValidator(
    controlName: string,
    matchingControlName: string
  ) {
    return (formGroup: UntypedFormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      }
    };
  }

  static userNameValidator: CustomValidator = {
    compose: Validators.compose([
      this.notSpaceValidator(),
      Validators.minLength(2),
      Validators.maxLength(50),
    ] as (ValidatorFn | null | undefined)[]),
    minLength: 2,
    maxLength: 50,
  };

  static emailValidator: CustomValidator = {
    compose: Validators.compose([
      Validators.email,
      Validators.minLength(4),
      Validators.maxLength(255),
    ] as (ValidatorFn | null | undefined)[]),
    minLength: 4,
    maxLength: 255,
  };

  static percentageValidator: CustomValidator = {
    compose: Validators.compose([Validators.min(0), Validators.max(100)] as (
      | ValidatorFn
      | null
      | undefined
    )[]),
    min: 0,
    max: 100,
  };

  static percentageNonZeroValidator: CustomValidator = {
    compose: Validators.compose([
      Validators.min(0.001),
      Validators.max(100),
    ] as (ValidatorFn | null | undefined)[]),
    min: 0.001,
    max: 100,
  };

  static nonZeroDecimalValidator: CustomValidator = {
    compose: Validators.compose([Validators.min(0.001)] as (
      | ValidatorFn
      | null
      | undefined
    )[]),
    min: 0.001,
  };
}

type CustomValidator = {
  compose: ValidatorFn | null;
  min?: number;
  max?: number;
  minLength?: number;
  maxLength?: number;
};
