import { ComponentLevel } from '../component/component-level.enum';
import { PkgComponent } from '../component/pkg-component';
import { Type } from 'class-transformer';

export class Packaging {
  id!: number;
  type!: ComponentLevel;
  isRecyclable!: boolean;

  @Type(() => PkgComponent)
  components: Array<PkgComponent> = [];
}
