import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProjectsSelectionComponent } from '../project-comparison/projects-selection/projects-selection.component';
import { ProjectsAssessmentComponent } from '../project-comparison/projects-assessment/projects-assessment.component';
import { ProjectComparisonComponent } from './project-comparison.component';

const projectRoutes: Routes = [
  {
    path: 'projects-comparison',
    component: ProjectComparisonComponent,
    children: [
      {
        path: 'definition',
        component: ProjectsSelectionComponent,
      },
      {
        path: 'assess',
        component: ProjectsAssessmentComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(projectRoutes)],
  exports: [RouterModule],
})
export class ProjectComparisonRoutingModule {}
