<ng-template #usermanagementmodal let-modal>
  <div class="modal-content">
    <div class="modal-header modal-spacing">
      <div class="modal-title">
        <span *ngIf="limitReached">
          {{ "interface_warning" | translationPipe }}
        </span>
        <span *ngIf="!limitReached">
          {{
            (this.creating ? "interface_create_user" : "interface_edit_user")
              | translationPipe
          }}
        </span>
      </div>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        aria-label="Close"
        (click)="close()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <form
      *ngIf="!limitReached"
      [formGroup]="userForm"
      id="createUserForm"
      (ngSubmit)="onSubmitUser()"
      autocomplete="off"
    >
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label for="firstname">
              {{ "interface_first_name" | translationPipe }}</label
            >
            <input
              id="firstname"
              class="form-control"
              name="firstname"
              type="text"
              formControlName="firstname"
              minlength="{{ CustomValidators.userNameValidator.minLength }}"
              maxlength="{{ CustomValidators.userNameValidator.maxLength }}"
            />
            <validation-messages
              [control]="userForm.get('firstname')"
              class="form-invalid-message"
            >
            </validation-messages>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="lastname">{{
              "interface_last_name" | translationPipe
            }}</label>
            <input
              id="lastname"
              class="form-control"
              name="lastname"
              type="text"
              formControlName="lastname"
              minlength="{{ CustomValidators.userNameValidator.minLength }}"
              maxlength="{{ CustomValidators.userNameValidator.maxLength }}"
            />
            <validation-messages
              [control]="userForm.get('lastname')"
              class="form-invalid-message"
            >
            </validation-messages>
          </div>
        </div>
      </div>

      <div class="form-group">
        <label for="email">{{ "interface_email" | translationPipe }}</label>
        <input
          id="email"
          class="form-control"
          name="email"
          type="text"
          formControlName="email"
          minlength="{{ CustomValidators.emailValidator.minLength }}"
          maxlength="{{ CustomValidators.emailValidator.maxLength }}"
        />
        <validation-messages
          [control]="userForm.get('email')"
          class="form-invalid-message"
        >
        </validation-messages>
        <div
          *ngIf="this.emailAlreadyExists"
          class="form-invalid-message"
          style="padding-top: 4px; padding-left: 2px"
        >
          {{
            "interface_create_user_given_email_already_exists" | translationPipe
          }}
        </div>

        <div *ngIf="displayResendInvitation()" class="mt-1">
          {{ "interface_user_status_not_yet_connected" | translationPipe }}
          <a class="link d-inline-block" (click)="resendInvitation()">
            <spinner [color]="'black'" *ngIf="resendingInvitation"></spinner>
            <span *ngIf="!resendingInvitation">
              {{ "interface_resend_invitation" | translationPipe }}
              <i class="far fa-envelope"></i>
            </span>
          </a>
        </div>
      </div>

      <div *ngIf="isSuperAdmin()" class="form-group">
        <label for="company">{{ "interface_company" | translationPipe }}</label>
        <ng-select
          [items]="$any(compagnies$ | async)"
          bindLabel="name"
          [hideSelected]="true"
          [loading]="companyLoading"
          [trackByFn]="trackByFn"
          formControlName="company"
          [loading]="companyLoading"
          [typeahead]="companyInput$"
        >
        </ng-select>
        <div class="form-invalid-message" *ngIf="searchFailed">
          {{ "interface_fetch_company_failed" | translationPipe }}
        </div>
        <validation-messages
          *ngIf="!searchFailed"
          [control]="userForm.get('company')"
          class="form-invalid-message"
        >
        </validation-messages>
      </div>

      <div class="form-group">
        <label for="brand">{{ "interface_brand" | translationPipe }}</label>
        <input
          id="brand"
          class="form-control"
          name="brand"
          type="text"
          formControlName="brand"
          maxlength="{{ boundaries.organization.maxLength }}"
        />
        <validation-messages
          [control]="userForm.get('brand')"
          class="form-invalid-message"
        >
        </validation-messages>
      </div>

      <div class="form-group">
        <label for="authority">{{ "interface_role" | translationPipe }}</label>
        <select class="custom-select form-control" formControlName="authority">
          <option
            *ngFor="let authority of availableAuthorities()"
            [value]="authority.role"
          >
            {{ authority.label | translationPipe }}
          </option>
        </select>
        <validation-messages
          [control]="userForm.get('authority')"
          class="form-invalid-message"
        >
        </validation-messages>
      </div>

      <div class="form-group">
        <label for="description">{{
          "interface_description" | translationPipe
        }}</label>
        <textarea
          class="form-control"
          formControlName="description"
          maxlength="{{ boundaries.description.maxLength }}"
        ></textarea>
        <validation-messages
          [control]="userForm.get('description')"
          class="form-invalid-message"
        >
        </validation-messages>
      </div>

      <div class="form-text text-center errorMsg">{{ errorMessage }}</div>

      <div class="modal-footer">
        <button type="button" class="btn btn-cancel" (click)="close()">
          {{ "interface_cancel" | translationPipe }}
        </button>
        <button
          type="submit"
          class="btn btn-primary login-btn"
          [disabled]="userLoading || !userForm.valid"
        >
          <span *ngIf="!userLoading">
            {{
              (this.creating ? "interface_create" : "interface_update")
                | translationPipe
            }}
          </span>
          <spinner *ngIf="userLoading"></spinner>
        </button>
      </div>
    </form>
    <span *ngIf="limitReached">
      <i class="fas fa-user-lock"></i>
      {{ "interface_create_user_limit_reached" | translationPipe }}
      <div class="modal-footer modal-footer-center">
        <button type="button" class="btn btn-close" (click)="close()">
          {{ "interface_close" | translationPipe }}
        </button>
      </div>
    </span>
  </div>
</ng-template>

<div class="ngb-toasts">
  <ngb-toast
    *ngIf="showResendInvitationToastSuccessfull"
    [delay]="4000"
    [autohide]="true"
    (hide)="showResendInvitationToastSuccessfull = false"
  >
    {{ "interface_resend_invitation_successful" | translationPipe }}
  </ngb-toast>
  <ngb-toast
    class="bg-danger"
    *ngIf="showResendInvitationToastFailure"
    [delay]="4000"
    [autohide]="true"
    (hide)="showResendInvitationToastFailure = false"
  >
    {{ "interface_resend_invitation_failure" | translationPipe }}
  </ngb-toast>
</div>
