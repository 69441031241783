import { Component, OnInit } from '@angular/core';
import { TranslationService } from '@/app/services/translationService/translation.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from '@/app/services/login/login.service';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.css'],
})
export class ErrorPageComponent implements OnInit {
  // @ts-ignore
  errorMsg: string;
  errorMsgDetail = '';
  errorType: string | null = '';

  constructor(
    public translationService: TranslationService,
    public route: ActivatedRoute,
    public loginService: LoginService,
    public router: Router
  ) {}

  ngOnInit() {
    this.errorType = this.route.snapshot.paramMap.get('type');
    this.errorMsg = 'interface_error_not_found';

    // leaving this logic in case we want to implement a different type of error in the future
    if (this.errorType == 'not-found') {
      this.errorMsg = 'interface_error_not_found';
    } else if (this.errorType == 'company-disabled') {
      this.errorMsg = 'interface_error_company_disabled';
      this.errorMsgDetail = 'interface_error_company_disabled_details';
    }
  }

  async logout() {
    await this.loginService.logout();
  }
}
