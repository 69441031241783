import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiUtils } from './api-utils';
import { ScenarioResult } from '../model/results/scenario-result';
import { Observable } from 'rxjs';
import { InputForCalculation } from '../model/results/input-for-calculation';

@Injectable({
  providedIn: 'root',
})
export class ResultApiService {
  constructor(private http: HttpClient) {}

  buildPath(endPoint: string): string {
    return ApiUtils.BASE_PATH + 'assessments/' + endPoint;
  }

  computeResults(
    scenarios: InputForCalculation
  ): Observable<Array<ScenarioResult>> {
    const url = this.buildPath('scenarios/');
    return this.http.post<Array<ScenarioResult>>(
      url,
      scenarios,
      ApiUtils.HTTP_OPTIONS
    );
  }
}
