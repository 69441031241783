import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  SortingDirection,
  TypedSorting,
} from '@/app/model/search/typed-sorting';

@Component({
  selector: 'app-sort-toggle[sorting][sortedBy][toggle]',
  templateUrl: './sort-toggle.component.html',
  styleUrls: ['./sort-toggle.component.css'],
})
export class SortToggleComponent {
  @Input() sorting!: TypedSorting<string>;

  @Input() sortedBy!: string;

  @Output() toggle = new EventEmitter<void>();

  readonly SortingDirection = SortingDirection;

  isSortingDesc(sortedBy: string) {
    return (
      this.sorting.direction === SortingDirection.ASC &&
      this.sorting.sortedBy === sortedBy
    );
  }

  isSortingAsc(sortedBy: string) {
    return (
      this.sorting.direction === SortingDirection.DESC &&
      this.sorting.sortedBy === sortedBy
    );
  }

  isSortingNone(sortedBy: string) {
    return !this.isSortingAsc(sortedBy) && !this.isSortingDesc(sortedBy);
  }

  onSort(direction: SortingDirection, sortedBy: string) {
    this.sorting.direction = direction;
    this.sorting.sortedBy = sortedBy;
    this.toggle.emit();
  }
}
