import { Project } from './project';
import { Type, Transform } from 'class-transformer';

export class ProjectAudit {
  id!: number;
  userId!: number;
  userName!: string;

  @Type(() => Project)
  project!: Project;

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-call
  @Transform((value) => new Date(value.substring(0, 19))) // Format "YYYY-MM-DDThh:mm::ss" (first 19 elements)
  date!: Date;
}
