/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Material } from '@/app/model/packaging-model/material';
import { PkgComponent } from '@/app/model/packaging-model/component/pkg-component';
import { MaterialType } from '@/app/model/data/material-type';
import { MaterialService } from '@/app/services/material.service';
import { ConversionProcess } from '@/app/model/data/conversion-process';
import { ProjectService } from '@/app/services/project.service';
import { ConfirmModalComponent } from '@/app/pages/confirm-modal/confirm-modal.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CustomValidators } from '@/app/utils/custom-validators';

@Component({
  selector: 'app-material-row, [app-material-row]',
  templateUrl: './material-row.component.html',
  styleUrls: ['./material-row.component.css'],
})
export class MaterialRowComponent implements OnInit, OnDestroy {
  @Input() materialOriginal!: Material;
  @Input() componentOriginal!: PkgComponent;
  @Input() materialTypes: Array<MaterialType> = [];
  @Input() conversionProcessesPerMaterialTypeGroup!: Map<
    number,
    Array<ConversionProcess>
  >;

  @Output() changeMade = new EventEmitter<any>();

  @ViewChild(ConfirmModalComponent)
  private confirmModalComponent!: ConfirmModalComponent;

  saveTimer!: NodeJS.Timer;

  materialForm = this.formBuilder.group({
    id: 0,
    materialType: [<MaterialType | null>null, Validators.required],
    conversionProcess: [<ConversionProcess | null>null, Validators.required],
    mass: [
      0,
      Validators.compose([
        Validators.required,
        CustomValidators.nonZeroDecimalValidator.compose,
      ]),
    ],
    recycledContent: [
      0,
      Validators.compose([
        Validators.required,
        CustomValidators.percentageValidator.compose,
      ]),
    ],
  });

  private onDestroy$ = new Subject();

  get isReadOnly(): boolean {
    return this._projectService.isReadOnly;
  }

  @HostListener('window:beforeunload', ['$event'])
  onQuit(): void {
    this.stopSaveTimer();
    this.saveAll();
  }

  constructor(
    private _materialService: MaterialService,
    private _projectService: ProjectService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.initForms();
    this.startSaveTimer();
  }

  ngOnDestroy(): void {
    this.onQuit();
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  initForms(): void {
    this.materialForm.patchValue({
      ...this.materialOriginal,
      recycledContent: this.materialOriginal.recycledContent * 100,
    });

    this.materialForm.controls.materialType.setValue(
      this.materialTypes.find(
        (mt) => mt.id === this.materialOriginal.materialType.id
      )!
    );
    this.materialForm.controls.conversionProcess.setValue(
      this.getConversionProcessesFor(this.materialOriginal.materialType)!.find(
        (cp) => cp.id === this.materialOriginal.conversionProcess.id
      )!
    );

    if (this.isReadOnly) {
      this.materialForm.disable();
    } else {
      this.materialForm.enable();
    }

    this.materialForm.controls.conversionProcess.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => this.emitChanges());
    this.materialForm.controls.mass.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => this.emitChanges());
    this.materialForm.controls.recycledContent.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => this.emitChanges());
  }

  startSaveTimer(): void {
    if (this.saveTimer == null)
      this.saveTimer = setInterval((_) => this.saveAll(), 5000);
  }

  stopSaveTimer(): void {
    if (this.saveTimer != null) {
      clearInterval(this.saveTimer);
      // @ts-ignore
      this.saveTimer = null;
    }
  }

  saveAll(): void {
    if (this.materialForm.valid) {
      this.emitChanges();
    }
  }

  deleteMaterial(): void {
    this.confirmModalComponent.open();
  }

  async confirmOrAbortDeletion(answer: boolean): Promise<void> {
    this.confirmModalComponent.close();
    if (answer)
      await this._materialService.deleteMaterial(
        this.componentOriginal,
        this.materialForm.value as Material
      );
  }

  onMaterialChanged(): void {
    this.materialForm.controls.conversionProcess.setValue(null, {
      emitEvent: false,
    });
    this.materialForm.controls.conversionProcess.markAsDirty();
    this.materialForm.controls.conversionProcess.markAsTouched();
  }

  getConversionProcessesFor(
    materialType: MaterialType
  ): ConversionProcess[] | undefined {
    return this.conversionProcessesPerMaterialTypeGroup.get(
      materialType.materialTypeGroup.id
    );
  }

  getHelpboxTranslation(): string | undefined {
    return this._materialService.getConversionHelpbox.get(
      this.materialForm.value.conversionProcess!.label
    );
  }

  emitChanges(): void {
    this.changeMade.emit({
      ...this.materialForm.value,
      recycledContent: this.materialForm.value.recycledContent! / 100,
    });
  }

  protected readonly CustomValidators = CustomValidators;
}
