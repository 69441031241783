import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { GeneralInfoComponent } from './general-info/general-info.component';
import { PackagingComponentComponent } from './packaging-component/packaging-component.component';
import { ProjectPageComponent } from '@/app/pages/projects/project-page.component';
import { AssessComponent } from './assess/assess.component';

const projectRoutes: Routes = [
  {
    path: 'single-use-project/:id',
    component: ProjectPageComponent,
    children: [
      {
        path: 'scenario/:id/general-info',
        component: GeneralInfoComponent,
      },
      {
        path: 'scenario/:id/component/:id',
        component: PackagingComponentComponent,
      },
      {
        path: 'assess',
        component: AssessComponent,
      },
    ],
  },
  {
    path: 'recharge-project/:id',
    component: ProjectPageComponent,
    children: [
      {
        path: 'assess',
        component: AssessComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(projectRoutes)],
  exports: [RouterModule],
})
export class ProjectsRoutingModule {}
