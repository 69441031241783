import { Injectable } from '@angular/core';
import { FileApiService } from '@/app/api/file-api.service';
import { Observable } from 'rxjs';
import { User } from '../model/user/user';
import { Type } from 'class-transformer';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  constructor(private apiService: FileApiService) {}

  getS3FileRecords(): Observable<S3FileRecord[]> {
    return this.apiService.getFiles();
  }

  downloadFileByName(fileName: string): Observable<string> {
    return this.apiService.downloadFile(fileName);
  }
}

export class S3FileRecord {
  fileName!: string;

  @Type(() => User)
  memberUser!: User;

  status!: 'CREATED' | 'SUCCEEDED' | 'FAILED' | 'DELETED';
  fileSize!: number;
  createdAt!: Date;
  uploadedAt!: Date;
}
