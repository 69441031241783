import { Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { Project } from '../model/packaging-model/project/project';
import { ProjectApiService } from '../api/project-api.service';

import { ProjectAudit } from '../model/packaging-model/project/project-audit';
import { SingleUseProject } from '../model/packaging-model/project/single-use-project';
import { Scenario } from '../model/packaging-model/scenario';
import { Router } from '@angular/router';
import { Page } from '../model/pagination/page';
import { FilterCriterion } from '../model/search/filter-criterion';
import { Sorting } from '../model/search/sorting';
import { ScenarioApiService } from '../api/scenario-api.service';
import { TranslationPipe } from '../intl/translation-pipe';

import { PkgComponent } from '../model/packaging-model/component/pkg-component';
import { GuestService } from '@/app/services/guest.service';
import { ComponentLevel } from '../model/packaging-model/component/component-level.enum';
import { RechargeProject } from '../model/packaging-model/project/recharge-project';
import { PackagingApiService } from '../api/packaging-api.service';
import { SecondaryPackaging } from '../model/packaging-model/packaging/secondary-packaging';
import { TertiaryPackagingBox } from '../model/packaging-model/packaging/tertiary-packaging-box';
import { TertiaryPackagingPalletization } from '../model/packaging-model/packaging/tertiary-packaging-palletization';
import { PrimaryPackaging } from '../model/packaging-model/packaging/primary-packaging';
import { PackagingAssociation } from '../model/packaging-model/packaging/packaging-association';
import { ResultApiService } from '../api/result-api.service';
import { ScenarioResult } from '../model/results/scenario-result';
import { ProjectLock } from '@/app/model/packaging-model/project/project-lock';
import { ProjectLockService } from './project-lock.service';
import { ToastService } from './toast.service';
import { plainToClassFromExist } from 'class-transformer';
import { InputForCalculation } from '../model/results/input-for-calculation';
import { map, catchError } from 'rxjs/operators';
import { LoginService } from './login/login.service';
import { getNumericEnumValues } from '@/app/utils/enum-utils';
import assert from 'assert';
import { PublishedScenarioApiService } from '../api/published-scenario-api.service';
import { ExportScenarioLibrary } from '../model/packaging-model/export-scenario-library';
import { PublishedScenario } from '../model/packaging-model/published-scenario';
import { PublishedScenarioEditableFields } from '../model/packaging-model/published-scenario-editable-fields';
import { TypedSorting } from '@/app/model/search/typed-sorting';

@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  static levelToString: Map<ComponentLevel, string> = new Map([
    [ComponentLevel.PRIMARY, 'interface_scenario_primary_layer'],
    [ComponentLevel.SECONDARY, 'interface_scenario_secondary_layer'],
    [ComponentLevel.TERTIARY_BOX, 'interface_scenario_tertiary_layer'],
    [
      ComponentLevel.TERTIARY_PALLETIZATION,
      'interface_scenario_tertiary_palletization_layer',
    ],
  ]);

  // @ts-ignore
  currentSingleUseProject: SingleUseProject;
  // @ts-ignore
  currentRechargeProject: RechargeProject;
  // @ts-ignore
  currentProjectLock: ProjectLock;

  // @ts-ignore
  selectedScenarioId: number;
  // @ts-ignore
  selectedScenario: Scenario;

  // @ts-ignore
  selectedScenariosForAssess: Array<any>;
  // @ts-ignore
  selectedProjectsForComparison: Array<any>;
  firstSelectedProject = null;
  secondSelectedProject = null;
  // @ts-ignore
  scenarioResults: Array<ScenarioResult>;
  assesspageIsLoaded = false;

  noTertiary = false;

  error500 = false;
  errorsWhileComputing = false;
  errors = new Array<string>();

  private _stream: Subject<Array<any>> = new Subject<Array<any>>();
  dispatcher$ = this._stream.asObservable();

  get isReadOnly(): boolean {
    if (this.guestService.isUserGuest()) return false;

    return this.currentProjectLock != null && !this.currentProjectLock.success;
  }

  get isUnlockable(): boolean {
    return (
      this.currentProjectLock != null && this.currentProjectLock.unlockable
    );
  }

  get componentLevels(): Array<ComponentLevel> {
    return getNumericEnumValues(ComponentLevel);
  }

  get scenarios(): Scenario[] {
    if (this.guestService.isUserGuest() && this.guestService.guestUserProject) {
      return this.guestService.guestUserProject.scenarios;
    } else return this.currentSingleUseProject.scenarios;
  }

  get scenarioPkgComponents(): PkgComponent[] {
    return this.selectedScenario.components;
  }
  get projectPkgComponents(): PkgComponent[] {
    let projectComponents: Array<PkgComponent> = new Array<PkgComponent>();
    for (const scenario of this.scenarios) {
      projectComponents = projectComponents.concat(scenario.components);
    }
    return projectComponents;
  }

  constructor(
    private router: Router,
    private projectApi: ProjectApiService,
    private scenarioApi: ScenarioApiService,
    private translationPipe: TranslationPipe,
    private guestService: GuestService,
    private packagingApi: PackagingApiService,
    private resultApi: ResultApiService,
    private projectLockService: ProjectLockService,
    private toastService: ToastService,
    private loginService: LoginService,
    private publishedScenarioApi: PublishedScenarioApiService
  ) {
    this.initSubscriptionOnProjectLockException();
  }

  initSubscriptionOnProjectLockException() {
    this.projectLockService.projectLockException$.subscribe((value) => {
      this.currentProjectLock = value;
    });
  }

  getLatestProjects(): Promise<ProjectAudit[]> {
    return this.projectApi.getLatestProjects();
  }

  getProjects(
    page: number,
    filterCriteria: Array<FilterCriterion>,
    sorting: TypedSorting<keyof Project>
  ): Observable<Page<Project>> {
    return this.projectApi.getProjects(page, filterCriteria, sorting);
  }

  updateProjectGeneralInfo(
    originalProject: Project,
    updatedProject: Project
  ): void {
    if (this.guestService.isUserGuest()) {
      originalProject.mergeGeneralInfo(updatedProject);
      this.saveProjectLocallyIfGuest();
    } else {
      this.projectApi
        .updateProjectGeneralInfo(updatedProject)
        .then((projectResponse) => {
          originalProject.mergeGeneralInfo(projectResponse);
        })
        .catch((err) => console.error(err));
    }
  }

  updateProjectCollaborators(
    originalProject: Project,
    updatedProject: Project,
    sendEmailNotificationsToCollaborators: boolean
  ): void {
    this.projectApi
      .updateProjectCollaborators(
        updatedProject,
        sendEmailNotificationsToCollaborators
      )
      .then((projectResponse) => {
        originalProject.mergeCollaborators(projectResponse);
      })
      .catch((err) => console.error(err));
  }

  saveProjectLocallyIfGuest(): void {
    this.guestService.storeProjectIfGuest(this.currentSingleUseProject);
  }

  deleteProject(project: Project): Promise<void> {
    if (project.isSingleUse) {
      return this.projectApi.deleteSingleUseProject(project.id);
    } else {
      return this.projectApi.deleteRechargeProject(project.id);
    }
  }

  // ----------------------------- SINGLE-USE -----------------------------

  async createSingleUseProject(project: SingleUseProject): Promise<void> {
    if (this.guestService.isUserGuest()) {
      this.currentSingleUseProject =
        this.guestService.createSingleUseProject(project);
      this.guestService.storeProject(this.currentSingleUseProject);
    } else {
      this.currentSingleUseProject =
        await this.projectApi.createSingleUseProject(project);
    }
  }

  async openSingleUseProject(projectId: number): Promise<void> {
    if (this.guestService.isUserGuest()) {
      if (
        this.guestService.isProjectStored() &&
        this.guestService.guestUserProject
      ) {
        this.currentSingleUseProject = this.guestService.guestUserProject;
      }
    } else {
      if (this.loginService.signedIn) {
        const response = await Promise.all([
          this.projectApi.lockProject(projectId),
          this.loadSingleUseProject(projectId),
        ]);
        this.currentProjectLock = response[0];
        this.currentSingleUseProject = response[1];
      } else {
        // @ts-ignore
        this.currentProjectLock = null;
        // @ts-ignore
        this.currentSingleUseProject = null;
        void this.router.navigate(['/']);
      }
    }
  }

  async reloadSingleUseProject(projectId: number): Promise<SingleUseProject> {
    return this.loadSingleUseProject(projectId).then(
      (sup) => (this.currentSingleUseProject = sup)
    );
  }

  async loadSingleUseProject(projectId: number): Promise<SingleUseProject> {
    return this.projectApi.loadSingleUseProject(projectId.toString());
  }

  lockProject(projectId: number): Promise<ProjectLock> {
    return this.projectApi
      .lockProject(projectId)
      .then((projectLock) => (this.currentProjectLock = projectLock));
  }

  async unlockProject(projectId: number): Promise<boolean> {
    return this.loginService.signedIn
      ? this.projectApi.unlockProject(projectId)
      : false;
  }

  async duplicateSingleUseProject(project: Project): Promise<number> {
    this.currentSingleUseProject =
      await this.projectApi.duplicateSingleUseProject(
        project.id,
        project.name +
          this.translationPipe.transform('interface_default_copy_project_name')
      );
    return this.currentSingleUseProject.id;
  }

  async setScenarioToReference(scenarioId: number): Promise<void> {
    this.currentSingleUseProject = await this.projectApi.setScenarioToReference(
      this.currentSingleUseProject.id.toString(),
      scenarioId.toString()
    );
  }

  exportProject(projectId: number): Observable<string> {
    return this.projectApi.exportProject(projectId);
  }

  generateAllProjectExport(): Promise<object> {
    return this.projectApi.generateAllProjectExport();
  }

  // ----------------------------- SCENARIO -----------------------------

  selectScenario(scenarioId: number): void {
    this.noTertiary = false;
    // @ts-ignore
    const s: Scenario = this.scenarios.find(
      (scen: Scenario) => scen.id == scenarioId
    );
    if (s != null) {
      this.selectedScenarioId = scenarioId;
      // @ts-ignore
      this.selectedScenario = this.scenarios.find(
        (scenario: Scenario) => scenario.id == this.selectedScenarioId
      );
    } else {
      void this.router.navigateByUrl('');
      throw new Error('Scenario is not in current project');
    }
  }

  updateSelectedScenariosForAssess(scenarios: Array<any>): void {
    this.selectedScenariosForAssess = scenarios;
    this.assesspageIsLoaded = true;
    this._stream.next(scenarios);
  }

  computeGUSingleUseScenarioResults(): Observable<Array<ScenarioResult>> {
    const inputForComputation: InputForCalculation = new InputForCalculation();
    inputForComputation.rechargeScenarios = [];
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    inputForComputation.singleUseScenarios = this.selectedScenariosForAssess;
    return this.resultApi.computeResults(inputForComputation);
  }

  computeSingleUseScenariosResults(
    projectId: number
  ): Observable<Array<ScenarioResult>> {
    return this.projectApi.computeSingleUseResults(projectId);
  }

  computeSingleUseRefScenarioResults(
    projectId: number
  ): Observable<Array<ScenarioResult>> {
    return this.projectApi.computeSingleUseRefOnlyResults(projectId);
  }

  computeRechargeProjectResults(projectId: number): Observable<ScenarioResult> {
    return this.projectApi.computeRechargeResults(projectId);
  }

  async computeProjectComparisonResults(): Promise<Array<ScenarioResult>> {
    let rechargeResults: Array<ScenarioResult> = new Array<ScenarioResult>();
    let rechargeResultsTMP: Array<ScenarioResult> = new Array<ScenarioResult>();
    let singleUseResults: Array<ScenarioResult> = new Array<ScenarioResult>();
    let singleUseResultsTMP: Array<ScenarioResult> =
      new Array<ScenarioResult>();
    let fullResults: Array<ScenarioResult> = new Array<ScenarioResult>();
    for (let i = 0; i < this.selectedProjectsForComparison.length; i++) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const project = this.selectedProjectsForComparison[i];
      if (project instanceof RechargeProject) {
        rechargeResultsTMP = await this.computeRechargeProjectResults(
          project.id
        )
          .pipe(
            map((r) =>
              Array.of(plainToClassFromExist(new ScenarioResult(), r))
            ),
            catchError((err) => this.handleError(err))
          )
          .toPromise();
        rechargeResults = rechargeResults.concat(rechargeResultsTMP);
      } else if (project instanceof SingleUseProject) {
        singleUseResultsTMP = await this.computeSingleUseRefScenarioResults(
          project.id
        )
          .pipe(
            map((r) => plainToClassFromExist(new Array<ScenarioResult>(), r)),
            catchError((err) => this.handleError(err))
          )
          .toPromise();
        singleUseResults = singleUseResults.concat(singleUseResultsTMP);
      }
    }

    fullResults = rechargeResults.concat(singleUseResults);
    return fullResults;
  }

  // @ts-ignore
  handleError(err) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (err.status == 500) {
      this.error500 = true;
    } else {
      this.errorsWhileComputing = true;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      if (err.error)
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        this.errors.push(err.error);
    }
    return [];
  }

  resetSelectedProjects(): void {
    this.firstSelectedProject = null;
    this.secondSelectedProject = null;
    this.selectedProjectsForComparison = [];
    this.selectedScenariosForAssess = [];
  }

  exportComparison(): Observable<string> {
    return this.projectApi.exportComparison(this.selectedProjectsForComparison);
  }

  // @ts-ignore
  async createScenario(): Promise<number> {
    if (!this.guestService.isUserGuest()) {
      const response: Scenario = await this.scenarioApi.createScenario(
        this.currentSingleUseProject.id
      );
      this.currentSingleUseProject.scenarios.push(response);
      return response.id;
    }
  }

  async updateScenario(
    scenarioLocal: Scenario,
    scenarioOriginal: Scenario
  ): Promise<void> {
    const updatedScenario: Scenario = this.guestService.isUserGuest()
      ? scenarioLocal
      : await this.scenarioApi.updateScenario(
          scenarioOriginal.id,
          scenarioLocal
        );
    scenarioOriginal.mergeEditableFields(updatedScenario);
    this.saveProjectLocallyIfGuest();
  }

  getScenarioFromDB(scenarioOriginal: Scenario): Promise<Scenario> {
    return this.scenarioApi.getScenarioFromDB(scenarioOriginal.id);
  }

  async updateScenarioHasSecondaryPackaging(
    hasSecondaryPackaging: boolean,
    scenarioOriginal: Scenario
  ): Promise<Scenario> {
    if (hasSecondaryPackaging) {
      const packaging: SecondaryPackaging = this.guestService.isUserGuest()
        ? this.guestService.createSecondaryPackaging(scenarioOriginal)
        : await this.packagingApi.createSecondaryPackaging(scenarioOriginal.id);
      scenarioOriginal.setSecondaryPackaging(packaging);
      this.selectedScenario.setSecondaryPackaging(packaging);
    } else {
      if (this.guestService.isUserGuest())
        this.guestService.deleteSecondaryPackaging(scenarioOriginal);
      else {
        const packagingId = scenarioOriginal.firstSecondaryPackaging?.id;
        assert(packagingId !== undefined);
        await this.packagingApi.deleteSecondaryPackaging(packagingId);
      }
      scenarioOriginal.deleteSecondaryPackaging();
      this.selectedScenario.deleteSecondaryPackaging();
    }
    if (!this.guestService.isUserGuest())
      scenarioOriginal = await this.getScenarioFromDB(scenarioOriginal);
    this.saveProjectLocallyIfGuest();
    return scenarioOriginal;
  }

  async updateScenarioHasTertiaryPackaging(
    hasTertiaryPackaging: boolean,
    scenarioOriginal: Scenario
  ): Promise<void> {
    if (hasTertiaryPackaging) {
      const packaging: TertiaryPackagingBox = this.guestService.isUserGuest()
        ? this.guestService.createTertiaryPackagingBox(scenarioOriginal)
        : await this.packagingApi.createTertiaryPackagingBox(
            scenarioOriginal.id
          );
      scenarioOriginal.setTertiaryPackagingBox(packaging);
      this.selectedScenario.setTertiaryPackagingBox(packaging);
    } else {
      if (this.guestService.isUserGuest())
        this.guestService.deleteTertiaryPackagingBox(scenarioOriginal);
      else {
        const packagingId = scenarioOriginal.firstTertiaryPackagingBox?.id;
        assert(packagingId !== undefined);
        await this.packagingApi.deleteTertiaryPackagingBox(packagingId);
      }
      scenarioOriginal.deleteTertiaryPackagingBox();
      this.selectedScenario.deleteTertiaryPackagingBox();
    }
    if (!this.guestService.isUserGuest())
      scenarioOriginal = await this.getScenarioFromDB(scenarioOriginal);
    this.saveProjectLocallyIfGuest();
  }

  async updateScenarioHasTertiaryPackagingPalletization(
    hasTertiaryPackagingPalletization: boolean,
    scenarioOriginal: Scenario
  ): Promise<void> {
    if (hasTertiaryPackagingPalletization) {
      const packaging: TertiaryPackagingPalletization =
        this.guestService.isUserGuest()
          ? this.guestService.createTertiaryPackagingPalletization(
              scenarioOriginal
            )
          : await this.packagingApi.createTertiaryPalletization(
              scenarioOriginal.id
            );
      scenarioOriginal.setTertiaryPackagingPalletization(packaging);
      this.selectedScenario.setTertiaryPackagingPalletization(packaging);
    } else {
      if (this.guestService.isUserGuest())
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        this.guestService.deleteTertiaryPackagingPalletization(
          scenarioOriginal
        );
      else {
        const packagingId =
          scenarioOriginal.firstTertiaryPackagingPalletization?.id;
        assert(packagingId !== undefined);
        await this.packagingApi.deleteTertiaryPackagingPalletization(
          packagingId
        );
      }
      scenarioOriginal.deleteTertiaryPackagingPalletization();
      this.selectedScenario.deleteTertiaryPackagingPalletization();
    }
    if (!this.guestService.isUserGuest())
      scenarioOriginal = await this.getScenarioFromDB(scenarioOriginal);
    this.saveProjectLocallyIfGuest();
  }

  async deleteScenario(scenario: Scenario): Promise<void> {
    await this.scenarioApi.deleteScenario(scenario.id);
    this.currentSingleUseProject.scenarios =
      this.currentSingleUseProject.scenarios.filter(
        (s: Scenario) => s.id != scenario.id
      );
  }

  async duplicateScenario(scenario: Scenario): Promise<number> {
    const response: Scenario = await this.scenarioApi.duplicateScenario(
      scenario.id,
      scenario.name +
        this.translationPipe.transform('interface_default_copy_scenario_name')
    );
    this.currentSingleUseProject.scenarios.push(response);
    return response.id;
  }

  async copyFromLibrary(
    publishedScenarioId: number,
    projectId: number
  ): Promise<number> {
    const response: Scenario = await this.scenarioApi?.copyFromLibrary(
      publishedScenarioId,
      projectId
    );
    this.currentSingleUseProject.scenarios.push(response);
    return response.id;
  }

  // ----------------------------- PUBLISHED SCENARIO -----------------------------

  async exportScenarioToLibrary(
    scenarios: Array<ExportScenarioLibrary>
  ): Promise<void> {
    await this.publishedScenarioApi.exportScenarioToLibrary(scenarios);
    await this.reloadSingleUseProject(this.currentSingleUseProject.id);
  }

  getScenarioLibrary(
    page: number,
    filterCriteria: Array<FilterCriterion>,
    sorting: Sorting,
    statuses: Array<string>
  ): Observable<Page<PublishedScenario>> {
    return this.publishedScenarioApi.getScenarioLibrary(
      page,
      filterCriteria,
      sorting,
      statuses
    );
  }

  async editPublishedScenario(
    publishedScenarioId: number,
    editableFields: PublishedScenarioEditableFields
  ): Promise<void> {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    await this.publishedScenarioApi.editPublishedScenario(
      publishedScenarioId,
      editableFields
    );
  }

  async deletePublishedScenario(
    publishedScenarioToDelete: PublishedScenario
  ): Promise<void> {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    await this.publishedScenarioApi.deletePublishedScenario(
      publishedScenarioToDelete.id
    );
  }

  // ----------------------------- PACKAGING -----------------------------

  async updatePrimaryPackaging(
    primaryPackLocal: PrimaryPackaging,
    primaryPackOriginal: PrimaryPackaging
  ): Promise<void> {
    const updatedPackaging: PrimaryPackaging = this.guestService.isUserGuest()
      ? primaryPackLocal
      : await this.packagingApi.editPrimaryPackaging(
          primaryPackOriginal.id,
          primaryPackLocal
        );
    primaryPackOriginal.mergeEditableFields(updatedPackaging);
    this.saveProjectLocallyIfGuest();
  }

  async updateSecondaryPackaging(
    packLocal: SecondaryPackaging,
    packOriginal: SecondaryPackaging
  ): Promise<void> {
    const updatedPackaging: SecondaryPackaging = this.guestService.isUserGuest()
      ? packLocal
      : await this.packagingApi.editSecondaryPackaging(
          packOriginal.id,
          packLocal
        );
    packOriginal.mergeEditableFields(updatedPackaging);
    this.saveProjectLocallyIfGuest();
  }

  async updatePackagingAssociation(
    assocLocal: PackagingAssociation,
    assocOriginal: PackagingAssociation
  ): Promise<void> {
    const editedAssociation: PackagingAssociation =
      this.guestService.isUserGuest()
        ? assocLocal
        : await this.packagingApi.editPackagingAssociation(
            assocOriginal.id,
            assocLocal
          );
    assocOriginal.mergeEditableFields(editedAssociation);
    this.saveProjectLocallyIfGuest();
  }

  // ----------------------------- RECHARGE -----------------------------

  async createRechargeProject(project: RechargeProject) {
    this.currentRechargeProject = await this.projectApi.createRechargeProject(
      project
    );
  }

  async openRechargeProject(projectId: number) {
    const response = await Promise.all([
      this.projectApi.lockProject(projectId),
      this.projectApi.loadRechargeProject(projectId.toString()),
    ]);
    this.currentProjectLock = response[0];
    this.currentRechargeProject = response[1];
  }

  async reloadRechargeProject(projectId: number): Promise<RechargeProject> {
    this.currentRechargeProject = await this.projectApi.loadRechargeProject(
      projectId.toString()
    );
    return this.currentRechargeProject;
  }

  updateRechargeProject(
    originalProject: RechargeProject,
    updatedProject: RechargeProject
  ): Promise<void> {
    return this.projectApi
      .updateRechargeProject(originalProject.id, updatedProject)
      .then((projectResponse) => {
        originalProject.fillFields(projectResponse);
      });
  }

  async duplicateRechargeProject(project: Project): Promise<number> {
    this.currentRechargeProject =
      await this.projectApi.duplicateRechargeProject(
        project.id,
        project.name +
          this.translationPipe.transform('interface_default_copy_project_name')
      );
    return this.currentRechargeProject.id;
  }

  // ----------------------------- PROJECT COMPARISON -----------------------------

  async setProjectsForComparison(
    firstProject: Project,
    secondProject: Project
  ) {
    // @ts-ignore
    this.firstSelectedProject =
      firstProject.type == 'SingleUseProject'
        ? await this.projectApi.loadSingleUseProject(firstProject.id.toString())
        : await this.projectApi.loadRechargeProject(firstProject.id.toString());
    // @ts-ignore
    this.secondSelectedProject =
      secondProject.type == 'SingleUseProject'
        ? await this.projectApi.loadSingleUseProject(
            secondProject.id.toString()
          )
        : await this.projectApi.loadRechargeProject(
            secondProject.id.toString()
          );
    this.selectedProjectsForComparison = Array.from([
      this.firstSelectedProject,
      this.secondSelectedProject,
    ]);
  }
}
