import { Component, Inject, LOCALE_ID } from '@angular/core';
import { TranslationService } from '@/app/services/translationService/translation.service';
import { SidebarService } from '@/app/services/sidebarService/sidebar.service';
import { GuestService } from '@/app/services/guest.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-methodology-explanation-page',
  templateUrl: './methodology-explanation-page.component.html',
  styleUrls: ['./methodology-explanation-page.component.css'],
})
export class MethodologyExplanationPageComponent {
  constructor(
    public translationService: TranslationService,
    public sidebarService: SidebarService,
    public guestService: GuestService,
    private location: Location,
    @Inject(LOCALE_ID) private locale: string
  ) {}

  goBack() {
    this.location.back();
  }

  onLinkClick(anchor: string): void {
    document.getElementById(anchor)!.scrollIntoView();
    // will scroll to 4th h3 element
  }
}
