export class Sorting {
  direction: SortingDirection;
  sortedBy: string;

  constructor(direction: SortingDirection, sortedby: string) {
    this.direction = direction;
    this.sortedBy = sortedby;
  }

  equals(sorting: Sorting) {
    return (
      this.direction == sorting.direction && this.sortedBy == sorting.sortedBy
    );
  }
}

export enum SortingDirection {
  // eslint-disable-next-line @typescript-eslint/indent
  ASC = 'ASC',
  DESC = 'DESC',
}
