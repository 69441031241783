import { Scenario } from '../packaging-model/scenario';
import { Indicator } from './indicator';
import { Project } from '../packaging-model/project/project';

export class ResultPerScenario {
  scenario!: Scenario;
  project!: Project;
  indicator1!: Indicator;
  resultIndicator1!: number;
  indicator2!: Indicator;
  resultIndicator2!: number;
  indicator3!: Indicator;
  resultIndicator3!: number;
}
