import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ApiUtils } from '@/app/api/api-utils';
import { Company } from '@/app/model/data/company';
import { map } from 'rxjs/operators';
import { plainToClassFromExist } from 'class-transformer';
import { Observable } from 'rxjs';
import { FilterCriterion } from '@/app/model/search/filter-criterion';
import { Page } from '@/app/model/pagination/page';
import { TypedSorting } from '@/app/model/search/typed-sorting';

@Injectable({
  providedIn: 'root',
})
export class CompanyApiService {
  constructor(private http: HttpClient) {}

  buildPath(endPoint: string): string {
    return ApiUtils.BASE_PATH + 'companies/' + endPoint;
  }

  async createCompany(company: Company): Promise<void> {
    const url = this.buildPath('');
    await this.http
      .post<Company>(url, company, ApiUtils.HTTP_OPTIONS)
      .toPromise();
  }

  async editCompany(company: Company): Promise<void> {
    const url = this.buildPath('');
    await this.http
      .put<Company>(url, company, ApiUtils.HTTP_OPTIONS)
      .toPromise();
  }

  getCompanies(
    page: number,
    filterCriteria: Array<FilterCriterion>,
    sorting: TypedSorting<keyof Company>
  ): Observable<Page<Company>> {
    const url = this.buildPath('');
    let queryParams = new HttpParams()
      .set('page', page.toString())
      .set('sortDirection', sorting.direction)
      .set('sortedBy', sorting.sortedBy);
    filterCriteria.forEach(
      (filterCriterion: FilterCriterion) =>
        (queryParams = queryParams.set(
          filterCriterion.key,
          filterCriterion.value
        ))
    );
    const httpOptions = {
      headers: { 'Content-Type': 'application/json' },
      params: queryParams,
    };
    return this.http
      .get<Page<Company>>(url, httpOptions)
      .pipe(
        map((response) =>
          plainToClassFromExist(new Page<Company>(Company), response)
        )
      );
  }
}
