import { Transform, Type } from 'class-transformer';
import { ComponentLevel } from './component-level.enum';
import { Material } from '../material';
import { FinishingProcess } from '../finishing-process';
import { Country } from '@/app/model/data/country';
export class PkgComponent {
  id!: number;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  @Transform((componentLevel) => ComponentLevel[componentLevel])
  componentLevel!: ComponentLevel;
  name!: string;
  numberOfThisComponentInPackaging!: number;
  productionCountry!: Country;

  @Type(() => Material)
  materials!: Array<Material>;

  @Type(() => FinishingProcess)
  finishingProcesses!: Array<FinishingProcess>;

  nbRotation!: number;

  static copy(pkgComponent: PkgComponent): PkgComponent {
    const copy: PkgComponent = new PkgComponent();
    copy.merge(pkgComponent);
    return copy;
  }

  merge(pkgComponent: PkgComponent | undefined): void {
    if (pkgComponent != undefined) {
      this.name = pkgComponent.name;
      this.componentLevel = pkgComponent.componentLevel;
      this.numberOfThisComponentInPackaging =
        pkgComponent.numberOfThisComponentInPackaging;
      this.productionCountry = pkgComponent.productionCountry;
      this.nbRotation = pkgComponent.nbRotation;
    }
  }

  editableFieldsHaveChanged(original: PkgComponent): boolean {
    return (
      this.name != original.name ||
      this.numberOfThisComponentInPackaging !=
        original.numberOfThisComponentInPackaging ||
      this.productionCountry.label != original.productionCountry.label ||
      this.nbRotation != original.nbRotation
    );
  }
}
