import { ComponentLevel } from '../component/component-level.enum';

export class PackagingAssociation {
  id!: number;
  containerPackId!: number;
  containerLevel!: ComponentLevel;
  contentPackId!: number;
  contentLevel!: ComponentLevel;
  numberOfContentPerContainer!: number;

  copy(): PackagingAssociation {
    const copy: PackagingAssociation = new PackagingAssociation();
    copy.id = this.id;
    copy.containerPackId = this.containerPackId;
    copy.containerLevel = this.containerLevel;
    copy.contentPackId = this.contentPackId;
    copy.contentLevel = this.contentLevel;
    copy.numberOfContentPerContainer = this.numberOfContentPerContainer;
    return copy;
  }

  editableFieldsHaveChanged(original: PackagingAssociation): boolean {
    return (
      this.numberOfContentPerContainer != original.numberOfContentPerContainer
    );
  }

  mergeEditableFields(pack: PackagingAssociation): void {
    this.numberOfContentPerContainer = pack.numberOfContentPerContainer;
  }
}
