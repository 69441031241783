import { deburr, lowerCase, trim } from 'lodash';

/**
 * Returns whether the given string is blank.
 *
 * A string is considered blank if either:
 * - It's lengths is equal to zero
 * - It only contains whitespace or line terminator characters
 *
 * @param str the string to test
 * @return whether the given string is blank or not
 */
export function isBlank(str: string | undefined): boolean {
  return trim(str).length === 0;
}

/**
 * Normalize the given string for filter and sort operations.
 *
 * The following operations will be applied:
 * - The string will be converted to lowercase
 * - Every latin character with diacritical mark (e.g. à) will be converted to the equivalent character without diacritical mark (a)
 *
 * @param str to normalize
 * @return the normalized string
 */
export function normalizeForFilterAndSort(str: string | undefined): string {
  return deburr(lowerCase(str));
}
