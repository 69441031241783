import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import {
  NgbModal,
  NgbModalOptions,
  NgbModalRef,
} from '@ng-bootstrap/ng-bootstrap';
import { ProjectService } from '@/app/services/project.service';
import { Scenario } from '@/app/model/packaging-model/scenario';
import { SingleUseProject } from '@/app/model/packaging-model/project/single-use-project';
import { ExportScenarioLibrary } from '@/app/model/packaging-model/export-scenario-library';
import { LoginService } from '@/app/services/login/login.service';

@Component({
  selector: 'app-export-scenarios-modal[project]',
  templateUrl: './export-scenarios-modal.component.html',
  styleUrls: ['./export-scenarios-modal.component.css'],
})
export class ExportScenariosModalComponent {
  private readonly MODAL_OPTIONS: NgbModalOptions = {
    size: 'xl',
    backdrop: 'static',
  };

  selectedStep: 'select' | 'rename' = 'select';

  @ViewChild('exportscenariomodal')
  private readonly templateModal!: TemplateRef<any>;
  private modal: NgbModalRef | undefined;

  @Input() project!: SingleUseProject;

  scenarios: Scenario[] = [];

  selectedScenarios: Scenario[] = [];

  selectedExportScenariosLibrary: Array<ExportScenarioLibrary> = [];

  allSelected = false;
  someSelected = false;

  constructor(
    private projectService: ProjectService,
    private modalService: NgbModal,
    private loginService: LoginService
  ) {}

  open(): void {
    this.resetDialog();
    this.loadDataAndRefreshView();
    this.modal = this.modalService.open(this.templateModal, this.MODAL_OPTIONS);
  }

  private loadDataAndRefreshView(): void {
    this.scenarios = this.project.scenarios;
    this.updateSelectAllCheckbox();
  }

  private resetDialog(): void {
    this.selectedExportScenariosLibrary = [];
    this.selectedScenarios = [];
    this.allSelected = false;
    this.someSelected = false;
    this.loadDataAndRefreshView();
    this.select('select');
  }

  private close(): void {
    this.modal?.close();
  }

  // endregion

  // region SAVE & CANCEL

  onSave(): void {
    this.close();
    void this.projectService.exportScenarioToLibrary(
      this.selectedExportScenariosLibrary
    );
    this.resetDialog();
  }

  onCancel(): void {
    this.close();
    this.resetDialog();
  }

  // endregion

  // region SELECT

  onSelectAll(event: Event): void {
    const checked = (event.target as unknown as { checked: boolean }).checked;
    if (checked)
      this.scenarios.forEach((scenario) => {
        if (!this.scenarioIsAlreadySelected(scenario.id)) {
          const scenarioExportLibrary =
            this.buildNewScenarioExportLibraryfromScenario(scenario);
          this.selectedExportScenariosLibrary.push(scenarioExportLibrary);
          this.selectedScenarios.push(scenario);
        }
      });
    else {
      this.selectedExportScenariosLibrary = [];
      this.selectedScenarios = [];
    }
    this.updateSelectAllCheckbox();
  }

  scenarioIsAlreadySelected(scenarioId: number) {
    let res = false;
    this.selectedExportScenariosLibrary.forEach((s: ExportScenarioLibrary) => {
      if (s.fromScenarioId == scenarioId) res = true;
    });
    return res;
  }

  onSelect(scenario: Scenario): void {
    if (this.scenarioIsAlreadySelected(scenario.id)) {
      let index = 0;
      this.selectedExportScenariosLibrary.forEach((s) => {
        if (s.fromScenarioId === scenario.id)
          index = this.selectedExportScenariosLibrary.indexOf(s);
      });
      this.selectedExportScenariosLibrary.splice(index, 1);
      this.selectedScenarios.splice(this.selectedScenarios.indexOf(scenario));
    } else {
      const scenarioExportLibrary =
        this.buildNewScenarioExportLibraryfromScenario(scenario);
      this.selectedExportScenariosLibrary.push(scenarioExportLibrary);
      this.selectedScenarios.push(scenario);
    }
    this.updateSelectAllCheckbox();
  }

  private updateSelectAllCheckbox() {
    const selectedCount = this.selectedExportScenariosLibrary.length;
    this.someSelected =
      selectedCount > 0 && selectedCount < this.scenarios.length;
    this.allSelected = selectedCount === this.scenarios.length;
  }

  // endregion

  select(s: 'select' | 'rename') {
    this.selectedStep = s;
  }

  isSelected(step: string) {
    return this.selectedStep === step;
  }

  updateScenarioExportLibrary(
    scenarioExportLibraryLocal: ExportScenarioLibrary
  ) {
    this.selectedExportScenariosLibrary.forEach((s: ExportScenarioLibrary) => {
      if (s.fromScenarioId === scenarioExportLibraryLocal.fromScenarioId)
        s.mergeEditableFields(scenarioExportLibraryLocal);
    });
  }

  buildNewScenarioExportLibraryfromScenario(
    scenario: Scenario
  ): ExportScenarioLibrary {
    const scenarioExportLibrary = new ExportScenarioLibrary();
    scenarioExportLibrary.fromScenarioId = scenario.id;
    scenarioExportLibrary.name = scenario.name;
    return scenarioExportLibrary;
  }
}
