import { Component, Input } from '@angular/core';

@Component({
  selector: 'sidebar-component',
  templateUrl: './sidebar.component.html',
})
export class SidebarComponent {
  // @ts-ignore
  @Input() collapsible: boolean;
}
