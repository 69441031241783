import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Pager } from '@/app/utils/pager';

@Component({
  selector: 'app-pagination-bar',
  templateUrl: './pagination-bar.component.html',
  styleUrls: ['./pagination-bar.component.css'],
})
export class PaginationBarComponent {
  @Input()
  pager: Pager | undefined;

  @Output() selectedPage = new EventEmitter<number>();

  setPage(page: number) {
    if (this.pager)
      if (page >= this.pager.startPage && page <= this.pager.endPage) {
        this.selectedPage.emit(page);
      }
  }
}
