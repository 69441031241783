import { DDL } from './ddl';
import { MaterialTypeGroup } from '@/app/model/data/material-type-group';
import { Type } from 'class-transformer';

export class MaterialType extends DDL {
  @Type(() => MaterialTypeGroup)
  materialTypeGroup!: MaterialTypeGroup;

  constructor() {
    super();
  }
}
