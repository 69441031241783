/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { CategoryResult } from '@/app/model/results/category-result';
import { ChartResult } from '@/app/model/results/chartResult';
import { ComponentResult } from '@/app/model/results/component-result';
import { DetailedResult } from '@/app/model/results/detailed-result';
import { Indicator } from '@/app/model/results/indicator';
import { RechargeType } from '@/app/model/results/recharge-type';
import { Result } from '@/app/model/results/result';
import { Injectable } from '@angular/core';
import { TranslationService } from '../translationService/translation.service';

@Injectable({
  providedIn: 'root',
})
export class ChartDataBuilderService {
  constructor(private translationService: TranslationService) {}

  colors: Array<string> = [
    '#02403B',
    '#00786E',
    '#00B1A1',
    '#87D8D1',
    '#CCF5F1',
    '#B5D9CD',
    '#68D9A5',
    '#00B570',
    '#63974C',
    '#AF9235',
    '#F9C116',
    '#f7de9f',
    '#f7eacb',
    '#BAC0A7',
    '#7D9583',
    '#3F6B5F',
  ];

  buildPackChartData(
    selectedScenarioResults: DetailedResult[],
    isSingleScoreData: boolean,
    indicator: Indicator
  ): ChartResult[] {
    const datasets: Array<ChartResult> = [];
    const pkgGraphData = isSingleScoreData
      ? this.breakdownPerPackagingPerScenario(selectedScenarioResults)
      : this.breakdownPerPackagingForIndicator(
          selectedScenarioResults,
          indicator
        );
    const packagingResultCats = [
      CategoryResult.PRIMARY_PACK,
      CategoryResult.SECONDARY_PACK,
      CategoryResult.TERTIARY_PACK_IN,
      CategoryResult.TERTIARY_PACK_OUT,
      CategoryResult.DISTRIBUTION,
    ];

    packagingResultCats.forEach((cat, idx) => {
      const obj: ChartResult = new ChartResult();
      obj.data = [];
      for (let i = 0; i < pkgGraphData.length; i++) {
        for (let j = 0; j < pkgGraphData[i].length; j++) {
          if (pkgGraphData[i][j].resultCategory.category === cat)
            obj.data.push(pkgGraphData[i][j].result.toString());
        }
      }
      obj.label = this.translationService.getTranslationValueForLabel(cat);
      obj.backgroundColor = this.colors[idx];
      obj.hoverBackgroundColor = this.colors[idx];
      if (!this.dataContainsOnlyZeros(obj.data)) datasets.push(obj);
    });
    return datasets;
  }

  buildLCAChartData(
    selectedScenarioResults: DetailedResult[],
    isSingleScoreData: boolean,
    indicator: Indicator
  ): ChartResult[] {
    const datasets: Array<ChartResult> = [];
    const lcaStepsCats = [
      CategoryResult.PRODUCTION,
      CategoryResult.TRANSPORT_RMPROD_SUPPLIER,
      CategoryResult.CONVERTING,
      CategoryResult.FINISHING,
      CategoryResult.TRANSPORT_SUPPLIER_MANUFACTURING,
      CategoryResult.DISTRIBUTION,
      CategoryResult.END_OF_LIFE,
    ];
    const lcaStepGraphData = isSingleScoreData
      ? this.breakdownPerLCAStep(selectedScenarioResults)
      : this.breakdownPerLCAStepForIndicator(
          selectedScenarioResults,
          indicator
        );

    lcaStepsCats.forEach((cat, idx) => {
      const obj: ChartResult = new ChartResult();
      obj.data = [];
      for (let i = 0; i < lcaStepGraphData.length; i++) {
        for (let j = 0; j < lcaStepGraphData[i].length; j++) {
          if (lcaStepGraphData[i][j].resultCategory.category == cat)
            obj.data.push(lcaStepGraphData[i][j].result.toString());
        }
      }
      obj.label = this.translationService.getTranslationValueForLabel(cat);
      obj.backgroundColor = this.colors[idx];
      obj.hoverBackgroundColor = this.colors[idx];
      if (!this.dataContainsOnlyZeros(obj.data)) datasets.push(obj);
    });

    return datasets;
  }

  buildComponentChartData(
    selectedScenarioResults: DetailedResult,
    isSingleScoreData: boolean,
    indicator: Indicator,
    rechargeType: RechargeType | null,
    rechargeProjectId?: number
  ): ChartResult[] {
    const datasets: Array<ChartResult> = [];
    const componentGraphData = isSingleScoreData
      ? this.breakdownPerComponent(selectedScenarioResults, rechargeType)
      : this.breakdownPerComponentForIndicator(
          selectedScenarioResults,
          indicator,
          rechargeType
        );

    for (let j = 0; j < componentGraphData.length; j++) {
      const obj: ChartResult = new ChartResult();
      obj.data = [];
      obj.data.push(componentGraphData[j].result.toString());
      obj.label = componentGraphData[j].elementLevel.name;
      obj.elementId = componentGraphData[j].elementLevel.id;
      obj.rechargeProjectId = rechargeProjectId;
      if (!this.dataContainsOnlyZeros(obj.data)) datasets.push(obj);
    }
    datasets.sort((a, b) =>
      parseFloat(a.data[0]) < parseFloat(b.data[0]) ? 1 : -1
    );
    datasets.forEach((d, i) => {
      d.backgroundColor = this.colors[i];
      d.hoverBackgroundColor = this.colors[i];
    });
    return datasets;
  }

  buildMaterialChartData(componentResult: ComponentResult): ChartResult[] {
    return componentResult.perLCAStepResults
      .sort(
        (a, b) =>
          Object.values(CategoryResult).indexOf(a.resultCategory.category) -
          Object.values(CategoryResult).indexOf(b.resultCategory.category)
      )
      .map((res, idx) => {
        const processName =
          res.processName !== ''
            ? ' - ' +
              this.translationService.getTranslationValueForLabel(
                res.processName
              )
            : '';
        return {
          data: [res.result.toString()],
          backgroundColor: this.colors[idx],
          label:
            res.elementName +
            ' - ' +
            this.translationService.getTranslationValueForLabel(
              res.resultCategory.category
            ) +
            processName,
        };
      })
      .filter((res) => !this.dataContainsOnlyZeros(res.data));
  }

  buildRechargeChartData(
    selectedScenarioResults: DetailedResult[],
    isSingleScoreData: boolean,
    indicator: Indicator
  ): ChartResult[] {
    const datasets: Array<ChartResult> = [];
    const rechargeGraphData = isSingleScoreData
      ? this.breakdownPerRechargeType(selectedScenarioResults)
      : this.breakdownPerRechargeTypeForIndicator(
          selectedScenarioResults,
          indicator
        );
    for (let k = 0; k < 2; k++) {
      const obj: ChartResult = new ChartResult();
      obj.data = [];
      for (let i = 0; i < rechargeGraphData.length; i++) {
        obj.data.push(rechargeGraphData[i][k].result.toString());
      }
      obj.label = this.translationService.getTranslationValueForLabel(
        rechargeGraphData[0][k].rechargeType
      );
      obj.backgroundColor = k == 0 ? this.colors[k] : this.colors[k + 2];
      obj.hoverBackgroundColor = k == 0 ? this.colors[k] : this.colors[k + 2];
      if (!this.dataContainsOnlyZeros(obj.data)) datasets.push(obj);
    }

    return datasets;
  }

  buildDataPerIndic(selectedScenarioResults: DetailedResult[]): ChartResult[] {
    const datasets: Array<ChartResult> = [];
    const perIndicGraphData = this.breakdownPerIndicator(
      selectedScenarioResults
    );

    const indicators = perIndicGraphData[0].map((res) => res.indicator);
    indicators.sort((a, b) => (a.id > b.id ? 1 : -1));

    for (let l = 0; l < indicators.length; l++) {
      const obj: ChartResult = new ChartResult();
      obj.data = [];
      for (let i = 0; i < perIndicGraphData.length; i++) {
        for (let j = 0; j < perIndicGraphData[i].length; j++) {
          if (perIndicGraphData[i][j].indicator.value == indicators[l].value)
            obj.data.push(perIndicGraphData[i][j].result.toString());
        }
      }
      obj.label = indicators[l].value;
      obj.backgroundColor = this.colors[l];
      obj.hoverBackgroundColor = this.colors[l];
      if (!this.dataContainsOnlyZeros(obj.data)) datasets.push(obj);
    }

    return datasets;
  }

  private dataContainsOnlyZeros(data: string[]): boolean {
    for (let i = 0; i < data.length; i++) {
      if (data[i] != '0.00e+0' && data[i] != '0') return false;
    }
    return true;
  }

  private breakdownPerPackagingPerScenario(
    selectedScenarioResults: DetailedResult[]
  ): Result[][] {
    return selectedScenarioResults.map((res) => res.perPackagingSingleScores);
  }

  private breakdownPerPackagingForIndicator(
    selectedScenarioResults: DetailedResult[],
    indic: Indicator
  ): Result[][] {
    return selectedScenarioResults.map((res) =>
      res.perPackagingAndIndicatorImpacts.filter(
        (res) => res.indicator.id === indic.id
      )
    );
  }

  private breakdownPerLCAStep(
    selectedScenarioResults: DetailedResult[]
  ): Result[][] {
    return selectedScenarioResults.map((res) => res.perLCAStepSingleScores);
  }

  private breakdownPerLCAStepForIndicator(
    selectedScenarioResults: DetailedResult[],
    indic: Indicator
  ): Result[][] {
    return selectedScenarioResults.map((res) =>
      res.perLCAStepAndIndicatorImpacts.filter(
        (res) => res.indicator.id === indic.id
      )
    );
  }

  private breakdownPerComponent(
    selectedScenarioResults: DetailedResult,
    rechargeType: RechargeType | null
  ): Result[] {
    return rechargeType != null
      ? rechargeType == RechargeType.MOTHER
        ? selectedScenarioResults.perComponentScores.filter(
            (compScore) => compScore.rechargeType === RechargeType.MOTHER
          )
        : selectedScenarioResults.perComponentScores.filter(
            (compScore) => compScore.rechargeType === RechargeType.DAUGHTER
          )
      : selectedScenarioResults.perComponentScores;
  }

  private breakdownPerComponentForIndicator(
    selectedScenarioResults: DetailedResult,
    indic: Indicator,
    rechargeType: RechargeType | null
  ): Result[] {
    return rechargeType != null
      ? rechargeType == RechargeType.MOTHER
        ? selectedScenarioResults.perComponentAndIndicatorImpacts.filter(
            (res) =>
              res.rechargeType === RechargeType.MOTHER &&
              res.indicator.id === indic.id
          )
        : selectedScenarioResults.perComponentAndIndicatorImpacts.filter(
            (res) =>
              res.rechargeType === RechargeType.DAUGHTER &&
              res.indicator.id === indic.id
          )
      : selectedScenarioResults.perComponentAndIndicatorImpacts.filter(
          (res) => res.indicator.id === indic.id
        );
  }

  private breakdownPerIndicator(
    selectedScenarioResults: DetailedResult[]
  ): Result[][] {
    return selectedScenarioResults.map((res) => res.perIndicatorScores);
  }

  private breakdownPerRechargeType(
    selectedScenarioResults: DetailedResult[]
  ): Result[][] {
    return selectedScenarioResults.map(
      (res) => res.perRechargeTypeSingleScores
    );
  }

  private breakdownPerRechargeTypeForIndicator(
    selectedScenarioResults: DetailedResult[],
    indic: Indicator
  ): Result[][] {
    return selectedScenarioResults.map((res) =>
      res.perRechargeTypeImpacts.filter((res) => res.indicator.id === indic.id)
    );
  }
}
