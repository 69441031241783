import { TranslationPipe } from '@/app/intl/translation-pipe';
import { Scenario } from '@/app/model/packaging-model/scenario';
import { FormUtils } from '@/app/utils/form-utils';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import assert from 'assert';
import { ExportScenarioLibrary } from '@/app/model/packaging-model/export-scenario-library';
import { User } from '@/app/model/user/user';

@Component({
  selector: 'export-scenario-row',
  templateUrl: './export-scenario-row.component.html',
  styleUrls: ['./export-scenario-row.component.css'],
})
export class ExportScenarioRowComponent implements OnInit {
  @Input() scenario!: Scenario;

  @Input() scenarioLibrary!: ExportScenarioLibrary;

  @Output() changeMade = new EventEmitter<ExportScenarioLibrary>();

  @Input() currentUser!: User | null;

  scenarioExportLibrary = new ExportScenarioLibrary();

  private _scenarioform: UntypedFormGroup | undefined;

  get scenarioform(): UntypedFormGroup {
    assert(this._scenarioform !== undefined);
    return this._scenarioform;
  }

  set scenarioform(scenarioform: UntypedFormGroup) {
    this._scenarioform = scenarioform;
  }

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private translationPipe: TranslationPipe
  ) {}

  printScenarioName(scenarioName: string): Map<string, string> {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return new Map().set('$1', scenarioName);
  }

  ngOnInit() {
    this.initScenarioExportLibrary();
    if (this._scenarioform === undefined) {
      this.initScenarioForm();
    } else {
      this.updateFieldsScenario();
    }
  }

  private initScenarioForm(): void {
    this.scenarioform = this.formBuilder.group({
      name: [
        this.scenarioExportLibrary.name,
        Validators.compose([Validators.maxLength(255)]),
      ],
    });
    FormUtils.updateModelFromFieldValue<string>(
      this.scenarioform.controls['name'],
      (v) => {
        this.scenarioExportLibrary.name = v;
        this.changeMade.emit(this.scenarioExportLibrary);
      }
    );
  }

  private updateFieldsScenario(): void {
    this.scenarioform.controls['name'].setValue(
      this.scenarioExportLibrary.name
    );
  }

  private initScenarioExportLibrary() {
    if (this.scenario != null) {
      this.scenarioExportLibrary.fromScenarioId = this.scenario.id;
      this.scenarioExportLibrary.name = this.scenario.name;
    } else this.scenarioExportLibrary = this.scenarioLibrary;
  }
}
