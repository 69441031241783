<ng-template #projectcreationmodal let-modal>
  <div class="modal-content">
    <div class="modal-header modal-spacing">
      <div class="modal-title" *ngIf="this.singleuse && this.createProject">
        {{ "interface_modal_new_single_use" | translationPipe }}
      </div>
      <div class="modal-title" *ngIf="this.singleuse && !this.createProject">
        {{ "interface_modal_edit_single_use" | translationPipe }}
      </div>
      <div class="modal-title" *ngIf="!this.singleuse && this.createProject">
        {{ "interface_modal_new_recharge" | translationPipe }}
      </div>
      <div class="modal-title" *ngIf="!this.singleuse && !this.createProject">
        {{ "interface_modal_edit_recharge" | translationPipe }}
      </div>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        aria-label="Close"
        (click)="close()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <form
      [formGroup]="checkoutForm"
      id="loginForm"
      (ngSubmit)="onSubmit(checkoutForm.value)"
    >
      <div class="modal-body break-word modal-spacing">
        <div class="form-group">
          <label for="new-project-name">
            {{ "interface_project_name" | translationPipe }}</label
          >
          <input
            id="new-project-name"
            class="form-control"
            name="login"
            type="text"
            formControlName="name"
          />
          <validation-messages
            [control]="checkoutForm.get('name')"
            class="form-invalid-message"
          >
          </validation-messages>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="new-project-brand">{{
                "interface_brand" | translationPipe
              }}</label>
              <input
                id="new-project-brand"
                class="form-control"
                name="brand"
                type="text"
                formControlName="brand"
              />
              <validation-messages
                [control]="checkoutForm.get('brand')"
                class="form-invalid-message"
              >
              </validation-messages>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="new-project-code">{{
                "interface_code" | translationPipe
              }}</label>
              <input
                id="new-project-code"
                class="form-control"
                name="code"
                type="text"
                formControlName="code"
              />
              <validation-messages
                [control]="checkoutForm.get('code')"
                class="form-invalid-message"
              >
              </validation-messages>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="new-project-description">{{
            "interface_description" | translationPipe
          }}</label>
          <textarea
            id="new-project-description"
            class="form-control"
            name="description"
            type="text"
            formControlName="description"
          >
          </textarea>
          <validation-messages
            [control]="checkoutForm.get('description')"
            class="form-invalid-message"
          >
          </validation-messages>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-cancel" (click)="close()">
          {{ "interface_cancel" | translationPipe }}
        </button>
        <button
          type="submit"
          id="new-project-create"
          class="btn btn-primary login-btn"
          [disabled]="
            isBusy ||
            !checkoutForm.valid ||
            !valuesHaveChanged(checkoutForm.value)
          "
        >
          {{
            createProject
              ? ("interface_create" | translationPipe)
              : ("interface_save" | translationPipe)
          }}
        </button>
      </div>
    </form>
  </div>
</ng-template>
