import { Component, Input } from '@angular/core';
import { SingleUseProject } from '@/app/model/packaging-model/project/single-use-project';
import { ProjectService } from '@/app/services/project.service';
import { GlobalService } from '@/app/services/global.service';
import { GuestService } from '@/app/services/guest.service';
import { Scenario } from '@/app/model/packaging-model/scenario';
import { RechargeProject } from '@/app/model/packaging-model/project/recharge-project';

@Component({
  selector: 'sidebar-assess-one-project',
  templateUrl: './sidebar-assess-one-project.component.html',
  styleUrls: ['./sidebar-assess-one-project.component.css'],
})
export class SidebarAssessOneProjectComponent {
  @Input() collapsible!: boolean;
  @Input() projectIsSingleUse!: boolean;

  get project(): SingleUseProject {
    if (this.guestService.isUserGuest() && this.guestService.guestUserProject) {
      return this.guestService.guestUserProject;
    } else return this.projectService.currentSingleUseProject;
  }

  get rechargeProject(): RechargeProject {
    return this.projectService.currentRechargeProject;
  }

  constructor(
    private projectService: ProjectService,
    public globalService: GlobalService,
    public guestService: GuestService
  ) {}

  switchIncludeInAssess(scenario: Scenario): void {
    const index = this.projectService.selectedScenariosForAssess
      .map((ssfa: Scenario) => ssfa.id)
      .indexOf(scenario.id);
    const scenarios = this.projectService.selectedScenariosForAssess;
    if (index > -1) {
      this.projectService.updateSelectedScenariosForAssess(
        this.projectService.selectedScenariosForAssess.filter(
          (s: Scenario) => s.id != scenario.id
        )
      );
    } else {
      scenarios.push(scenario);
      this.projectService.updateSelectedScenariosForAssess(scenarios);
    }
  }

  async switchSelectAsReference(scenario: Scenario): Promise<void> {
    return await this.projectService.setScenarioToReference(scenario.id);
  }

  isSelected(scenario: Scenario): boolean {
    return this.projectService.selectedScenariosForAssess != null
      ? this.projectService.selectedScenariosForAssess.filter(
          (item: Scenario) => item.id === scenario.id
        ).length > 0
      : false;
  }

  isCurrentReferenceScenario(scenario: Scenario): boolean {
    return (
      scenario.id === this.project.referenceScenarioId &&
      !this.guestService.isUserGuest()
    );
  }
}
