import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  isSaving: boolean | undefined;

  constructor() {
    this.isSaving = false;
  }

  setIsSaving(isSaving: boolean) {
    this.isSaving = isSaving;
  }
}
