<div class="sidebar-content">
  <sidebar-user-mgmt [isCollapsible]="collapsible"></sidebar-user-mgmt>

  <div class="projects-management" *ngIf="globalService.listsReady">
    <div class="scrollableMenu">
      <div *ngFor="let project of projects; let i = index">
        <div class="project-title-container">
          <p class="scenario-title">Project {{ i + 1 }}</p>
        </div>
        <div class="scenario-name-container">
          <div class="scenario-name">
            {{ project.name }}
          </div>
        </div>
        <div class="scenarios-management" *ngIf="!isRecharge(project)">
          <div class="scenario-title-container">
            <p class="scenario-title">Scenarios</p>
          </div>
          <div class="scenario-container">
            <ul class="nav flex-column">
              <li class="menu-li-scenario">
                <div class="scenario-name-container">
                  <div class="scenario-name">
                    <a class="scenario-header-name">{{
                      getReferenceScenarioFromProject(project)?.name
                    }}</a>
                    <em
                      *ngIf="
                        getReferenceScenarioFromProject(project)?.id ==
                          getReferenceScenarioFromProject(project)?.id &&
                        !guestService.isUserGuest()
                      "
                      class="fas fa-star isReference"
                      title="{{ 'interface_is_reference' | translationPipe }}"
                    ></em>
                  </div>
                </div>
              </li>
              <li class="menu-li">
                <ul class="sub-list-scenario-definition">
                  <li class="menu-li" *ngFor="let level of componentLevels">
                    <div
                      class="label-with-plus"
                      *ngIf="
                        isLevelExisting(
                          level,
                          getReferenceScenarioFromProject(project)
                        )
                      "
                    >
                      {{ getStringFromLevel(level) | translationPipe }}
                    </div>
                    <div
                      class="component-name-container"
                      *ngFor="
                        let component of getReferenceScenarioFromProject(
                          project
                        )?.componentsOfLevel(level)
                      "
                    >
                      <div class="component-name-sidebar">
                        <a>
                          {{ component.name }}
                        </a>
                      </div>
                    </div>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
        <div class="scenarios-management" *ngIf="isRecharge(project)">
          <div class="scenario-title-container">
            <p class="scenario-title">
              {{ "interface_mother_project" | translationPipe }}
            </p>
          </div>
          <div class="scenario-container">
            <div class="scenario-name-container">
              <div class="scenario-name">
                <a class="scenario-header-name"
                  >{{
                    project.motherProject != null
                      ? project.motherProject.name
                      : ("interface_project_undefined" | translationPipe)
                  }}
                  <p
                    class="project-name-complement"
                    *ngIf="project.motherProject"
                  >
                    {{ "interface_compare_mother_refilled" | translationPipe }}
                    {{ project.nbMotherUsed }}
                    {{ "interface_compare_times" | translationPipe }}
                  </p></a
                >
              </div>
            </div>
          </div>
          <div class="scenario-title-container">
            <p class="scenario-title">
              {{ "interface_daughter_project" | translationPipe }}
            </p>
          </div>
          <div class="scenario-container">
            <div class="scenario-name-container">
              <div class="scenario-name">
                <a class="scenario-header-name"
                  >{{
                    project.daughterProject != null
                      ? project.daughterProject.name
                      : ("interface_project_undefined" | translationPipe)
                  }}
                  <p
                    class="project-name-complement"
                    *ngIf="project.daughterProject"
                  >
                    {{ "interface_compare_daughter_used" | translationPipe }}
                    {{ project.nbDaughterUsed }}
                    {{ "interface_compare_times" | translationPipe }}
                  </p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <sidebar-footer></sidebar-footer>
</div>
