import { Injectable } from '@angular/core';
import { GlobalApi } from '../api/global-api.service';
import { Bundle } from '../model/data/bundle';
import { Country } from '../model/data/country';
import { Zone } from '../model/data/zone';
import { TranslationService } from './translationService/translation.service';
import { DDL } from '../model/data/ddl';
import { FeatureService } from '@/app/services/featureService/feature.service';
import { FeatureStatus } from '@/app/services/featureService/feature-status';
import { UsualFeaturesAndRules } from '@/app/services/featureService/usual-features-and-rules';
import { ConversionProcess } from '@/app/model/data/conversion-process';
import { MaterialType } from '@/app/model/data/material-type';
import { FinishingProcessType } from '@/app/model/data/finishing-process-type';
import { MaterialTypeGroup } from '@/app/model/data/material-type-group';
import { LoginService } from './login/login.service';
import { switchMap } from 'rxjs/operators';
import { PublishedScenarioStatus } from '../model/packaging-model/published-scenario_status';

@Injectable({
  providedIn: 'root',
})
export class GlobalService {
  // @ts-ignore
  countries: Array<Country>;
  // @ts-ignore
  zones: Array<Zone>;
  // @ts-ignore
  conversionProcesses: Array<ConversionProcess>;
  // @ts-ignore
  materialTypes: Array<MaterialType>;
  // @ts-ignore
  conversionProcessesPerMaterialTypeGroup: Map<
    number,
    Array<ConversionProcess>
  >;
  // @ts-ignore
  limits: Map<string, number>;
  limitsGuestUser = new Map<string, number>()
    .set('componentsPerLevel', 4)
    .set('materialsPerComponent', 3)
    .set('finishingProcessesPerComponent', 2);
  // @ts-ignore
  finishingProcessTypes: Array<FinishingProcessType>;
  // @ts-ignore
  defaultNbContentPerContainer: number;
  listsReady = false;
  dbVersion: string | undefined;
  availablePublishedScenarioStatusList:
    | Array<PublishedScenarioStatus>
    | undefined;

  constructor(
    private _globalApi: GlobalApi,
    private _translationService: TranslationService,
    private _featureService: FeatureService,
    private _loginService: LoginService
  ) {
    _featureService.dispatcher$.subscribe((fs: FeatureStatus) => {
      if (fs.feature == TranslationService.TRANSLATION_FEATURE) {
        if (fs.isActive) {
          this.reloadData();
        } else if (
          _featureService.isActive(UsualFeaturesAndRules.ONLINE_FEATURE)
        ) {
          this.countries = [];
          this.zones = [];
          this.listsReady = false;
          this.dbVersion = undefined;
          this.availablePublishedScenarioStatusList = [];
        }
      }
    });
    if (_featureService.isActive(TranslationService.TRANSLATION_FEATURE)) {
      this.reloadData();
    }
  }

  reloadData() {
    this.listsReady = false;
    if (this._loginService.signedIn)
      this._globalApi
        .getBundle()
        .pipe(
          switchMap(async (response: Bundle) => {
            await this._translationService.translationsLoaded;
            const tmpCountries = this.keepOnlyCountries(response.countries);
            const tmpZones = this.keepOnlyZones(response.countries);
            this.countries = this.translateAndSortCountries(
              tmpZones,
              tmpCountries
            );
            this.conversionProcesses = this.translateAndSort(
              response.conversionProcesses
            );
            this.materialTypes = this.translateAndSort(response.materialTypes);
            this.createMapOfConversionProcessesPerMaterialTypeGroup(
              response.materialTypeGroups
            );
            this.limits = response.limits;
            this.finishingProcessTypes = this.translateAndSort(
              response.finishingProcessTypes
            );
            this.defaultNbContentPerContainer =
              response.defaultNbContentPerContainer;
            this.listsReady = true;
            this.dbVersion = response.dbVersion;
            this.availablePublishedScenarioStatusList =
              response.publishedScenarioStatusList;
          })
        )
        .subscribe();
  }

  keepOnlyCountries<T extends DDL>(array: Array<T>): Array<T> {
    return array.filter((p) => p.label.startsWith('c'));
  }

  keepOnlyZones<T extends DDL>(array: Array<T>): Array<T> {
    return array.filter((p) => p.label.startsWith('z'));
  }

  translateAndSort<T extends DDL>(array: Array<T>): Array<T> {
    return array
      .map((object) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        object.valueIntl = this._translationService.getTranslationValueForLabel(
          object.label
        );
        return object;
      })
      .sort((a, b) => a.valueIntl.localeCompare(b.valueIntl));
  }

  translateAndSortCountries<T extends DDL>(
    countries: Array<T>,
    zones: Array<T>
  ): Array<T> {
    return countries
      .map((object) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        object.valueIntl = this._translationService.getTranslationValueForLabel(
          object.label
        );
        return object;
      })
      .sort((a, b) => a.valueIntl.localeCompare(b.valueIntl))
      .concat(
        zones
          .map((object) => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            object.valueIntl =
              this._translationService.getTranslationValueForLabel(
                object.label
              );
            return object;
          })
          .sort((a, b) => a.valueIntl.localeCompare(b.valueIntl))
      );
  }

  createMapOfConversionProcessesPerMaterialTypeGroup(
    materialTypeGroups: Array<MaterialTypeGroup>
  ) {
    this.conversionProcessesPerMaterialTypeGroup = new Map<
      number,
      Array<ConversionProcess>
    >();
    materialTypeGroups.forEach((mtGroup) => {
      this.conversionProcessesPerMaterialTypeGroup.set(
        mtGroup.id,
        this.translateAndSort(mtGroup.conversionProcesses)
      );
    });
  }

  getCountries() {
    return this.countries;
  }
}
