<label
  [ngClass]="{
    far: !indeterminate && !checked,
    fas: indeterminate || checked,
    'fa-square': !indeterminate && !checked,
    'fa-check-square': !indeterminate && checked,
    'fa-minus-square': indeterminate,
    disabled: disabled,
    unclickable: !clickable
  }"
>
  <input
    type="checkbox"
    [checked]="checked"
    (change)="onChanged($event)"
    [disabled]="disabled"
  />
</label>
