import { Project } from './project';
import { User } from '@/app/model/user/user';
import { Type, Transform } from 'class-transformer';

export class ProjectLock {
  @Type(() => Project)
  project!: Project;

  @Type(() => User)
  user!: User;

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-call
  @Transform((value) => new Date(value.substring(0, 19))) // Format "YYYY-MM-DDThh:mm::ss" (first 19 elements)
  lastAction!: Date;

  success!: boolean;

  unlockable!: boolean;
}
