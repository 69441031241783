<ng-template #finishingprocesscreationmodal let-modal>
  <div class="modal-content">
    <div class="modal-header modal-spacing">
      <div class="modal-title">
        {{ "interface_component_add_finishing_process" | translationPipe }}
      </div>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        aria-label="Close"
        (click)="close()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <form
      [formGroup]="createFinProcessForm"
      (ngSubmit)="onSubmit(createFinProcessForm.value)"
    >
      <div class="modal-body break-word modal-spacing">
        <div class="form-group">
          <label for="fPType">
            {{
              "interface_component_finishing_processes_type" | translationPipe
            }}</label
          >
          <select
            id="dropdown"
            class="custom-select form-control"
            [ngClass]="{ gray: isPristine('finishingProcessType') }"
            formControlName="finishingProcessType"
          >
            <option class="select-default" value="null">
              {{ "interface_select_placeholder" | translationPipe }}
            </option>
            <option
              *ngFor="let fPType of finishingProcessTypes"
              [ngValue]="fPType"
            >
              {{ fPType.valueIntl }}
            </option>
          </select>
          <span
            class="process-help"
            *ngIf="
              getSelectedProcess() != null &&
              getSelectedProcess().label != 'noprocess'
            ">
            <em class="fas fa-info-circle"></em>
            {{ getHelpBoxTranslation() | translationPipe }}
          </span>
          <validation-messages
            [control]="createFinProcessForm.get('finishingProcessType')"
            class="form-invalid-message"
          >
          </validation-messages>
        </div>

        <div class="row">
          <div class="col-md-7">
            <label for="surface">{{
              "interface_component_finishing_processes_surface"
                | translationPipe
            }}</label>
          </div>
        </div>
        <div class="row">
          <div class="col-md-5">
            <input
              id="surface"
              class="form-control inline"
              type="number"
              formControlName="surface"
              min="{{ CustomValidators.nonZeroDecimalValidator.min }}"
            />
            <span class="pl"> cm<sup>2</sup> </span>
          </div>
        </div>
        <div class="row pb">
          <div class="col-md-5 pad-right">
            <validation-messages
              [control]="createFinProcessForm.get('surface')"
              class="form-invalid-message"
            >
            </validation-messages>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-cancel" (click)="close()">
          {{ "interface_cancel" | translationPipe }}
        </button>
        <button
          type="submit"
          class="btn btn-primary login-btn"
          [disabled]="isBusy || !createFinProcessForm.valid"
        >
          {{ "interface_add" | translationPipe }}
        </button>
      </div>
    </form>
  </div>
</ng-template>
