import { Country } from './country';
import { ConversionProcess } from './conversion-process';
import { Transform, Type } from 'class-transformer';
import { MaterialType } from '@/app/model/data/material-type';
import { FinishingProcessType } from '@/app/model/data/finishing-process-type';
import { MaterialTypeGroup } from '@/app/model/data/material-type-group';
import { objectToMap } from '@/app/utils/object-utils';
import { PublishedScenarioStatus } from '../packaging-model/published-scenario_status';

export class Bundle {
  @Type(() => Country)
  countries!: Array<Country>;

  @Type(() => ConversionProcess)
  conversionProcesses!: Array<ConversionProcess>;

  @Type(() => MaterialType)
  materialTypes!: Array<MaterialType>;

  @Type(() => FinishingProcessType)
  finishingProcessTypes!: Array<FinishingProcessType>;

  @Type(() => MaterialTypeGroup)
  materialTypeGroups!: Array<MaterialTypeGroup>;

  @Transform((value) => objectToMap(value))
  limits!: Map<string, number>;

  defaultNbContentPerContainer!: number;

  dbVersion!: string;

  publishedScenarioStatusList!: Array<PublishedScenarioStatus>;
}
