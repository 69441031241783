import { Component, Input } from '@angular/core';
import { SingleUseProject } from '@/app/model/packaging-model/project/single-use-project';
import { ComponentLevel } from '@/app/model/packaging-model/component/component-level.enum';
import { ProjectService } from '@/app/services/project.service';
import { GlobalService } from '@/app/services/global.service';
import { GuestService } from '@/app/services/guest.service';
import { Scenario } from '@/app/model/packaging-model/scenario';
import { RechargeProject } from '@/app/model/packaging-model/project/recharge-project';
import { plainToClass } from 'class-transformer';
import { Project } from '@/app/model/packaging-model/project/project';

@Component({
  selector: 'sidebar-assess-two-project',
  templateUrl: './sidebar-assess-two-project.component.html',
  styleUrls: ['./sidebar-assess-two-project.component.css'],
})
export class SidebarAssessTwoProjectComponent {
  // @ts-ignore
  @Input() collapsible: boolean;

  get firstProject() {
    return this.projectService.firstSelectedProject;
  }
  get secondProject() {
    return this.projectService.secondSelectedProject;
  }
  get projects() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.projectService.selectedProjectsForComparison;
  }

  get componentLevels(): Array<ComponentLevel> {
    return this.projectService.componentLevels;
  }

  getStringFromLevel(componentLevel: ComponentLevel): string {
    return ProjectService.levelToString.get(componentLevel) ?? '';
  }

  constructor(
    private projectService: ProjectService,
    public globalService: GlobalService,
    public guestService: GuestService
  ) {}

  closeCollapsibleSidebar() {
    if (this.collapsible) {
      const event = new Event('closeSidebar');
      window.dispatchEvent(event);
    }
  }

  isLevelExisting(
    level: ComponentLevel,
    scenario: Scenario | undefined
  ): boolean {
    if (scenario === undefined) return false;

    let res = false;
    switch (level) {
      case ComponentLevel.PRIMARY:
        res = scenario.firstPrimaryPackaging !== undefined;
        break;
      case ComponentLevel.SECONDARY:
        res = scenario.firstSecondaryPackaging !== undefined;
        break;
      case ComponentLevel.TERTIARY_BOX:
        res = scenario.firstTertiaryPackagingBox !== undefined;
        break;
      case ComponentLevel.TERTIARY_PALLETIZATION:
        res = scenario.firstTertiaryPackagingPalletization !== undefined;
        break;
    }
    return res;
  }

  // @ts-ignore
  isRecharge(project) {
    return project instanceof RechargeProject;
  }

  singleUseProject(project: Project) {
    return plainToClass(SingleUseProject, project);
  }

  getReferenceScenarioFromProject(project: SingleUseProject) {
    return project.scenarios.find((s) => s.id == project.referenceScenarioId);
  }
}
