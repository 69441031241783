import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiUtils } from './api-utils';
import { plainToClass } from 'class-transformer';
import { PrimaryPackaging } from '@/app/model/packaging-model/packaging/primary-packaging';
import { TertiaryPackagingBox } from '@/app/model/packaging-model/packaging/tertiary-packaging-box';
import { SecondaryPackaging } from '@/app/model/packaging-model/packaging/secondary-packaging';
import { TertiaryPackagingPalletization } from '@/app/model/packaging-model/packaging/tertiary-packaging-palletization';
import { PackagingAssociation } from '@/app/model/packaging-model/packaging/packaging-association';

@Injectable({
  providedIn: 'root',
})
export class PackagingApiService {
  constructor(private http: HttpClient) {}

  buildPath(endPoint: string): string {
    return ApiUtils.BASE_PATH + 'packagings/' + endPoint;
  }

  async createSecondaryPackaging(
    scenarioId: number
  ): Promise<SecondaryPackaging> {
    const url = this.buildPath('secondary/' + scenarioId.toString());
    const response = await this.http
      .post<SecondaryPackaging>(url, ApiUtils.HTTP_OPTIONS)
      .toPromise();
    return plainToClass(SecondaryPackaging, response);
  }

  async createTertiaryPackagingBox(
    scenarioId: number
  ): Promise<TertiaryPackagingBox> {
    const url = this.buildPath('tertiary/' + scenarioId.toString());
    const response = await this.http
      .post<TertiaryPackagingBox>(url, ApiUtils.HTTP_OPTIONS)
      .toPromise();
    return plainToClass(TertiaryPackagingBox, response);
  }

  async createTertiaryPalletization(
    scenarioId: number
  ): Promise<TertiaryPackagingPalletization> {
    const url = this.buildPath(
      'tertiary-palletization/' + scenarioId.toString()
    );
    const response = await this.http
      .post<TertiaryPackagingPalletization>(url, ApiUtils.HTTP_OPTIONS)
      .toPromise();
    return plainToClass(TertiaryPackagingPalletization, response);
  }

  async editPrimaryPackaging(
    packagingId: number,
    packaging: PrimaryPackaging
  ): Promise<PrimaryPackaging> {
    const url = this.buildPath('primary/' + packagingId.toString());
    const response = await this.http
      .put<PrimaryPackaging>(url, packaging, ApiUtils.HTTP_OPTIONS)
      .toPromise();
    return plainToClass(PrimaryPackaging, response);
  }

  async editSecondaryPackaging(
    packagingId: number,
    packaging: SecondaryPackaging
  ): Promise<SecondaryPackaging> {
    const url = this.buildPath('secondary/' + packagingId.toString());
    const response = await this.http
      .put<SecondaryPackaging>(url, packaging, ApiUtils.HTTP_OPTIONS)
      .toPromise();
    return plainToClass(SecondaryPackaging, response);
  }

  async editTertiaryPackagingBox(
    packagingId: number,
    packaging: TertiaryPackagingBox
  ): Promise<TertiaryPackagingBox> {
    const url = this.buildPath('tertiary/' + packagingId.toString());
    const response = await this.http
      .put<TertiaryPackagingBox>(url, packaging, ApiUtils.HTTP_OPTIONS)
      .toPromise();
    return plainToClass(TertiaryPackagingBox, response);
  }

  async editTertiaryPackagingPalletization(
    packagingId: number,
    packaging: TertiaryPackagingPalletization
  ): Promise<TertiaryPackagingPalletization> {
    const url = this.buildPath(
      'tertiary-palletization/' + packagingId.toString()
    );
    const response = await this.http
      .put<TertiaryPackagingPalletization>(
        url,
        packaging,
        ApiUtils.HTTP_OPTIONS
      )
      .toPromise();
    return plainToClass(TertiaryPackagingPalletization, response);
  }

  async editPackagingAssociation(
    associationId: number,
    packagingAssociation: PackagingAssociation
  ): Promise<PackagingAssociation> {
    const url = this.buildPath('association/' + associationId.toString());
    const response = await this.http
      .put<PackagingAssociation>(
        url,
        packagingAssociation,
        ApiUtils.HTTP_OPTIONS
      )
      .toPromise();
    return plainToClass(PackagingAssociation, response);
  }

  async deleteSecondaryPackaging(packagingId: number): Promise<void> {
    const url = this.buildPath('secondary/' + packagingId.toString());
    await this.http.delete<void>(url, ApiUtils.HTTP_OPTIONS).toPromise();
  }

  async deleteTertiaryPackagingBox(packagingId: number): Promise<void> {
    const url = this.buildPath('tertiary/' + packagingId.toString());
    await this.http.delete<void>(url, ApiUtils.HTTP_OPTIONS).toPromise();
  }

  async deleteTertiaryPackagingPalletization(
    packagingId: number
  ): Promise<void> {
    const url = this.buildPath(
      'tertiary-palletization/' + packagingId.toString()
    );
    await this.http.delete<void>(url, ApiUtils.HTTP_OPTIONS).toPromise();
  }
}
