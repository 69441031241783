import { Transform } from 'class-transformer';

export class Project {
  id!: number;
  type!: string;

  ownerId!: number;
  ownerName!: string;

  name!: string;
  brand!: string;
  description!: string;
  code!: string;
  collaboratorIds!: number[];

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-call
  @Transform((value) => new Date(value.substring(0, 19))) // Format "YYYY-MM-DDThh:mm::ss" (first 19 elements)
  createdAt!: Date;

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-call
  @Transform((value) => new Date(value.substring(0, 19))) // Format "YYYY-MM-DDThh:mm::ss" (first 19 elements)
  lastOpenedAt!: Date;

  get isSingleUse() {
    return this.type == 'SingleUseProject';
  }

  fillFields(p: Project): void {
    this.id = p.id;
    this.type = p.type;
    this.ownerId = p.ownerId;
    this.ownerName = p.ownerName;
    this.name = p.name;
    this.brand = p.brand;
    this.description = p.description;
    this.code = p.code;
    this.createdAt = new Date(p.createdAt);
    this.lastOpenedAt = new Date(p.lastOpenedAt);
    this.collaboratorIds = p.collaboratorIds;
  }

  mergeGeneralInfo(p: Project | undefined): void {
    if (p != undefined) {
      this.name = p.name;
      this.brand = p.brand;
      this.code = p.code;
      this.description = p.description;
    }
  }

  mergeCollaborators(p: Project | undefined): void {
    if (p != undefined) {
      this.collaboratorIds = p.collaboratorIds;
    }
  }
}
