import { Component, ViewChild, TemplateRef } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-terms-of-use-modal',
  templateUrl: './terms-of-use-modal.component.html',
  styleUrls: ['./terms-of-use-modal.component.css'],
})
export class TermsOfUseModalComponent {
  modal: NgbModalRef | undefined;
  // @ts-ignore
  @ViewChild('termsofusemodal') templateModal: TemplateRef<any>;

  constructor(private modalService: NgbModal) {}

  open() {
    this.modal = this.modalService.open(this.templateModal, {
      windowClass: 'terms-of-use-modal',
    });
  }

  close() {
    this.modal?.close();
  }
}
