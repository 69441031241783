import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  UntypedFormControl,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectService } from 'src/app/services/project.service';
import { TranslationService } from 'src/app/services/translationService/translation.service';
import { PkgComponent } from 'src/app/model/packaging-model/component/pkg-component';
import { Scenario } from 'src/app/model/packaging-model/scenario';
import { GlobalService } from 'src/app/services/global.service';
import { ComponentLevel } from 'src/app/model/packaging-model/component/component-level.enum';
import { CreateMaterialModalComponent } from './create-material-modal/create-material-modal.component';
import { CustomValidators } from '@/app/utils/custom-validators';
// eslint-disable-next-line max-len
import { CreateFinishingProcessModalComponent } from '@/app/pages/projects/packaging-component/create-finishing-process-modal/create-finishing-process-modal.component';
import { FinishingProcessService } from '@/app/services/finishing-process.service';
import { FinishingProcess } from '@/app/model/packaging-model/finishing-process';
import { Material } from '@/app/model/packaging-model/material';
import { MaterialService } from '@/app/services/material.service';
import { FormUtils } from '@/app/utils/form-utils';
import { PkgComponentService } from '@/app/services/pkg-component.service';
import { ConfirmModalComponent } from '../../confirm-modal/confirm-modal.component';
import { GuestService } from '@/app/services/guest.service';
import assert from 'assert';

@Component({
  selector: 'app-packaging-component',
  templateUrl: './packaging-component.component.html',
  styleUrls: ['./packaging-component.component.css'],
})
export class PackagingComponentComponent implements OnInit, OnDestroy {
  @ViewChild(CreateMaterialModalComponent)
  private readonly createMaterialModalComponent!: CreateMaterialModalComponent;

  @ViewChild(CreateFinishingProcessModalComponent)
  private readonly createFinishingProcessModalComponent!: CreateFinishingProcessModalComponent;

  @ViewChild(ConfirmModalComponent)
  private readonly deleteComponentModal!: ConfirmModalComponent;

  private _componentLocal: PkgComponent | undefined;

  protected readonly boundaries = {
    componentName: {
      maxLength: 100,
    },
    nbComponents: {
      min: 1,
    },
    rotations: {
      min: 1,
      max: 999,
    },
  };

  get componentLocal(): PkgComponent {
    assert(this._componentLocal !== undefined);
    return this._componentLocal;
  }

  set componentLocal(componentLocal: PkgComponent) {
    this._componentLocal = componentLocal;
  }

  private _componentOriginal: PkgComponent | undefined;

  get componentOriginal(): PkgComponent {
    assert(this._componentOriginal !== undefined);
    return this._componentOriginal;
  }

  set componentOriginal(componentOriginal: PkgComponent) {
    this._componentOriginal = componentOriginal;
  }

  readonly countries = this._globalService.countries;
  readonly materialTypes = this._globalService.materialTypes;
  readonly conversionProcessesPerMaterialTypeGroup =
    this._globalService.conversionProcessesPerMaterialTypeGroup;
  readonly finishingProcessTypes = this._globalService.finishingProcessTypes;

  private saveTimer: NodeJS.Timer | undefined;

  get isReadOnly(): boolean {
    return this.projectService.isReadOnly;
  }

  private _componentForm: UntypedFormGroup | undefined;

  get componentForm(): UntypedFormGroup {
    assert(this._componentForm !== undefined);
    return this._componentForm;
  }

  set componentForm(componentForm: UntypedFormGroup) {
    this._componentForm = componentForm;
  }

  constructor(
    private readonly route: ActivatedRoute,
    private readonly projectService: ProjectService,
    private readonly pkgComponentService: PkgComponentService,
    private readonly translationService: TranslationService,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly _finishingProcessService: FinishingProcessService,
    private readonly _materialService: MaterialService,
    private readonly _globalService: GlobalService,
    private readonly router: Router,
    private readonly guestService: GuestService
  ) {}

  ngOnInit() {
    this.route.params.subscribe(() => {
      try {
        this.saveComponentIfChanged(); // save previous component if we were on another component page
        this.fillLocalVariables();
        this.initForm();
        this.updateReadOnlyForm();
        this.startSaveTimer();
      } catch (err) {
        console.error(err);
      }
    });
  }

  ngOnDestroy() {
    this.onQuit();
  }

  private onQuit() {
    this.stopSaveTimer();
    this.saveComponentIfChanged();
  }

  private updateReadOnlyForm() {
    if (this.isReadOnly) {
      this.componentForm.disable();
    } else {
      this.componentForm.enable();
    }
  }

  private startSaveTimer() {
    if (this.saveTimer === undefined)
      this.saveTimer = setInterval((_) => this.saveComponentIfChanged(), 5000);
  }

  private stopSaveTimer(): void {
    if (this.saveTimer !== undefined) {
      clearInterval(this.saveTimer);
      this.saveTimer = undefined;
    }
  }

  private fillLocalVariables() {
    this.componentOriginal = this.pkgComponentService.selectedPkgComponent;
    this.componentLocal = PkgComponent.copy(this.componentOriginal);
  }

  private initForm() {
    this.componentForm = this.formBuilder.group({
      componentName: [
        this.componentLocal.name,
        Validators.compose([
          Validators.required,
          Validators.maxLength(this.boundaries.componentName.maxLength),
          CustomValidators.notSpaceValidator(),
        ]),
      ],
      productionCountry: [
        this.componentLocal.productionCountry.label,
        Validators.compose([Validators.required]),
      ],
      nbComponentInPrimary: [
        this.componentLocal.numberOfThisComponentInPackaging,
        Validators.compose([
          Validators.required,
          Validators.min(this.boundaries.nbComponents.min),
        ]),
      ],
      nbRotation: [
        this.componentLocal.nbRotation,
        Validators.compose([
          Validators.required,
          Validators.min(this.boundaries.rotations.min),
          Validators.max(this.boundaries.rotations.max),
        ]),
      ],
    });
    FormUtils.updateModelFromFieldValue<string>(
      this.componentForm.controls['componentName'],
      (v) => (this.componentLocal.name = v)
    );
    FormUtils.updateModelFromFieldValue(
      this.componentForm.controls['productionCountry'] as UntypedFormControl,
      (v) => {
        const productionCountry = this.countries.find((s) => s.label == v);
        assert(productionCountry !== undefined);
        this.componentLocal.productionCountry = productionCountry;
      }
    );

    FormUtils.updateModelFromFieldValue<number>(
      this.componentForm.controls['nbComponentInPrimary'],
      (v) => (this.componentLocal.numberOfThisComponentInPackaging = v)
    );
    FormUtils.updateModelFromFieldValue<number>(
      this.componentForm.controls['nbRotation'],
      (v) => (this.componentLocal.nbRotation = v)
    );
  }

  private saveComponentIfChanged() {
    if (
      this._componentOriginal === undefined ||
      this._componentLocal === undefined
    )
      return;

    if (
      this.isFormValid() &&
      this.componentLocal.editableFieldsHaveChanged(this.componentOriginal)
    ) {
      this.pkgComponentService
        .updatePkgComponent(this.componentLocal, this.componentOriginal)
        .catch((err) => console.error(err));
    }
  }

  private isFormValid(): boolean {
    const componentName = this.componentForm.get('componentName');
    assert(componentName !== null);

    const productionCountry = this.componentForm.get('productionCountry');
    assert(productionCountry !== null);

    const nbComponentInPrimary = this.componentForm.get('nbComponentInPrimary');
    assert(nbComponentInPrimary !== null);

    const nbRotation = this.componentForm.get('nbRotation');
    assert(nbRotation !== null);

    return (
      componentName.valid &&
      productionCountry.valid &&
      nbComponentInPrimary.valid &&
      nbRotation.valid
    );
  }

  private get selectedScenario(): Scenario {
    return this.projectService.selectedScenario;
  }

  private isSelectedComponent(pkgComponent: PkgComponent): boolean {
    return pkgComponent.id === this.componentLocal.id;
  }

  get isPrimary(): boolean {
    return this.componentLocal.componentLevel === ComponentLevel.PRIMARY;
  }

  get isSecondary(): boolean {
    return this.componentLocal.componentLevel === ComponentLevel.SECONDARY;
  }

  get isTertiaryBox(): boolean {
    return (
      this.componentOriginal.componentLevel === ComponentLevel.TERTIARY_BOX
    );
  }

  get isTertiaryPallet(): boolean {
    return (
      this.componentOriginal.componentLevel ===
      ComponentLevel.TERTIARY_PALLETIZATION
    );
  }

  get isTertiary(): boolean {
    return this.isTertiaryPallet || this.isTertiaryBox;
  }

  openModalToCreateMaterial() {
    this.createMaterialModalComponent.open();
  }

  async editProcess(processLocal: FinishingProcess): Promise<FinishingProcess> {
    return await this._finishingProcessService.updateFinishingProcess(
      processLocal,
      this.componentOriginal
    );
  }

  isMaterialLimitReached(): boolean {
    const limit = this.guestService.isUserGuest()
      ? this._globalService.limitsGuestUser.get('materialsPerComponent')
      : this._globalService.limits.get('materialsPerComponent');
    assert(limit !== undefined);
    return this.componentOriginal.materials.length >= limit;
  }

  isFinishingProcessLimitReached(): boolean {
    const limit = this.guestService.isUserGuest()
      ? this._globalService.limitsGuestUser.get(
          'finishingProcessesPerComponent'
        )
      : this._globalService.limits.get('finishingProcessesPerComponent');
    assert(limit !== undefined);
    return this.componentOriginal.finishingProcesses.length >= limit;
  }

  isComponentLimitReached(level: number): boolean {
    const limit = this.guestService.isUserGuest()
      ? this._globalService.limitsGuestUser.get('componentsPerLevel')
      : this._globalService.limits.get('componentsPerLevel');
    assert(limit !== undefined);
    return (
      this.projectService.selectedScenario.componentsOfLevel(level).length >=
      limit
    );
  }

  openModalToCreateFinishingProcess() {
    this.createFinishingProcessModalComponent.open();
  }

  async editMaterial(materialLocal: Material): Promise<Material> {
    return await this._materialService.updateMaterial(
      materialLocal,
      this.componentOriginal
    );
  }

  deleteComponent() {
    this.deleteComponentModal.open();
  }

  private async createComponent(componentLevel: ComponentLevel) {
    await this.pkgComponentService.createPackagingComponent(
      this.projectService.selectedScenario,
      componentLevel
    );
  }

  createComponentAndNavigate(level: ComponentLevel) {
    void this.createComponent(level)
      .then((any) => {
        void this.router.navigate(
          ['../' + this.pkgComponentService.selectedPkgComponentId.toString()],
          { relativeTo: this.route }
        );
      })
      .catch((err) => console.error(err));
  }

  async confirmOrAbortComponentDeletion(answer: boolean): Promise<void> {
    this.deleteComponentModal.close();
    if (answer) {
      await this.pkgComponentService.deletePkgComponent(this.componentOriginal);
      this.pkgComponentService.removeComponentFromSelectedScenario(
        this.componentOriginal
      );
    }
    if (
      this.componentOriginal.id ===
      this.pkgComponentService.selectedPkgComponentId
    )
      this.goToScenario(this.selectedScenario.id);
  }

  private goToScenario(scenarioId: number): void {
    void this.router.navigate(['../../..', scenarioId, 'general-info'], {
      relativeTo: this.route,
    });
  }
}
