import { Component, OnDestroy, OnInit } from '@angular/core';
import { FileService } from '@/app/services/file.service';
import {
  BehaviorSubject,
  combineLatest,
  EMPTY,
  Observable,
  Subject,
} from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';
import { DownloadService } from '@/app/services/download-service';
import { ProjectService } from '@/app/services/project.service';
import { TranslationService } from '@/app/services/translationService/translation.service';
import {
  SortingDirection,
  TypedSorting,
} from '@/app/model/search/typed-sorting';
import { SortService } from '@/app/services/sort.service';

@Component({
  selector: 'app-all-projects-files',
  templateUrl: './all-projects-files.component.html',
  styleUrls: ['./all-projects-files.component.scss'],
})
export class AllProjectsFilesComponent implements OnInit, OnDestroy {
  DATE_FORMAT = 'dd/MM/YYYY, HH:mm:ss';
  files$: Observable<File[]> = EMPTY;
  filesSubject$: Subject<void> = new Subject<void>();
  onDestroy$: Subject<void> = new Subject<void>();

  sorting: TypedSorting<keyof File> = new TypedSorting<keyof File>(
    SortingDirection.DESC,
    'createdDate'
  );
  sortableColumn$: Subject<keyof File> = new BehaviorSubject<keyof File>(
    this.sorting.sortedBy
  );

  constructor(
    private fileService: FileService,
    private projectService: ProjectService,
    protected translationService: TranslationService,
    private sortService: SortService<File>
  ) {}

  ngOnInit(): void {
    this.filesSubject$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => (this.files$ = this.combineLatestToFileArray()));

    this.filesSubject$.next();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private combineLatestToFileArray(): Observable<File[]> {
    return combineLatest(
      this.fileService.getS3FileRecords(),
      this.sortableColumn$
    ).pipe(
      map(([files]) => {
        return files
          .map((f) => {
            return {
              ...f,
              createdBy: `${f.memberUser.firstname} ${f.memberUser.lastname}`,
              status: this.toFileStatus(f.status),
              createdDate: f.createdAt,
              uploadedDate: f.uploadedAt,
            } as File;
          })
          .sort(this.sortService.getComparator(this.sorting));
      })
    );
  }

  setSortColumn(column: keyof File): void {
    this.sortableColumn$.next(column);
  }

  downloadFile(fileName: string): void {
    this.fileService
      .downloadFileByName(fileName)
      .pipe(take(1))
      .subscribe({
        next: (blob: string) => {
          const data = new Blob([blob]);
          DownloadService.download(fileName, data);
        },
        error: (err) => {
          console.log(err);
        },
      });
  }

  generateAllProjectExport(): void {
    this.projectService
      .generateAllProjectExport()
      .finally(() => this.filesSubject$.next());
  }

  private toFileStatus(
    s: 'CREATED' | 'SUCCEEDED' | 'FAILED' | 'DELETED'
  ): 'In progress' | 'Ready' | 'Failed' {
    switch (s) {
      case 'CREATED':
        return 'In progress';
      case 'SUCCEEDED':
        return 'Ready';
      default:
        return 'Failed';
    }
  }
}

type File = {
  fileName: string;
  createdBy: string;
  status: 'In progress' | 'Ready' | 'Failed';
  fileSize: number;
  createdDate: Date;
  uploadedDate: Date;
};
