import { Injectable } from '@angular/core';

import { plainToClass } from 'class-transformer';
import { LoginService } from '@/app/services/login/login.service';
import { TranslationService } from '@/app/services/translationService/translation.service';
import { SingleUseProject } from '@/app/model/packaging-model/project/single-use-project';
import { Scenario } from '@/app/model/packaging-model/scenario';
import { PkgComponent } from '@/app/model/packaging-model/component/pkg-component';
import { ComponentLevel } from '@/app/model/packaging-model/component/component-level.enum';
import { FinishingProcess } from '@/app/model/packaging-model/finishing-process';
import { Material } from '@/app/model/packaging-model/material';
import { PrimaryPackaging } from '@/app/model/packaging-model/packaging/primary-packaging';
import { SecondaryPackaging } from '@/app/model/packaging-model/packaging/secondary-packaging';
import { TertiaryPackagingBox } from '@/app/model/packaging-model/packaging/tertiary-packaging-box';
import { TertiaryPackagingPalletization } from '@/app/model/packaging-model/packaging/tertiary-packaging-palletization';
import { PackagingAssociation } from '@/app/model/packaging-model/packaging/packaging-association';
import { GlobalService } from '@/app/services/global.service';
import { Packaging } from '../model/packaging-model/packaging/packaging';

@Injectable({
  providedIn: 'root',
})
export class GuestService {
  get nbContent(): number {
    return this.globalService.defaultNbContentPerContainer;
  }
  static key = 'currentSingleUseProject';

  get userWasGuest() {
    return this.loginService.userWasGuest;
  }

  guestUserProject: SingleUseProject | undefined;

  constructor(
    private translationService: TranslationService,
    private loginService: LoginService,
    private globalService: GlobalService
  ) {
    if (this.isProjectStored()) this.guestUserProject = this.getStoredProject();
  }

  isUserGuest(): boolean {
    return this.loginService.isGuestUser();
  }

  getNewId(): number {
    return new Date().getTime();
  }

  //------------------------ MANAGING SESSIONSTORAGE ------------------------

  storeProjectIfGuest(project: SingleUseProject) {
    if (this.isUserGuest()) this.storeProject(project);
  }

  storeProject(project: SingleUseProject) {
    sessionStorage.setItem(GuestService.key, JSON.stringify(project));
  }

  deleteStoredProject() {
    sessionStorage.removeItem(GuestService.key);
    this.guestUserProject = undefined;
  }

  isProjectStored() {
    return (
      sessionStorage.getItem(GuestService.key) !== null &&
      sessionStorage.getItem(GuestService.key) !== 'undefined'
    );
  }

  private getStoredProject(): SingleUseProject {
    const storedPlain: SingleUseProject = JSON.parse(
      // @ts-ignore
      sessionStorage.getItem(GuestService.key)
    ) as SingleUseProject;
    return plainToClass(SingleUseProject, storedPlain);
  }

  //------------------------ MANAGING PROJECTS / SCENARIOS ------------------------

  createSingleUseProject(project: SingleUseProject) {
    project.id = 0;
    project.scenarios = [];
    project.collaboratorIds = [];
    const baselineScenario: Scenario = this.createBaselineScenario(project);
    project.scenarios.push(baselineScenario);
    project.referenceScenarioId = baselineScenario.id;
    this.guestUserProject = project;
    return project;
  }

  createBaselineScenario(project: SingleUseProject): Scenario {
    const scenario: Scenario = new Scenario();
    scenario.id = 0;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    scenario.name = this.translationService.getTranslationValueForLabel(
      'interface_default_scenario_name'
    );
    scenario.primaryPackagings = new Array<PrimaryPackaging>();
    // @ts-ignore
    scenario.productionCountry = this.globalService.countries.find(
      (c) => c.label == 'cFRA'
    );
    // @ts-ignore
    scenario.salesCountry = this.globalService.countries.find(
      (c) => c.label == 'cFRA'
    );
    this.buildPrimaryPackaging(scenario);
    scenario.secondaryPackagings = new Array<SecondaryPackaging>();
    scenario.tertiaryPackagingBoxes = new Array<TertiaryPackagingBox>();
    scenario.tertiaryPackagingPalletizations =
      new Array<TertiaryPackagingPalletization>();
    return scenario;
  }

  //------------------------ MANAGING PACKAGINGS ------------------------

  buildPrimaryPackaging(scenario: Scenario): PrimaryPackaging {
    const primaryPackaging: PrimaryPackaging = new PrimaryPackaging();
    primaryPackaging.id = this.getNewId();
    primaryPackaging.isRecyclable = false;
    primaryPackaging.claimedVolume = 50.0;
    primaryPackaging.rateOfRestitution = 0.95;
    primaryPackaging.type = ComponentLevel.PRIMARY;
    primaryPackaging.components = new Array<PkgComponent>();
    primaryPackaging.contentPackagingAssociations =
      new Array<PackagingAssociation>();
    scenario.addPrimaryPackaging(primaryPackaging);
    return primaryPackaging;
  }

  public createSecondaryPackaging(scenario: Scenario): SecondaryPackaging {
    const secondaryPackaging: SecondaryPackaging = new SecondaryPackaging();
    secondaryPackaging.id = this.getNewId();
    secondaryPackaging.isRecyclable = false;
    secondaryPackaging.type = ComponentLevel.SECONDARY;
    secondaryPackaging.components = new Array<PkgComponent>();
    secondaryPackaging.containerPackagingAssociations =
      new Array<PackagingAssociation>();
    secondaryPackaging.contentPackagingAssociations =
      new Array<PackagingAssociation>();
    scenario.setSecondaryPackaging(secondaryPackaging);

    if (scenario.tertiaryPackagingBoxes.length > 0) {
      scenario.tertiaryPackagingBoxes.forEach((box) => {
        box.containerPackagingAssociations.forEach((assoc) => {
          assoc.contentPackId = secondaryPackaging.id;
          assoc.contentLevel = secondaryPackaging.type;
          secondaryPackaging.contentPackagingAssociations.push(assoc);
        });
      });
    }

    scenario.primaryPackagings.forEach((prim) => {
      prim.contentPackagingAssociations = new Array<PackagingAssociation>();
      const assoc: PackagingAssociation = this.createPackagingAssociation(
        secondaryPackaging,
        prim,
        this.nbContent
      );
      secondaryPackaging.containerPackagingAssociations.push(assoc);
      prim.contentPackagingAssociations.push(assoc);
    });
    if (this.guestUserProject) this.storeProject(this.guestUserProject);
    return secondaryPackaging;
  }

  public deleteSecondaryPackaging(scenario: Scenario) {
    if (scenario.tertiaryPackagingBoxes.length > 0) {
      scenario.primaryPackagings.forEach((prim) => {
        prim.contentPackagingAssociations = new Array<PackagingAssociation>();
        scenario.tertiaryPackagingBoxes.forEach((box) => {
          box.containerPackagingAssociations.forEach((assoc) => {
            assoc.contentPackId = prim.id;
            assoc.contentLevel = prim.type;
            prim.contentPackagingAssociations.push(assoc);
          });
        });
      });
    } else {
      scenario.primaryPackagings.forEach((pri) => {
        pri.contentPackagingAssociations =
          pri.contentPackagingAssociations.filter(
            (assoc) => assoc.containerLevel !== ComponentLevel.SECONDARY
          );
      });
    }
    if (this.guestUserProject) this.storeProject(this.guestUserProject);
  }

  public createTertiaryPackagingBox(scenario: Scenario): TertiaryPackagingBox {
    const tertiaryPackagingBox: TertiaryPackagingBox =
      new TertiaryPackagingBox();
    tertiaryPackagingBox.id = this.getNewId();
    tertiaryPackagingBox.type = ComponentLevel.TERTIARY_BOX;
    tertiaryPackagingBox.components = new Array<PkgComponent>();
    tertiaryPackagingBox.contentPackagingAssociations =
      new Array<PackagingAssociation>();
    tertiaryPackagingBox.containerPackagingAssociations =
      new Array<PackagingAssociation>();
    scenario.setTertiaryPackagingBox(tertiaryPackagingBox);

    if (scenario.hasSecondaryPackaging) {
      scenario.secondaryPackagings.forEach((sec) => {
        const assoc: PackagingAssociation = this.createPackagingAssociation(
          tertiaryPackagingBox,
          sec,
          this.nbContent
        );
        tertiaryPackagingBox.containerPackagingAssociations.push(assoc);
        sec.contentPackagingAssociations.push(assoc);
      });
    } else {
      scenario.primaryPackagings.forEach((prim) => {
        const assoc: PackagingAssociation = this.createPackagingAssociation(
          tertiaryPackagingBox,
          prim,
          this.nbContent
        );
        tertiaryPackagingBox.containerPackagingAssociations.push(assoc);
        prim.contentPackagingAssociations.push(assoc);
      });
    }
    return tertiaryPackagingBox;
  }

  public deleteTertiaryPackagingBox(scenario: Scenario) {
    if (scenario.hasSecondaryPackaging) {
      scenario.secondaryPackagings.forEach((sec) => {
        sec.contentPackagingAssociations =
          sec.contentPackagingAssociations.filter(
            (assoc) => assoc.containerLevel !== ComponentLevel.TERTIARY_BOX
          );
      });
    } else {
      scenario.primaryPackagings.forEach((pri) => {
        pri.contentPackagingAssociations =
          pri.contentPackagingAssociations.filter(
            (assoc) => assoc.containerLevel !== ComponentLevel.TERTIARY_BOX
          );
      });
    }
  }

  public createTertiaryPackagingPalletization(
    scenario: Scenario
  ): TertiaryPackagingPalletization {
    const tertiaryPackagingPalletization: TertiaryPackagingPalletization =
      new TertiaryPackagingPalletization();
    tertiaryPackagingPalletization.id = this.getNewId();
    tertiaryPackagingPalletization.type = ComponentLevel.TERTIARY_PALLETIZATION;
    tertiaryPackagingPalletization.components = new Array<PkgComponent>();
    tertiaryPackagingPalletization.containerPackagingAssociations =
      new Array<PackagingAssociation>();
    scenario.setTertiaryPackagingPalletization(tertiaryPackagingPalletization);

    scenario.tertiaryPackagingBoxes.forEach((ter) => {
      const assoc: PackagingAssociation = this.createPackagingAssociation(
        tertiaryPackagingPalletization,
        ter,
        this.nbContent
      );
      tertiaryPackagingPalletization.containerPackagingAssociations.push(assoc);
      ter.contentPackagingAssociations.push(assoc);
    });
    return tertiaryPackagingPalletization;
  }

  public deleteTertiaryPackagingPalletization(scenario: Scenario) {
    scenario.tertiaryPackagingBoxes.forEach((ter) => {
      ter.contentPackagingAssociations =
        ter.contentPackagingAssociations.filter(
          (assoc) =>
            assoc.containerLevel !== ComponentLevel.TERTIARY_PALLETIZATION
        );
    });
  }

  createPackagingAssociation(
    container: Packaging,
    content: Packaging,
    nbContentIntoContainer: number
  ): PackagingAssociation {
    const packagingAssociation: PackagingAssociation =
      new PackagingAssociation();
    packagingAssociation.containerPackId = container.id;
    packagingAssociation.containerLevel = container.type;
    packagingAssociation.contentPackId = content.id;
    packagingAssociation.contentLevel = content.type;
    packagingAssociation.numberOfContentPerContainer = nbContentIntoContainer;
    return packagingAssociation;
  }

  //------------------------ MANAGING COMPONENTS ------------------------

  createPackagingComponent(
    pkgcomponent: PkgComponent,
    scenario: Scenario,
    level: ComponentLevel
  ): PkgComponent {
    this.ifTooManyComponentsThenThrowError(level, scenario);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    pkgcomponent.name = this.translationService.getTranslationValueForLabel(
      'interface_default_component_name'
    );
    pkgcomponent.id = this.getNewId();
    pkgcomponent.componentLevel = level;
    pkgcomponent.finishingProcesses = new Array<FinishingProcess>();
    pkgcomponent.materials = new Array<Material>();
    pkgcomponent.nbRotation = 1;
    return pkgcomponent;
  }

  // security if the add button is re-enabled by the user
  ifTooManyComponentsThenThrowError(level: ComponentLevel, scenario: Scenario) {
    // @ts-ignore
    const limit: number =
      this.globalService.limitsGuestUser.get('componentsPerLevel');
    if (scenario.componentsOfLevel(level).length >= limit) {
      throw new Error(
        `There must not be more than ${limit} elements per level (level: ${level}).`
      );
    }
  }
}
