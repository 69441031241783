<ng-template #companycreationmodal let-modal>
  <div class="modal-content">
    <div class="modal-header modal-spacing">
      <div class="modal-title" *ngIf="this.addCompany">
        {{ "interface_new_company" | translationPipe }}
      </div>
      <div class="modal-title" *ngIf="!this.addCompany">
        {{ "interface_edit_company" | translationPipe }}
      </div>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        aria-label="Close"
        (click)="close()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <form
      [formGroup]="checkoutForm"
      id="loginForm"
      (ngSubmit)="onSubmit(checkoutForm.value)"
    >
      <div class="modal-body break-word modal-spacing">
        <div class="form-group">
          <label for="name">
            {{ "interface_company_name" | translationPipe }}</label
          >
          <input
            id="name"
            class="form-control"
            name="login"
            type="text"
            formControlName="name"
          />
          <validation-messages
            [control]="checkoutForm.get('name')"
            class="form-invalid-message"
          >
          </validation-messages>
        </div>
        <div class="form-group" *ngIf="!this.addCompany">
          <label for="enabled">{{
            "interface_status" | translationPipe
          }}</label>
          <select class="custom-select form-control" formControlName="enabled">
            <option [value]="true">
              {{ "interface_enabled" | translationPipe }}
            </option>
            <option [value]="false">
              {{ "interface_disabled" | translationPipe }}
            </option>
          </select>
          <validation-messages
            [control]="checkoutForm.get('enabled')"
            class="form-invalid-message"
          >
          </validation-messages>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-cancel" (click)="close()">
          {{ "interface_cancel" | translationPipe }}
        </button>
        <button
          type="submit"
          class="btn btn-primary login-btn"
          [disabled]="isBusy || !checkoutForm.valid"
        >
          <span *ngIf="this.addCompany">
            {{ "interface_create" | translationPipe }}
          </span>
          <span *ngIf="!this.addCompany">
            {{ "interface_save" | translationPipe }}
          </span>
        </button>
      </div>
    </form>
  </div>
</ng-template>
