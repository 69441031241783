import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiUtils } from './api-utils';
import { Populate } from '@/app/model/populate/populate';

@Injectable({
  providedIn: 'root',
})
export class PopulateApiService {
  constructor(private http: HttpClient) {}

  buildPath(): string {
    return ApiUtils.BASE_PATH + 'populate';
  }

  async populate(populate: Populate): Promise<void> {
    const url = this.buildPath();
    await this.http
      .post<void>(url, populate, ApiUtils.HTTP_OPTIONS)
      .toPromise();
  }
}
