/**
 * Get the response body of the given XHR.
 *
 * If the response body is in JSON, it is returned as a JSON object (not string).
 * If the response body is in any other type, is is returned as a string.
 *
 * @param xhr an arbitrary XMLHttpRequest
 * @return the XHR response body
 */
export function getXhrResponse(xhr: XMLHttpRequest): any {
  try {
    if (xhr.responseType === 'json')
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return xhr.response;
    else if (xhr.responseType === 'text') {
      const contentType = xhr.getResponseHeader('Content-Type');
      const isJsonContentType = contentType?.search(/json/i) !== -1;
      if (isJsonContentType)
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return JSON.parse(xhr.responseText); // parse the JSON string, which removes newlines and indentation
    }
  } catch (err: any) {
    // ignore error and fallback to return xhr.responseText
  }
  return xhr.responseText;
}
