<ul
  *ngIf="pager != null && pager.totalItems > 0"
  class="pagination justify-content-center"
>
  <li class="p-1" [class.disabled]="pager.currentPage == 1">
    <a (click)="setPage(1)">
      <span aria-hidden="true">&lt;&lt;</span>
    </a>
  </li>
  <li class="p-1" [class.disabled]="pager.currentPage == 1">
    <a (click)="setPage(pager.currentPage - 1)">
      <span aria-hidden="true">&lt;</span>
    </a>
  </li>
  <li
    class="p-1"
    *ngFor="let page of pager.pages"
    [class.active]="pager.currentPage == page"
  >
    <a (click)="setPage(page)">{{ page }}</a>
  </li>
  <li class="p-1" [class.disabled]="pager.currentPage == pager.totalPages">
    <a (click)="setPage(pager.currentPage + 1)">
      <span aria-hidden="true">&gt;</span>
    </a>
  </li>
  <li class="p-1" [class.disabled]="pager.currentPage == pager.totalPages">
    <a (click)="setPage(pager.totalPages)">
      <span aria-hidden="true">&gt;&gt;</span>
    </a>
  </li>
</ul>
