<div class="sidebar-content">
  <sidebar-user-mgmt [isCollapsible]="collapsible"></sidebar-user-mgmt>

  <div
    class="scenarios-management"
    *ngIf="globalService.listsReady && projectIsSingleUse"
  >
    <div class="scenario-title-container">
      <div class="scenario-title">
        {{ "interface_scenarios" | translationPipe }}
        <div ngbDropdown class="d-inline-block dropdown-icon-only">
          <button class="btn" ngbDropdownToggle>
            <span class="fas fa-ellipsis-v"></span>
          </button>
          <div ngbDropdownMenu aria-labelledby="More actions">
            <span
              matTooltip="{{
                'interface_max_scenarios_reached' | translationPipe
              }} "
              matTooltipPosition="above"
              [matTooltipDisabled]="!isScenarioLimitReached()"
              matTooltipClass="custom-tooltip"
            >
              <button
                *ngIf="!guestService.isUserGuest() && !isReadOnly"
                ngbDropdownItem
                (click)="createScenario()"
                [disabled]="isScenarioLimitReached()"
              >
                <span class="fas fa-plus-circle"></span>
                {{ "interface_add" | translationPipe }}
              </button>
            </span>
            <span
              matTooltip="{{
                'interface_pro_version_tooltip' | translationPipe
              }} "
              matTooltipPosition="above"
              [matTooltipDisabled]="!guestService.isUserGuest()"
              matTooltipClass="custom-tooltip"
            >
              <button
                ngbDropdownItem
                *ngIf="!isReadOnly && userIsOwner()"
                class="export-scenario"
                (click)="exportScenario()"
                [disabled]="guestService.isUserGuest()"
              >
                <span class="fas fa-file-export"></span>
                {{ "interface_publish" | translationPipe }}
              </button>
            </span>
            <span
              matTooltip="{{
                'interface_max_scenarios_reached' | translationPipe
              }} "
              matTooltipPosition="above"
              [matTooltipDisabled]="!isScenarioLimitReached()"
              matTooltipClass="custom-tooltip"
            >
              <button
                ngbDropdownItem
                *ngIf="!guestService.isUserGuest() && !isReadOnly"
                class="export-scenario"
                (click)="openLibraryModal()"
                [disabled]="
                  isScenarioLimitReached() || guestService.isUserGuest()
                "
              >
                <span class="fas fa-file-import"></span>
                {{ "interface_copy_publish_scenario" | translationPipe }}
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="scrollableMenu">
      <div
        class="scenario-container"
        *ngFor="let scenario of this.project.scenarios"
      >
        <ul class="nav flex-column">
          <li
            class="menu-li-scenario"
            [class.scenario-selected]="
              selectedScenario?.id === scenario.id && isGeneralInfo
            "
          >
            <div class="scenario-name-container">
              <div class="scenario-name">
                <a
                  class="scenario-header-name"
                  (click)="goToScenario(scenario.id)"
                  >{{ scenario.name }}</a
                >
                <em
                  *ngIf="
                    scenario.id === this.project.referenceScenarioId &&
                    !guestService.isUserGuest()
                  "
                  class="fas fa-star isReference"
                  title="{{ 'interface_is_reference' | translationPipe }}"
                ></em>
                <em
                  *ngIf="
                    scenario.id !== this.project.referenceScenarioId &&
                    !guestService.isUserGuest() &&
                    !isReadOnly
                  "
                  class="fas fa-star"
                  title="{{ 'interface_reference' | translationPipe }}"
                  (click)="setToReference(scenario)"
                ></em>
                <span
                  *ngIf="!guestService.isUserGuest() && !isReadOnly"
                  matTooltip="{{
                    'interface_max_scenarios_reached' | translationPipe
                  }} "
                  matTooltipPosition="above"
                  [matTooltipDisabled]="!isScenarioLimitReached()"
                  matTooltipClass="custom-tooltip"
                >
                  <button
                    class="button"
                    [disabled]="isScenarioLimitReached()"
                    (click)="duplicateScenario(scenario)"
                  >
                    <em
                      class="fas fa-copy"
                      title="{{ 'interface_duplicate' | translationPipe }}"
                    ></em>
                  </button>
                </span>
                <em
                  class="fas fa-trash"
                  (click)="deleteScenario(scenario)"
                  *ngIf="
                    scenario.id != this.project.referenceScenarioId &&
                    !isReadOnly
                  "
                  title="{{ 'interface_delete' | translationPipe }}"
                ></em>
              </div>
            </div>
          </li>
          <li class="menu-li">
            <ul
              class="sub-list-scenario-definition"
              *ngIf="selectedScenario?.id === scenario.id"
            >
              <li
                class="menu-li packaging-layer-{{ level }}"
                *ngFor="let level of componentLevels"
              >
                <div class="label-with-plus" *ngIf="isLevelExisting(level)">
                  <p class="layer-title">
                    {{ getStringFromLevel(level) | translationPipe }}
                  </p>
                  <span
                    matTooltip="{{
                      'interface_max_components_reached' | translationPipe
                    }}"
                    *ngIf="!isReadOnly"
                    matTooltipPosition="above"
                    [matTooltipDisabled]="
                      !isComponentLimitReached(scenario, level)
                    "
                    matTooltipClass="custom-tooltip"
                  >
                    <button
                      class="button"
                      [class.not-allowed]="
                        isComponentLimitReached(scenario, level) ||
                        !isLevelExisting(level)
                      "
                      [disabled]="
                        isComponentLimitReached(scenario, level) ||
                        !isLevelExisting(level)
                      "
                      (click)="createComponentAndNavigate(scenario, level)"
                    >
                      <em
                        class="fas fa-plus-circle"
                        title="{{ 'interface_add' | translationPipe }}"
                      ></em>
                    </button>
                  </span>
                </div>
                <div
                  class="component-name-container"
                  *ngFor="
                    let component of selectedScenario.componentsOfLevel(level)
                  "
                >
                  <div
                    class="component-name-sidebar"
                    [class.componentselected]="
                      this.selectedComponent == component && !isGeneralInfo
                    "
                  >
                    <a (click)="selectComponent(component.id)">
                      {{ component.name }}
                    </a>
                  </div>
                  <div class="component-actions">
                    <em
                      class="fas fa-trash space-left"
                      title="{{ 'interface_delete' | translationPipe }}"
                      *ngIf="!isReadOnly"
                      (click)="deleteComponent(component)"
                    ></em>
                  </div>
                </div>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <sidebar-footer></sidebar-footer>
</div>

<app-confirm-modal
  [id]="'delete-scenario'"
  [title]="'interface_scenario_deletion_title' | translationPipe"
  [content]="
    ('interface_scenario_deletion_body' | translationPipe) +
    this.scenarioToBeDeleted?.name +
    ' ?'
  "
  (answer)="confirmOrAbortScenarioDeletion($event)"
>
</app-confirm-modal>

<app-confirm-modal
  [id]="'delete-component'"
  [title]="'interface_component_deletion_title' | translationPipe"
  [content]="
    ('interface_component_deletion_body' | translationPipe) +
    this.componentToBeDeleted?.name +
    ' ?'
  "
  (answer)="confirmOrAbortComponentDeletion($event)"
>
</app-confirm-modal>

<app-confirm-modal
  [id]="'side-reference-scenario'"
  [title]="'interface_scenario_reference_title' | translationPipe"
  [content]="
    ('interface_scenario_reference_body' | translationPipe) +
    this.scenarioToBeReferenced?.name +
    ' ?'
  "
  (answer)="confirmOrAbortReferencingScenario($event)"
>
</app-confirm-modal>

<app-export-scenarios-modal
  [project]="this.projectService.currentSingleUseProject"
>
</app-export-scenarios-modal>

<app-scenario-library [project]="this.projectService.currentSingleUseProject">
</app-scenario-library>
