/**
 * Returns all the keys of a an enum.
 *
 * @example
 * ```
 * enum E
 * {
 *     A,
 *     B
 * }
 *
 * getEnumKeys(E); // returns ["A", "B"]
 * ```
 *
 * @param _enum_ the enum
 * @return all the keys of _enum_
 */
export function getEnumKeys<T extends object>(_enum_: T): string[] {
  return Object.keys(_enum_);
}

/**
 * Returns all the values of a numeric enum.
 *
 * @example
 * ```
 * enum E
 * {
 *     A,
 *     B
 * }
 *
 * getNumericEnumValues(E); // returns [0, 1]
 * ```
 *
 * @example
 * ```
 * enum E
 * {
 *     A = 1,
 *     B = 2
 * }
 *
 * getNumericEnumValues(E); // returns [1, 2]
 * ```
 * @param _enum_ the enum
 * @return all the values of _enum_
 */
export function getNumericEnumValues<T extends object>(_enum_: T): number[] {
  return Object.values(_enum_)
    .filter((value) => typeof value === 'number')
    .map((value) => value as number);
}

/**
 * Returns all the values of a string enum.
 *
 * @example
 * ```
 * enum E
 * {
 *     A = "VALUE_A",
 *     B = "VALUE_B"
 * }
 *
 * getStringEnumValues(E); // returns ["VALUE_A", "VALUE_B"]
 * ```
 * @param _enum_ the enum
 * @return all the values of _enum_
 */
export function getStringEnumValues<T extends object>(_enum_: T): string[] {
  return Object.values(_enum_)
    .filter((value) => typeof value === 'string')
    .map((value) => value as string);
}
