export enum CategoryResult {
  PRIMARY_PACK = 'PRIMARY_PACK',
  SECONDARY_PACK = 'SECONDARY_PACK',
  TERTIARY_PACK_OUT = 'TERTIARY_PACK_OUT',
  TERTIARY_PACK_IN = 'TERTIARY_PACK_IN',

  // MANUFACTURING = "MANUFACTURING",

  PRODUCTION = 'PRODUCTION',
  TRANSPORT_RMPROD_SUPPLIER = 'TRANSPORT_RMPROD_SUPPLIER',
  CONVERTING = 'CONVERTING',
  FINISHING = 'FINISHING',
  TRANSPORT_SUPPLIER_MANUFACTURING = 'TRANSPORT_SUPPLIER_MANUFACTURING',
  DISTRIBUTION = 'DISTRIBUTION',
  END_OF_LIFE = 'END_OF_LIFE',

  TOTAL = 'TOTAL',
}
