import { Result } from './result';
import { Type } from 'class-transformer';

export class DetailedResult {
  @Type(() => Result)
  totalSingleScore!: Result;
  @Type(() => Result)
  perPackagingSingleScores!: Array<Result>;
  @Type(() => Result)
  perPackagingAndIndicatorImpacts!: Array<Result>;
  @Type(() => Result)
  perLCAStepSingleScores!: Array<Result>;
  @Type(() => Result)
  perLCAStepAndIndicatorImpacts!: Array<Result>;
  @Type(() => Result)
  perIndicatorScores!: Array<Result>;
  @Type(() => Result)
  perIndicatorImpacts!: Array<Result>;
  @Type(() => Result)
  perRechargeTypeSingleScores!: Array<Result>;
  @Type(() => Result)
  perRechargeTypeImpacts!: Array<Result>;
  @Type(() => Result)
  perComponentAndIndicatorImpacts!: Array<Result>;
  @Type(() => Result)
  perComponentScores!: Array<Result>;
}
