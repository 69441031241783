<ng-container *ngIf="this.translationService.translationsLoaded | async">
  <app-container-sidebar>
    <div class="col-md-10 landing-page-container">
      <h3 id="landing-get-started" class="box-title">
        {{ "interface_landing_get_started" | translationPipe }}
      </h3>
      <div class="main-actions-container">
        <span
          matTooltip="{{
            'interface_projects_limit_reached' | translationPipe
          }}"
          matTooltipPosition="above"
          [matTooltipDisabled]="
            !guestService.isUserGuest() || guestUserProject === undefined
          "
          matTooltipClass="custom-tooltip"
        >
          <button
            id="landing-start-new-single-use-project"
            class="btn btn-secondary landing-main-action-button"
            [disabled]="
              guestService.isUserGuest() && guestUserProject !== undefined
            "
            (click)="openModalToCreateProject(true)"
          >
            <em class="fas fa-star"></em>
            {{ "interface_landing_new_single_use" | translationPipe }}
          </button>
        </span>
        <span
          matTooltip="{{ 'interface_pro_version_tooltip' | translationPipe }} "
          matTooltipPosition="above"
          [matTooltipDisabled]="!guestService.isUserGuest()"
          matTooltipClass="custom-tooltip"
        >
          <button
            id="landing-start-new-recharge-refill-project"
            class="btn btn-secondary landing-main-action-button"
            [disabled]="guestService.isUserGuest()"
            (click)="openModalToCreateProject(false)"
          >
            <em class="fas fa-recycle"></em>
            {{ "interface_landing_new_recharge_refill" | translationPipe }}
          </button>
        </span>
        <span
          matTooltip="{{ 'interface_pro_version_tooltip' | translationPipe }} "
          matTooltipPosition="above"
          [matTooltipDisabled]="!guestService.isUserGuest()"
          matTooltipClass="custom-tooltip"
        >
          <button
            id="landing-open-existing-project"
            class="btn btn-secondary landing-main-action-button"
            [disabled]="guestService.isUserGuest()"
            (click)="openModalToOpenExistingProject()"
          >
            <em class="fas fa-folder-open"></em>
            {{ "interface_landing_open_existing" | translationPipe }}
          </button>
        </span>
        <span
          matTooltip="{{ 'interface_pro_version_tooltip' | translationPipe }} "
          matTooltipPosition="above"
          [matTooltipDisabled]="!guestService.isUserGuest()"
          matTooltipClass="custom-tooltip"
        >
          <button
            id="landing-compare-two-projects"
            class="btn btn-secondary landing-main-action-button"
            [disabled]="guestService.isUserGuest()"
            (click)="openProjectsSelectionPage()"
          >
            <em class="fas fa-balance-scale"></em>
            {{ "interface_landing_compare_two_projects" | translationPipe }}
          </button>
        </span>
      </div>
    </div>
    <div
      *ngIf="this.guestService.isUserGuest()"
      class="col-md-10 landing-page-container-not-first"
    >
      <h3 class="box-title" id="latest">
        {{ "interface_spice_pro_title" | translationPipe }}
      </h3>
      <div class="main-actions-container spice-pro-promo">
        <p>{{ "interface_landing_gu_1" | translationPipe }}</p>
        <p>{{ "interface_landing_gu_2" | translationPipe }}</p>
        <ul>
          <li>{{ "interface_landing_gu_li_1" | translationPipe }}</li>
          <li>{{ "interface_landing_gu_li_2" | translationPipe }}</li>
          <li>{{ "interface_landing_gu_li_3" | translationPipe }}</li>
          <li>{{ "interface_landing_gu_li_4" | translationPipe }}</li>
          <li>{{ "interface_landing_gu_li_5" | translationPipe }}</li>
          <li>{{ "interface_landing_gu_li_6" | translationPipe }}</li>
        </ul>
        <p>
          {{ "interface_landing_gu_3" | translationPipe
          }}<a href="http://open-spice.com/contact/" target="_blank">{{
            "interface_landing_gu_4" | translationPipe
          }}</a>
        </p>
      </div>
    </div>
    <div
      class="col-md-10 landing-page-container-not-first"
      *ngIf="!guestService.isUserGuest()"
    >
      <h3 class="box-title" id="latest">
        {{ "interface_landing_latest_projects" | translationPipe }}
      </h3>
      <div class="main-actions-container">
        <div *ngIf="latestProjects.length == 0" class="no-projects">
          {{ "interface_no_projects_to_display" | translationPipe }}
        </div>
        <div class="table-responsive latest-projects">
          <table
            *ngIf="latestProjects.length > 0"
            class="table"
            aria-describedby="latest"
          >
            <thead>
              <tr>
                <th scope="col">{{ "interface_name" | translationPipe }}</th>
                <th scope="col">{{ "interface_type" | translationPipe }}</th>
                <th *ngIf="isAdmin" scope="col">
                  {{ "interface_owner" | translationPipe }}
                </th>
                <th scope="col">{{ "interface_created" | translationPipe }}</th>
                <th scope="col">
                  {{ "interface_last_opened" | translationPipe }}
                </th>
                <th scope="col"></th>
              </tr>
            </thead>

            <tbody>
              <tr *ngFor="let projectAudit of latestProjects" class="clickable">
                <td (click)="openProject(projectAudit.project)">
                  <div>
                    <span
                      matTooltip="{{ projectAudit.project.name }} "
                      matTooltipPosition="above"
                      matTooltipClass="custom-tooltip"
                    >
                      {{ projectAudit.project.name }}
                    </span>
                  </div>
                </td>
                <td (click)="openProject(projectAudit.project)">
                  <div>
                    {{
                      projectAudit.project.isSingleUse
                        ? ("interface_single_use" | translationPipe)
                        : ("interface_recharge" | translationPipe)
                    }}
                  </div>
                </td>
                <td
                  *ngIf="
                    isAdmin && projectAudit.project.ownerId == currentUser?.id
                  "
                  (click)="openProject(projectAudit.project)"
                >
                  {{ "interface_me" | translationPipe }}
                </td>
                <td
                  *ngIf="
                    isAdmin && projectAudit.project.ownerId != currentUser?.id
                  "
                  (click)="openProject(projectAudit.project)"
                >
                  {{ projectAudit.project.ownerName }}
                </td>
                <td (click)="openProject(projectAudit.project)">
                  {{
                    projectAudit.project.createdAt.toLocaleString([], {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric"
                    })
                  }}
                </td>
                <td (click)="openProject(projectAudit.project)">
                  {{
                    projectAudit.date.toLocaleString([], {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric"
                    })
                  }}
                </td>
                <td class="icons">
                  <span
                    matTooltip="{{
                      'interface_collaborators' | translationPipe
                    }} "
                    matTooltipPosition="above"
                    matTooltipClass="custom-tooltip"
                  >
                    <em
                      class="fas fa-users"
                      (click)="openModalToShareProject(projectAudit.project)"
                    ></em>
                  </span>
                  <span
                    matTooltip="{{ 'interface_duplicate' | translationPipe }} "
                    matTooltipPosition="above"
                    matTooltipClass="custom-tooltip"
                  >
                    <em
                      class="fas fa-copy no-outline"
                      (click)="duplicateProject(projectAudit.project)"
                    ></em>
                  </span>
                  <span
                    matTooltip="{{ 'interface_delete' | translationPipe }} "
                    matTooltipPosition="above"
                    matTooltipClass="custom-tooltip"
                  >
                    <em
                      class="fas fa-trash no-outline"
                      (click)="deleteProject(projectAudit.project)"
                      *ngIf="projectAudit.project.ownerId == currentUser?.id"
                    ></em>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div
      *ngIf="this.guestService.isUserGuest() && guestUserProject !== undefined"
      class="col-md-10 landing-page-container-not-first"
    >
      <h3 class="box-title" id="latest">
        {{ "interface_landing_latest_projects" | translationPipe }}
      </h3>
      <div class="main-actions-container">
        <div *ngIf="guestUserProject === undefined" class="no-projects">
          {{ "interface_no_projects_to_display" | translationPipe }}
        </div>
        <div class="table-responsive latest-projects">
          <table
            *ngIf="guestUserProject !== undefined"
            class="table"
            aria-describedby="latest"
          >
            <thead>
              <tr>
                <th scope="col">{{ "interface_name" | translationPipe }}</th>
                <th scope="col"></th>
              </tr>
            </thead>

            <tbody>
              <tr class="clickable">
                <td (click)="openProject(guestUserProject)">
                  <div>
                    <span
                      matTooltip="{{ guestUserProject.name }} "
                      matTooltipPosition="above"
                      matTooltipClass="custom-tooltip"
                    >
                      {{ guestUserProject.name }}
                    </span>
                  </div>
                </td>
                <td class="icons">
                  <span
                    matTooltip="{{ 'interface_delete' | translationPipe }} "
                    matTooltipPosition="above"
                    matTooltipClass="custom-tooltip"
                  >
                    <em
                      class="fas fa-trash no-outline"
                      (click)="deleteProject(guestUserProject)"
                    ></em>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </app-container-sidebar>

  <app-project-modal [singleuse]="this.isSingleUse" [createProject]="true">
  </app-project-modal>

  <app-open-project-modal> </app-open-project-modal>

  <app-confirm-modal
    [id]="'delete-project'"
    [title]="'interface_project_deletion_title' | translationPipe"
    [content]="
      ('interface_project_deletion_body_1' | translationPipe) +
      this.selectedProject?.name +
      ('interface_project_deletion_body_2' | translationPipe) +
      this.selectedProject?.ownerName +
      ' ?'
    "
    (answer)="confirmOrAbortDeletion($event)"
  >
  </app-confirm-modal>

  <app-confirm-modal
    [id]="'duplicate-project'"
    [title]="'interface_project_duplication_title' | translationPipe"
    [content]="
      ('interface_project_duplication_body_1' | translationPipe) +
      this.selectedProject?.name +
      ('interface_project_duplication_body_2' | translationPipe) +
      this.selectedProject?.ownerName +
      ' ?'
    "
    (answer)="confirmOrAbortDuplication($event)"
  >
  </app-confirm-modal>

  <app-project-collaborators-modal
    [project]="this.selectedProject!"
    [filterSelectedOnly]="true"
    [editMode]="false"
  >
  </app-project-collaborators-modal>
</ng-container>
