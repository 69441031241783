import { Injectable } from '@angular/core';
import { FilterCriterion } from '@/app/model/search/filter-criterion';
import { Sorting, SortingDirection } from '@/app/model/search/sorting';
import { Page } from '@/app/model/pagination/page';
import { CollaboratorApiService } from '@/app/api/collaborator-api.service';
import { Collaborator } from '@/app/model/collaborator/collaborator';

@Injectable({
  providedIn: 'root',
})
export class CollaboratorService {
  constructor(private collaboratorApiService: CollaboratorApiService) {}

  async getAllCollaborators(): Promise<Collaborator[]> {
    const page = await this.getCollaborators(
      0,
      100,
      [],
      new Sorting(SortingDirection.ASC, 'firstname')
    );
    return page.content;
  }

  private getCollaborators(
    page: number,
    size: number,
    filterCriteria: Array<FilterCriterion>,
    sorting: Sorting
  ): Promise<Page<Collaborator>> {
    return this.collaboratorApiService
      .getCollaborators(page, size, filterCriteria, sorting)
      .toPromise();
  }
}
