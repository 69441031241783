import { Component, Input, OnInit } from '@angular/core';
import { GuestService } from '@/app/services/guest.service';
import { ProjectService } from '@/app/services/project.service';
import { DetailedResult } from '@/app/model/results/detailed-result';
import { ScenarioResult } from '@/app/model/results/scenario-result';

class RechargeProjectForAssess {
  name!: string;
  singleScore: number | undefined;
}

@Component({
  selector: 'app-recharge-project-for-assessment[scenarioResults]',
  templateUrl: './recharge-project-for-assessment.component.html',
})
export class RechargeProjectForAssessmentComponent implements OnInit {
  rechargeProjectsForAssess!: Array<RechargeProjectForAssess>;

  @Input()
  scenarioResults!: Array<ScenarioResult>;

  constructor(
    protected guestService: GuestService,
    protected projectService: ProjectService
  ) {}

  ngOnInit(): void {
    this.rechargeProjectsForAssess = Array.of(
      this.projectService.currentRechargeProject
    ).map((p) => {
      return {
        name: p.name,
        singleScore: this.resultsForProject(p.id)?.totalSingleScore.result,
      };
    });
  }

  resultsForProject(projectId: number): DetailedResult | undefined {
    //see assess.component.ts for why this is scenario results, requires further refactoring
    return this.scenarioResults.find((result) => result.id == projectId)
      ?.results;
  }
}
