<table class="table score-table table-striped">
  <thead>
    <th width="33.33%" scope="col">
      {{ "interface_scenario" | translationPipe }}
      <app-sort-toggle
        [sorting]="sorting"
        sortedBy="name"
        (toggle)="launchSort()"
      ></app-sort-toggle>
    </th>
    <th width="33.33%" scope="col">
      {{ "interface_assess_score" | translationPipe }} ({{
        "unit_pt_per_ml" | translationPipe
      }})
      <app-sort-toggle
        [sorting]="sorting"
        sortedBy="singleScore"
        (toggle)="launchSort()"
      ></app-sort-toggle>
    </th>
    <th width="33.33%" scope="col">
      {{ "interface_assess_difference_from_reference" | translationPipe }}
      <app-sort-toggle
        [sorting]="sorting"
        sortedBy="singleScoreDifference"
        (toggle)="launchSort()"
      ></app-sort-toggle>
    </th>
  </thead>
  <tbody>
    <tr *ngFor="let scenario of scenariosForAssess">
      <td>
        {{ scenario.name
        }}<em
          *ngIf="
            scenario.scenarioId === referenceScenarioId &&
            !guestService.isUserGuest()
          "
          class="fas fa-star is-reference"
          title="{{ 'interface_is_reference' | translationPipe }}"
        ></em>
      </td>
      <td>
        {{ scenario.singleScore | toFixed: 2 }}
        <em
          *ngIf="isBestSingleScore(scenario.scenarioId)"
          class="fas fa-check-circle best-scenario"
        ></em>
      </td>
      <td>
        <div
          *ngIf="
            scenariosForAssess.length > 0 &&
            scenario.scenarioId !== referenceScenarioId &&
            scenario.singleScoreDifference < 0
          "
          class="difference-tag"
          [ngClass]="{
            'negative-difference': scenario.singleScoreDifference <= -10,
            'nonsignificant-difference': scenario.singleScoreDifference > -10
          }"
        >
          <span
            matTooltip="{{
              (scenario.singleScoreDifference > -10
                ? 'interface_assess_nonsignificant_difference'
                : 'interface_assess_better_than_reference'
              ) | translationPipe
            }} "
            matTooltipPosition="above"
            matTooltipClass="custom-tooltip"
          >
            <p>
              <em class="fa fa-arrow-down mright-5"></em>
              {{ scenario.singleScoreDifference | toFixed: 0 }}%
            </p>
          </span>
        </div>
        <div
          *ngIf="
            scenariosForAssess.length > 0 &&
            scenario.scenarioId !== referenceScenarioId &&
            scenario.singleScoreDifference > 0
          "
          class="difference-tag"
          [ngClass]="{
            'positive-difference': scenario.singleScoreDifference >= 10,
            'nonsignificant-difference': scenario.singleScoreDifference < 10
          }"
        >
          <span
            matTooltip="{{
              (scenario.singleScoreDifference < 10
                ? 'interface_assess_nonsignificant_difference'
                : 'interface_assess_worse_than_reference'
              ) | translationPipe
            }}"
            matTooltipPosition="above"
            matTooltipClass="custom-tooltip"
          >
            <p>
              <em class="fa fa-arrow-up mright-5"></em
              >{{ scenario.singleScoreDifference | toFixed: 0 }}%
            </p>
          </span>
        </div>
        <div *ngIf="scenario.scenarioId === referenceScenarioId">-</div>
      </td>
    </tr>
  </tbody>
</table>
