<div
  *ngIf="translationService.translationsLoaded | async"
  class="background-img"
>
  <div class="full-height">
    <div id="projectMain" class="full-width">
      <div class="row banner">
        <div class="col-12 col-md-12 col-lg-12">
          <img src="assets/sidebar_spiceLogo.svg" class="header-spice" />
          <p class="spice-text-helvetica main-title-text">
            {{ "interface_landing_spice_subtitle" | translationPipe }}
          </p>
        </div>
      </div>
      <div class="row below-banner">
        <div class="col-md-8 col-lg-6 content-container">
          <div class="content-header" *ngIf="errorType !== 'company-disabled'">
            <app-back-button></app-back-button>
          </div>
          <div
            class="main-actions-container"
            *ngIf="errorType !== 'company-disabled'"
          >
            <div class="parent">
              <h3 class="child">{{ errorMsg | translationPipe }}</h3>
            </div>
          </div>
          <div
            class="main-actions-container-company"
            *ngIf="errorType === 'company-disabled'"
          >
            <div class="parent-company">
              <h3 class="child disable-company-title">
                {{ errorMsg | translationPipe }}
              </h3>
              <div class="details">
                <p>{{ errorMsgDetail | translationPipe }}</p>
                <button class="btn btn-primary" (click)="logout()">
                  {{ "interface_ok" | translationPipe }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
