import { Indicator } from './indicator';
import { ResultCategory } from './result-category';
import { ResultType } from './result-type';
import { Type } from 'class-transformer';
import { RechargeType } from './recharge-type';

export class Result {
  @Type(() => Indicator)
  indicator!: Indicator;
  @Type(() => ResultCategory)
  resultCategory!: ResultCategory;
  rechargeType!: RechargeType;
  resultType!: ResultType;
  result!: number;
  elementLevel!: any;
}
