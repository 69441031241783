<nav aria-label="breadcrumb" *ngIf="show">
  <ol class="breadcrumb" id="spice-breadcrumb">
    <li class="breadcrumb-item clickable">
      <a (click)="goBackToHomePage()">
        <em class="fas fa-home"></em> {{ "interface_home" | translationPipe }}
      </a>
    </li>
    <li class="breadcrumb-item">
      {{ projectName }}
    </li>
    <li
      class="breadcrumb-item"
      [class.active]="isGeneralInfo"
      [class.clickable]="!isGeneralInfo"
      *ngIf="this.selectedScenario && !isRechargeProject"
    >
      <a (click)="goToScenario()" *ngIf="!isGeneralInfo">
        {{ this.selectedScenario.name }}
      </a>
      <span *ngIf="isGeneralInfo">{{ this.selectedScenario.name }}</span>
    </li>
    <li
      class="breadcrumb-item"
      *ngIf="
        this.selectedComponent != null && !isGeneralInfo && !isRechargeProject
      "
    >
      {{
        getStringFromLevel(this.selectedComponent.componentLevel)
          | translationPipe
      }}
    </li>
    <li
      class="breadcrumb-item"
      [class.active]="
        this.selectedComponent != null && !isGeneralInfo && !isRechargeProject
      "
      *ngIf="this.selectedComponent != null && !isGeneralInfo"
    >
      {{ this.selectedComponent.name }}
    </li>
  </ol>
</nav>
