import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  TemplateRef,
} from '@angular/core';
import {
  NgbModal,
  NgbModalOptions,
  NgbModalRef,
} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: [],
})
export class ConfirmModalComponent {
  modal: NgbModalRef | undefined;

  @Input() id = 'confirm-modal';
  // @ts-ignore
  @Input() title: string;
  // @ts-ignore
  @Input() content: string;

  // @ts-ignore
  @ViewChild('confirmmodal') templateModal: TemplateRef<any>;

  @Output() answer = new EventEmitter<boolean>();

  private readonly MODAL_OPTIONS: NgbModalOptions = {
    centered: true,
  };

  constructor(private modalService: NgbModal) {}

  confirm() {
    this.answer.emit(true);
  }

  abort() {
    this.answer.emit(false);
  }

  open() {
    this.modal = this.modalService.open(this.templateModal, this.MODAL_OPTIONS);
  }

  close() {
    this.modal?.close();
  }
}
