import { Type } from 'class-transformer';

import { Project } from './project';
import { Scenario } from './../scenario';

export class SingleUseProject extends Project {
  referenceScenarioId!: number;

  @Type(() => Scenario)
  scenarios!: Array<Scenario>;

  static copy(p: SingleUseProject): SingleUseProject {
    const newProject: SingleUseProject = new SingleUseProject();
    newProject.fillFields(p);
    return newProject;
  }

  constructor() {
    super();
  }

  fillFields(p: SingleUseProject): void {
    super.fillFields(p);
    this.referenceScenarioId = p.referenceScenarioId;
  }

  get referenceScenario(): Scenario | undefined {
    if (!this.scenarios) return undefined;
    return this.scenarios.filter(
      (scenario) => scenario.id == this.referenceScenarioId
    )[0];
  }
}
