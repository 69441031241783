<form [formGroup]="scenarioform" id="scenarioform">
  <span *ngIf="scenario.published">
    <em class="fas fa-info-circle"></em>
    {{
      "interface_library_already_in"
        | translationPipe: printScenarioName(scenario.name)
    }}</span
  >
  <label>{{
    "interface_library_copy_name"
      | translationPipe: printScenarioName(scenario.name)
  }}</label>
  <input
    type="text"
    class="form-control"
    formControlName="name"
    placeholder="{{ scenario.name }}"
  />
  <validation-messages
    [control]="scenarioform.get('name')"
    class="form-invalid-message"
  >
  </validation-messages>
</form>
