import { Component, Input, ViewChild } from '@angular/core';
import { LoginService } from 'src/app/services/login/login.service';
import { SidebarService } from '@/app/services/sidebarService/sidebar.service';
import { UserService } from '@/app/services/user.service';
import { GlobalService } from '@/app/services/global.service';
import { GuestService } from '@/app/services/guest.service';
import { ChangelogModalComponent } from '@/app/component/changelog/changelog-modal.component';
import { environment } from '@/environments/environment';

@Component({
  selector: 'sidebar-user-mgmt',
  templateUrl: './sidebar-user-mgmt.component.html',
  styleUrls: ['./sidebar-user-mgmt.component.css'],
})
export class SidebarUserMgmtComponent {
  @ViewChild(ChangelogModalComponent)
  private changelogModalComponent!: ChangelogModalComponent;

  // @ts-ignore
  @Input() isCollapsible: boolean;
  userInfo = false;
  openDocuments = false;

  constructor(
    private _loginService: LoginService,
    public sidebarService: SidebarService,
    public userService: UserService,
    public globalService: GlobalService,
    private guestService: GuestService
  ) {}

  get currentUser(): string {
    return this._loginService.getCurrentUserFullName();
  }

  get dbVersion(): string | undefined {
    return this.globalService.dbVersion;
  }

  get isUserGuest(): boolean {
    return this.guestService.isUserGuest();
  }

  isUserSuperAdmin() {
    return this._loginService.isSuperAdmin();
  }

  isUserSuperAdminOrAdmin() {
    return this._loginService.isAdminOrSuperAdmin();
  }

  isUserAdmin() {
    return this._loginService.isAdmin();
  }

  closeSidebarIfCollapsible() {
    if (this.isCollapsible) {
      this.sidebarService.closeSidebar();
    }
  }

  displayUserRole(): string {
    return this._loginService.getHigherRoleLabel();
  }

  openChangelogModal(): void {
    this.changelogModalComponent.open();
  }

  logout() {
    void this._loginService.logout();
  }

  protected readonly environment = environment;
}
