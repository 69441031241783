import { Packaging } from './packaging';
import { Type } from 'class-transformer';
import { PackagingAssociation } from './packaging-association';

export class PrimaryPackaging extends Packaging {
  isRefillable!: boolean;
  claimedVolume!: number;
  claimedVolumeUnit = 'ml';
  rateOfRestitution!: number;

  @Type(() => PackagingAssociation)
  contentPackagingAssociations!: Array<PackagingAssociation>;

  copy(): PrimaryPackaging {
    const copy: PrimaryPackaging = new PrimaryPackaging();
    copy.id = this.id;
    copy.type = this.type;
    copy.isRecyclable = this.isRecyclable;
    copy.isRefillable = this.isRefillable;
    copy.claimedVolume = this.claimedVolume;
    copy.claimedVolumeUnit = this.claimedVolumeUnit;
    copy.rateOfRestitution = this.rateOfRestitution;
    return copy;
  }

  editableFieldsHaveChanged(original: PrimaryPackaging): boolean {
    return (
      this.claimedVolume != original.claimedVolume ||
      this.rateOfRestitution != original.rateOfRestitution ||
      this.isRecyclable != original.isRecyclable
    );
  }

  mergeEditableFields(pack: PrimaryPackaging): void {
    this.claimedVolume = pack.claimedVolume;
    this.rateOfRestitution = pack.rateOfRestitution;
    this.isRecyclable = pack.isRecyclable;
  }
}
