<app-container-sidebar
  *ngIf="this.translationService.translationsLoaded | async"
>
  <div class="col-md-10 col-lg-12 content-container">
    <div class="content-header">
      <app-back-button></app-back-button>
      <h3 class="box-title inline-block">
        {{ "interface_manage_scenario_library" | translationPipe }}
      </h3>
    </div>
    <div class="main-actions-container">
      <div class="top-bar" *ngIf="publishedScenarios.length > 0">
        <div class="search-box">
          <input
            type="text"
            class="search-input"
            placeholder="{{ 'interface_search' | translationPipe }} "
            [(ngModel)]="this.nameFilter"
            (keyup)="launchSearch()"
          />
          <em class="fas fa-search mar-right"> </em>
        </div>
      </div>
      <div class="table-responsive">
        <table *ngIf="publishedScenarios.length > 0" class="table">
          <thead>
            <tr>
              <th scope="col">
                <div class="parent">
                  {{ "interface_scenario" | translationPipe }}
                  <app-sort-toggle
                    [sorting]="sorting"
                    sortedBy="name"
                    (toggle)="launchSearch()"
                  >
                  </app-sort-toggle>
                </div>
              </th>
              <th scope="col">
                <div class="parent">
                  {{ "interface_scenario_prod_zone" | translationPipe }}
                </div>
              </th>
              <th scope="col">
                <div class="parent">
                  {{ "interface_scenario_sales_zone" | translationPipe }}
                </div>
              </th>
              <th scope="col">
                <div class="parent">
                  {{ "interface_status" | translationPipe }}
                  <app-sort-toggle
                    [sorting]="sorting"
                    sortedBy="status"
                    (toggle)="launchSearch()"
                  >
                  </app-sort-toggle>
                </div>
              </th>
              <th scope="col">
                <div class="parent">
                  {{ "interface_created_by" | translationPipe }}
                </div>
              </th>
              <th scope="col">
                <div class="parent">
                  {{ "interface_exported_at" | translationPipe }}
                  <app-sort-toggle
                    [sorting]="sorting"
                    sortedBy="exportedAt"
                    (toggle)="launchSearch()"
                  >
                  </app-sort-toggle>
                </div>
              </th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let publishedScenario of publishedScenarios">
              <td class="space-left">
                {{ publishedScenario.name }}
              </td>
              <td class="space-left">
                {{
                  publishedScenario.productionCountry.label | translationPipe
                }}
              </td>
              <td class="space-left">
                {{ publishedScenario.salesCountry.label | translationPipe }}
              </td>
              <td class="space-left">
                <span *ngIf="publishedScenario.status === 'APPROVED'">
                  <em class="fas fa-check green"></em>
                  {{ publishedScenario.status | translationPipe }}</span
                >
                <span *ngIf="publishedScenario.status === 'PENDING'">
                  <em class="fas fa-clock orange"></em>
                  {{ publishedScenario.status | translationPipe }}</span
                >
              </td>
              <td class="space-left">
                {{
                  publishedScenario.exportedBy?.displayedName ??
                    "(deleted user)"
                }}
              </td>
              <td class="space-left">
                {{
                  publishedScenario.exportedAt.toLocaleString([], {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                    hour: "2-digit",
                    minute: "2-digit"
                  })
                }}
              </td>
              <td class="actions">
                <em
                  class="fas fa-pen"
                  (click)="openModalToEditPublishedScenario(publishedScenario)"
                ></em>
                <em
                  class="fas fa-trash"
                  (click)="deletePublishedScenario(publishedScenario)"
                ></em>
              </td>
            </tr>
          </tbody>
        </table>
        <div
          *ngIf="publishedScenarios.length == 0"
          class="no-published_scenarios"
        >
          {{ "interface_library_is_empty" | translationPipe }}
        </div>
      </div>
      <div *ngIf="publishedScenarios.length > 0" class="paginationContainer">
        <app-pagination-bar
          [pager]="pager"
          (selectedPage)="setPage($event)"
        ></app-pagination-bar>
      </div>
    </div>
  </div>
</app-container-sidebar>

<app-edit-published-scenario-modal
  [currentPublishedScenario]="currentPublishedScenario"
>
</app-edit-published-scenario-modal>

<app-confirm-modal
  [id]="'delete-publishedScenario'"
  [title]="'interface_published_scenario_deletion_title' | translationPipe"
  [content]="
    'interface_published_scenario_deletion_body_1'
      | translationPipe: getPublishedScenarioArgs()
  "
  (answer)="confirmOrAbortDeletion($event)"
>
</app-confirm-modal>
