import { Component } from '@angular/core';
import { LoginService } from '@/app/services/login/login.service';
import { TranslationService } from '@/app/services/translationService/translation.service';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.css'],
})
export class UserManagementComponent {
  constructor(
    private loginService: LoginService,
    public translationService: TranslationService
  ) {}

  isSuperAdmin(): boolean {
    return this.loginService.isSuperAdmin();
  }
}
