import { Component, Output, OnInit, EventEmitter } from '@angular/core';
import {
  UntypedFormBuilder,
  Validators,
  UntypedFormGroup,
  AbstractControl,
} from '@angular/forms';
import { CustomValidators } from '@/app/utils/custom-validators';
import { PasswordValidationHelper } from '@/app/model/validation/password-validation-helper';

@Component({
  selector: 'app-new-password-validation',
  templateUrl: './new-password-validation.component.html',
  styleUrls: ['./new-password-validation.component.css'],
})
export class NewPasswordValidationComponent implements OnInit {
  @Output() formReady = new EventEmitter<UntypedFormGroup>();
  // @ts-ignore
  newPasswordForm: UntypedFormGroup;

  passwordhelpers: Array<PasswordValidationHelper> = [
    new PasswordValidationHelper('minlength', 'interface_password_min_length'),
    new PasswordValidationHelper('hasNumber', 'interface_password_has_number'),
    new PasswordValidationHelper(
      'hasCapitalCase',
      'interface_password_has_capital_case'
    ),
    new PasswordValidationHelper(
      'hasSmallCase',
      'interface_password_has_small_case'
    ),
    new PasswordValidationHelper(
      'hasSpecialCharacters',
      'interface_password_has_special_character'
    ),
  ];

  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit() {
    this.newPasswordForm = this.fb.group(
      {
        newPassword: [
          null,
          Validators.compose([
            // 1. Password Field is Required
            Validators.required,
            // 2. check whether the entered password has a number
            CustomValidators.patternValidator(/\d/, { hasNumber: true }),
            // 3. check whether the entered password has upper case letter
            CustomValidators.patternValidator(/[A-Z]/, {
              hasCapitalCase: true,
            }),
            // 4. check whether the entered password has a lower-case letter
            CustomValidators.patternValidator(/[a-z]/, { hasSmallCase: true }),
            // 5. check whether the entered password has a special character
            CustomValidators.patternValidator(
              // eslint-disable-next-line no-useless-escape
              /[?!@#$%^&*()_\+\-={};':"|,.<>\[\]\/]/,
              {
                hasSpecialCharacters: true,
              }
            ),
            // 6. Has a minimum length of 8 characters
            Validators.minLength(8),
          ]),
        ],
        confirmNewPassword: [null, Validators.compose([Validators.required])],
      },
      {
        // check whether our password and confirm password match
        validator: CustomValidators.passwordMatchValidator(
          'newPassword',
          'confirmNewPassword'
        ),
      }
    );

    this.formReady.emit(this.newPasswordForm);
  }

  passwordFieldHasError(error: string): boolean {
    return this.getControlHasErrorMessage(
      this.newPasswordForm.controls['newPassword'],
      error
    );
  }

  getControlHasErrorMessage(control: AbstractControl, error: string): boolean {
    return control.hasError(error);
  }
}
