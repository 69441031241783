import { Component, ViewChild } from '@angular/core';
import { PopulateModalComponent } from '@/app/component/populate/populate-modal/populate-modal.component';

@Component({
  selector: 'app-populate',
  templateUrl: './populate.component.html',
  styleUrls: ['./populate.component.css'],
})
export class PopulateComponent {
  @ViewChild(PopulateModalComponent)
  private openPopulateModalComponent!: PopulateModalComponent;

  openPopulateModal() {
    this.openPopulateModalComponent.open();
  }
}
