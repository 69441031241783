import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ApiUtils } from './api-utils';
import { Scenario } from '@/app/model/packaging-model/scenario';
import { PublishedScenario } from '../model/packaging-model/published-scenario';
import { map } from 'rxjs/operators';
import { plainToClass, plainToClassFromExist } from 'class-transformer';
import { Observable } from 'rxjs';
import { Page } from '../model/pagination/page';
import { ExportScenarioLibrary } from '../model/packaging-model/export-scenario-library';
import { Sorting } from '../model/search/sorting';
import { FilterCriterion } from '../model/search/filter-criterion';
import { PublishedScenarioEditableFields } from '../model/packaging-model/published-scenario-editable-fields';

@Injectable({
  providedIn: 'root',
})
export class PublishedScenarioApiService {
  constructor(private http: HttpClient) {}

  buildPath(endPoint: string): string {
    return ApiUtils.BASE_PATH + 'published-scenarios/' + endPoint;
  }

  async exportScenarioToLibrary(scenarios: Array<ExportScenarioLibrary>) {
    const url = this.buildPath('');
    await this.http
      .post<Scenario>(url, scenarios, ApiUtils.HTTP_OPTIONS)
      .toPromise();
  }

  getScenarioLibrary(
    page: number,
    filterCriteria: Array<FilterCriterion>,
    sorting: Sorting,
    statuses: Array<string>
  ): Observable<Page<PublishedScenario>> {
    const url = this.buildPath('');
    let queryParams = new HttpParams()
      .set('page', page.toString())
      .set('sortDirection', sorting.direction)
      .set('sortedBy', sorting.sortedBy);
    filterCriteria.forEach(
      (filterCriterion: FilterCriterion) =>
        (queryParams = queryParams.set(
          filterCriterion.key,
          filterCriterion.value
        ))
    );
    const httpOptions = {
      headers: { 'Content-Type': 'application/json' },
      params: queryParams,
    };

    const params = statuses?.length ? `?statuses=${statuses.join(',')}` : '';
    return this.http
      .get<Page<PublishedScenario>>(url + params, httpOptions)
      .pipe(
        map((response) =>
          plainToClassFromExist(
            new Page<PublishedScenario>(PublishedScenario),
            response
          )
        )
      );
  }

  async editPublishedScenario(
    publishedScenarioId: number,
    editableFields: PublishedScenarioEditableFields
  ): Promise<PublishedScenario> {
    const url = this.buildPath(publishedScenarioId.toString());
    const response = await this.http
      .put<PublishedScenario>(url, editableFields, ApiUtils.HTTP_OPTIONS)
      .toPromise();
    return plainToClass(PublishedScenario, response);
  }

  async deletePublishedScenario(scenarioId: number): Promise<void> {
    const url = this.buildPath(scenarioId.toString());
    await this.http
      .delete<PublishedScenario>(url, ApiUtils.HTTP_OPTIONS)
      .toPromise();
  }
}
