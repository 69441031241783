import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'toFixed' })
export class ToFixedPipe implements PipeTransform {
  transform(value: number | undefined, digits: number): string {
    if (value == undefined) {
      return '-';
    }
    return value.toFixed(digits);
  }
}
