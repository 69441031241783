import {
  Component,
  ViewChild,
  TemplateRef,
  Output,
  EventEmitter,
  OnInit,
} from '@angular/core';
import {
  NgbModal,
  ModalDismissReasons,
  NgbModalRef,
} from '@ng-bootstrap/ng-bootstrap';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Company } from '@/app/model/data/company';
import { CompanyService } from '@/app/services/company.service';
import { LoginService } from '@/app/services/login/login.service';
import { UserService } from '@/app/services/user.service';
import { CustomValidators } from '@/app/utils/custom-validators';
import { Observable, of, Subject, concat } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  tap,
  switchMap,
} from 'rxjs/operators';
import { authorities, Authority, Role } from '@/app/model/user/authority';
import { User } from '@/app/model/user/user';
import { UserComplete } from '@/app/model/user/user-complete';
import { FormUtils } from '@/app/utils/form-utils';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-user-management-modal',
  templateUrl: './user-management-modal.component.html',
  styleUrls: ['./user-management-modal.component.css'],
})
export class UserManagementModalComponent implements OnInit {
  @Output() changeMade = new EventEmitter<User>();

  // @ts-ignore
  _userLocal: User;
  // @ts-ignore
  _userComplete: UserComplete;

  searchFailed = false;

  modal: NgbModalRef | undefined;
  // @ts-ignore
  userForm: UntypedFormGroup;
  // @ts-ignore
  errorMessage: string;

  // @ts-ignore
  compagnies$: Observable<Company[]>;
  companyLoading = false;
  companyInput$ = new Subject<string>();

  userLoading = false;

  authorities: Authority[] = authorities.filter(
    (authority) => authority.role != Role.GUEST_USER
  );

  // @ts-ignore
  creating: boolean;

  // @ts-ignore
  emailAlreadyExists: boolean;
  // @ts-ignore
  limitReached: boolean;

  // @ts-ignore
  resendingInvitation: boolean;

  // @ts-ignore
  showResendInvitationToastSuccessfull: boolean;
  // @ts-ignore
  showResendInvitationToastFailure: boolean;

  // @ts-ignore
  @ViewChild('usermanagementmodal') templateModal: TemplateRef<any>;

  protected readonly boundaries = {
    organization: {
      maxLength: 255,
    },
    description: {
      maxLength: 5000,
    },
  };

  constructor(
    private modalService: NgbModal,
    private formBuilder: UntypedFormBuilder,
    private companyService: CompanyService,
    private userService: UserService,
    private loginService: LoginService
  ) {}

  ngOnInit() {
    this.userForm = this.formBuilder.group({
      email: [
        '',
        Validators.compose([
          Validators.required,
          CustomValidators.emailValidator.compose,
        ]),
      ],
      firstname: [
        '',
        Validators.compose([
          Validators.required,
          CustomValidators.userNameValidator.compose,
        ]),
      ],
      lastname: [
        '',
        Validators.compose([
          Validators.required,
          CustomValidators.userNameValidator.compose,
        ]),
      ],
      brand: [
        '',
        // @ts-ignore
        Validators.compose([
          CustomValidators.notSpaceValidator(),
          Validators.maxLength(this.boundaries.organization.maxLength),
        ]),
      ],
      company: ['', Validators.required],
      authority: ['', Validators.required],
      description: [
        '',
        // @ts-ignore
        Validators.compose([
          CustomValidators.notSpaceValidator(),
          Validators.maxLength(this.boundaries.description.maxLength),
        ]),
      ],
    });

    FormUtils.updateModelFromFieldValue<string>(
      this.userForm.controls['email'],
      (v) => {
        this._userLocal.email = v;
        this.emailAlreadyExists = false;
      }
    );
    FormUtils.updateModelFromFieldValue<string>(
      this.userForm.controls['firstname'],
      (v) => (this._userLocal.firstname = v)
    );
    FormUtils.updateModelFromFieldValue<string>(
      this.userForm.controls['lastname'],
      (v) => (this._userLocal.lastname = v)
    );
    FormUtils.updateModelFromFieldValue<string>(
      this.userForm.controls['brand'],
      (v) => (this._userLocal.brand = v)
    );
    FormUtils.updateModelFromFieldValue<string>(
      this.userForm.controls['authority'],
      (v) => (this._userLocal.authorities = [v])
    );
    FormUtils.updateModelFromFieldValue<string>(
      this.userForm.controls['description'],
      (v) => (this._userLocal.description = v)
    );
    FormUtils.updateModelFromFieldValue<Company>(
      this.userForm.controls['company'],
      (v) => (this._userLocal.company = v)
    );

    this.loadCompagnies();
  }

  onCreating() {
    this.creating = true;

    if (!this.isSuperAdmin()) {
      // @ts-ignore
      this.userForm
        .get('company')
        // @ts-ignore
        .setValue(this.loginService.currentUser.company);
    }
  }

  onUpdating() {
    this.creating = false;

    this.userForm.controls['email'].setValue(this._userLocal.email);
    this.userForm.controls['firstname'].setValue(this._userLocal.firstname);
    this.userForm.controls['lastname'].setValue(this._userLocal.lastname);
    this.userForm.controls['brand'].setValue(this._userLocal.brand);
    this.userForm.controls['company'].setValue(this._userLocal.company);
    this.userForm.controls['authority'].setValue(
      this.getAuthority(this._userLocal.authorities)
    );
    this.userForm.controls['description'].setValue(this._userLocal.description);

    this.userForm.controls['email'].disable();
    this.userForm.controls['company'].disable();
  }

  getAuthority(authorities: string[]): string {
    if (authorities && authorities[0]) return authorities[0];
    else return '';
  }

  isSuperAdmin(): boolean {
    return this.loginService.isSuperAdmin();
  }

  trackByFn(item: Company) {
    return item.id;
  }

  onSubmitUser() {
    this.emailAlreadyExists = false;

    this.userLoading = true;

    (this.creating
      ? this.userService.adminCreateMemberUser(this._userLocal)
      : this.userService.adminEditMemberUser(this._userLocal)
    )
      .then((user) => {
        this.changeMade.emit(user);
        this.close();
      })
      .catch((response: HttpErrorResponse) => {
        if (response.status == 400) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          if (response.error.type === 'UsernameExistsException') {
            this.emailAlreadyExists = true;
          }
        } else if (response.status == 403) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          if (response.error.type === 'TooManyItemsException') {
            this.limitReached = true;
          }
        }
      })
      .finally(() => {
        this.userLoading = false;
      });
  }

  open(user?: User) {
    this.modal = this.modalService.open(this.templateModal);

    if (user) {
      this._userLocal = user;
      this.userService
        .adminGetUserComplete(user.id)
        .then((userComplete) => (this._userComplete = userComplete))
        .catch((err) => console.error(err));
      this.onUpdating();
    } else {
      this._userLocal = new User();
      this.onCreating();
    }

    this.modal.result.then(null, (reason: any) => {
      this.resetAfterDismissal(reason);
    });
  }

  availableAuthorities(): Authority[] {
    return this.isSuperAdmin()
      ? this.authorities
      : this.authorities.filter(
          (authority) => authority.role != Role.SUPER_ADMIN
        );
  }

  close() {
    this.resetFields();
    this.modal?.close();
  }

  resetAfterDismissal(reason: any) {
    if (
      reason === ModalDismissReasons.ESC ||
      reason === ModalDismissReasons.BACKDROP_CLICK
    ) {
      this.resetFields();
    }
  }

  resetFields() {
    this.userForm.reset();
    this.userForm.enable();
    // @ts-ignore
    this._userLocal = null;
    // @ts-ignore
    this._userComplete = null;
    this.userLoading = false;
    this.emailAlreadyExists = false;
    this.resendingInvitation = false;
    this.limitReached = false;
  }

  resendInvitation() {
    this.resendingInvitation = true;

    this.userService
      .adminResendInvitation(this._userLocal.id)
      .then((_) => this.showResendInvitationToast(true))
      .catch(() => this.showResendInvitationToast(false))
      .finally(() => (this.resendingInvitation = false));
  }

  showResendInvitationToast(success: boolean) {
    success
      ? (this.showResendInvitationToastSuccessfull = true)
      : (this.showResendInvitationToastFailure = true);
  }

  displayResendInvitation(): boolean {
    return (
      !this.creating &&
      this._userComplete &&
      this._userComplete.status === 'FORCE_CHANGE_PASSWORD'
    );
  }

  private loadCompagnies() {
    this.compagnies$ = concat(
      of([]), // default items
      this.companyInput$.pipe(
        debounceTime(300),
        distinctUntilChanged(),
        tap(() => (this.companyLoading = true)),
        switchMap((term) =>
          this.companyService.searchCompanyByName(term).pipe(
            catchError(() => {
              this.searchFailed = true;
              return of([]);
            })
          )
        ),
        tap(() => (this.companyLoading = false))
      )
    );
  }

  protected readonly CustomValidators = CustomValidators;
}
