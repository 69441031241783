import { Observable, timer, NEVER, Subject, of } from 'rxjs';
import { map, takeWhile, takeUntil, switchMap, take } from 'rxjs/operators';
import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  LOCALE_ID,
  HostListener,
} from '@angular/core';
import { ProjectService } from 'src/app/services/project.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SingleUseProject } from 'src/app/model/packaging-model/project/single-use-project';
import { Scenario } from 'src/app/model/packaging-model/scenario';
import { TranslationService } from 'src/app/services/translationService/translation.service';
import { SidebarService } from '@/app/services/sidebarService/sidebar.service';
import { ProjectModalComponent } from '@/app/pages/landing/create-project-modal/project-modal.component';
import { RechargeProject } from '@/app/model/packaging-model/project/recharge-project';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  AbstractControl,
} from '@angular/forms';
import { FormUtils } from '@/app/utils/form-utils';
import { PkgComponentService } from '@/app/services/pkg-component.service';
import { GuestService } from '@/app/services/guest.service';
import { OpenProjectModalComponent } from '@/app/pages/landing/open-project-modal/open-project-modal.component';
import { ProjectLock } from '@/app/model/packaging-model/project/project-lock';
import { HttpErrorResponse } from '@angular/common/http';
import { Inject } from '@angular/core';
import { ToastService } from '@/app/services/toast.service';
import { TranslationPipe } from '@/app/intl/translation-pipe';
import { GeneralInfoComponent } from './general-info/general-info.component';
import { ProjectLockService } from '@/app/services/project-lock.service';
import { Project } from '@/app/model/packaging-model/project/project';
import assert from 'assert';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';
import { ProjectCollaboratorsModalComponent } from '@/app/pages/projects/share-project-modal/project-collaborators-modal.component';
import { LoginService } from '@/app/services/login/login.service';
import { Role } from '@/app/model/user/authority';
import { CustomValidators } from '@/app/utils/custom-validators';
import { PasswordValidationHelper } from '@/app/model/validation/password-validation-helper';
import { LoadingService } from '@/app/services/loading.service';
import { DownloadService } from '@/app/services/download-service';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-project-page',
  templateUrl: './project-page.component.html',
  styleUrls: ['./project-page.component.css'],
})
export class ProjectPageComponent implements OnInit, OnDestroy {
  @ViewChild(ProjectModalComponent)
  private editProjectModalComponent!: ProjectModalComponent;

  @ViewChild(OpenProjectModalComponent)
  private openProjectModalComponent!: OpenProjectModalComponent;

  @ViewChild(ProjectCollaboratorsModalComponent)
  private shareProjectModalComponent!: ProjectCollaboratorsModalComponent;

  @ViewChild(ConfirmModalComponent)
  private confirmModal!: ConfirmModalComponent;

  // TODO extract single project variable of type Project and leverage polymorphism

  rechargeProjectLocal!: RechargeProject;
  rechargeOriginal!: RechargeProject;
  singleUseProject!: SingleUseProject;
  isSingleUse = false;
  isSelectingMother = false;
  projectId!: number;
  motherForm!: UntypedFormGroup;
  daughterForm!: UntypedFormGroup;
  forms: Array<UntypedFormGroup> = [];
  saveTimer: NodeJS.Timer | null = null;
  exporting = false;

  //Project Lock
  lockTimer: Observable<number> = of(0);
  toggle$: Subject<boolean> = new Subject<boolean>();
  TIMER_LOCK = 5;
  INTERVAL_LOCK = 1000;
  remainingSeconds$: Observable<number> = of(0);
  remainingSeconds!: number;
  loadingProjectLock = false;
  failedFetchCurrentStatusProjectLock = false;

  private unsubscribe$ = new Subject<void>();
  private routedComponent: any;

  validationHelpers: Array<PasswordValidationHelper> = [
    new PasswordValidationHelper('onlyNumbers', 'interface_only_integers'),
  ];

  get animateSaveIcon(): boolean {
    return this.loadingService.isSaving === true;
  }

  get selectedScenario(): Scenario {
    return this.projectService.selectedScenario;
  }

  get showShareButton(): boolean {
    if (this.guestService.isUserGuest()) return true;

    const currentUser = this.loginService.currentUser;

    if (
      currentUser?.authorities.includes(Role.SUPER_ADMIN) ||
      currentUser?.authorities.includes(Role.ADMIN)
    )
      return true;

    if (this.isSingleUse)
      return this.singleUseProject.ownerId === currentUser?.id;
    else return this.rechargeOriginal.ownerId === currentUser?.id;
  }

  get isReadOnly(): boolean {
    return this.projectService.isReadOnly;
  }

  get scenarios(): Array<Scenario> {
    return this.projectService.scenarios;
  }

  get projectName(): string {
    if (this.isSingleUse) return this.singleUseProject.name;
    else return this.rechargeOriginal.name;
  }

  get projectCollaboratorsIds(): number[] {
    if (this.isSingleUse) return this.singleUseProject.collaboratorIds;
    else return this.rechargeOriginal.collaboratorIds;
  }

  isPackDefSelected = true;

  protected readonly boundaries = {
    uses: {
      min: 1,
    },
  };

  @HostListener('window:beforeunload', ['$event'])
  unlockCurrentProject(): void {
    this.projectService
      ?.unlockProject(this.projectId)
      .catch((err) => console.error(err));
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private projectService: ProjectService,
    public guestService: GuestService,
    private pkgComponentService: PkgComponentService,
    public translationService: TranslationService,
    public sidebarService: SidebarService,
    private _translationService: TranslationService,
    private toastService: ToastService,
    private translationPipe: TranslationPipe,
    private projectLockService: ProjectLockService,
    private loginService: LoginService,
    public loadingService: LoadingService,
    @Inject(LOCALE_ID) private locale: string
  ) {}

  ngOnInit(): void {
    window.addEventListener('beforeunload', () => this.unlockCurrentProject());
    this.route.paramMap.subscribe((params) => {
      this.isPackDefSelected = !this.router.url.includes('assess');
      this.isSingleUse = this.router.url.includes('single-use');
      this.projectId = Number(params.get('id'));
      // TODO add catch block
      void this.openProject()
        .then(() => {
          if (this.isPackDefSelected && this.isSingleUse) {
            if (this.guestService.isUserGuest()) {
              if (this.guestService.guestUserProject != undefined)
                this.navigateToScenarioReplaceUrl(
                  this.guestService.guestUserProject.referenceScenarioId
                );
              else void this.router.navigate(['/']);
            } else {
              if (this.projectService.currentSingleUseProject != null)
                this.navigateToScenarioReplaceUrl(
                  this.projectService.currentSingleUseProject
                    .referenceScenarioId
                );
              else void this.router.navigate(['/']);
            }
          } else if (!this.isSingleUse) {
            this.initRechargeProject();
          }

          this.initLockTimer();
        })
        .catch((err) => console.error(err));
    });
  }

  navigateToScenarioReplaceUrl(scenarioId: number): void {
    void this.router.navigate(['scenario', scenarioId, 'general-info'], {
      relativeTo: this.route,
      replaceUrl: true,
    });
  }

  navigateToScenario(scenarioId: number): void {
    void this.router.navigate(['scenario', scenarioId, 'general-info'], {
      relativeTo: this.route,
    });
  }

  setRoutedComponent(componentRef: any): void {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    this.routedComponent = componentRef;
  }

  openSidebar(): void {
    if (this.isPackDefSelected) this.sidebarService.openPackDefSidebar();
    else this.sidebarService.openAssessSidebar();
  }

  closeSidebar(): void {
    if (this.isPackDefSelected) this.sidebarService.closeSidebar();
    else this.sidebarService.closeAssessSidebar();
  }

  initRechargeProject(): void {
    this.initForms();
    this.updateReadOnlyForms();
    this.startSaveTimer();
  }

  ngOnDestroy(): void {
    if (!this.isSingleUse) {
      this.stopSaveTimer();
      this.saveProjectIfChanged().catch((err) => console.error(err));
    }

    if (!this.guestService.userWasGuest) this.unlockCurrentProject();

    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  lockCurrentProject(): Promise<ProjectLock> {
    return this.projectService.lockProject(this.projectId);
  }

  async reloadCurrentProject(): Promise<void> {
    if (this.isSingleUse) {
      await this.reloadSingleUseProject();
    } else {
      await this.reloadRechargeProject();
    }

    if (this.isReadOnly) {
      this.toggle$.next(true);
    }
  }

  async reloadSingleUseProject(): Promise<void> {
    await this.projectService.reloadSingleUseProject(this.projectId);
    this.fillLocalVariables();

    if (this.guestService.isUserGuest() && this.guestService.guestUserProject)
      this.navigateToScenario(
        this.guestService.guestUserProject.referenceScenarioId
      );
    else
      this.navigateToScenario(
        this.projectService.currentSingleUseProject.referenceScenarioId
      );
    if (this.routedComponent instanceof GeneralInfoComponent) {
      this.routedComponent.initOrUpdateAllForms();
    }
  }

  async reloadRechargeProject(): Promise<void> {
    await this.projectService.reloadRechargeProject(this.projectId);
    this.fillLocalVariables();
    this.updateForms();
    this.updateReadOnlyForms();
  }

  initLockTimer(): void {
    this.remainingSeconds$ = this.toggle$.pipe(
      switchMap((running: boolean) =>
        running ? timer(0, this.INTERVAL_LOCK) : NEVER
      ),
      map((t: number) => this.TIMER_LOCK - t),
      takeWhile((t) => t >= 0),
      takeUntil(this.unsubscribe$)
    );

    this.remainingSeconds$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((value) => {
        this.remainingSeconds = value;
        if (value == 0) {
          this.loadingProjectLock = true;
          setTimeout(() => this.refreshLockStatus(), 500);
        }
      });

    this.projectLockService.projectLockException$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((pl: ProjectLock) => {
        this.reloadCurrentProject().catch((err) => console.error(err));
        this.toastService.show(
          this.translationPipe.transform(
            'interface_project_lock_edition_title'
          ),
          this.translationPipe.transform(
            'interface_project_lock_edition_body',
            new Map().set('$1', pl.user.displayedName)
          ),
          { classname: 'bg-warning' }
        );
      });

    if (this.isReadOnly) {
      this.toggle$.next(true);
    }
  }

  refreshLockStatus(): void {
    this.lockCurrentProject()
      .then((projectLock) => {
        if (projectLock.success) {
          this.reloadCurrentProject().catch((err) => console.error(err));
        } else {
          this.toggle$.next(true);
        }
      })
      .catch((response: HttpErrorResponse) => {
        if (response.status == 404) {
          void this.router.navigate(['/']);

          this.toastService.show(
            this.translationPipe.transform(
              'interface_project_lock_project_deleted_title'
            ),
            this.translationPipe.transform(
              'interface_project_lock_project_deleted_body',
              new Map().set('$1', this.projectName)
            ),
            { classname: 'bg-warning' }
          );
        } else {
          this.failedFetchCurrentStatusProjectLock = true;
        }
      })
      .finally(() => {
        this.loadingProjectLock = false;
      });
  }

  getProjectOwnerArgs(): Map<string, string> {
    const projectLock = this.projectService.currentProjectLock;
    return new Map<string, string>().set('$1', projectLock.user.displayedName);
  }

  getRemainingSecondsArgs(): Map<string, number> {
    return new Map<string, number>().set('$1', this.remainingSeconds);
  }

  updateReadOnlyForms(): void {
    this.forms.forEach((form) => {
      if (this.isReadOnly) {
        form.disable();
      } else {
        form.enable();
      }
    });
  }

  async openProject(): Promise<void> {
    if (this.isSingleUse)
      await this.projectService.openSingleUseProject(this.projectId);
    else await this.projectService.openRechargeProject(this.projectId);

    this.fillLocalVariables();
  }

  fillLocalVariables(): void {
    if (this.isSingleUse) {
      this.singleUseProject = this.projectService.currentSingleUseProject;
    } else {
      this.rechargeOriginal = this.projectService.currentRechargeProject;
      this.rechargeProjectLocal = RechargeProject.copy(this.rechargeOriginal);
    }
  }

  isSelectedScenario(scenario: Scenario): boolean {
    return this.selectedScenario.id == scenario.id;
  }

  displayScenarioTree(scenario: Scenario): boolean {
    return this.isSelectedScenario(scenario);
  }

  goBackToHomePage(): void {
    this.projectService.resetSelectedProjects();
    void this.router.navigate(['/'], { relativeTo: this.route });
  }

  goToPackagingDefinition(): void {
    this.isPackDefSelected = true;
    if (this.isSingleUse) {
      if (this.guestService.isUserGuest() && this.guestService.guestUserProject)
        this.navigateToScenario(
          this.projectService.selectedScenario != null
            ? this.projectService.selectedScenario.id
            : this.guestService.guestUserProject.referenceScenarioId
        );
      else
        this.navigateToScenario(
          this.projectService.selectedScenario != null
            ? this.projectService.selectedScenario.id
            : this.projectService.currentSingleUseProject.referenceScenarioId
        );
    } else
      void this.router.navigate(
        ['../', this.projectService.currentRechargeProject.id],
        { relativeTo: this.route }
      );
  }

  goToAssess(): void {
    if (this.canAssess()) {
      void this.router.navigate(['assess'], { relativeTo: this.route });
      this.isPackDefSelected = false;
    }
  }

  goToComponent(pkgComponentId: number): void {
    this.pkgComponentService.selectPkgComponent(pkgComponentId);
    void this.router.navigate(
      [
        'scenario',
        this.projectService.selectedScenario.id,
        'component',
        pkgComponentId,
      ],
      { relativeTo: this.route }
    );
  }

  openModalToEditProject(): void {
    if (!this.isReadOnly) this.editProjectModalComponent.open();
  }

  openModalToShareProject(): void {
    if (!this.isReadOnly && !this.guestService.isUserGuest())
      this.shareProjectModalComponent.open();
  }

  deleteStoredProject(): void {
    this.singleUseProject.ownerName = 'Me';
    this.confirmModal.open();
  }

  confirmOrAbortDeletion(answer: boolean): void {
    this.confirmModal.close();
    if (answer) {
      this.guestService.deleteStoredProject();
      this.goBackToHomePage();
    }
  }

  exportProject(dropdown: NgbDropdown): void {
    this.exporting = true;
    this.projectService
      .exportProject(this.projectId)
      .pipe(take(1))
      .subscribe({
        next: (blob: string) => {
          this.exporting = false;
          const data = new Blob([blob]);
          DownloadService.download('project_export.xlsx', data);
          dropdown.close();
        },
        error: () => {
          this.exporting = false;
          dropdown.close();
        },
      });
  }

  // ----------------------- RECHARGE PROJECT -----------------------------

  startSaveTimer(): void {
    if (this.saveTimer == null)
      this.saveTimer = setInterval(
        () => void this.saveProjectIfChanged(),
        5000
      );
  }

  stopSaveTimer(): void {
    if (this.saveTimer != null) {
      clearInterval(this.saveTimer);
      this.saveTimer = null;
    }
  }

  saveProjectIfChanged(): Promise<void> {
    if (
      this.rechargeProjectLocal != null &&
      this.rechargeProjectLocal.editableFieldsHaveChanged(this.rechargeOriginal)
    ) {
      return this.projectService.updateRechargeProject(
        this.rechargeOriginal,
        this.rechargeProjectLocal
      );
    } else {
      return Promise.resolve();
    }
  }

  openModalToChooseMother(): void {
    this.isSelectingMother = true;
    this.openProjectModalComponent.open();
  }

  openModalToChooseDaughter(): void {
    this.isSelectingMother = false;
    this.openProjectModalComponent.open();
  }

  selectProject(project: Project): void {
    this.openProjectModalComponent.close();
    if (this.isSelectingMother) {
      this.projectService
        .loadSingleUseProject(project.id)
        .then((project) => (this.rechargeProjectLocal.motherProject = project))
        .catch((err) => console.error(err));
    } else {
      this.projectService
        .loadSingleUseProject(project.id)
        .then(
          (project) => (this.rechargeProjectLocal.daughterProject = project)
        )
        .catch((err) => console.error(err));
    }
  }

  motherFieldHasError(error: string): boolean {
    return this.getControlHasErrorMessage(
      this.motherForm.controls['nbUses'],
      error
    );
  }

  daughterFieldHasError(error: string): boolean {
    return this.getControlHasErrorMessage(
      this.daughterForm.controls['nbUses'],
      error
    );
  }

  getControlHasErrorMessage(control: AbstractControl, error: string): boolean {
    return control.hasError(error);
  }

  initForms(): void {
    this.motherForm = this.formBuilder.group({
      nbUses: [
        this.rechargeProjectLocal.nbMotherUsed,
        Validators.compose([
          Validators.required,
          Validators.min(this.boundaries.uses.min),
          CustomValidators.patternValidator(/^-?(0|[1-9]\d*)?$/, {
            onlyNumbers: true,
          }),
        ]),
      ],
    });
    FormUtils.updateModelFromFieldValue<number>(
      this.motherForm.controls['nbUses'],
      (v) => (this.rechargeProjectLocal.nbMotherUsed = v)
    );

    this.daughterForm = this.formBuilder.group({
      nbUses: [
        this.rechargeProjectLocal.nbDaughterUsed,
        Validators.compose([
          Validators.required,
          Validators.min(this.boundaries.uses.min),
          CustomValidators.patternValidator(/^-?(0|[1-9]\d*)?$/, {
            onlyNumbers: true,
          }),
        ]),
      ],
    });
    FormUtils.updateModelFromFieldValue<number>(
      this.daughterForm.controls['nbUses'],
      (v) => (this.rechargeProjectLocal.nbDaughterUsed = v)
    );

    this.forms = [this.motherForm, this.daughterForm];
  }

  updateForms(): void {
    this.motherForm.controls['nbUses'].setValue(
      this.rechargeProjectLocal.nbMotherUsed
    );
    this.daughterForm.controls['nbUses'].setValue(
      this.rechargeProjectLocal.nbDaughterUsed
    );
  }

  motherName(): string {
    return this.rechargeProjectLocal.motherProject
      ? this.rechargeProjectLocal.motherProject.name
      : this._translationService.getTranslationValueForLabel(
          'interface_select_file'
        );
  }

  daughterName(): string {
    return this.rechargeProjectLocal.daughterProject
      ? this.rechargeProjectLocal.daughterProject.name
      : this._translationService.getTranslationValueForLabel(
          'interface_select_file'
        );
  }

  referenceScenarioName(project: SingleUseProject): Map<string, string> {
    const referenceScenario = project.referenceScenario;
    assert(referenceScenario !== undefined);
    return new Map<string, string>().set(
      '$1',
      referenceScenario ? referenceScenario.name : ''
    );
  }

  canAssess(): boolean {
    return this.isSingleUse || this.rechargeProjectLocal.isComplete();
  }

  isAssess(): boolean {
    return this.router.url.includes('assess');
  }

  getExportErrorMessageToDisplay(): string {
    return this.guestService.isUserGuest()
      ? this._translationService.getTranslationValueForLabel(
          'interface_pro_version_tooltip'
        )
      : !this.canAssess()
      ? this._translationService.getTranslationValueForLabel(
          'interface_recharge_incomplete'
        )
      : '';
  }
}
