<div class="general-info-container">
  <div class="warning-container" *ngIf="isPrimary">
    <em class="fas fa-info-circle"></em
    >{{ "interface_component_input_warning" | translationPipe }}
  </div>
  <div class="warning-container" *ngIf="isSecondary">
    <em class="fas fa-info-circle"></em
    >{{ "interface_component_input_warning_secondary" | translationPipe }}
  </div>
  <div class="warning-container" *ngIf="isTertiaryBox">
    <em class="fas fa-info-circle"></em
    >{{ "interface_component_input_warning_tertiary_box" | translationPipe }}
  </div>
  <div class="warning-container" *ngIf="isTertiaryPallet">
    <em class="fas fa-info-circle"></em
    >{{ "interface_component_input_warning_tertiary_pallet" | translationPipe }}
  </div>
  <form [formGroup]="componentForm" (keydown.enter)="$event.preventDefault()">
    <div class="general-info-block">
      <h4 class="box-title">
        {{ componentLocal.name }}
        <em
          class="fas fa-trash icon-16"
          *ngIf="!isReadOnly"
          (click)="deleteComponent()"
        ></em>
      </h4>
      <div class="main-actions-container">
        <div class="level-tag">
          <p>{{ "interface_component_general_data" | translationPipe }}</p>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="componentName">{{
                "interface_component_name" | translationPipe
              }}</label>
              <input
                id="componentName"
                class="form-control"
                name="componentName"
                formControlName="componentName"
                type="text"
                maxlength="{{ boundaries.componentName.maxLength }}"
              />
              <validation-messages
                [control]="componentForm.get('componentName')"
                class="form-invalid-message"
              >
              </validation-messages>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="nbComponentInPrimary">{{
                "interface_component_number_in_primary" | translationPipe
              }}</label>
              <input
                id="nbComponentInPrimary"
                class="form-control"
                name="nbComponentInPrimary"
                formControlName="nbComponentInPrimary"
                type="text"
                min="{{ boundaries.nbComponents.min }}"
              />
              <validation-messages
                [control]="componentForm.get('nbComponentInPrimary')"
                class="form-invalid-message"
              ></validation-messages>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="packaging-component-production-country">
                {{ "interface_scenario_prod_zone" | translationPipe }}
                <span
                  matTooltip="{{
                    'helpbox_component_production_zone' | translationPipe
                  }} {{ 'interface_refer_to_doc_details' | translationPipe }} "
                  matTooltipPosition="above"
                  matTooltipClass="custom-tooltip"
                >
                  <em class="fas fa-question-circle"></em>
                </span>
              </label>
              <select
                id="packaging-component-production-country"
                class="select-scenario"
                formControlName="productionCountry"
                class="custom-select form-control"
              >
                <option
                  *ngFor="let country of countries"
                  [value]="country.label"
                  [disabled]="country.label === 'zRoW'"
                >
                  {{ country.valueIntl }}
                </option>
              </select>
              <validation-messages
                [control]="componentForm.get('productionCountry')"
                class="form-invalid-message"
              >
              </validation-messages>
            </div>
          </div>
          <div class="col-md-6">
            <div *ngIf="isTertiary" class="form-group">
              <label for="packaging-component-nb-rotation">{{
                "interface_scenario_nb_rotation" | translationPipe
              }}</label>
              <input
                id="packaging-component-nb-rotation"
                class="form-control"
                formControlName="nbRotation"
                type="number"
                min="{{ boundaries.rotations.min }}"
                max="{{ boundaries.rotations.max }}"
              />
              <validation-messages
                [control]="componentForm.get('nbRotation')"
                class="form-invalid-message"
              >
              </validation-messages>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="general-info-block">
      <div class="main-actions-container">
        <div class="level-tag">
          <p id="materials">
            {{ "interface_component_materials" | translationPipe }}
          </p>
        </div>
        <div class="row">
          <table
            *ngIf="componentOriginal.materials.length > 0"
            class="table"
            aria-describedby="materials"
          >
            <thead>
              <tr>
                <th scope="col">
                  {{ "interface_component_material_type" | translationPipe }}
                </th>
                <th scope="col">
                  {{ "interface_component_material_mass" | translationPipe }}
                </th>
                <th scope="col">
                  {{
                    "interface_component_material_conversion_process"
                      | translationPipe
                  }}
                </th>
                <th scope="col">
                  {{
                    "interface_component_material_recycled_content"
                      | translationPipe
                  }}<span
                    matTooltip="{{ 'helpbox_material_PCR' | translationPipe }} "
                    matTooltipPosition="above"
                    matTooltipClass="custom-tooltip"
                  >
                    <em class="fas fa-question-circle pcr"></em>
                  </span>
                </th>
                <th scope="col"></th>
              </tr>
            </thead>

            <tbody>
              <tr
                *ngFor="let material of componentOriginal.materials"
                app-material-row
                [componentOriginal]="componentOriginal"
                [materialOriginal]="material"
                [materialTypes]="materialTypes"
                [conversionProcessesPerMaterialTypeGroup]="
                  this.conversionProcessesPerMaterialTypeGroup
                "
                (changeMade)="editMaterial($event)"
              ></tr>
            </tbody>
          </table>
        </div>
        <div>
          <span
            *ngIf="!isReadOnly"
            matTooltip="{{
              'interface_max_materials_reached' | translationPipe
            }} "
            matTooltipPosition="above"
            [matTooltipDisabled]="!isMaterialLimitReached()"
            matTooltipClass="custom-tooltip"
          >
            <button
              [disabled]="isMaterialLimitReached()"
              (click)="openModalToCreateMaterial()"
              class="btn btn-secondary"
            >
              <em class="fas fa-plus"></em>
              {{ "interface_component_add_material" | translationPipe }}
            </button>
          </span>
        </div>
      </div>
    </div>
    <div class="general-info-block" *ngIf="!isTertiary">
      <div class="main-actions-container">
        <div class="level-tag">
          <p id="finishing">
            {{ "interface_component_finishing_processes" | translationPipe }}
          </p>
        </div>
        <div class="row">
          <table
            *ngIf="componentOriginal.finishingProcesses.length > 0"
            class="table"
            aria-describedby="finishing"
          >
            <thead>
              <tr>
                <th scope="col">
                  {{
                    "interface_component_finishing_processes_type"
                      | translationPipe
                  }}
                </th>
                <th scope="col">
                  {{
                    "interface_component_finishing_processes_surface"
                      | translationPipe
                  }}
                </th>
                <th scope="col"></th>
              </tr>
            </thead>

            <tbody>
              <tr
                *ngFor="let process of componentOriginal.finishingProcesses"
                app-finishing-process-row
                [componentOriginal]="componentOriginal"
                [finishingProcessTypes]="finishingProcessTypes"
                [processOriginal]="process"
                (changeMade)="editProcess($event)"
              ></tr>
            </tbody>
          </table>
        </div>
        <span
          *ngIf="!isReadOnly"
          matTooltip="{{
            'interface_max_finishing_processes_reached' | translationPipe
          }} "
          matTooltipPosition="above"
          [matTooltipDisabled]="!isFinishingProcessLimitReached()"
          matTooltipClass="custom-tooltip"
        >
          <button
            [disabled]="isFinishingProcessLimitReached()"
            class="btn btn-secondary"
            (click)="openModalToCreateFinishingProcess()"
          >
            <em class="fas fa-plus"></em>
            {{ "interface_component_add_finishing_process" | translationPipe }}
          </button>
        </span>
      </div>
    </div>
    <div class="button-container">
      <span
        *ngIf="!isReadOnly && isPrimary"
        matTooltip="{{ 'interface_max_components_reached' | translationPipe }} "
        matTooltipPosition="above"
        [matTooltipDisabled]="
          !isComponentLimitReached(
            this.componentOriginal.componentLevel.valueOf()
          )
        "
        matTooltipClass="custom-tooltip"
      >
        <button
          [disabled]="
            isComponentLimitReached(
              this.componentOriginal.componentLevel.valueOf()
            )
          "
          class="btn btn-secondary landing-main-action-button"
          (click)="
            createComponentAndNavigate(this.componentOriginal.componentLevel)
          "
        >
          <em class="fas fa-plus"></em>
          {{ "interface_scenario_add_primary_pack" | translationPipe }}
        </button>
      </span>
      <span
        *ngIf="!isReadOnly && isSecondary"
        matTooltip="{{ 'interface_max_components_reached' | translationPipe }} "
        matTooltipPosition="above"
        [matTooltipDisabled]="
          !isComponentLimitReached(
            this.componentOriginal.componentLevel.valueOf()
          )
        "
        matTooltipClass="custom-tooltip"
      >
        <button
          [disabled]="
            isComponentLimitReached(
              this.componentOriginal.componentLevel.valueOf()
            )
          "
          class="btn btn-secondary landing-main-action-button"
          (click)="
            createComponentAndNavigate(this.componentOriginal.componentLevel)
          "
        >
          <em class="fas fa-plus"></em>
          {{ "interface_scenario_add_secondary_pack" | translationPipe }}
        </button>
      </span>
      <span
        *ngIf="!isReadOnly && isTertiaryBox"
        matTooltip="{{ 'interface_max_components_reached' | translationPipe }} "
        matTooltipPosition="above"
        [matTooltipDisabled]="
          !isComponentLimitReached(
            this.componentOriginal.componentLevel.valueOf()
          )
        "
        matTooltipClass="custom-tooltip"
      >
        <button
          [disabled]="
            isComponentLimitReached(
              this.componentOriginal.componentLevel.valueOf()
            )
          "
          class="btn btn-secondary landing-main-action-button"
          (click)="
            createComponentAndNavigate(this.componentOriginal.componentLevel)
          "
        >
          <em class="fas fa-plus"></em>
          {{ "interface_scenario_add_tertiary_pack" | translationPipe }}
        </button>
      </span>
      <span
        *ngIf="!isReadOnly && isTertiaryPallet"
        matTooltip="{{ 'interface_max_components_reached' | translationPipe }} "
        matTooltipPosition="above"
        [matTooltipDisabled]="
          !isComponentLimitReached(
            this.componentOriginal.componentLevel.valueOf()
          )
        "
        matTooltipClass="custom-tooltip"
      >
        <button
          [disabled]="
            isComponentLimitReached(
              this.componentOriginal.componentLevel.valueOf()
            )
          "
          class="btn btn-secondary landing-main-action-button"
          (click)="
            createComponentAndNavigate(this.componentOriginal.componentLevel)
          "
        >
          <em class="fas fa-plus"></em>
          {{
            "interface_scenario_add_tertiary_palletization_pack"
              | translationPipe
          }}
        </button>
      </span>
    </div>
  </form>
</div>

<create-finishing-process-modal
  [finishingProcessTypes]="this.finishingProcessTypes"
  [componentOriginal]="this.componentOriginal"
>
</create-finishing-process-modal>

<create-material-modal
  [materialTypes]="this.materialTypes"
  [conversionProcessesPerMaterialTypeGroup]="
    this.conversionProcessesPerMaterialTypeGroup
  "
  [componentOriginal]="this.componentOriginal"
>
</create-material-modal>

<app-confirm-modal
  [title]="'interface_component_deletion_title' | translationPipe"
  [content]="
    ('interface_component_deletion_body' | translationPipe) +
    this.componentOriginal.name +
    ' ?'
  "
  (answer)="confirmOrAbortComponentDeletion($event)"
>
</app-confirm-modal>
