/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { EMPTY, Observable, throwError } from 'rxjs';
import { LoginService } from '@/app/services/login/login.service';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { ProjectLock } from '@/app/model/packaging-model/project/project-lock';
import { plainToClass } from 'class-transformer';
import { ProjectLockService } from '@/app/services/project-lock.service';
import { ErrorService } from '@/app/services/error.service';
import { LoadingService } from '@/app/services/loading.service';
import { ToastService } from '@/app/services/toast.service';

@Injectable()
export class GlobalInterceptor implements HttpInterceptor {
  urls: Array<string> = [
    '/global/',
    '/users/me',
    '/translations/',
    '/assessments/',
  ];

  constructor(
    private loginService: LoginService,
    private projectLockService: ProjectLockService,
    private errorService: ErrorService,
    private loadingService: LoadingService,
    private toastService: ToastService
  ) {}

  lookForUrlsAppearance(request: HttpRequest<any>): number {
    let urlAppearance = 0;
    this.urls.forEach((url) => {
      urlAppearance += request.url.search(url);
    });

    return urlAppearance;
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.updateIsSaving(true);
    let event: Observable<HttpEvent<any>>;

    if (this.lookForUrlsAppearance(request) > 0) {
      event = next.handle(request);
    } else {
      if (this.loginService.signedIn && this.loginService.isGuestUser()) {
        void this.delay(200).then(() => {
          this.updateIsSaving(false);
        });
        return EMPTY;
      } else {
        event = next.handle(request).pipe(
          catchError((err: HttpErrorResponse) => {
            if (
              err.error &&
              err.error.type &&
              err.error.type === 'ProjectLockException'
            ) {
              const projectLock = plainToClass(ProjectLock, err.error.message);
              this.projectLockService.projectLockException$.next(projectLock);
              return throwError(projectLock);
            }
            return throwError(err);
          })
        );
      }
    }
    void this.delay(200).then(() => {
      this.updateIsSaving(false);
    });

    return event.pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status == 401) {
          void this.loginService.logout();
        } else if (err.status == 403 || err.status == 404) {
          if (err.error?.type == 'CompanyDisabledException') {
            this.errorService.redirectToCompanyDisabledPage();
            return EMPTY;
          } else if (err.error?.type !== 'ImpossibleDeletionException')
            this.errorService.redirectToErrorPage();
        } else if (
          err.status == 400 &&
          err.error?.type === 'InvalidScenarioException'
        ) {
          return throwError(err);
        } else if (
          err.status == 400 &&
          err.error?.type === 'UsernameExistsException'
        ) {
          this.toastService.show(
            'Error while creating user',
            'An error occured during the creation : ' +
              err.error?.message +
              'Please contact our support at tool@open-spice.com',
            { classname: 'bg-danger', delay: 10000 }
          );
        } else if (err.url?.endsWith('export')) {
          this.toastService.show(
            'Error while exporting',
            'An error occured during your export : ' + err.message,
            { classname: 'bg-danger', delay: 10000 }
          );
        }

        return throwError(err);
      })
    );
  }

  private updateIsSaving(isSaving: boolean) {
    this.loadingService.setIsSaving(isSaving);
  }

  delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
}
