<div
  class="general-info-container"
  *ngIf="
    globalService.listsReady &&
    !computing &&
    !errorsWhileComputing &&
    !error500 &&
    !isProjectIncomplete
  "
>
  <div
    class="warning-container"
    *ngIf="this.selectedScenariosForAssess.length > 0"
  >
    <em class="fas fa-info-circle"></em
    >{{ "interface_result_warning" | translationPipe }}
  </div>
  <div
    class="general-info-block"
    [hidden]="this.selectedScenariosForAssess.length === 0"
  >
    <div class="main-actions-container align-left">
      <div class="flex-header">
        <h4>{{ "interface_assess_packaging_overview" | translationPipe }}</h4>
        <div>
          <em class="fas fa-star is-reference"></em>
          {{ "interface_scenario_reference_title" | translationPipe }}
          <em class="fas fa-check-circle best-scenario legend"></em>
          {{ "interface_assess_best_scenario" | translationPipe }}
        </div>
      </div>
      <div class="level-tag">
        <p>
          {{ "interface_assess_environmental_single_score" | translationPipe }}
        </p>
      </div>
      <div class="explanations">
        {{ "interface_explanations_single_score" | translationPipe }}
        <a (click)="goToExplanationPage()">{{
          "interface_explanation_this_page" | translationPipe
        }}</a>
      </div>
      <app-scenarios-for-assessment
        *ngIf="isSingleUse"
        [referenceScenarioId]="this.project.referenceScenarioId"
        [scenarioResults]="scenarioResults"
      ></app-scenarios-for-assessment>
      <app-recharge-project-for-assessment
        *ngIf="!isSingleUse"
        [scenarioResults]="scenarioResults"
      >
      </app-recharge-project-for-assessment>
      <div class="row scenario-infos">
        <div class="col-md-12">
          <div class="form-group">
            <span class="graph-title">{{
              "interface_assess_kpi_breakdown" | translationPipe
            }}</span>
            <div class="legend explanations">
              {{ "interface_assess_click_legend" | translationPipe }}
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <div class="graph_container">
              <canvas
                id="packagingKPIChart_{{
                  isSingleUse ? project.id : rechargeProject.id
                }}"
              ></canvas>
            </div>
          </div>
        </div>
      </div>
      <div class="level-tag">
        <p>{{ "interface_assess_absolute_results" | translationPipe }}</p>
      </div>
      <div class="explanations">
        {{ "interface_explanations_absolute_results" | translationPipe }}
        <a (click)="goToExplanationPage()">{{
          "interface_explanation_this_page" | translationPipe
        }}</a>
      </div>
      <div class="table-container">
        <table class="table table-hover table-striped">
          <thead>
            <th width="15%" style="vertical-align: middle" scope="col">
              {{ "interface_scenario" | translationPipe }}
              <app-sort-toggle
                [sorting]="resultsPerIndicatorSorting"
                sortedBy="scenarioName"
                (toggle)="launchSort()"
              ></app-sort-toggle>
            </th>
            <th width="28.33%" scope="col">
              <ng-select
                class="select-scenarios"
                [items]="$any(indicatorsAsList())"
                bindLabel="value"
                [trackByFn]="trackByFn"
                [multiple]="false"
                [(ngModel)]="firstSelectedIndic"
                (change)="updateSelectedIndicators()"
                [closeOnSelect]="true"
                [clearable]="false"
              >
              </ng-select>
              <app-sort-toggle
                [sorting]="resultsPerIndicatorSorting"
                sortedBy="resultIndicator1"
                (toggle)="launchSort()"
              ></app-sort-toggle>
            </th>
            <th width="28.33%" scope="col">
              <ng-select
                class="select-scenarios"
                [items]="$any(indicatorsAsList())"
                bindLabel="value"
                [trackByFn]="trackByFn"
                [multiple]="false"
                [(ngModel)]="secondSelectedIndic"
                (change)="updateSelectedIndicators()"
                [closeOnSelect]="true"
                [clearable]="false"
              >
              </ng-select>
              <app-sort-toggle
                [sorting]="resultsPerIndicatorSorting"
                sortedBy="resultIndicator2"
                (toggle)="launchSort()"
              ></app-sort-toggle>
            </th>
            <th width="28.33%" scope="col">
              <ng-select
                class="select-scenarios"
                [items]="$any(indicatorsAsList())"
                bindLabel="value"
                [trackByFn]="trackByFn"
                [multiple]="false"
                [(ngModel)]="thirdSelectedIndic"
                (change)="updateSelectedIndicators()"
                [closeOnSelect]="true"
                [clearable]="false"
              >
              </ng-select>
              <app-sort-toggle
                [sorting]="resultsPerIndicatorSorting"
                sortedBy="resultIndicator3"
                (toggle)="launchSort()"
              ></app-sort-toggle>
            </th>
          </thead>
          <tbody>
            <ng-template ngFor [ngForOf]="resultsPerIndicators" let-i="index">
              <tr>
                <td>
                  {{ resultsPerIndicators[i].scenarioName }}
                  <em
                    *ngIf="
                      isSingleUse &&
                      resultsPerIndicators[i].scenarioId ===
                        this.project.referenceScenarioId &&
                      !guestService.isUserGuest()
                    "
                    class="fas fa-star is-reference"
                  ></em>
                </td>
                <td>
                  {{
                    getFormattedResult(resultsPerIndicators[i].resultIndicator1)
                  }}
                  {{ resultsPerIndicators[i].indicator1.displayUnit }}
                  <em
                    *ngIf="
                      isSingleUse &&
                      resultsPerIndicators[i].scenarioId === indic1BestScenario
                    "
                    class="fas fa-check-circle best-scenario"
                  ></em>
                  <span *ngIf="isSingleUse">
                    <span
                      *ngIf="
                        selectedScenariosForAssess.length > 0 &&
                        resultsPerIndicators[i].scenarioId !==
                          project.referenceScenarioId &&
                        getIndicatorDifference(
                          resultsPerIndicators[i].scenarioId,
                          resultsPerIndicators[i].indicator1
                        ) < 0
                      "
                      class="difference-tag"
                      [ngClass]="{
                        'negative-difference':
                          getIndicatorDifference(
                            resultsPerIndicators[i].scenarioId,
                            resultsPerIndicators[i].indicator1
                          ) <= -10,
                        'nonsignificant-difference':
                          getIndicatorDifference(
                            resultsPerIndicators[i].scenarioId,
                            resultsPerIndicators[i].indicator1
                          ) > -10
                      }"
                    >
                      <span
                        matTooltip="{{
                          (getIndicatorDifference(
                            resultsPerIndicators[i].scenarioId,
                            resultsPerIndicators[i].indicator1
                          ) > -10
                            ? 'interface_assess_nonsignificant_difference'
                            : 'interface_assess_better_than_reference'
                          ) | translationPipe
                        }} "
                        matTooltipPosition="above"
                        matTooltipClass="custom-tooltip"
                      >
                        <p>
                          <em class="fa fa-arrow-down mright-5"></em>
                          {{
                            getIndicatorDifference(
                              resultsPerIndicators[i].scenarioId,
                              resultsPerIndicators[i].indicator1
                            ).toFixed(0)
                          }}%
                        </p>
                      </span>
                    </span>
                    <span
                      *ngIf="
                        selectedScenariosForAssess.length > 0 &&
                        resultsPerIndicators[i].scenarioId !==
                          project.referenceScenarioId &&
                        getIndicatorDifference(
                          resultsPerIndicators[i].scenarioId,
                          resultsPerIndicators[i].indicator1
                        ) > 0
                      "
                      class="difference-tag"
                      [ngClass]="{
                        'positive-difference':
                          getIndicatorDifference(
                            resultsPerIndicators[i].scenarioId,
                            resultsPerIndicators[i].indicator1
                          ) >= 10,
                        'nonsignificant-difference':
                          getIndicatorDifference(
                            resultsPerIndicators[i].scenarioId,
                            resultsPerIndicators[i].indicator1
                          ) < 10
                      }"
                    >
                      <span
                        matTooltip="{{
                          (getIndicatorDifference(
                            resultsPerIndicators[i].scenarioId,
                            resultsPerIndicators[i].indicator1
                          ) < 10
                            ? 'interface_assess_nonsignificant_difference'
                            : 'interface_assess_worse_than_reference'
                          ) | translationPipe
                        }} "
                        matTooltipPosition="above"
                        matTooltipClass="custom-tooltip"
                      >
                        <p>
                          <em class="fa fa-arrow-up mright-5"></em>
                          {{
                            getIndicatorDifference(
                              resultsPerIndicators[i].scenarioId,
                              resultsPerIndicators[i].indicator1
                            ).toFixed(0)
                          }}%
                        </p>
                      </span>
                    </span>
                  </span>
                </td>
                <td>
                  {{
                    getFormattedResult(resultsPerIndicators[i].resultIndicator2)
                  }}
                  {{ resultsPerIndicators[i].indicator2.displayUnit }}
                  <em
                    *ngIf="
                      isSingleUse &&
                      resultsPerIndicators[i].scenarioId === indic2BestScenario
                    "
                    class="fas fa-check-circle best-scenario"
                  ></em>
                  <span *ngIf="isSingleUse">
                    <span
                      *ngIf="
                        selectedScenariosForAssess.length > 0 &&
                        resultsPerIndicators[i].scenarioId !==
                          project.referenceScenarioId &&
                        getIndicatorDifference(
                          resultsPerIndicators[i].scenarioId,
                          resultsPerIndicators[i].indicator2
                        ) < 0
                      "
                      class="difference-tag"
                      [ngClass]="{
                        'negative-difference':
                          getIndicatorDifference(
                            resultsPerIndicators[i].scenarioId,
                            resultsPerIndicators[i].indicator2
                          ) <= -10,
                        'nonsignificant-difference':
                          getIndicatorDifference(
                            resultsPerIndicators[i].scenarioId,
                            resultsPerIndicators[i].indicator2
                          ) > -10
                      }"
                    >
                      <span
                        matTooltip="{{
                          (getIndicatorDifference(
                            resultsPerIndicators[i].scenarioId,
                            resultsPerIndicators[i].indicator2
                          ) > -10
                            ? 'interface_assess_nonsignificant_difference'
                            : 'interface_assess_better_than_reference'
                          ) | translationPipe
                        }} "
                        matTooltipPosition="above"
                        matTooltipClass="custom-tooltip"
                      >
                        <p>
                          <em class="fa fa-arrow-down mright-5"></em>
                          {{
                            getIndicatorDifference(
                              resultsPerIndicators[i].scenarioId,
                              resultsPerIndicators[i].indicator2
                            ).toFixed(0)
                          }}%
                        </p>
                      </span>
                    </span>
                    <span
                      *ngIf="
                        selectedScenariosForAssess.length > 0 &&
                        resultsPerIndicators[i].scenarioId !==
                          project.referenceScenarioId &&
                        getIndicatorDifference(
                          resultsPerIndicators[i].scenarioId,
                          resultsPerIndicators[i].indicator2
                        ) > 0
                      "
                      class="difference-tag"
                      [ngClass]="{
                        'positive-difference':
                          getIndicatorDifference(
                            resultsPerIndicators[i].scenarioId,
                            resultsPerIndicators[i].indicator2
                          ) >= 10,
                        'nonsignificant-difference':
                          getIndicatorDifference(
                            resultsPerIndicators[i].scenarioId,
                            resultsPerIndicators[i].indicator2
                          ) < 10
                      }"
                    >
                      <span
                        matTooltip="{{
                          (getIndicatorDifference(
                            resultsPerIndicators[i].scenarioId,
                            resultsPerIndicators[i].indicator2
                          ) < 10
                            ? 'interface_assess_nonsignificant_difference'
                            : 'interface_assess_worse_than_reference'
                          ) | translationPipe
                        }} "
                        matTooltipPosition="above"
                        matTooltipClass="custom-tooltip"
                      >
                        <p>
                          <em class="fa fa-arrow-up mright-5"></em>
                          {{
                            getIndicatorDifference(
                              resultsPerIndicators[i].scenarioId,
                              resultsPerIndicators[i].indicator2
                            ).toFixed(0)
                          }}%
                        </p>
                      </span>
                    </span>
                  </span>
                </td>
                <td>
                  {{
                    getFormattedResult(resultsPerIndicators[i].resultIndicator3)
                  }}
                  {{ resultsPerIndicators[i].indicator3.displayUnit }}
                  <em
                    *ngIf="
                      isSingleUse &&
                      resultsPerIndicators[i].scenarioId === indic3BestScenario
                    "
                    class="fas fa-check-circle best-scenario"
                  ></em>
                  <span *ngIf="isSingleUse">
                    <span
                      *ngIf="
                        selectedScenariosForAssess.length > 0 &&
                        resultsPerIndicators[i].scenarioId !==
                          project.referenceScenarioId &&
                        getIndicatorDifference(
                          resultsPerIndicators[i].scenarioId,
                          resultsPerIndicators[i].indicator3
                        ) < 0
                      "
                      class="difference-tag"
                      [ngClass]="{
                        'negative-difference':
                          getIndicatorDifference(
                            resultsPerIndicators[i].scenarioId,
                            resultsPerIndicators[i].indicator3
                          ) <= -10,
                        'nonsignificant-difference':
                          getIndicatorDifference(
                            resultsPerIndicators[i].scenarioId,
                            resultsPerIndicators[i].indicator3
                          ) > -10
                      }"
                    >
                      <span
                        matTooltip="{{
                          (getIndicatorDifference(
                            resultsPerIndicators[i].scenarioId,
                            resultsPerIndicators[i].indicator3
                          ) > -10
                            ? 'interface_assess_nonsignificant_difference'
                            : 'interface_assess_better_than_reference'
                          ) | translationPipe
                        }} "
                        matTooltipPosition="above"
                        matTooltipClass="custom-tooltip"
                      >
                        <p>
                          <em class="fa fa-arrow-down mright-5"></em>
                          {{
                            getIndicatorDifference(
                              resultsPerIndicators[i].scenarioId,
                              resultsPerIndicators[i].indicator3
                            ).toFixed(0)
                          }}%
                        </p>
                      </span>
                    </span>
                    <span
                      *ngIf="
                        selectedScenariosForAssess.length > 0 &&
                        resultsPerIndicators[i].scenarioId !==
                          project.referenceScenarioId &&
                        getIndicatorDifference(
                          resultsPerIndicators[i].scenarioId,
                          resultsPerIndicators[i].indicator3
                        ) > 0
                      "
                      class="difference-tag"
                      [ngClass]="{
                        'positive-difference':
                          getIndicatorDifference(
                            resultsPerIndicators[i].scenarioId,
                            resultsPerIndicators[i].indicator3
                          ) >= 10,
                        'nonsignificant-difference':
                          getIndicatorDifference(
                            resultsPerIndicators[i].scenarioId,
                            resultsPerIndicators[i].indicator3
                          ) < 10
                      }"
                    >
                      <span
                        matTooltip="{{
                          (getIndicatorDifference(
                            resultsPerIndicators[i].scenarioId,
                            resultsPerIndicators[i].indicator3
                          ) < 10
                            ? 'interface_assess_nonsignificant_difference'
                            : 'interface_assess_worse_than_reference'
                          ) | translationPipe
                        }} "
                        matTooltipPosition="above"
                        matTooltipClass="custom-tooltip"
                      >
                        <p>
                          <em class="fa fa-arrow-up mright-5"></em>
                          {{
                            getIndicatorDifference(
                              resultsPerIndicators[i].scenarioId,
                              resultsPerIndicators[i].indicator3
                            ).toFixed(0)
                          }}%
                        </p>
                      </span>
                    </span>
                  </span>
                </td>
              </tr>
            </ng-template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div
    class="general-info-block"
    *ngIf="this.selectedScenariosForAssess.length > 0"
  >
    <div class="main-actions-container align-left">
      <h4>{{ "interface_assess_packaging_deep_dive" | translationPipe }}</h4>
      <div class="flex-indicator-select">
        <p class="p-no-margin-bottom">
          {{ "interface_assess_display_values_for" | translationPipe }} :
        </p>
        <mat-radio-group
          value="score"
          (change)="singleScoreOrIndicatorSelectionChanged()"
          [(ngModel)]="deepDiveRadioGroup"
        >
          <mat-radio-button #ssRadio value="score">{{
            "interface_assess_environmental_single_score" | translationPipe
          }}</mat-radio-button>
          <mat-radio-button value="indicator"
            >{{ "interface_assess_indicator_title" | translationPipe }} :
          </mat-radio-button>
        </mat-radio-group>
        <ng-select
          [disabled]="ssRadio.checked"
          class="indicator-select"
          [items]="$any(indicatorsAsList())"
          bindLabel="value"
          [trackByFn]="trackByFn"
          [multiple]="false"
          [(ngModel)]="deepDiveSelectedIndic"
          (change)="singleScoreOrIndicatorSelectionChanged()"
          [closeOnSelect]="true"
          [clearable]="false"
        ></ng-select>
      </div>
      <div *ngIf="!isSingleUse">
        <div class="row scenario-infos">
          <div class="col-md-12">
            <div class="form-group">
              <span class="graph-title">
                {{ "interface_assess_recharge_breakdown" | translationPipe }}
                ({{
                  this.deepDiveRadioGroup === "score"
                    ? ("interface_assess_environmental_single_score"
                      | translationPipe)
                    : this.deepDiveSelectedIndic.value
                }})</span
              >
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <div class="graph_container">
                <canvas
                  id="rechargeBreakdownChart_{{ rechargeProject.id }}"
                ></canvas>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row scenario-infos">
        <div class="col-md-12">
          <div class="form-group">
            <span class="graph-title">
              {{ "interface_assess_packaging_breakdown" | translationPipe }}
              ({{
                this.deepDiveRadioGroup === "score"
                  ? ("interface_assess_environmental_single_score"
                    | translationPipe)
                  : this.deepDiveSelectedIndic.value
              }})</span
            >
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <div class="graph_container">
              <canvas
                id="packagingBreakdownChart_{{
                  isSingleUse ? project.id : rechargeProject.id
                }}"
              ></canvas>
            </div>
          </div>
        </div>
      </div>
      <div class="row scenario-infos">
        <div class="col-md-12">
          <div class="form-group">
            <span class="graph-title"
              >{{ "interface_assess_lcaStep_breakdown" | translationPipe }} ({{
                this.deepDiveRadioGroup === "score"
                  ? ("interface_assess_environmental_single_score"
                    | translationPipe)
                  : this.deepDiveSelectedIndic.value
              }})</span
            >
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <div class="graph_container">
              <canvas
                id="packagingLCAStepChart_{{
                  isSingleUse ? project.id : rechargeProject.id
                }}"
              ></canvas>
            </div>
          </div>
        </div>
      </div>
      <div class="row scenario-infos">
        <div class="col-md-12">
          <div class="form-group">
            <span class="graph-title"
              >{{
                "interface_assess_component_breakdown" | translationPipe
              }}
              ({{
                this.deepDiveRadioGroup === "score"
                  ? ("interface_assess_environmental_single_score"
                    | translationPipe)
                  : this.deepDiveSelectedIndic.value
              }})</span
            >
            <div class="explanations legend">
              {{ "interface_assess_click_component" | translationPipe }}
            </div>
          </div>
        </div>
      </div>

      <div
        class="row component-graph-container"
        *ngFor="let scenario of getScenariosForComponentCharts()"
      >
        <div class="col-md-12 pointer" (click)="hideOrShow(scenario?.id)">
          <div class="form-group">
            <span class="graph-sub-title">{{
              isSingleUse
                ? scenario?.name
                : rechargeProject.daughterProject.referenceScenarioId ===
                  scenario?.id
                ? "DAUGHTER - " + rechargeProject.daughterProject.name
                : "MOTHER - " + rechargeProject.motherProject.name
            }}</span>
            <i
              class="fas fa-chevron-down float-right"
              *ngIf="!isGraphShown(scenario?.id)"
            ></i>
            <i
              class="fas fa-chevron-up float-right"
              *ngIf="isGraphShown(scenario?.id)"
            ></i>
          </div>
        </div>
        <div class="col-md-12" id="collapse_{{ scenario?.id }}">
          <div class="form-group">
            <div class="graph_container">
              <canvas id="componentChart_{{ scenario?.id }}"></canvas>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="general-info-block"
    *ngIf="this.selectedScenariosForAssess.length === 0"
  >
    <div class="main-actions-container align-left">
      {{ "interface_assess_select_scenario" | translationPipe }}
    </div>
  </div>
</div>
<div *ngIf="computing && !isProjectIncomplete" class="spinner-container">
  <div class="general-info-block">
    <div class="main-actions-container">
      <div class="row">
        <div class="col-md-12 computing-message">
          {{ "interface_assess_computing" | translationPipe }}
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 mt-10">
          <spinner *ngIf="computing" [color]="'blue'"></spinner>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="general-info-container" *ngIf="errorsWhileComputing">
  <div class="general-info-block">
    <div class="main-actions-container">
      <div class="row">
        <div class="col-md-12 error-message">
          <i class="fas fa-exclamation-circle red"></i>
          {{ "interface_assess_error_results" | translationPipe }}
        </div>
        <div class="col-md-12">
          {{ this.errors?.message || this.errors }}
        </div>
      </div>
    </div>
  </div>
</div>

<div class="general-info-container" *ngIf="error500">
  <div class="general-info-block">
    <div class="main-actions-container">
      <div class="row">
        <div class="col-md-12 error-message">
          <i class="fas fa-exclamation-circle red"></i>
          {{ "interface_error500" | translationPipe
          }}{{ "interface_quantis_address" | translationPipe }}.
        </div>
      </div>
    </div>
  </div>
</div>

<div class="general-info-container" *ngIf="isProjectIncomplete">
  <div class="general-info-block">
    <div class="main-actions-container">
      <div class="row">
        <div class="col-md-12 error-message">
          <i class="fas fa-exclamation-circle red"></i>
          {{ "interface_assess_error_interface_incomplete" | translationPipe }}
        </div>
        <div class="col-md-12">
          <ul>
            <li class="list-inside">
              {{ rechargeProject.name }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<app-breakdown-by-material-modal></app-breakdown-by-material-modal>
