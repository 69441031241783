import { Injectable, EventEmitter } from '@angular/core';
import { LoginApiService } from 'src/app/api/login/login-api.service';
import { User } from '@/app/model/user/user';
import { JWTUser } from '@/app/model/user/jwtuser';
import { Router } from '@angular/router';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { AmplifyService } from 'aws-amplify-angular';

import { FeatureService } from 'src/app/services/featureService/feature.service';
import { UsualFeaturesAndRules } from 'src/app/services/featureService/usual-features-and-rules';
import { MatchingRuleEffect } from 'src/app/services/featureService/matching-rule-effect.enum';
import { LOCALE_ID, Inject } from '@angular/core';
import { authorities, Authority, Role } from '@/app/model/user/authority';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  jwtUser: JWTUser | null = null;
  currentUser: User | null = null;
  signedIn = false;

  activationDispatcher: EventEmitter<any> = new EventEmitter();

  userWasGuest = false;

  constructor(
    private loginApi: LoginApiService,
    private router: Router,
    private _featureService: FeatureService,
    private amplifyService: AmplifyService,
    @Inject(LOCALE_ID) private locale: string
  ) {
    this.amplifyService.authStateChange$
      .pipe(
        switchMap(async (authState) => {
          this.signedIn = authState.state === 'signedIn';

          if (this.signedIn) {
            this._featureService.changeRuleMatchingEffect(
              UsualFeaturesAndRules.LOGGED_FEATURE_RULE,
              MatchingRuleEffect.ALLOW_FEATURE
            );

            this.jwtUser = await this.getCurrentUserOnIdentityProvider();

            if (!this.isGuestUser()) {
              this.currentUser = await this.getCurrentUserOnRessourceServer();
            }
          } else {
            this.currentUser = null;
            this.jwtUser = null;
            this._featureService.changeRuleMatchingEffect(
              UsualFeaturesAndRules.LOGGED_FEATURE_RULE,
              MatchingRuleEffect.DENY_FEATURE,
              'Logged out'
            );
          }
        })
      )
      .subscribe();
  }

  getCurrentUserFullName(): string {
    return this.jwtUser ? this.jwtUser.fullname : '';
  }

  isUser() {
    return this.hasRole(Role.USER);
  }

  isGuestUser() {
    return (
      this.signedIn &&
      this.jwtUser != null &&
      this.hasRole(Role.GUEST_USER) &&
      this.jwtUser.authorities.length == 1
    );
  }

  isAdminOrSuperAdmin() {
    return this.isAdmin() || this.isSuperAdmin();
  }

  isAdmin() {
    return this.hasRole(Role.ADMIN);
  }

  isSuperAdmin(): boolean {
    return this.hasRole(Role.SUPER_ADMIN);
  }

  hasRole(role: Role): boolean {
    return this.jwtUser != null && this.jwtUser.authorities.includes(role);
  }

  getHigherRoleLabel(): string {
    let result: Authority;

    if (this.jwtUser != null && this.jwtUser.authorities) {
      for (const authority of authorities) {
        if (
          this.hasRole(authority.role) &&
          // @ts-ignore
          (!result || result.priority < authority.priority)
        ) {
          result = authority;
        }
      }
    }

    // @ts-ignore
    return result ? result.label : '';
  }

  getCurrentUserOnRessourceServer(): Promise<User> {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.loginApi.getCurrentUserOnRessoureServer();
  }

  getCurrentUserOnIdentityProvider(): Promise<JWTUser> {
    return this.loginApi
      .getCurrentUserOnIdentityProvider()
      .then((user) => JWTUser.from(user));
  }

  completeNewPassword(user: CognitoUser, password: string): Promise<any> {
    return this.loginApi.completeNewPassword(user, password, {
      locale: this.locale,
    });
  }

  login(username: string, password: string): Promise<any> {
    return this.loginApi.login(username, password);
  }

  async logout() {
    this.userWasGuest = this.signedIn ? this.isGuestUser() : false;
    await this.loginApi.logout();
    void this.router.navigate(['/']);
  }
}
