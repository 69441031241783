import { HttpHeaders } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ApiUtils {
  static readonly BASE_PATH = environment.apiUrl + '/';

  static readonly CONTENT_TYPE_JSON_HEADER = new HttpHeaders({
    'Content-Type': 'application/json',
  });

  static readonly HTTP_OPTIONS = {
    headers: ApiUtils.CONTENT_TYPE_JSON_HEADER,
  };

  static readonly HTTP_OPTIONS_FOR_DOWNLOAD_BLOB = {
    headers: ApiUtils.CONTENT_TYPE_JSON_HEADER,
    responseType: 'blob' as 'json',
    observe: 'response' as 'body',
  };
}
