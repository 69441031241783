import { ActivationRule } from './activation-rule';
import { FeatureService } from './feature.service';
import { Feature } from './feature';

export class UsualFeaturesAndRules {
  static ONLINE_FEATURE_PART = 'online';
  static LOGGED_FEATURE_PART = 'logged';
  static ADMIN_FEATURE_PART = 'admin';

  static ONLINE_FEATURE_RULE: ActivationRule = ActivationRule.allowFeature([
    UsualFeaturesAndRules.ONLINE_FEATURE_PART,
  ]);
  static LOGGED_FEATURE_RULE: ActivationRule = ActivationRule.denyFeature(
    [
      UsualFeaturesAndRules.ONLINE_FEATURE_PART,
      UsualFeaturesAndRules.LOGGED_FEATURE_PART,
    ],
    'Not logged in'
  );
  static ADMIN_FEATURE_RULE: ActivationRule = ActivationRule.denyFeature(
    [
      UsualFeaturesAndRules.ONLINE_FEATURE_PART,
      UsualFeaturesAndRules.LOGGED_FEATURE_PART,
      UsualFeaturesAndRules.ADMIN_FEATURE_PART,
    ],
    'Not admin'
  );

  static ONLINE_FEATURE: Feature = new Feature([
    UsualFeaturesAndRules.ONLINE_FEATURE_PART,
  ]);
  static LOGGED_FEATURE: Feature = new Feature([
    UsualFeaturesAndRules.ONLINE_FEATURE_PART,
    UsualFeaturesAndRules.LOGGED_FEATURE_PART,
  ]);
  static ADMIN_FEATURE: Feature = new Feature([
    UsualFeaturesAndRules.ONLINE_FEATURE_PART,
    UsualFeaturesAndRules.LOGGED_FEATURE_PART,
    UsualFeaturesAndRules.ADMIN_FEATURE_PART,
  ]);

  static registerUsualFeaturesAndRules(featureService: FeatureService) {
    featureService.declareRule(UsualFeaturesAndRules.ONLINE_FEATURE_RULE);
    featureService.declareRule(UsualFeaturesAndRules.LOGGED_FEATURE_RULE);
    featureService.declareRule(UsualFeaturesAndRules.ADMIN_FEATURE_RULE);

    featureService.declareFeature(UsualFeaturesAndRules.ONLINE_FEATURE);
    featureService.declareFeature(UsualFeaturesAndRules.LOGGED_FEATURE);
    featureService.declareFeature(UsualFeaturesAndRules.ADMIN_FEATURE);

    featureService.initialize();
  }
}
