import { Component } from '@angular/core';
import { LoginService } from 'src/app/services/login/login.service';

@Component({
  selector: 'app-welcome-page',
  templateUrl: './welcome-page.component.html',
  styleUrls: ['./welcome-page.component.css'],
})
export class WelcomePageComponent {
  get userLogged(): boolean {
    return this._loginService.signedIn;
  }

  constructor(private _loginService: LoginService) {}
}
