import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiUtils } from 'src/app/api/api-utils';

@Injectable({
  providedIn: 'root',
})
export class TranslationApiService {
  http: HttpClient;

  constructor(http: HttpClient) {
    this.http = http;
  }

  async getAllTranslationsForLocaleId(localeId: number) {
    const url = ApiUtils.BASE_PATH + 'translations/' + localeId.toString();
    return this.http
      .get(url)
      .toPromise()
      .catch((msg) => console.log(msg));
  }
}
