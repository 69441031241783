import { Collaborator } from '@/app/model/collaborator/collaborator';

export type SelectableCollaboratorFields = {
  selected: boolean;
};

export type NormalizedCollaboratorFields = {
  normalizedFirstname: string;
  normalizedLastname: string;
  normalizedEmail: string;
  normalizedBrand: string;
};

export type SelectableCollaborator = Collaborator &
  SelectableCollaboratorFields;
export type NormalizedCollaborator = Collaborator &
  NormalizedCollaboratorFields;
export type NormalizedSelectableCollaborator = NormalizedCollaborator &
  SelectableCollaborator;

export const collaboratorNormalizedFieldsMapping: Array<{
  fieldName: keyof Collaborator;
  normalizedFieldName: keyof NormalizedCollaboratorFields;
}> = [
  {
    fieldName: 'firstname',
    normalizedFieldName: 'normalizedFirstname',
  },
  {
    fieldName: 'lastname',
    normalizedFieldName: 'normalizedLastname',
  },
  {
    fieldName: 'email',
    normalizedFieldName: 'normalizedEmail',
  },
  {
    fieldName: 'brand',
    normalizedFieldName: 'normalizedBrand',
  },
];

export const collaboratorNormalizedFields =
  collaboratorNormalizedFieldsMapping.map(
    (normalizedField) => normalizedField.normalizedFieldName
  );
