import { Injectable } from '@angular/core';
import { FinishingProcessApiService } from '@/app/api/finishing-process-api.service';
import { FinishingProcess } from '../model/packaging-model/finishing-process';
import { PkgComponent } from '../model/packaging-model/component/pkg-component';
import { GuestService } from '@/app/services/guest.service';
import { ProjectService } from '@/app/services/project.service';

@Injectable({
  providedIn: 'root',
})
export class FinishingProcessService {
  constructor(
    private finishingProcessApiService: FinishingProcessApiService,
    private guestService: GuestService,
    private projectService: ProjectService
  ) {}

  finishingProcessHelp: Map<string, string> = new Map([
    ['finishing_process_acidetching', 'helpbox_finishing_process_acidetching'],
    [
      'finishing_process_acidetching_glass',
      'helpbox_finishing_process_acidetching_glass',
    ],
    ['finishing_process_anodising', 'helpbox_finishing_process_anodising'],
    [
      'finishing_process_electroplating_2',
      'helpbox_finishing_process_electroplating_2',
    ],
    [
      'finishing_process_electroplating_3',
      'helpbox_finishing_process_electroplating_3',
    ],
    [
      'finishing_process_glasslacquering',
      'helpbox_finishing_process_glasslacquering',
    ],
    ['finishing_process_hotstamping', 'helpbox_finishing_process_hotstamping'],
    [
      'finishing_process_nickelelectroplating',
      'helpbox_finishing_process_nickelelectroplating',
    ],
    ['finishing_process_offset', 'helpbox_finishing_process_offset'],
    [
      'finishing_process_plasticcoating',
      'helpbox_finishing_process_plasticcoating',
    ],
    ['finishing_process_PVD', 'helpbox_finishing_process_PVD'],
    ['finishing_process_PVD_glass', 'helpbox_finishing_process_PVD_glass'],
    ['finishing_process_PVD_pieces', 'helpbox_finishing_process_PVD_pieces'],
    [
      'finishing_process_solventplasticcoating',
      'helpbox_finishing_process_solventplasticcoating',
    ],
    ['finishing_process_sputtering', 'helpbox_finishing_process_sputtering'],
    [
      'finishing_process_sputtering_pieces',
      'helpbox_finishing_process_sputtering_pieces',
    ],
  ]);

  async createFinishingProcess(
    componentOriginal: PkgComponent,
    processData: FinishingProcess
  ): Promise<FinishingProcess> {
    let response: FinishingProcess;
    if (this.guestService.isUserGuest()) {
      response = processData;
      response.id = componentOriginal.finishingProcesses.length;
    } else {
      response = await this.finishingProcessApiService.createFinishingProcess(
        componentOriginal.id,
        processData
      );
    }
    componentOriginal.finishingProcesses.push(response);
    this.projectService.saveProjectLocallyIfGuest();
    return response;
  }

  async updateFinishingProcess(
    processLocal: FinishingProcess,
    componentOriginal: PkgComponent
  ): Promise<FinishingProcess> {
    // @ts-ignore
    const processOriginal: FinishingProcess =
      componentOriginal.finishingProcesses.find((v) => v.id == processLocal.id);
    const response: FinishingProcess = this.guestService.isUserGuest()
      ? processLocal
      : await this.finishingProcessApiService.updateFinishingProcess(
          processOriginal.id,
          processLocal
        );
    processOriginal.mergeAll(response);
    this.projectService.saveProjectLocallyIfGuest();
    return processOriginal;
  }

  async deleteFinishingProcess(
    componentOriginal: PkgComponent,
    process: FinishingProcess
  ): Promise<void> {
    await this.finishingProcessApiService.deleteFinishingProcess(process.id);
    componentOriginal.finishingProcesses =
      componentOriginal.finishingProcesses.filter(function (value) {
        return value.id != process.id;
      });
    this.projectService.saveProjectLocallyIfGuest();
  }
}
