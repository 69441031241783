import { ComponentLevel } from '@/app/model/packaging-model/component/component-level.enum';
import { PkgComponent } from '@/app/model/packaging-model/component/pkg-component';
import { Scenario } from '@/app/model/packaging-model/scenario';
import { PkgComponentService } from '@/app/services/pkg-component.service';
import { ProjectService } from '@/app/services/project.service';
import { AfterViewChecked, ChangeDetectorRef, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.css'],
})
export class BreadcrumbComponent implements AfterViewChecked {
  show = false;

  get isGeneralInfo() {
    return this.router.url.split('/').find((s) => s == 'general-info') != null;
  }

  get isRechargeProject() {
    return (
      this.router.url.split('/').find((s) => s == 'recharge-project') != null
    );
  }

  get projectName() {
    if (this.isRechargeProject)
      return this.projectService.currentRechargeProject?.name;
    else return this.projectService.currentSingleUseProject?.name;
  }

  selectedScenario!: Scenario;

  get selectedComponent(): PkgComponent {
    return this.pkgComponentService.selectedPkgComponent;
  }

  get componentLevels(): Array<ComponentLevel> {
    return this.projectService.componentLevels;
  }

  getStringFromLevel(componentLevel: ComponentLevel): string {
    return ProjectService.levelToString.get(componentLevel) ?? '';
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private projectService: ProjectService,
    private pkgComponentService: PkgComponentService,
    private cdRef: ChangeDetectorRef
  ) {}

  ngAfterViewChecked(): void {
    let refresh = false;
    if (this.selectedScenario != this.projectService.selectedScenario) {
      this.selectedScenario = this.projectService.selectedScenario;
      refresh = true;
    }
    const show =
      this.selectedScenario || this.projectService.currentRechargeProject
        ? true
        : false;
    if (show != this.show) {
      this.show = show;
      refresh = true;
    }

    if (refresh) this.cdRef.detectChanges();
  }

  goToScenario(): void {
    void this.router.navigate(
      ['scenario', this.selectedScenario.id, 'general-info'],
      { relativeTo: this.route }
    );
  }

  selectComponent(pkgComponentId: number) {
    this.pkgComponentService.selectPkgComponent(pkgComponentId);
    void this.router.navigate(
      ['scenario', this.selectedScenario.id, 'component', pkgComponentId],
      { relativeTo: this.route }
    );
  }

  goBackToHomePage() {
    this.projectService.resetSelectedProjects();
    void this.router.navigate(['/'], { relativeTo: this.route });
  }
}
