import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiUtils } from './api-utils';
import { Material } from '../model/packaging-model/material';
import { plainToClass } from 'class-transformer';

@Injectable({
  providedIn: 'root',
})
export class MaterialApiService {
  buildPath(endPoint: string): string {
    return ApiUtils.BASE_PATH + 'materials/' + endPoint;
  }

  constructor(private http: HttpClient) {}

  async createMaterial(
    componentId: number,
    materialData: Material
  ): Promise<Material> {
    const url = this.buildPath(componentId.toString());
    const response = await this.http
      .post<Material>(url, materialData)
      .toPromise();
    return plainToClass(Material, response);
  }

  async updateMaterial(id: number, materialData: Material): Promise<Material> {
    const url = this.buildPath(id.toString());
    const response = await this.http
      .put<Material>(url, materialData, ApiUtils.HTTP_OPTIONS)
      .toPromise();
    return plainToClass(Material, response);
  }

  async deleteMaterial(id: number): Promise<void> {
    const url = this.buildPath(id.toString());
    await this.http.delete<Material>(url, ApiUtils.HTTP_OPTIONS).toPromise();
  }
}
