import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ApiUtils } from './api-utils';
import { Project } from '../model/packaging-model/project/project';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { plainToClass, plainToClassFromExist } from 'class-transformer';
import { ProjectAudit } from '../model/packaging-model/project/project-audit';
import { Page } from '../model/pagination/page';
import { FilterCriterion } from '../model/search/filter-criterion';
import { SingleUseProject } from 'src/app/model/packaging-model/project/single-use-project';
import { RechargeProject } from '../model/packaging-model/project/recharge-project';
import { ProjectLock } from '@/app/model/packaging-model/project/project-lock';
import { ScenarioResult } from '../model/results/scenario-result';
import { TypedSorting } from '@/app/model/search/typed-sorting';

@Injectable({
  providedIn: 'root',
})
export class ProjectApiService {
  constructor(private http: HttpClient) {}

  buildPath(endPoint: string): string {
    return ApiUtils.BASE_PATH + 'projects/' + endPoint;
  }

  getLatestProjects(): Promise<ProjectAudit[]> {
    const url = this.buildPath('latest');
    return this.http
      .get<ProjectAudit[]>(url, ApiUtils.HTTP_OPTIONS)
      .pipe(map((response) => plainToClass(ProjectAudit, response)))
      .toPromise();
  }

  getProjects(
    page: number,
    filterCriteria: Array<FilterCriterion>,
    sorting: TypedSorting<keyof Project>
  ): Observable<Page<Project>> {
    const url = this.buildPath('');
    let queryParams = new HttpParams()
      .set('page', page.toString())
      .set('sortDirection', sorting.direction)
      .set('sortedBy', sorting.sortedBy);
    filterCriteria.forEach(
      (filterCriterion: FilterCriterion) =>
        (queryParams = queryParams.set(
          filterCriterion.key,
          filterCriterion.value
        ))
    );
    const httpOptions = {
      headers: { 'Content-Type': 'application/json' },
      params: queryParams,
    };
    return this.http
      .get<Page<Project>>(url, httpOptions)
      .pipe(
        map((response) =>
          plainToClassFromExist(new Page<Project>(Project), response)
        )
      );
  }

  async lockProject(projectId: number): Promise<ProjectLock> {
    const url = this.buildPath(`${projectId}/lock`);
    return this.http
      .post<ProjectLock>(url, ApiUtils.HTTP_OPTIONS)
      .toPromise()
      .then((response) => plainToClass(ProjectLock, response));
  }

  async unlockProject(projectId: number): Promise<boolean> {
    const url = this.buildPath(`${projectId}/unlock`);
    return this.http.post<boolean>(url, ApiUtils.HTTP_OPTIONS).toPromise();
  }

  async updateProjectGeneralInfo(project: Project): Promise<Project> {
    const url = this.buildPath(project.id.toString());
    const response = await this.http
      .put<Project>(url, project, ApiUtils.HTTP_OPTIONS)
      .toPromise();
    return plainToClass(Project, response);
  }

  async updateProjectCollaborators(
    project: Project,
    sendEmailNotificationsToCollaborators: boolean
  ): Promise<Project> {
    const url = this.buildPath(`${project.id}/share`);
    const queryParams = new HttpParams().set(
      'sendEmailNotificationsToCollaborators',
      String(sendEmailNotificationsToCollaborators)
    );
    const httpOptions = {
      ...ApiUtils.HTTP_OPTIONS,
      params: queryParams,
    };

    const response = await this.http
      .put<Project>(url, project, httpOptions)
      .toPromise();
    return plainToClass(Project, response);
  }

  async setScenarioToReference(
    projectId: string,
    scenarioId: string
  ): Promise<SingleUseProject> {
    const url = this.buildPath(projectId + '/reference-scenario/' + scenarioId);
    const response = await this.http
      .put<string>(url, ApiUtils.HTTP_OPTIONS)
      .toPromise();
    return plainToClass(SingleUseProject, response);
  }

  // ----------------------------- SINGLE USE -----------------------------

  async createSingleUseProject(
    project: SingleUseProject
  ): Promise<SingleUseProject> {
    const url = this.buildPath('single-use');
    const response = await this.http
      .post<SingleUseProject>(url, project, ApiUtils.HTTP_OPTIONS)
      .toPromise();
    return plainToClass(SingleUseProject, response);
  }

  async duplicateSingleUseProject(
    projectId: number,
    newName: string
  ): Promise<SingleUseProject> {
    const url = this.buildPath(`single-use/duplicate/${projectId}`);
    const response = await this.http
      .post<SingleUseProject>(url, newName, ApiUtils.HTTP_OPTIONS)
      .toPromise();
    return plainToClass(SingleUseProject, response);
  }

  async loadSingleUseProject(projectId: string): Promise<SingleUseProject> {
    const url = this.buildPath(`single-use/${projectId}`);
    const response = await this.http
      .get<SingleUseProject>(url, ApiUtils.HTTP_OPTIONS)
      .toPromise();
    return plainToClass(SingleUseProject, response);
  }

  deleteSingleUseProject(projectId: number): Promise<void> {
    const url = this.buildPath('single-use/' + projectId.toString());
    return this.http.delete<void>(url, ApiUtils.HTTP_OPTIONS).toPromise();
  }

  computeSingleUseRefOnlyResults(
    projectId: number
  ): Observable<Array<ScenarioResult>> {
    const url = this.buildPath(`single-use/${projectId}/assess`);
    const queryParams = new HttpParams().set('reference-scenario-only', 'true');
    const httpOptions = {
      headers: { 'Content-Type': 'application/json' },
      params: queryParams,
    };
    return this.http.post<Array<ScenarioResult>>(
      url,
      'reference-scenario-only=true',
      httpOptions
    );
  }

  computeSingleUseResults(
    projectId: number
  ): Observable<Array<ScenarioResult>> {
    const url = this.buildPath(`single-use/${projectId}/assess`);
    return this.http.post<Array<ScenarioResult>>(url, ApiUtils.HTTP_OPTIONS);
  }

  exportProject(projectId: number): Observable<string> {
    const url = this.buildPath(`${projectId}/export`);
    const httpOptions = {
      responseType: 'blob' as 'json',
    };
    return this.http.get<string>(url, httpOptions);
  }

  generateAllProjectExport(): Promise<object> {
    const url = this.buildPath(`export`);
    const httpOptions = {
      responseType: 'blob' as 'json',
    };
    return this.http.post(url, httpOptions).toPromise();
  }

  // ----------------------------- RECHARGE -----------------------------

  async createRechargeProject(
    project: RechargeProject
  ): Promise<RechargeProject> {
    const url = this.buildPath('recharge');
    const response = await this.http
      .post<RechargeProject>(url, project, ApiUtils.HTTP_OPTIONS)
      .toPromise();
    return plainToClass(RechargeProject, response);
  }

  async duplicateRechargeProject(
    projectId: number,
    newName: string
  ): Promise<RechargeProject> {
    const url = this.buildPath(`recharge/duplicate/${projectId}`);
    const response = await this.http
      .post<RechargeProject>(url, newName, ApiUtils.HTTP_OPTIONS)
      .toPromise();
    return plainToClass(RechargeProject, response);
  }

  async loadRechargeProject(projectId: string): Promise<RechargeProject> {
    const url = this.buildPath('recharge/' + projectId);
    const response = await this.http
      .get<RechargeProject>(url, ApiUtils.HTTP_OPTIONS)
      .toPromise();
    return plainToClass(RechargeProject, response);
  }

  async updateRechargeProject(
    projectId: number,
    updatedProject: RechargeProject
  ): Promise<RechargeProject> {
    const url = this.buildPath(`recharge/${projectId}`);
    const response = await this.http
      .put<RechargeProject>(url, updatedProject, ApiUtils.HTTP_OPTIONS)
      .toPromise();
    return plainToClass(RechargeProject, response);
  }

  deleteRechargeProject(projectId: number): Promise<void> {
    const url = this.buildPath('recharge/' + projectId.toString());
    return this.http.delete<void>(url, ApiUtils.HTTP_OPTIONS).toPromise();
  }

  computeRechargeResults(projectId: number): Observable<ScenarioResult> {
    const url = this.buildPath(`recharge/${projectId}/assess`);
    return this.http.post<ScenarioResult>(url, ApiUtils.HTTP_OPTIONS);
  }

  /* EXPORT COMPARISON */

  exportComparison(projects: Array<Project>): Observable<string> {
    const url = this.buildPath(`comparison/export`);
    const queryParams = new HttpParams()
      .set('project1Id', projects[0].id.toString())
      .set('project2Id', projects[1].id.toString());
    const httpOptions = {
      responseType: 'blob' as 'json',
      params: queryParams,
    };
    return this.http.get<string>(url, httpOptions);
  }
}
