import { Packaging } from './packaging';
import { PackagingAssociation } from './packaging-association';
import { Type } from 'class-transformer';

export class TertiaryPackagingBox extends Packaging {
  @Type(() => PackagingAssociation)
  containerPackagingAssociations!: Array<PackagingAssociation>;
  @Type(() => PackagingAssociation)
  contentPackagingAssociations!: Array<PackagingAssociation>;

  get associationWithConsummerPack(): PackagingAssociation | undefined {
    return this.containerPackagingAssociations[0];
  }

  copy(): TertiaryPackagingBox {
    const copy: TertiaryPackagingBox = new TertiaryPackagingBox();
    copy.id = this.id;
    copy.type = this.type;
    copy.isRecyclable = this.isRecyclable;
    copy.containerPackagingAssociations = new Array<PackagingAssociation>();
    for (const assoc of this.containerPackagingAssociations) {
      copy.containerPackagingAssociations.push(assoc.copy());
    }
    return copy;
  }
}
