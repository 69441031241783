<ng-template #importscenariomodal let-modal>
  <div class="modal-header modal-spacing">
    <div class="modal-title" id="share">
      {{ "interface_copy_publish_scenario" | translationPipe }}
    </div>
    <button
      type="button"
      class="close"
      data-dismiss="modal"
      aria-label="Close"
      (click)="onCancel()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body break-word modal-spacing">
    <div class="container-fluid">
      <div class="top-bar" *ngIf="publishedScenarios.length > 0">
        <div class="search-box">
          <input
            type="text"
            class="search-input"
            placeholder="{{ 'interface_search' | translationPipe }} "
            [(ngModel)]="this.nameFilter"
            (keyup)="launchSearch()"
          />
          <em class="fas fa-search mar-right"> </em>
        </div>
      </div>
      <div class="table-responsive">
        <table *ngIf="publishedScenarios.length > 0" class="table">
          <thead>
            <tr>
              <th scope="col">
                <app-input-checkbox
                  [checked]="allSelected"
                  [indeterminate]="someSelected"
                  (change)="onSelectAll($event)"
                ></app-input-checkbox>
              </th>
              <th scope="col">
                <div class="parent">
                  {{ "interface_scenario" | translationPipe }}
                  <app-sort-toggle
                    [sorting]="sorting"
                    sortedBy="name"
                    (toggle)="launchSearch()"
                  >
                  </app-sort-toggle>
                </div>
              </th>
              <th scope="col">
                <div class="parent">
                  {{ "interface_scenario_prod_zone" | translationPipe }}
                </div>
              </th>
              <th scope="col">
                <div class="parent">
                  {{ "interface_scenario_sales_zone" | translationPipe }}
                </div>
              </th>
              <th scope="col">
                <div class="parent">
                  {{ "interface_status" | translationPipe }}
                  <app-sort-toggle
                    [sorting]="sorting"
                    sortedBy="status"
                    (toggle)="launchSearch()"
                  >
                  </app-sort-toggle>
                </div>
              </th>
              <th scope="col">
                <div class="parent">
                  {{ "interface_created_by" | translationPipe }}
                </div>
              </th>
              <th scope="col">
                <div class="parent">
                  {{ "interface_exported_at" | translationPipe }}
                  <app-sort-toggle
                    [sorting]="sorting"
                    sortedBy="exportedAt"
                    (toggle)="launchSearch()"
                  >
                  </app-sort-toggle>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let publishedScenario of publishedScenarios">
              <td
                class="d-flex"
                (click)="onSelect(publishedScenario)"
                scope="col"
              >
                <app-input-checkbox
                  [checked]="
                    this.scenarioIsAlreadySelected(publishedScenario.id)
                  "
                  [clickable]="false"
                >
                </app-input-checkbox>
              </td>
              <td class="space-left">
                {{ publishedScenario.name }}
              </td>
              <td class="space-left">
                {{
                  publishedScenario.productionCountry.label | translationPipe
                }}
              </td>
              <td class="space-left">
                {{ publishedScenario.salesCountry.label | translationPipe }}
              </td>
              <td class="space-left">
                <span
                  *ngIf="publishedScenario.status == getStringFromStatus(1)"
                >
                  <em class="fas fa-check green"></em>
                  {{ publishedScenario.status | translationPipe }}</span
                >
                <span
                  *ngIf="publishedScenario.status == getStringFromStatus(0)"
                >
                  <em class="fas fa-clock orange"></em>
                  {{ publishedScenario.status | translationPipe }}</span
                >
              </td>
              <td class="space-left">
                {{
                  publishedScenario.exportedBy?.displayedName ??
                    "(deleted user)"
                }}
              </td>
              <td class="space-left">
                {{
                  publishedScenario.exportedAt.toLocaleString([], {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                    hour: "2-digit",
                    minute: "2-digit"
                  })
                }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div *ngIf="publishedScenarios.length > 0" class="paginationContainer">
        <app-pagination-bar
          [pager]="pager"
          (selectedPage)="setPage($event)"
        ></app-pagination-bar>
      </div>
      <div
        *ngIf="publishedScenarios.length == 0"
        class="no-published_scenarios"
      >
        {{ "interface_library_is_empty" | translationPipe }}
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-cancel" (click)="onCancel()">
      {{ "interface_cancel" | translationPipe }}
    </button>
    <button
      type="button"
      class="btn btn-primary"
      (click)="onSave()"
      [disabled]="this.selectedPublishedScenarios.length == 0"
    >
      {{ "interface_copy" | translationPipe }}
    </button>
  </div>
</ng-template>
