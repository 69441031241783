import { Component, ViewChild } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
  UntypedFormControl,
  ValidatorFn,
} from '@angular/forms';
import { UserService } from 'src/app/services/user.service';
import { TranslationPipe } from '@/app/intl/translation-pipe';
import { CustomValidators } from '@/app/utils/custom-validators';
import EmailUtils from '@/app/utils/email-utils';
import { FormUtils } from '@/app/utils/form-utils';
import { TermsOfUseModalComponent } from '../terms-of-use-modal/terms-of-use-modal.component';

@Component({
  selector: 'app-guest-access-box',
  templateUrl: './guest-access-box.component.html',
  styleUrls: ['./guest-access-box.component.css'],
})
export class GuestAccessBoxComponent {
  @ViewChild(TermsOfUseModalComponent)
  // @ts-ignore
  private termsOfUseModalComponent: TermsOfUseModalComponent;

  checkoutForm: UntypedFormGroup;
  validateCodeForm: UntypedFormGroup;
  haveAlreadyCodeFrom: UntypedFormGroup;

  // @ts-ignore
  email: string;
  // @ts-ignore
  errorMessage: string;
  state: State = State.INIT;
  State = State;

  emailAlreadyTaken = false;

  protected readonly boundaries = {
    organization: {
      maxLength: 255,
    },
  };

  constructor(
    private formBuilder: UntypedFormBuilder,
    private userService: UserService,
    private translationPipe: TranslationPipe
  ) {
    // @ts-ignore
    const email: ValidatorFn = Validators.compose([
      Validators.required,
      CustomValidators.emailValidator.compose,
    ]);
    // @ts-ignore
    const code: ValidatorFn = Validators.compose([
      Validators.required,
      CustomValidators.notSpaceValidator(),
    ]);

    this.checkoutForm = this.formBuilder.group({
      firstname: [
        '',
        Validators.compose([
          Validators.required,
          CustomValidators.userNameValidator.compose,
        ]),
      ],
      lastname: [
        '',
        Validators.compose([
          Validators.required,
          CustomValidators.userNameValidator.compose,
        ]),
      ],
      organization: [
        '',
        Validators.compose([
          Validators.required,
          CustomValidators.notSpaceValidator(),
          Validators.maxLength(this.boundaries.organization.maxLength),
        ]),
      ],
      email: ['', email],
      acceptedTermsOfUse: ['', Validators.required],
    });

    this.validateCodeForm = this.formBuilder.group({
      code: ['', code],
    });

    this.haveAlreadyCodeFrom = this.formBuilder.group({
      email: ['', email],
      code: ['', code],
    });

    FormUtils.updateModelFromFieldValue(
      this.checkoutForm.controls['email'],
      (v) => (this.emailAlreadyTaken = false)
    );
  }

  formInitialized(name: string, form: UntypedFormGroup) {
    this.checkoutForm.setControl(name, form);
  }

  // @ts-ignore
  onSubmitGuestUser(guestuserCreationData) {
    // @ts-ignore
    this.errorMessage = null;
    this.emailAlreadyTaken = false;
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    this.email = this.checkoutForm.get('email').value;
    this.userService
      .createGuestUserAccount(guestuserCreationData)
      .then((_) => {
        this.state = State.VERIFICATION_CODE;
      })
      .catch((err) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        switch (err.code) {
          case 'UsernameExistsException':
            this.emailAlreadyTaken = true;
            break;
          default:
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
            this.errorMessage = err.message;
            break;
        }
      });
  }

  onHaveAlreadyValidationCode() {
    // @ts-ignore
    this.errorMessage = null;
    this.state = State.EMAIL_CODE_PROVIDED;
  }

  onSubmitCode(code: string) {
    // @ts-ignore
    this.errorMessage = null;
    this.confirmSignUp(this.email, code);
  }

  // @ts-ignore
  onSubmitHaveAlreadyCode(data) {
    new UntypedFormControl();
    // @ts-ignore
    this.errorMessage = null;

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    this.confirmSignUp(data.email, data.code);
  }

  confirmSignUp(email: string, code: string) {
    this.userService
      .confirmSignUp(email, code)
      .then((_) => (this.state = State.SUCCESS))
      .catch((err) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        switch (err.code) {
          case 'CodeMismatchException':
            this.errorMessage = 'interface_code_mismatch_exception';
            break;
          default:
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
            this.errorMessage = err.message;
            break;
        }
      });
  }

  isState(state: State): boolean {
    return this.state == state;
  }

  emailSentBodyMessage() {
    return this.translationPipe.transform(
      'interface_guest_user_validation_code_sent',
      new Map().set('$1', EmailUtils.obfuscateEmailAddress(this.email))
    );
  }

  openTermsOfUse() {
    this.termsOfUseModalComponent.open();
  }

  protected readonly CustomValidators = CustomValidators;
}

enum State {
  // eslint-disable-next-line @typescript-eslint/indent
  INIT,
  VERIFICATION_CODE,
  EMAIL_CODE_PROVIDED,
  SUCCESS,
}
