<table class="table score-table table-striped">
  <thead>
    <th width="33.33%" scope="col">
      {{ "interface_project_recharge" | translationPipe }}
    </th>
    <th width="33.33%" scope="col">
      {{ "interface_assess_score" | translationPipe }} ({{
        "unit_pt_per_ml" | translationPipe
      }})
    </th>
  </thead>
  <tbody>
    <tr *ngFor="let project of rechargeProjectsForAssess">
      <td>
        {{ project.name }}
      </td>
      <td>
        {{ project.singleScore | toFixed: 2 }}
      </td>
    </tr>
  </tbody>
</table>
