/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import {
  Component,
  EventEmitter,
  ViewChild,
  TemplateRef,
  Output,
  Input,
  OnInit,
  ViewChildren,
  QueryList,
} from '@angular/core';
import {
  NgbModal,
  NgbModalOptions,
  NgbModalRef,
} from '@ng-bootstrap/ng-bootstrap';
import { ProjectService } from 'src/app/services/project.service';
import { Page } from 'src/app/model/pagination/page';
import { Project } from 'src/app/model/packaging-model/project/project';
import { FilterCriterion } from 'src/app/model/search/filter-criterion';
import { SortingDirection } from 'src/app/model/search/sorting';
import { User } from '@/app/model/user/user';
import { LoginService } from 'src/app/services/login/login.service';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ConfirmModalComponent } from '@/app/pages/confirm-modal/confirm-modal.component';
import { ToastService } from '@/app/services/toast.service';
import { ProjectLock } from '@/app/model/packaging-model/project/project-lock';
import { TranslationPipe } from '@/app/intl/translation-pipe';
import { Pager } from '@/app/utils/pager';
import { ProjectCollaboratorsModalComponent } from '../../projects/share-project-modal/project-collaborators-modal.component';
import { GuestService } from '@/app/services/guest.service';
import { HttpErrorResponse } from '@angular/common/http';
import { TypedSorting } from '@/app/model/search/typed-sorting';

@Component({
  selector: 'app-open-project-modal',
  templateUrl: './open-project-modal.component.html',
  styleUrls: ['./open-project-modal.component.css'],
})
export class OpenProjectModalComponent implements OnInit {
  readonly MODAL_OPTIONS: NgbModalOptions = { size: 'xxl', backdrop: 'static' };
  modal: NgbModalRef | undefined;

  searchInput$: Subject<string> = new Subject();
  @Output() answer = new EventEmitter<Project>();
  @Input() onlySingleUseSelection = false;
  @Input() isComparisonSelection = false;

  page = 0;
  selectedProject: Project | undefined;
  searchFilter = '';
  sorting: TypedSorting<keyof Project>;

  pager: Pager | undefined;
  projects: Array<Project> = [];

  user: User | undefined;

  @ViewChild('openprojectmodal')
  templateModal!: TemplateRef<any>;
  @ViewChildren(ConfirmModalComponent)
  private confirmModals!: QueryList<ConfirmModalComponent>;
  @ViewChild(ProjectCollaboratorsModalComponent)
  private shareProjectModalComponent!: ProjectCollaboratorsModalComponent;

  get currentUser(): User | null {
    return this._loginService.currentUser;
  }

  constructor(
    private router: Router,
    private _loginService: LoginService,
    private projectService: ProjectService,
    private modalService: NgbModal,
    public guestService: GuestService,
    private toastService: ToastService,
    private translationPipe: TranslationPipe
  ) {
    this.sorting = new TypedSorting<keyof Project>(
      SortingDirection.ASC,
      'name'
    );
  }

  ngOnInit(): void {
    this.reset();
    this.loadProjects();
  }

  open(user?: User): void {
    this.reset();
    this.loadProjects();
    this.modal = this.modalService.open(this.templateModal, this.MODAL_OPTIONS);

    if (user) {
      this.user = user;
    } else {
      this.user = undefined;
    }

    this.reset();
    this.launchSearch();
  }

  close(): void {
    this.modal?.close();
  }

  reset(): void {
    this.page = 0;
    this.sorting = new TypedSorting<keyof Project>(
      SortingDirection.ASC,
      'name'
    );
    this.searchFilter = '';
    this.selectedProject = undefined;
  }

  onSearch(val: string | undefined): void {
    this.searchInput$.next(val);
    this.setPage(1);
  }

  setPage(page: number): void {
    this.page = page - 1;
    this.launchSearch();
  }

  launchSearch(): void {
    const filterCriteria: Array<FilterCriterion> = [];

    if (this.searchFilter != '') {
      filterCriteria.push(new FilterCriterion('search', this.searchFilter));
    }

    if (this.user) {
      filterCriteria.push(
        new FilterCriterion('ownerId', this.user.id.toString())
      );
    }

    if (this.onlySingleUseSelection) {
      filterCriteria.push(
        new FilterCriterion(
          'onlySingleUse',
          this.onlySingleUseSelection.toString()
        )
      );
    }

    this.projectService
      .getProjects(this.page, filterCriteria, this.sorting)
      .subscribe((page: Page<Project>) => {
        this.projects = page.content;
        this.pager = Pager.getPager(
          page.totalElements,
          page.number + 1,
          page.size
        );
      });
  }

  openProject(): void {
    this.close();
    if (this.onlySingleUseSelection || this.isComparisonSelection) {
      this.answer.emit(this.selectedProject);
    } else {
      if (this.selectedProject?.isSingleUse) {
        void this.router.navigate([
          '/single-use-project',
          this.selectedProject.id,
        ]);
      } else if (this.selectedProject) {
        void this.router.navigate([
          '/recharge-project',
          this.selectedProject.id,
        ]);
      }
    }
  }

  setProject(proj: Project): void {
    this.selectedProject = proj;
  }

  deleteProject(project: Project): void {
    this.selectedProject = project;
    this.confirmModals?.filter((item) => item.id == 'delete-project')[0].open();
  }

  confirmOrAbortDeletion(answer: boolean): void {
    this.confirmModals
      ?.filter((item) => item.id == 'delete-project')[0]
      .close();
    if (answer && this.selectedProject != undefined) {
      this.projectService
        .deleteProject(this.selectedProject)
        .then(() => {
          this.launchSearch();
        })
        .catch((err: HttpErrorResponse) => {
          if (err instanceof ProjectLock) {
            this.toastService.show(
              this.translationPipe.transform(
                'interface_project_lock_project_delete_failure_title'
              ),
              this.translationPipe.transform(
                'interface_project_lock_project_delete_failure_body',
                new Map().set('$1', err.user.displayedName)
              ),
              { classname: 'bg-warning' }
            );
          } else if (err.error.type == 'ImpossibleDeletionException') {
            this.toastService.show(
              this.translationPipe.transform('interface_deletion_forbidden'),
              err.error.message,
              { classname: 'bg-warning' }
            );
            this.close();
          }
          this.selectedProject = undefined;
        });
    }
  }

  duplicateProject(project: Project): void {
    this.selectedProject = project;
    this.confirmModals
      .filter((item) => item.id == 'duplicate-project-modal')[0]
      .open();
  }

  async confirmOrAbortDuplication(answer: boolean): Promise<void> {
    this.confirmModals
      .filter((item) => item.id == 'duplicate-project-modal')[0]
      .close();
    this.close();

    if (answer) {
      if (this.selectedProject?.isSingleUse) {
        const responseId: number =
          await this.projectService.duplicateSingleUseProject(
            this.selectedProject
          );
        this.goToSingleUseProject(responseId);
      } else if (this.selectedProject) {
        const responseId: number =
          await this.projectService.duplicateRechargeProject(
            this.selectedProject
          );
        this.gotToRechargeProject(responseId);
      }
    }
  }

  goToSingleUseProject(projectId: number): void {
    void this.router.navigateByUrl(`/single-use-project/${projectId}`);
  }

  gotToRechargeProject(projectId: number): void {
    void this.router.navigateByUrl(`/recharge-project/${projectId}`);
  }

  getUserFullname(): Map<string, string> {
    return new Map<string, string>().set(
      '$1',
      this.user ? this.user.displayedName : ''
    );
  }

  private loadProjects() {
    this.searchInput$
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe(() => this.launchSearch());
  }

  openCollaboratorsModal(selectedProject: Project): void {
    this.selectedProject = selectedProject;
    if (!this.guestService.isUserGuest())
      this.shareProjectModalComponent.open();
  }
}
