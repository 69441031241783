<ng-template #shareprojectmodal let-modal>
  <div class="modal-header modal-spacing">
    <div class="modal-title" id="share">
      {{ "interface_collaborators" | translationPipe }}
    </div>
    <button
      type="button"
      class="close"
      data-dismiss="modal"
      aria-label="Close"
      (click)="onCancel()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body break-word modal-spacing">
    <div class="container-fluid">
      <div class="row">
        <div class="top-bar">
          <div class="search-box">
            <input
              type="text"
              class="search-input"
              placeholder="{{ 'interface_search' | translationPipe }} "
              [(ngModel)]="this.searchFilter"
              (ngModelChange)="onSearch($event)"
            />
            <em class="fas fa-search mar-right"> </em>
          </div>
        </div>
        <div class="table-responsive">
          <table class="table" aria-describedby="share">
            <thead>
              <tr>
                <th scope="col">
                  <app-input-checkbox
                    *ngIf="editMode"
                    [checked]="allSelected"
                    [indeterminate]="someSelected"
                    [disabled]="!someSelectable"
                    (change)="onSelectAll($event)"
                  ></app-input-checkbox>
                  <span
                    *ngIf="editMode"
                    class="fas fa-filter"
                    matTooltip="{{
                      'interface_show_only_selected' | translationPipe
                    }} "
                    matTooltipPosition="above"
                    matTooltipClass="custom-tooltip"
                    [class.active]="filterSelectedOnly"
                    (click)="onToggleShowSelectedOnly()"
                  ></span>
                </th>
                <th scope="col">
                  {{ "interface_first_name" | translationPipe }}
                  <app-sort-toggle
                    [sorting]="sorting"
                    sortedBy="normalizedFirstname"
                    (toggle)="onToggleSort()"
                  ></app-sort-toggle>
                </th>
                <th scope="col">
                  {{ "interface_last_name" | translationPipe }}
                  <app-sort-toggle
                    [sorting]="sorting"
                    sortedBy="normalizedLastname"
                    (toggle)="onToggleSort()"
                  ></app-sort-toggle>
                </th>
                <th scope="col">
                  {{ "interface_email" | translationPipe }}
                  <app-sort-toggle
                    [sorting]="sorting"
                    sortedBy="normalizedEmail"
                    (toggle)="onToggleSort()"
                  ></app-sort-toggle>
                </th>
                <th scope="col">
                  {{ "interface_brand" | translationPipe }}
                  <app-sort-toggle
                    [sorting]="sorting"
                    sortedBy="normalizedBrand"
                    (toggle)="onToggleSort()"
                  ></app-sort-toggle>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="collaborator-row"
                [ngClass]="{ clickable: !isDisabled(collaborator) }"
                *ngFor="let collaborator of collaborators"
              >
                <td (click)="onSelect(collaborator)" scope="col">
                  <app-input-checkbox
                    *ngIf="editMode"
                    [checked]="collaborator.selected"
                    [clickable]="false"
                    [disabled]="isDisabled(collaborator)"
                  ></app-input-checkbox>
                  <div class="tags-container">
                    <span *ngIf="isOwner(collaborator)" class="tag">{{
                      "interface_owner" | translationPipe
                    }}</span>
                    <span *ngIf="isAdmin(collaborator)" class="tag">{{
                      "interface_role_admin" | translationPipe
                    }}</span>
                    <span *ngIf="isSuperAdmin(collaborator)" class="tag">{{
                      "interface_role_super_admin" | translationPipe
                    }}</span>
                  </div>
                </td>
                <td (click)="onSelect(collaborator)">
                  {{ collaborator.firstname }}
                </td>
                <td (click)="onSelect(collaborator)">
                  {{ collaborator.lastname }}
                </td>
                <td (click)="onSelect(collaborator)">
                  {{ collaborator.email }}
                </td>
                <td (click)="onSelect(collaborator)">
                  {{ collaborator.brand }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <app-pagination-bar
        *ngIf="collaborators.length > 0"
        [pager]="pager"
        (selectedPage)="onSetPage($event)"
      ></app-pagination-bar>
    </div>
  </div>
  <div class="modal-footer">
    <label
      *ngIf="editMode"
      [class.disabled]="!canSave"
      matTooltip="{{
        'interface_send_email_notifications_to_collaborators_description'
          | translationPipe
      }}"
      matTooltipPosition="above"
      matTooltipClass="custom-tooltip"
      >{{ "interface_send_email_notifications" | translationPipe }}
      <app-input-checkbox
        [(ngModel)]="sendEmailNotificationsToCollaborators"
        [disabled]="!canSave"
      ></app-input-checkbox>
    </label>
    <button class="btn btn-cancel" (click)="onCancel()">
      {{ "interface_cancel" | translationPipe }}
    </button>
    <button
      *ngIf="editMode"
      type="button"
      class="btn btn-primary"
      [disabled]="!canSave"
      (click)="onSave()"
    >
      {{ "interface_save" | translationPipe }}
    </button>
  </div>
</ng-template>
