import * as Sentry from '@sentry/angular-ivy';
import { DsnComponents, Event } from '@sentry/types';
import assert from 'assert';

// https://docs.sentry.io/platforms/javascript/enriching-events/attachments/

interface Attachment {
  filename: string;
  content: any;
  contentType: string;
}

/**
 * Send the given attachments for the given Sentry event.
 *
 * @param event to add the attachments to
 * @param attachments to add to the event
 */
export function sendAttachments(event: Event, attachments: Attachment[]) {
  const client = Sentry.getCurrentHub().getClient();
  if (client === undefined)
    throw new Error('Cannot add attachments because client is undefined');
  const dsn = client.getDsn();
  if (dsn === undefined)
    throw new Error('Cannot add attachments because dsn is undefined');
  const eventId = event.event_id;
  if (eventId === undefined)
    throw new Error('Cannot add attachments because eventId is undefined');
  const endpoint = attachmentUrlFromDsn(dsn, eventId);
  const formData = new FormData();
  for (const attachment of attachments)
    addAttachmentToFormData(
      formData,
      attachment.filename,
      attachment.content,
      attachment.contentType
    );
  fetch(endpoint, {
    method: 'POST',
    body: formData,
  }).catch((ex) => {
    // we have to catch this otherwise it throws an infinite loop in Sentry
    console.error(ex);
  });
}

function addAttachmentToFormData(
  formData: FormData,
  filename: string,
  content: any,
  contentType: string
) {
  formData.append(
    filename,
    new Blob([content], {
      type: contentType,
    }),
    filename
  );
}

function attachmentUrlFromDsn(dsn: DsnComponents, eventId: string) {
  const { host, path, projectId, port, protocol, publicKey } = dsn;

  const optionalPort = port !== undefined && port !== '' ? `:${port}` : '';
  const optionalPath = path !== undefined && path !== '' ? `/${path}` : '';

  assert(publicKey !== undefined);

  return (
    `${protocol}://${host}${optionalPort}${optionalPath}/api/${projectId}/events/${eventId}/attachments/` +
    `?sentry_key=${publicKey}&sentry_version=7&sentry_client=custom-javascript`
  );
}
