<ng-template #confirmmodal let-modal>
  <div class="modal-content">
    <div class="modal-header modal-spacing">
      <div class="modal-title">{{ title }}</div>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        aria-label="Close"
        (click)="abort()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body break-word modal-spacing">
      <p>{{ content }}</p>
    </div>
    <div class="modal-footer">
      <button class="btn btn-cancel" (click)="abort()">
        {{ "interface_cancel" | translationPipe }}
      </button>
      <button
        type="button"
        id="confirm-modal-ok"
        class="btn btn-primary"
        (click)="confirm()"
      >
        {{ "interface_ok" | translationPipe }}
      </button>
    </div>
  </div>
</ng-template>
