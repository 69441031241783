import { Injectable, TemplateRef } from '@angular/core';
import { Toast } from '@/app/component/toast-container/toast-container';

@Injectable({ providedIn: 'root' })
export class ToastService {
  toasts: Toast[] = [];

  show(
    header: string,
    textOrTpl: string | TemplateRef<any>,
    options: any = {}
  ): void {
    this.toasts.push({ header, textOrTpl, ...options });
  }

  remove(toast: Toast): void {
    this.toasts = this.toasts.filter((t) => t !== toast);
  }
}
