import { Component, Input, OnInit } from '@angular/core';
import { GuestService } from '@/app/services/guest.service';
import { DetailedResult } from '@/app/model/results/detailed-result';
import { ScenarioResult } from '@/app/model/results/scenario-result';
import { SingleUseProject } from '@/app/model/packaging-model/project/single-use-project';
import { ProjectService } from '@/app/services/project.service';
import {
  SortingDirection,
  TypedSorting,
} from '@/app/model/search/typed-sorting';
import { SortService } from '@/app/services/sort.service';

class ScenarioForAssess {
  scenarioId!: number;
  name!: string;
  singleScore: number | undefined;
  singleScoreDifference!: number;
}

@Component({
  selector:
    'app-scenarios-for-assessment[referenceScenarioId][scenarioResults]',
  templateUrl: './scenarios-for-assessment.component.html',
  styleUrls: ['../assess.component.css'],
})
export class ScenariosForAssessmentComponent implements OnInit {
  scenariosForAssess!: Array<ScenarioForAssess>;
  sorting: TypedSorting<keyof ScenarioForAssess> = new TypedSorting<
    keyof ScenarioForAssess
  >(SortingDirection.ASC, 'name');

  @Input()
  referenceScenarioId!: number;
  @Input()
  scenarioResults!: Array<ScenarioResult>;

  constructor(
    protected guestService: GuestService,
    protected projectService: ProjectService,
    private sortService: SortService<ScenarioForAssess>
  ) {}

  async ngOnInit(): Promise<void> {
    await this.projectService.reloadSingleUseProject(this.project.id);
    this.scenariosForAssess = this.project.scenarios.map((s) => {
      return {
        scenarioId: s.id,
        name: s.name,
        singleScore: this.resultsForScenario(s.id)?.totalSingleScore.result,
        singleScoreDifference: this.getSingleScoreDifference(s.id),
      } as ScenarioForAssess;
    });
  }

  get project(): SingleUseProject {
    if (this.guestService.isUserGuest() && this.guestService.guestUserProject) {
      return this.guestService.guestUserProject;
    } else return this.projectService.currentSingleUseProject;
  }

  resultsForScenario(scenarioId: number): DetailedResult | undefined {
    return this.scenarioResults.find((result) => result.id == scenarioId)
      ?.results;
  }

  getSingleScoreDifference(scenarioId: number): number {
    const scenarioCurrentSS =
      this.resultsForScenario(scenarioId)!.totalSingleScore.result;
    const scenarioReferenceSS = this.resultsForScenario(
      this.referenceScenarioId
    )!.totalSingleScore.result;
    return (
      100 * ((scenarioCurrentSS - scenarioReferenceSS) / scenarioReferenceSS)
    );
  }

  isBestSingleScore(scenarioId: number): boolean {
    const scenario = this.scenarioResults.find((s) => s.id === scenarioId)!;
    return this.scenarioResults
      .filter((s) => s.id !== scenario.id)
      .every(
        (s) =>
          s.results.totalSingleScore.result >
          scenario.results.totalSingleScore.result
      );
  }

  launchSort(): void {
    this.sortService.sortArray(this.scenariosForAssess, this.sorting);
  }
}
