<div
  *ngIf="translationService.translationsLoaded | async"
  class="container-fluid background-colored"
  (window:resize)="sidebarService.onResize($event)"
>
  <div class="row full-height">
    <div
      *ngIf="!isAssess()"
      id="sidebar"
      class="sidebar col-lg-3 min-vh-100 position-fixed"
    >
      <sidebar-component [collapsible]="false"></sidebar-component>
    </div>
    <div *ngIf="!isAssess()" id="sidebarCollapsible" class="sidebar min-vh-100">
      <sidebar-component [collapsible]="true"></sidebar-component>
    </div>
    <div
      *ngIf="isAssess()"
      id="sidebarAssess"
      class="sidebar col-lg-3 min-vh-100 position-fixed"
    >
      <sidebar-assess-two-project [collapsible]="false">
      </sidebar-assess-two-project>
    </div>
    <div
      *ngIf="isAssess()"
      id="sidebarAssessCollapsible"
      class="sidebar min-vh-100"
    >
      <sidebar-assess-two-project [collapsible]="true">
      </sidebar-assess-two-project>
    </div>

    <div id="overlay" (click)="closeSidebar()"></div>

    <div id="projectMain" class="col-lg-9 offset-lg-3 project-main">
      <button
        id="openCollapsibleSidebar"
        class="btn-color"
        (click)="openSidebar()"
      >
        <em class="fas fa-bars"></em>
      </button>
      <div class="col-md-11 project-container">
        <div class="back">
          <button class="btn btn-secondary back-btn" (click)="goToPrevious()">
            <em class="fas fa-arrow-left"></em
            >{{ "interface_back" | translationPipe }}
          </button>
          <div class="project-title">
            <span class="project-edit">
              <span class="project-name">{{
                "interface_comparison_title" | translationPipe
              }}</span>
              <span
                matTooltip="Export"
                matTooltipPosition="above"
                matTooltipClass="custom-tooltip"
              >
                <button
                  *ngIf="isAssess() && !this.guestService.isUserGuest()"
                  [disabled]="exporting"
                  (click)="exportComparison()"
                  class="export-button"
                >
                  <span class="fas fa-file-export"></span>
                  <spinner
                    *ngIf="exporting"
                    [color]="'blue'"
                    class="export-progress-spinner"
                  ></spinner>
                </button>
              </span>
            </span>
          </div>
        </div>
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
