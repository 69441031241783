import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { ValidationService } from 'src/app/services/formValidationService/validation.service';

@Component({
  selector: 'validation-messages',
  template: `
    <div
      *ngIf="getErrorMessage() !== null"
      style="padding-top: 4px; padding-left: 2px;"
    >
      {{ getErrorMessage() }}
    </div>
  `,
})
export class ValidationMessagesComponent {
  @Input() control: AbstractControl | null = null;

  @Input() touch = true;

  constructor(private _validationService: ValidationService) {}

  getErrorMessage(): string {
    if (this.control === null) return '';

    if (
      this.control.errors != null &&
      (this.control.dirty || this.control.touched)
    ) {
      const keys: string[] = Object.keys(this.control.errors);
      const errorName = keys[0];
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return this._validationService.getValidatorErrorMessage(
        errorName,
        this.control.errors[errorName]
      );
    }
    return '';
  }
}
