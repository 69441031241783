import { Injectable } from '@angular/core';
import { GuestUserApiService } from '@/app/api/guest-user-api.service';

import { FilterCriterion } from '@/app/model/search/filter-criterion';
import { Sorting } from '@/app/model/search/sorting';
import { Page } from '@/app/model/pagination/page';
import { GuestUser } from '@/app/model/user/user';
import { CsvExportService } from './csv-export.service';
import { TranslationService } from './translationService/translation.service';

@Injectable({
  providedIn: 'root',
})
export class GuestUserService {
  constructor(
    private userApiService: GuestUserApiService,
    private translationService: TranslationService
  ) {}

  getUsers(
    page: number,
    filterCriteria: Array<FilterCriterion>,
    sorting: Sorting
  ): Promise<Page<GuestUser>> {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.userApiService
      .getUsers(page, filterCriteria, sorting)
      .toPromise();
  }

  adminDeleteGuestUser(guestUserId: number): Promise<any> {
    return this.userApiService.adminDeleteGuestUser(guestUserId);
  }

  async downloadExcelExport() {
    let p = 0;
    let totalPages = 0;
    let data: Array<GuestUser> = [];
    do {
      const guestUsersPage = await this.userApiService.getDataToExport(p);
      totalPages = guestUsersPage.totalPages;
      data = data.concat(guestUsersPage.content);
    } while (++p < totalPages);
    const filename = this.translationService.getTranslationValueForLabel(
      'interface_export_title'
    );
    CsvExportService.exportToCsv(filename + '.csv', data);
  }
}
