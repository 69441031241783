import { Component, TemplateRef, ViewChild } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { Populate } from '@/app/model/populate/populate';
import { PopulateApiService } from '@/app/api/populate-api.service';

@Component({
  selector: 'app-populate-modal',
  templateUrl: './populate-modal.component.html',
  styleUrls: ['./populate-modal.component.css'],
})
export class PopulateModalComponent {
  readonly defaultValues: Populate = {
    resetDatabaseBeforePopulate: false,
    nbProjects: 1,
    nbScenarios: 1,
    nbPrimaryPackagingComponents: 1,
    nbSecondaryPackagingComponents: 1,
    nbTertiaryBoxPackagingComponents: 1,
    nbTertiaryPalletizationPackagingComponents: 1,
  };

  @ViewChild('populatemodal')
  readonly templateModal!: TemplateRef<any>;

  readonly populateForm: UntypedFormGroup;

  modal: NgbModalRef | undefined;

  isBusy = false;

  protected readonly boundaries = {
    nbProjects: {
      min: 0,
      max: 100,
    },
    nbScenarios: {
      min: 1,
      max: 100,
    },
    nbComponents: {
      min: 0,
      max: 100,
    },
  };

  constructor(
    private router: Router,
    private modalService: NgbModal,
    private formBuilder: UntypedFormBuilder,
    private populateApiService: PopulateApiService
  ) {
    // TODO align min/max constraints with server-side constraints
    this.populateForm = this.formBuilder.group({
      resetDatabaseBeforePopulate: [{ disabled: this.isBusy }],
      nbProjects: [
        { disabled: this.isBusy },
        Validators.compose([
          Validators.required,
          Validators.min(this.boundaries.nbProjects.min),
          Validators.max(this.boundaries.nbProjects.max),
        ]),
      ],
      nbScenarios: [
        { disabled: this.isBusy },
        Validators.compose([
          Validators.required,
          Validators.min(this.boundaries.nbScenarios.min),
          Validators.max(this.boundaries.nbScenarios.max),
        ]),
      ],
      nbPrimaryPackagingComponents: [
        { disabled: this.isBusy },
        Validators.compose([
          Validators.required,
          Validators.min(this.boundaries.nbComponents.min),
          Validators.max(this.boundaries.nbComponents.max),
        ]),
      ],
      nbSecondaryPackagingComponents: [
        { disabled: this.isBusy },
        Validators.compose([
          Validators.required,
          Validators.min(this.boundaries.nbComponents.min),
          Validators.max(this.boundaries.nbComponents.max),
        ]),
      ],
      nbTertiaryBoxPackagingComponents: [
        { disabled: this.isBusy },
        Validators.compose([
          Validators.required,
          Validators.min(this.boundaries.nbComponents.min),
          Validators.max(this.boundaries.nbComponents.max),
        ]),
      ],
      nbTertiaryPalletizationPackagingComponents: [
        { disabled: this.isBusy },
        Validators.compose([
          Validators.required,
          Validators.min(this.boundaries.nbComponents.min),
          Validators.max(this.boundaries.nbComponents.max),
        ]),
      ],
    });
  }

  async onSubmit() {
    this.isBusy = true;
    await this.populateApiService.populate(this.populateForm.value);
    this.isBusy = false;
    this.close();
  }

  open() {
    this.populateForm.reset();
    this.populateForm.controls['resetDatabaseBeforePopulate'].setValue(
      this.defaultValues.resetDatabaseBeforePopulate
    );
    this.populateForm.controls['nbProjects'].setValue(
      this.defaultValues.nbProjects
    );
    this.populateForm.controls['nbScenarios'].setValue(
      this.defaultValues.nbScenarios
    );
    this.populateForm.controls['nbPrimaryPackagingComponents'].setValue(
      this.defaultValues.nbPrimaryPackagingComponents
    );
    this.populateForm.controls['nbSecondaryPackagingComponents'].setValue(
      this.defaultValues.nbSecondaryPackagingComponents
    );
    this.populateForm.controls['nbTertiaryBoxPackagingComponents'].setValue(
      this.defaultValues.nbTertiaryBoxPackagingComponents
    );
    this.populateForm.controls[
      'nbTertiaryPalletizationPackagingComponents'
    ].setValue(this.defaultValues.nbTertiaryPalletizationPackagingComponents);
    this.modal = this.modalService.open(this.templateModal);
  }

  close() {
    this.modal?.close();
  }
}
