import { Transform, Type } from 'class-transformer';
import { User } from '../user/user';
import { Scenario } from './scenario';

export class PublishedScenario extends Scenario {
  id!: number;
  companyId!: number;
  status!: string;

  @Type(() => User)
  exportedBy!: User | null;

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-call
  @Transform((value) => new Date(value.substring(0, 19))) // Format "YYYY-MM-DDThh:mm::ss" (first 19 elements)
  exportedAt!: Date;
}
