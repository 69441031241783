import { Breadcrumb } from '@sentry/types';

// https://develop.sentry.dev/sdk/event-payloads/breadcrumbs/

export const isXhrBreadcrumb = (
  breadcrumb: Breadcrumb
): breadcrumb is XhrBreadcrumb =>
  breadcrumb.type === 'http' && breadcrumb.category === 'xhr';

export interface HttpBreadcrumbData {
  url?: string;
  method?: string;
  status_code?: number;
  reason: string;
}

export interface HttpBreadcrumb extends Breadcrumb {
  type: 'http';
  data: HttpBreadcrumbData;
}

export interface XhrBreadcrumb extends HttpBreadcrumb {
  category: 'xhr';
}
