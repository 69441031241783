<ng-container *ngIf="this.translationService.translationsLoaded | async">
  <div *ngIf="this.componentReady" class="col">
    <div class="user-management">
      <div class="top-bar">
        <div class="search-box">
          <input
            type="text"
            class="search-input"
            placeholder="{{ 'interface_search' | translationPipe }} "
            [formControl]="search"
          />
          <em class="fas fa-search mar-right"> </em>
        </div>
        <div class="add-company-btn">
          <button
            class="btn btn-primary add-comp"
            (click)="openModalToCreateUser()"
          >
            <em class="fas fa-plus mar-right"></em>
            {{ "interface_create_user" | translationPipe }}
          </button>
        </div>
        <div *ngIf="isAdminOrSuperAdmin()" class="export-stats-btn">
          <button
            class="btn btn-secondary add-comp"
            (click)="exportMemberStats()"
          >
            <em class="far fa-file-excel mar-right"></em>
            {{ "interface_export_member_stats" | translationPipe }}
          </button>
        </div>
      </div>
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th *ngIf="isSuperAdmin()" scope="col">
                {{ "interface_company" | translationPipe }}
                <app-sort-toggle
                  [sorting]="sorting"
                  sortedBy="company"
                  (toggle)="launchSearch()"
                ></app-sort-toggle>
              </th>
              <th scope="col">
                {{ "interface_firstname" | translationPipe }}
                <app-sort-toggle
                  [sorting]="sorting"
                  sortedBy="firstname"
                  (toggle)="launchSearch()"
                ></app-sort-toggle>
              </th>
              <th scope="col">
                {{ "interface_lastname" | translationPipe }}
                <app-sort-toggle
                  [sorting]="sorting"
                  sortedBy="lastname"
                  (toggle)="launchSearch()"
                ></app-sort-toggle>
              </th>
              <th scope="col">
                {{ "interface_email" | translationPipe }}
                <app-sort-toggle
                  [sorting]="sorting"
                  sortedBy="email"
                  (toggle)="launchSearch()"
                ></app-sort-toggle>
              </th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let user of users">
              <td *ngIf="isSuperAdmin()">
                {{ user.company.name }}
              </td>
              <td>
                {{ user.firstname }}
              </td>
              <td>
                {{ user.lastname }}
              </td>
              <td>
                {{ user.email }}
              </td>
              <td class="actions">
                <em
                  *ngIf="displayEditUser(user)"
                  class="fas fa-pen"
                  (click)="openModalToEditUser(user)"
                ></em>
                <em
                  *ngIf="displayDisableUser(user)"
                  class="fas fa-pause"
                  (click)="disableUser(user)"
                ></em>
                <em
                  *ngIf="displayEnabledUser(user)"
                  class="fas fa-play"
                  (click)="activateUser(user)"
                ></em>
                <em
                  *ngIf="displayDeleteUser(user)"
                  class="fas fa-trash"
                  (click)="deleteUser(user)"
                ></em>
                <em
                  *ngIf="displayProjectUser(user)"
                  class="fas fa-bars"
                  (click)="openModalProject(user)"
                ></em>
              </td>
            </tr>
            <tr *ngIf="users.length == 0">
              <td colspan="5" class="text-center">
                {{ "interface_member_user_not_found" | translationPipe }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div *ngIf="users.length > 0" class="paginationContainer">
        <app-pagination-bar
          [pager]="pager"
          (selectedPage)="setPage($event)"
        ></app-pagination-bar>
      </div>
    </div>
  </div>

  <app-user-management-modal (changeMade)="launchSearch()">
  </app-user-management-modal>

  <app-delete-user-modal (changeMade)="launchSearch()"> </app-delete-user-modal>

  <app-open-project-modal> </app-open-project-modal>

  <app-confirm-modal
    [id]="'user-deactivate'"
    [title]="'interface_user_deactivate_title' | translationPipe"
    [content]="
      'interface_user_deactivate_body' | translationPipe: getUserFullname()
    "
    (answer)="confirmEnableDisableUser($event)"
  >
  </app-confirm-modal>

  <app-confirm-modal
    [id]="'user-activate'"
    [title]="'interface_user_activate_title' | translationPipe"
    [content]="
      'interface_user_activate_body' | translationPipe: getUserFullname()
    "
    (answer)="confirmEnableDisableUser($event)"
  >
  </app-confirm-modal>
</ng-container>
