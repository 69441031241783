/* eslint-disable @typescript-eslint/ban-types */

import { Type, Exclude } from 'class-transformer';

export class Page<T> {
  @Type((options) => (options?.newObject as Page<T>).T)
  content!: Array<T>;

  empty!: boolean;
  first!: boolean;
  last!: boolean;
  size!: number;
  number!: number;
  numberOfElements!: number;
  totalElements!: number;
  totalPages!: number;

  @Exclude() public T: Function;

  constructor(T: Function) {
    this.T = T;
  }
}
