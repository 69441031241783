<div class="sidebar-content">
  <sidebar-user-mgmt [isCollapsible]="collapsible"></sidebar-user-mgmt>

  <div
    class="scenarios-management"
    *ngIf="globalService.listsReady && projectIsSingleUse"
  >
    <div class="scenario-title-container">
      <p class="scenario-title">Scenarios</p>
    </div>
    <div class="scrollableMenu">
      <div
        class="scenario-container"
        *ngFor="let scenario of this.project.scenarios"
      >
        <ul class="nav flex-column">
          <li class="menu-li-scenario">
            <div class="scenario-name-container">
              <div class="scenario-name">
                <label
                  class="switch"
                  matTooltip="Cannot disable the reference scenario"
                  [matTooltipDisabled]="!isCurrentReferenceScenario(scenario)"
                >
                  <input
                    type="checkbox"
                    (change)="switchIncludeInAssess(scenario)"
                    [checked]="isSelected(scenario)"
                    [disabled]="isCurrentReferenceScenario(scenario)"
                  />
                  <div class="slider round"></div>
                </label>
                <a class="scenario-header-name">{{ scenario.name }}</a>
                <a
                  [ngClass]="{
                    isReference: isCurrentReferenceScenario(scenario),
                    disabled: !isSelected(scenario)
                  }"
                  class="fas fa-star referenceIcon"
                  (click)="
                    isSelected(scenario) && switchSelectAsReference(scenario)
                  "
                ></a>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div class="scenarios-management" *ngIf="!projectIsSingleUse">
    <div class="scenario-title-container">
      <p class="scenario-title">
        {{ "interface_mother_project" | translationPipe }}
      </p>
    </div>
    <div class="scenario-container">
      <div class="scenario-name-container">
        <div class="scenario-name">
          <a class="scenario-header-name"
            >{{
              rechargeProject.motherProject != null
                ? rechargeProject.motherProject.name
                : ("interface_project_undefined" | translationPipe)
            }}
            <p
              class="project-name-complement"
              *ngIf="rechargeProject.motherProject"
            >
              {{ "interface_compare_mother_refilled" | translationPipe }}
              {{ rechargeProject.nbMotherUsed }}
              {{ "interface_compare_times" | translationPipe }}
            </p></a
          >
        </div>
      </div>
    </div>
    <div class="scenario-title-container">
      <p class="scenario-title">
        {{ "interface_daughter_project" | translationPipe }}
      </p>
    </div>
    <div class="scenario-container">
      <div class="scenario-name-container">
        <div class="scenario-name">
          <a class="scenario-header-name"
            >{{
              rechargeProject.daughterProject != null
                ? rechargeProject.daughterProject.name
                : ("interface_project_undefined" | translationPipe)
            }}
            <p
              class="project-name-complement"
              *ngIf="rechargeProject.daughterProject"
            >
              {{ "interface_compare_daughter_used" | translationPipe }}
              {{ rechargeProject.nbDaughterUsed }}
              {{ "interface_compare_times" | translationPipe }}
            </p>
          </a>
        </div>
      </div>
    </div>
  </div>

  <sidebar-footer></sidebar-footer>
</div>
