<ng-template #materialcreationmodal let-modal>
  <div class="modal-content">
    <div class="modal-header modal-spacing">
      <div class="modal-title">
        {{ "interface_component_add_material" | translationPipe }}
      </div>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        aria-label="Close"
        (click)="close()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <form [formGroup]="checkoutForm" (ngSubmit)="onSubmit()">
      <div class="modal-body break-word modal-spacing">
        <div class="form-group">
          <label for="mt">
            {{ "interface_component_material_type" | translationPipe }}</label
          >
          <select
            id="mt"
            class="custom-select form-control"
            [ngClass]="{ gray: checkoutForm.controls.materialType.pristine }"
            (change)="setConversionProcesses()"
            formControlName="materialType"
          >
            <option class="select-default" value="null">
              {{ "interface_select_placeholder" | translationPipe }}
            </option>
            <option *ngFor="let mt of materialTypes" [ngValue]="mt">
              {{ mt.valueIntl }}
            </option>
          </select>
          <validation-messages
            [control]="checkoutForm.get('materialType')"
            class="form-invalid-message"
          >
          </validation-messages>
        </div>
        <div class="form-group">
          <label for="cp">
            {{
              "interface_component_material_conversion_process"
                | translationPipe
            }}</label
          >
          <select
            id="cp"
            class="custom-select form-control"
            [ngClass]="{
              gray: checkoutForm.controls.conversionProcess.pristine
            }"
            formControlName="conversionProcess"
          >
            <option class="select-default" value="null">
              {{ "interface_select_placeholder" | translationPipe }}
            </option>
            <option *ngFor="let cp of conversionProcesses" [ngValue]="cp">
              {{ cp.valueIntl }}
            </option>
          </select>
          <span
            class="process-help"
            *ngIf="
              checkoutForm.controls.conversionProcess.value !== null &&
              checkoutForm.controls.conversionProcess.value.label
            "
          >
            <em
              *ngIf="getHelpBoxTranslation() !== undefined"
              class="fas fa-info-circle"
            ></em>
            {{ getHelpBoxTranslation() | translationPipe }}
          </span>
          <validation-messages
            [control]="checkoutForm.get('conversionProcess')"
            class="form-invalid-message"
          >
          </validation-messages>
        </div>
        <div class="row">
          <div class="col-md-5">
            <label for="mass">{{
              "interface_component_material_mass" | translationPipe
            }}</label>
          </div>
          <div class="col-md-5">
            <label class="mr-2" for="rc">{{
              "interface_component_material_recycled_content" | translationPipe
            }}</label>
            <span
              matTooltip="{{ 'helpbox_material_PCR' | translationPipe }} "
              matTooltipPosition="above"
              matTooltipClass="custom-tooltip"
            >
              <em class="fas fa-question-circle"></em>
            </span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-5">
            <input
              id="mass"
              class="form-control inline"
              type="number"
              formControlName="mass"
              min="{{ CustomValidators.nonZeroDecimalValidator.min }}"
            />
            <span class="pl"> g </span>
          </div>
          <div class="col-md-5">
            <input
              id="rc"
              class="form-control inline"
              type="number"
              formControlName="recycledContent"
              min="{{ CustomValidators.percentageValidator.min }}"
              max="{{ CustomValidators.percentageValidator.max }}"
            />
            <span class="pl"> % </span>
          </div>
        </div>
        <div class="row pb">
          <div class="col-md-5 pad-right">
            <validation-messages
              [control]="checkoutForm.get('mass')"
              class="form-invalid-message"
            >
            </validation-messages>
          </div>
          <div class="col-md-5 pad-right">
            <validation-messages
              [control]="checkoutForm.get('recycledContent')"
              class="form-invalid-message"
            ></validation-messages>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-cancel" (click)="close()">
          {{ "interface_cancel" | translationPipe }}
        </button>
        <button
          type="submit"
          class="btn btn-primary login-btn"
          [disabled]="checkoutForm.disabled || !checkoutForm.valid"
        >
          {{ "interface_add" | translationPipe }}
        </button>
      </div>
    </form>
  </div>
</ng-template>
