import { PublishedScenario } from '@/app/model/packaging-model/published-scenario';
import { PublishedScenarioEditableFields } from '@/app/model/packaging-model/published-scenario-editable-fields';
import { GlobalService } from '@/app/services/global.service';
import { ProjectService } from '@/app/services/project.service';
import { CustomValidators } from '@/app/utils/custom-validators';
import { TemplateRef } from '@angular/core';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import {
  NgbModalRef,
  NgbModal,
  ModalDismissReasons,
} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-edit-published-scenario-modal',
  templateUrl: './edit-published-scenario-modal.component.html',
  styleUrls: ['./edit-published-scenario-modal.component.css'],
})
export class EditPublishedScenarioModalComponent implements OnChanges {
  @Input() currentPublishedScenario: PublishedScenario | undefined = undefined;
  @Output() changeMade = new EventEmitter<any>();

  modal: NgbModalRef | undefined;
  checkoutForm: UntypedFormGroup;
  isBusy = false;

  get availableStatus() {
    return this.globalService.availablePublishedScenarioStatusList;
  }

  @ViewChild('publishedscenarioeditionmodal')
  private readonly templateModal!: TemplateRef<any>;

  constructor(
    private modalService: NgbModal,
    private formBuilder: UntypedFormBuilder,
    private projectService: ProjectService,
    private globalService: GlobalService
  ) {
    this.checkoutForm = this.formBuilder.group({
      name: [
        { value: '', disabled: this.isBusy },
        Validators.compose([
          Validators.required,
          Validators.maxLength(255),
          CustomValidators.notSpaceValidator(),
        ]),
      ],
      status: [
        { value: '', disabled: this.isBusy },
        Validators.compose([Validators.required]),
      ],
    });
  }

  ngOnChanges() {
    this.checkoutForm.reset();
    if (this.currentPublishedScenario) {
      this.checkoutForm.controls['name'].setValue(
        this.currentPublishedScenario.name
      );
      this.checkoutForm.controls['status'].setValue(
        this.currentPublishedScenario.status
      );
    }
  }

  async onSubmit(customerData: any) {
    this.isBusy = true;

    if (this.currentPublishedScenario != undefined) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
      this.currentPublishedScenario.name = customerData.name;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
      this.currentPublishedScenario.status = customerData.status;
      const editableFields = new PublishedScenarioEditableFields();
      editableFields.mergeEditableFields(this.currentPublishedScenario);
      await this.projectService.editPublishedScenario(
        this.currentPublishedScenario.id,
        editableFields
      );
    }
    this.checkoutForm.reset();
    this.isBusy = false;
    this.changeMade.emit();
    this.modal?.close();
  }

  open() {
    this.resetFields();
    this.modal = this.modalService.open(this.templateModal);
    this.modal.result.then(null, (reason: any) => {
      this.resetAfterDismissal(reason);
    });
  }

  close() {
    this.resetFields();
    this.modal?.close();
  }

  resetAfterDismissal(reason: any) {
    if (
      reason === ModalDismissReasons.ESC ||
      reason === ModalDismissReasons.BACKDROP_CLICK
    ) {
      this.resetFields();
    }
  }

  resetFields() {
    this.checkoutForm.reset();
    if (this.currentPublishedScenario) {
      this.checkoutForm.controls['name'].setValue(
        this.currentPublishedScenario.name
      );
      this.checkoutForm.controls['status'].setValue(
        this.currentPublishedScenario.status
      );
    }
  }
}
