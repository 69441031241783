import { PublishedScenario } from './published-scenario';

export class PublishedScenarioEditableFields {
  name!: string;
  status!: string;

  mergeEditableFields(scenario: PublishedScenario): void {
    this.name = scenario.name;
    this.status = scenario.status;
  }
}
