import { Role } from '@/app/model/user/authority';

export class Collaborator {
  id!: number;
  firstname!: string;
  lastname!: string;
  email!: string;
  brand!: string;
  authorities!: Role[];
}
