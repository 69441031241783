<div
  *ngIf="translationService.translationsLoaded | async"
  class="container-fluid background-colored"
  (window:resize)="sidebarService.onResize($event)"
>
  <div class="row full-height">
    <div id="sidebar" class="sidebar col-lg-3 min-vh-100 position-fixed">
      <sidebar-component [collapsible]="false"></sidebar-component>
    </div>
    <div id="sidebarCollapsible" class="sidebar min-vh-100">
      <sidebar-component [collapsible]="true"></sidebar-component>
    </div>
    <div id="overlay" (click)="sidebarService.closeSidebar()"></div>

    <div id="projectMain" class="col-lg-9 offset-lg-3 project-main">
      <button
        id="openCollapsibleSidebar"
        class="btn-color"
        (click)="sidebarService.openPackDefSidebar()"
      >
        <em class="fas fa-bars"></em>
      </button>
      <div class="col-md-10 project-container">
        <div class="back">
          <button class="btn btn-secondary back-btn" (click)="goBack()">
            <em class="fas fa-arrow-left"></em
            >{{ "interface_back" | translationPipe }}
          </button>
        </div>
        <div class="project-title">
          <span class="project-edit">
            <span class="project-name">Methodology overview</span>
          </span>
        </div>
        <div class="explanation-container">
          <div class="general-info-block" id="results">
            <div class="row">
              <div class="col-md-12">
                <h4 class="box-title">
                  {{
                    "interface_explanation_page_subtitle_results"
                      | translationPipe
                  }}
                </h4>
              </div>
            </div>
            <div class="main-actions-container">
              <p class="explanation-normal-text">
                {{
                  "interface_explanation_page_results_first_line"
                    | translationPipe
                }}
              </p>
              <ul class="explanation-normal-text">
                <li>
                  <span class="bold">{{
                    "interface_explanation_page_results_bullet_point_1_bold"
                      | translationPipe
                  }}</span>
                  <span>{{
                    "interface_explanation_page_results_bullet_point_1_normal"
                      | translationPipe
                  }}</span>
                  <span class="underline"
                    ><a (click)="onLinkClick('units')">{{
                      "interface_explanation_page_results_bullet_point_1_unit_underline"
                        | translationPipe
                    }}</a></span
                  >
                  <span>{{
                    "interface_explanation_page_results_bullet_point_1_unit_parenthesis"
                      | translationPipe
                  }}</span>
                  <span class="underline">
                    <a (click)="onLinkClick('scope')">
                      {{
                        "interface_explanation_page_results_bullet_point_1_scope_underline"
                          | translationPipe
                      }}
                    </a>
                  </span>
                </li>
                <li>
                  <span class="bold">{{
                    "interface_explanation_page_results_bullet_point_2_bold"
                      | translationPipe
                  }}</span>
                  <span>{{
                    "interface_explanation_page_results_bullet_point_2_normal"
                      | translationPipe
                  }}</span>
                  <ul>
                    <li>
                      <span>{{
                        "interface_explanation_page_results_bullet_point_2_subbulletpoint_1"
                          | translationPipe
                      }}</span>
                      <span class="underline"
                        ><a (click)="onLinkClick('scope')">{{
                          "interface_explanation_page_results_bullet_point_2_subbulletpoint_1_underline"
                            | translationPipe
                        }}</a></span
                      >
                    </li>
                    <li>
                      <span>{{
                        "interface_explanation_page_results_bullet_point_2_subbulletpoint_2"
                          | translationPipe
                      }}</span>
                      <span class="underline"
                        ><a (click)="onLinkClick('scope')">{{
                          "interface_explanation_page_results_bullet_point_2_subbulletpoint_2_underline"
                            | translationPipe
                        }}</a></span
                      >
                    </li>
                    <li>
                      <span>{{
                        "interface_explanation_page_results_bullet_point_2_subbulletpoint_3"
                          | translationPipe
                      }}</span>
                      <span class="underline"
                        ><a (click)="onLinkClick('single-score')">{{
                          "interface_explanation_page_results_bullet_point_2_subbulletpoint_3_underline"
                            | translationPipe
                        }}</a></span
                      >
                    </li>
                  </ul>
                </li>
                <li>
                  <span class="bold">{{
                    "interface_explanation_page_results_bullet_point_3_bold"
                      | translationPipe
                  }}</span>
                  <span>{{
                    "interface_explanation_page_results_bullet_point_3_normal"
                      | translationPipe
                  }}</span>
                  <span class="underline"
                    ><a (click)="onLinkClick('units')">{{
                      "interface_explanation_page_results_bullet_point_1_unit_underline"
                        | translationPipe
                    }}</a></span
                  >
                  <span>{{
                    "interface_explanation_page_results_bullet_point_1_unit_parenthesis"
                      | translationPipe
                  }}</span>
                  <span class="underline"
                    ><a (click)="onLinkClick('scope')">{{
                      "interface_explanation_page_results_bullet_point_1_scope_underline"
                        | translationPipe
                    }}</a></span
                  >
                  <span>{{
                    "interface_explanation_page_results_bullet_point_3_after_scope"
                      | translationPipe
                  }}</span>

                  <span>{{ "" | translationPipe }}</span>
                </li>
              </ul>
            </div>
          </div>
          <div class="general-info-block" id="single-score">
            <div class="row">
              <div class="col-md-12">
                <h4 class="box-title">
                  {{
                    "interface_explanation_page_subtitle_single_score"
                      | translationPipe
                  }}
                </h4>
              </div>
            </div>
            <div class="main-actions-container">
              <p class="explanation-normal-text">
                {{
                  "interface_explanation_page_single_score_paragraph_1"
                    | translationPipe
                }}
              </p>
              <p class="explanation-normal-text">
                {{
                  "interface_explanation_page_single_score_paragraph_2"
                    | translationPipe
                }}
              </p>
              <ul class="explanation-normal-text">
                <li>
                  <span>{{
                    "interface_explanation_page_single_score_paragraph_2_bullet_1"
                      | translationPipe
                  }}</span>
                </li>
                <li>
                  <span>{{
                    "interface_explanation_page_single_score_paragraph_2_bullet_2"
                      | translationPipe
                  }}</span>
                </li>
                <li>
                  <span>{{
                    "interface_explanation_page_single_score_paragraph_2_bullet_3"
                      | translationPipe
                  }}</span>
                </li>
              </ul>
              <p class="explanation-normal-text">
                {{
                  "interface_explanation_page_single_score_first_graph_title"
                    | translationPipe
                }}
              </p>
              <div class="img-container">
                <a
                  ><img
                    class="calc-step-img"
                    src="assets/calculation_steps.png"
                /></a>
              </div>
            </div>
          </div>
          <div class="general-info-block" id="units">
            <div class="row">
              <div class="col-md-12">
                <h4 class="box-title">
                  {{
                    "interface_explanation_page_subtitle_unit" | translationPipe
                  }}
                </h4>
              </div>
            </div>
            <div class="main-actions-container">
              <p class="explanation-normal-text">
                {{
                  "interface_explanation_page_unit_paragraph_1"
                    | translationPipe
                }}
              </p>
              <p class="explanation-normal-text">
                {{
                  "interface_explanation_page_unit_paragraph_2"
                    | translationPipe
                }}
              </p>
              <p class="explanation-normal-text bold">
                {{
                  "interface_explanation_page_unit_paragraph_3_bold"
                    | translationPipe
                }}
              </p>
              <ul class="explanation-normal-text bold">
                <li>
                  <span>{{
                    "interface_explanation_page_unit_paragraph_3_bold_bullet_1"
                      | translationPipe
                  }}</span>
                </li>
                <li>
                  <span>{{
                    "interface_explanation_page_unit_paragraph_3_bold_bullet_2"
                      | translationPipe
                  }}</span>
                </li>
                <li>
                  <span>{{
                    "interface_explanation_page_unit_paragraph_3_bold_bullet_3"
                      | translationPipe
                  }}</span>
                </li>
                <li>
                  <span>{{
                    "interface_explanation_page_unit_paragraph_3_bold_bullet_4"
                      | translationPipe
                  }}</span>
                </li>
                <li>
                  <span>{{
                    "interface_explanation_page_unit_paragraph_3_bold_bullet_5"
                      | translationPipe
                  }}</span>
                </li>
                <li>
                  <span>{{
                    "interface_explanation_page_unit_paragraph_3_bold_bullet_6"
                      | translationPipe
                  }}</span>
                </li>
                <li>
                  <span>{{
                    "interface_explanation_page_unit_paragraph_3_bold_bullet_7"
                      | translationPipe
                  }}</span>
                </li>
              </ul>
            </div>
          </div>
          <div class="general-info-block" id="scope">
            <div class="row">
              <div class="col-md-12">
                <h4 class="box-title">
                  {{
                    "interface_explanation_page_subtitle_scope"
                      | translationPipe
                  }}
                </h4>
              </div>
            </div>
            <div class="main-actions-container explanation-normal-text">
              <span class="explanation-normal-text">
                {{
                  "interface_explanation_page_scope_paragraph_1"
                    | translationPipe
                }}</span
              >
              <span class="explanation-normal-text underline">
                {{
                  "interface_explanation_page_results_bullet_point_1_unit_underline"
                    | translationPipe
                }}</span
              >
              <span class="explanation-normal-text">{{
                "interface_explanation_page_scope_paragraph_1_2"
                  | translationPipe
              }}</span>
              <p>
                {{
                  "interface_explanation_page_scope_paragraph_2"
                    | translationPipe
                }}
              </p>
              <ul class="explanation-normal-text">
                <li>
                  <span>{{
                    "interface_explanation_page_scope_paragraph_2_bullet_1"
                      | translationPipe
                  }}</span>
                </li>
                <li>
                  <span>{{
                    "interface_explanation_page_scope_paragraph_2_bullet_2"
                      | translationPipe
                  }}</span>
                </li>
                <li>
                  <span>{{
                    "interface_explanation_page_scope_paragraph_2_bullet_3"
                      | translationPipe
                  }}</span>
                  <ul>
                    <li>
                      <span>{{
                        "interface_explanation_page_scope_paragraph_2_bullet_3_sub1"
                          | translationPipe
                      }}</span>
                    </li>
                    <li>
                      <span>{{
                        "interface_explanation_page_scope_paragraph_2_bullet_3_sub2"
                          | translationPipe
                      }}</span>
                    </li>
                  </ul>
                </li>
              </ul>
              <div class="explanation-table-container">
                <table class="table">
                  <thead>
                    <th>
                      {{
                        "interface_explanation_page_scope_table_header_1"
                          | translationPipe
                      }}
                    </th>
                    <th>
                      {{
                        "interface_explanation_page_scope_table_header_2"
                          | translationPipe
                      }}
                    </th>
                  </thead>
                  <tbody>
                    <tr>
                      <td rowspan="3">
                        {{
                          "interface_explanation_page_scope_table_row_1_col_1"
                            | translationPipe
                        }}
                      </td>
                      <td>
                        {{
                          "interface_explanation_page_scope_table_row_1_col_2_l1"
                            | translationPipe
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {{
                          "interface_explanation_page_scope_table_row_1_col_2_l2"
                            | translationPipe
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {{
                          "interface_explanation_page_scope_table_row_1_col_2_l3"
                            | translationPipe
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {{
                          "interface_explanation_page_scope_table_row_2_col_1"
                            | translationPipe
                        }}
                      </td>
                      <td>
                        {{
                          "interface_explanation_page_scope_table_row_2_col_2"
                            | translationPipe
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {{
                          "interface_explanation_page_scope_table_row_3_col_1"
                            | translationPipe
                        }}
                      </td>
                      <td>
                        {{
                          "interface_explanation_page_scope_table_row_3_col_2"
                            | translationPipe
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {{
                          "interface_explanation_page_scope_table_row_4_col_1"
                            | translationPipe
                        }}
                      </td>
                      <td>
                        {{
                          "interface_explanation_page_scope_table_row_4_col_2"
                            | translationPipe
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {{
                          "interface_explanation_page_scope_table_row_5_col_1"
                            | translationPipe
                        }}
                      </td>
                      <td>
                        {{
                          "interface_explanation_page_scope_table_row_5_col_2"
                            | translationPipe
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {{
                          "interface_explanation_page_scope_table_row_6_col_1"
                            | translationPipe
                        }}
                      </td>
                      <td>
                        {{
                          "interface_explanation_page_scope_table_row_6_col_2"
                            | translationPipe
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td rowspan="4">
                        {{
                          "interface_explanation_page_scope_table_row_7_col_1"
                            | translationPipe
                        }}
                      </td>
                      <td>
                        {{
                          "interface_explanation_page_scope_table_row_7_col_2_l1"
                            | translationPipe
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {{
                          "interface_explanation_page_scope_table_row_7_col_2_l2"
                            | translationPipe
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {{
                          "interface_explanation_page_scope_table_row_7_col_2_l3"
                            | translationPipe
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {{
                          "interface_explanation_page_scope_table_row_7_col_2_l4"
                            | translationPipe
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {{
                          "interface_explanation_page_scope_table_row_8_col_1"
                            | translationPipe
                        }}
                      </td>
                      <td>
                        {{
                          "interface_explanation_page_scope_table_row_8_col_2"
                            | translationPipe
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td rowspan="6">
                        {{
                          "interface_explanation_page_scope_table_row_9_col_1"
                            | translationPipe
                        }}
                      </td>
                      <td>
                        {{
                          "interface_explanation_page_scope_table_row_9_col_2_l1"
                            | translationPipe
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {{
                          "interface_explanation_page_scope_table_row_9_col_2_l2"
                            | translationPipe
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {{
                          "interface_explanation_page_scope_table_row_9_col_2_l3"
                            | translationPipe
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {{
                          "interface_explanation_page_scope_table_row_9_col_2_l4"
                            | translationPipe
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {{
                          "interface_explanation_page_scope_table_row_9_col_2_l5"
                            | translationPipe
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {{
                          "interface_explanation_page_scope_table_row_9_col_2_l6"
                            | translationPipe
                        }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <p>
                {{ "interface_explanation_page_graph_title" | translationPipe }}
              </p>
              <div class="img-container">
                <a><img class="calc-step-img" src="assets/schema.png" /></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
