import { Component } from '@angular/core';
import { TranslationService } from 'src/app/services/translationService/translation.service';
import { SidebarService } from '@/app/services/sidebarService/sidebar.service';
import { GuestService } from '@/app/services/guest.service';

@Component({
  selector: 'app-container-sidebar',
  templateUrl: './container-sidebar.component.html',
  styleUrls: ['./container-sidebar.component.css'],
})
export class ContainerSidebarComponent {
  constructor(
    public translationService: TranslationService,
    public guestService: GuestService,
    public sidebarService: SidebarService
  ) {}
}
