import { Project } from './project';
import { SingleUseProject } from '@/app/model/packaging-model/project/single-use-project';
import { Type } from 'class-transformer';

export class RechargeProject extends Project {
  @Type(() => SingleUseProject)
  motherProject!: SingleUseProject;

  nbMotherUsed = 1;

  @Type(() => SingleUseProject)
  daughterProject!: SingleUseProject;

  nbDaughterUsed = 1;

  static copy(p: RechargeProject): RechargeProject {
    const newProject: RechargeProject = new RechargeProject();
    newProject.fillFields(p);
    return newProject;
  }

  constructor() {
    super();
  }

  fillFields(p: RechargeProject): void {
    super.fillFields(p);
    this.motherProject = p.motherProject;
    this.nbMotherUsed = p.nbMotherUsed;
    this.daughterProject = p.daughterProject;
    this.nbDaughterUsed = p.nbDaughterUsed;
  }

  editableFieldsHaveChanged(p: RechargeProject): boolean {
    if (
      (this.motherProject != null && p.motherProject == null) ||
      (this.motherProject == null && p.motherProject != null) ||
      (this.daughterProject != null && p.daughterProject == null) ||
      (this.daughterProject == null && p.daughterProject != null)
    ) {
      return true;
    }

    let result: boolean =
      this.nbMotherUsed != p.nbMotherUsed ||
      this.nbDaughterUsed != p.nbDaughterUsed;

    if (this.motherProject != null) {
      result = result || this.motherProject.id != p.motherProject.id;
    }

    if (this.daughterProject != null) {
      result = result || this.daughterProject.id != p.daughterProject.id;
    }
    return result;
  }

  isComplete() {
    return (
      this.motherProject != null &&
      this.daughterProject != null &&
      this.nbMotherUsed > 0 &&
      this.nbDaughterUsed > 0
    );
  }
}
