import { Auth } from 'aws-amplify';
import { isRole, Role } from '@/app/model/user/authority';
import assert from 'assert';

export class JWTUser {
  username!: string;
  firstname!: string;
  lastname!: string;
  email!: string;
  authorities!: Role[];
  locale!: string;

  get fullname(): string {
    return this.firstname + ' ' + this.lastname;
  }

  static async from(cognitoUser: any): Promise<JWTUser> {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
    const attributes = cognitoUser.attributes;

    const user: JWTUser = new JWTUser();
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
    user.firstname = attributes.given_name;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
    user.lastname = attributes.family_name;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
    user.email = attributes.email;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
    user.locale = attributes.locale;

    const session: any = await Auth.currentSession();
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
    const groups: string[] = session.idToken.payload['cognito:groups'];

    user.authorities = groups.map((group) => {
      assert(isRole(group));
      return Role[group];
    });

    return Promise.resolve(user);
  }
}
