import { PkgComponentApiService } from '@/app/api/pkg-component-api.service';
import { ChartResult } from '@/app/model/results/chartResult';
import { ComponentResult } from '@/app/model/results/component-result';
import { Indicator } from '@/app/model/results/indicator';
import { ChartDataBuilderService } from '@/app/services/charts/chart-data-builder.service';
import { Component, TemplateRef, ViewChild } from '@angular/core';
import {
  NgbModal,
  NgbModalOptions,
  NgbModalRef,
} from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { GuestService } from '@/app/services/guest.service';

declare function buildChart(
  scenarioName: Array<string>,
  chart: string,
  results: Array<ChartResult>,
  number: number,
  xLabel: string
): any;

declare function resetCanvas(canvasNames: Array<string>): any;

@Component({
  selector: 'app-breakdown-by-material-modal',
  templateUrl: './breakdown-by-material-modal.component.html',
  styleUrls: ['./breakdown-by-material-modal.component.css'],
})
export class BreakdownByMaterialModalComponent {
  constructor(
    private modalService: NgbModal,
    private componentService: PkgComponentApiService,
    private chartDataBuilderService: ChartDataBuilderService,
    public guestService: GuestService
  ) {}

  componentId!: number;
  MODAL_OPTIONS: NgbModalOptions = { size: 'xl', centered: true };
  modal!: NgbModalRef;

  @ViewChild('breakdownByMaterial') templateModal!: TemplateRef<any>;

  open(
    componentId: number,
    componentName: string,
    isSingleScore: boolean,
    indicator: Indicator,
    rechargeProjectId?: number
  ): void {
    this.componentId = componentId;
    this.modal = this.modalService.open(this.templateModal, this.MODAL_OPTIONS);

    this.modal.dismissed.pipe(take(1)).subscribe(() => {
      resetCanvas([`materialBreakdownForComponentChart_${this.componentId}`]);
    });

    if (!this.guestService.isUserGuest()) {
      let assessMethod: Observable<ComponentResult>;
      if (rechargeProjectId) {
        assessMethod = isSingleScore
          ? this.componentService.assessRechargePkgComponentForSingleScore(
              componentId,
              rechargeProjectId
            )
          : this.componentService.assessRechargePkgComponentForIndicator(
              componentId,
              indicator.id,
              rechargeProjectId
            );
      } else {
        assessMethod = isSingleScore
          ? this.componentService.assessPkgComponentForSingleScore(componentId)
          : this.componentService.assessPkgComponentForIndicator(
              componentId,
              indicator.id
            );
      }

      assessMethod.pipe(take(1)).subscribe((result) => {
        const chartId = `materialBreakdownForComponentChart_${componentId}`;
        buildChart(
          [componentName],
          chartId,
          this.chartDataBuilderService.buildMaterialChartData(result),
          25,
          indicator.displayUnit
        );
      });
    }
  }
}
