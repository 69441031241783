import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { FeatureStatus } from './feature-status';
import { Feature } from './feature';
import { ActivationRule } from './activation-rule';
import { MatchingRuleEffect } from './matching-rule-effect.enum';

@Injectable({
  providedIn: 'root',
})
export class FeatureService {
  static readonly LOCAL_STORAGE_TOKEN_PARAM_NAME: string = 'access_token';
  private _initialized = false;

  private _stream: Subject<FeatureStatus> = new Subject<FeatureStatus>();
  dispatcher$ = this._stream.asObservable();

  private _features = new Map<Feature, FeatureStatus>();
  private _rules = new Map<ActivationRule, Array<Feature>>();

  initialize() {
    this._initialized = true;
    this._features.forEach((fs, f) => this._refreshFeatureStatus(f));
  }

  declareFeature(f: Feature) {
    if (!this._features.has(f)) {
      const matchingRules: Array<ActivationRule> = new Array<ActivationRule>();
      for (const key of this._rules.keys()) {
        if (key.perfectOrCascadeMatch(f)) {
          matchingRules.push(key);
          // @ts-ignore
          this._rules.get(key).push(f);
        }
      }

      const perfectMatchList: Array<ActivationRule> =
        new Array<ActivationRule>();
      const cascadeMatchList: Array<ActivationRule> =
        new Array<ActivationRule>();

      for (const rule of matchingRules) {
        if (rule.perfectMatch(f)) perfectMatchList.push(rule);

        if (rule.cascadeMatch(f)) cascadeMatchList.push(rule);
      }

      this._features.set(
        f,
        new FeatureStatus(f, perfectMatchList, cascadeMatchList)
      );
      this._refreshFeatureStatus(f);
    }
  }

  declareRule(r: ActivationRule) {
    if (!this._rules.has(r)) {
      const perfectOrCascadeMatchingFeatures: Array<Feature> =
        new Array<Feature>();
      for (const feature of this._features.keys()) {
        if (r.perfectOrCascadeMatch(feature))
          perfectOrCascadeMatchingFeatures.push(feature);
      }
      const matchingFeatures: Array<Feature> = Array.from(
        perfectOrCascadeMatchingFeatures
      );
      this._rules.set(r, matchingFeatures);
      matchingFeatures.forEach((f) => {
        if (r.perfectMatch(f)) {
          // @ts-ignore
          this._features.get(f)._perfectMatchRules.push(r);
        } else {
          // @ts-ignore
          this._features.get(f)._cascadeMatchRules.push(r);
        }
        this._refreshFeatureStatus(f);
      });
    }
  }

  _refreshFeatureStatus(f: Feature) {
    if (!this._initialized) {
      this._markAsDenied(
        // @ts-ignore
        this._features.get(f),
        'Feature service not initialized'
      );
      return;
    }

    // @ts-ignore
    const s: FeatureStatus = this._features.get(f);
    let explicitAllowExists = false;
    s._perfectMatchRules.forEach((r) => {
      if (r._matchingRuleEffect == MatchingRuleEffect.DENY_FEATURE) {
        this._markAsDenied(s, r._denyReason);
        return;
      } else explicitAllowExists = true;
    });

    for (const r of s._cascadeMatchRules) {
      if (r._matchingRuleEffect == MatchingRuleEffect.DENY_FEATURE) {
        this._markAsDenied(s, r._denyReason);
        return;
      } else if (
        r._matchingRuleEffect == MatchingRuleEffect.ALLOW_IN_CASCADE_FEATURE
      )
        explicitAllowExists = true;
    }

    if (explicitAllowExists) this._markAsAllowed(s);
    else this._markAsDenied(s, 'No explicit matching activation rule');
  }

  _markAsAllowed(s: FeatureStatus) {
    const mustDispatch = !s._isActive;
    s._isActive = true;
    // @ts-ignore
    s._inactivityReason = null;
    if (mustDispatch) this._stream.next(s);
  }

  _markAsDenied(s: FeatureStatus, reason: string) {
    const mustDispatch: boolean = s._isActive || s._inactivityReason != reason;
    s._isActive = false;
    s._inactivityReason = reason;
    if (mustDispatch) this._stream.next(s);
  }

  isActive(f: Feature): boolean {
    if (this._features.get(f) == null) return false;
    // @ts-ignore
    return this._features.get(f)._isActive;
  }

  inactivityReason(f: Feature): string {
    if (this._features.get(f) == null) return 'Feature is not declared';
    // @ts-ignore
    return this._features.get(f)._inactivityReason;
  }

  changeRuleMatchingEffect(
    r: ActivationRule,
    effect: MatchingRuleEffect,
    denyReason?: string
  ) {
    if (denyReason == null) denyReason = 'No reason given';
    if (!this._rules.has(r)) throw 'Rule not registered';
    if (effect == MatchingRuleEffect.DENY_FEATURE && denyReason == null)
      throw 'Deny reason cannot be null on deny effect';
    if (r._matchingRuleEffect != effect) {
      r._matchingRuleEffect = effect;
      // @ts-ignore
      r._denyReason =
        effect == MatchingRuleEffect.DENY_FEATURE ? denyReason : null;
      // @ts-ignore
      this._rules.get(r).forEach((f) => this._refreshFeatureStatus(f));
    }
  }
}
