import { Injectable } from '@angular/core';
import { MaterialApiService } from '../api/material-api.service';
import { Material } from '../model/packaging-model/material';
import { PkgComponent } from '@/app/model/packaging-model/component/pkg-component';
import { GuestService } from '@/app/services/guest.service';
import { ProjectService } from '@/app/services/project.service';

@Injectable({
  providedIn: 'root',
})
export class MaterialService {
  constructor(
    private materialApiService: MaterialApiService,
    private guestService: GuestService,
    private projectService: ProjectService
  ) {}

  conversionProcessHelp: Map<string, string> = new Map([
    ['converting_process_wood', 'helpbox_converting_process_wood'],
    ['converting_process_therrmo', 'helpbox_converting_process_therrmo'],
    ['converting_process_thermowc', 'helpbox_converting_process_thermowc'],
    ['converting_process_textile', 'helpbox_converting_process_textile'],
    [
      'converting_process_stretchblow',
      'helpbox_converting_process_stretchblow',
    ],
    ['converting_process_metal', 'helpbox_converting_process_metal'],
    ['converting_process_injection', 'helpbox_converting_process_injection'],
    ['converting_process_glass', 'helpbox_converting_process_glass'],
    ['converting_process_fleece', 'helpbox_converting_process_fleece'],
    ['converting_process_extrusion', 'helpbox_converting_process_extrusion'],
    ['converting_process_cardboard', 'helpbox_converting_process_cardboard'],
    [
      'converting_process_calendering',
      'helpbox_converting_process_calendering',
    ],
    ['converting_process_blow', 'helpbox_converting_process_blow'],
    [
      'converting_process_extruandthermo',
      'helpbox_converting_process_extruandthermo',
    ],
    [
      'converting_process_lpbprocessing',
      'helpbox_converting_process_lpbprocessing',
    ],
    [
      'converting_process_deepdrawsteel',
      'helpbox_converting_process_deepdrawsteel',
    ],
    [
      'converting_process_impactextalu',
      'helpbox_converting_process_impactextalu',
    ],
    [
      'converting_process_sheetrollalu',
      'helpbox_converting_process_sheetrollalu',
    ],
    [
      'converting_process_sheetrollsteel',
      'helpbox_converting_process_sheetrollsteel',
    ],
    [
      'converting_process_injectionTREVA',
      'helpbox_converting_process_injectionTREVA',
    ],
  ]);

  async createMaterial(
    componentOriginal: PkgComponent,
    materialData: Material
  ): Promise<Material> {
    let createdMaterial: Material;
    if (this.guestService.isUserGuest()) {
      createdMaterial = materialData;
      createdMaterial.id = componentOriginal.materials.length;
    } else {
      createdMaterial = await this.materialApiService.createMaterial(
        componentOriginal.id,
        materialData
      );
    }
    componentOriginal.materials.push(createdMaterial);
    this.projectService.saveProjectLocallyIfGuest();
    return createdMaterial;
  }

  async updateMaterial(
    materialLocal: Material,
    componentOriginal: PkgComponent
  ): Promise<Material> {
    // @ts-ignore
    const materialOriginal: Material = componentOriginal.materials.find(
      (v) => v.id == materialLocal.id
    );
    const response: Material = this.guestService.isUserGuest()
      ? materialLocal
      : await this.materialApiService.updateMaterial(
          materialOriginal.id,
          materialLocal
        );
    materialOriginal.mergeAll(response);
    this.projectService.saveProjectLocallyIfGuest();
    return materialOriginal;
  }

  async deleteMaterial(
    componentOriginal: PkgComponent,
    material: Material
  ): Promise<void> {
    await this.materialApiService.deleteMaterial(material.id);
    componentOriginal.materials = componentOriginal.materials.filter(function (
      value
    ) {
      return value.id != material.id;
    });
    this.projectService.saveProjectLocallyIfGuest();
  }

  get getConversionHelpbox() {
    return this.conversionProcessHelp;
  }
}
