<ng-template #exportscenariomodal let-modal>
  <div class="modal-header modal-spacing">
    <div class="modal-title" id="share">
      {{ "interface_publish_scenario" | translationPipe }}
      <span *ngIf="isSelected('select')"> (1/2)</span>
      <span *ngIf="isSelected('rename')"> (2/2)</span>
    </div>
    <button
      type="button"
      class="close"
      data-dismiss="modal"
      aria-label="Close"
      (click)="onCancel()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body break-word modal-spacing">
    <div id="select" *ngIf="isSelected('select')">
      <div class="container-fluid">
        <div class="row">
          <div class="table-responsive">
            <table class="table" aria-describedby="share">
              <thead>
                <tr>
                  <th scope="col">
                    <app-input-checkbox
                      [checked]="allSelected"
                      [indeterminate]="someSelected"
                      (change)="onSelectAll($event)"
                    ></app-input-checkbox>
                  </th>
                  <th scope="col">
                    {{ "interface_name" | translationPipe }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  class="collaborator-row clickable"
                  *ngFor="let s of scenarios"
                >
                  <td class="d-flex" (click)="onSelect(s)" scope="col">
                    <app-input-checkbox
                      [checked]="this.scenarioIsAlreadySelected(s.id)"
                      [clickable]="false"
                    >
                    </app-input-checkbox>
                  </td>
                  <td (click)="onSelect(s)">{{ s.name }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div id="rename" *ngIf="isSelected('rename')">
      <div class="container-fluid">
        <div class="row">
          <div
            *ngFor="let s of selectedScenarios"
            class="export-scenario-container"
          >
            <export-scenario-row
              [scenario]="s"
              (changeMade)="updateScenarioExportLibrary($event)"
            >
            </export-scenario-row>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-cancel" (click)="onCancel()">
      {{ "interface_cancel" | translationPipe }}
    </button>
    <button
      *ngIf="isSelected('select')"
      type="button"
      class="btn btn-primary"
      (click)="select('rename')"
      [disabled]="this.selectedExportScenariosLibrary.length == 0"
    >
      {{ "interface_next" | translationPipe }}
    </button>
    <button
      *ngIf="isSelected('rename')"
      type="button"
      class="btn btn-secondary"
      (click)="select('select')"
    >
      {{ "interface_back" | translationPipe }}
    </button>
    <button
      *ngIf="isSelected('rename')"
      type="button"
      class="btn btn-primary"
      (click)="onSave()"
    >
      {{ "interface_save" | translationPipe }}
    </button>
  </div>
</ng-template>
