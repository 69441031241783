/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Component, Input, ViewChild, TemplateRef } from '@angular/core';
import {
  UntypedFormBuilder,
  Validators,
  UntypedFormGroup,
} from '@angular/forms';
import {
  NgbModal,
  NgbModalOptions,
  NgbModalRef,
} from '@ng-bootstrap/ng-bootstrap';
import { Project } from 'src/app/model/packaging-model/project/project';
import { ProjectService } from 'src/app/services/project.service';
import { SingleUseProject } from 'src/app/model/packaging-model/project/single-use-project';
import { Router } from '@angular/router';
import { plainToClass } from 'class-transformer';
import { CustomValidators } from '@/app/utils/custom-validators';
import { RechargeProject } from '@/app/model/packaging-model/project/recharge-project';

@Component({
  selector: 'app-project-modal[singleuse][createProject]',
  templateUrl: './project-modal.component.html',
})
export class ProjectModalComponent {
  @Input() singleuse: boolean | undefined;
  @Input() createProject!: boolean;
  @Input() inputProject: Project | undefined;

  modal: NgbModalRef | undefined;
  checkoutForm: UntypedFormGroup;
  // @ts-ignore
  isBusy: boolean;

  get isReadOnly(): boolean {
    return this._projectService.isReadOnly;
  }

  private readonly MODAL_OPTIONS: NgbModalOptions = {
    centered: true,
  };

  @ViewChild('projectcreationmodal') templateModal!: TemplateRef<any>;

  constructor(
    private router: Router,
    private modalService: NgbModal,
    private formBuilder: UntypedFormBuilder,
    private _projectService: ProjectService
  ) {
    this.checkoutForm = this.formBuilder.group({
      name: [
        // @ts-ignore
        { value: '', disabled: this.isBusy },
        Validators.compose([
          Validators.required,
          Validators.maxLength(100),
          CustomValidators.notSpaceValidator(),
        ]),
      ],
      // @ts-ignore
      brand: [{ value: '', disabled: this.isBusy }, Validators.maxLength(100)],
      // @ts-ignore
      code: [{ value: '', disabled: this.isBusy }, Validators.maxLength(100)],
      description: [
        // @ts-ignore
        { value: '', disabled: this.isBusy },
        Validators.maxLength(255),
      ],
    });
  }

  // @ts-ignore
  async onSubmit(projectData): void {
    this.isBusy = true;
    if (projectData != null) {
      if (this.singleuse && this.createProject) {
        const p: SingleUseProject = new SingleUseProject();
        p.mergeGeneralInfo(projectData);
        await this._projectService.createSingleUseProject(p);
        void this.router.navigate([
          '/single-use-project',
          this._projectService.currentSingleUseProject.id,
        ]);
      } else if (!this.createProject && this.inputProject) {
        const project: Project = plainToClass(Project, projectData);
        project.id = this.inputProject.id;
        this._projectService.updateProjectGeneralInfo(
          this.inputProject,
          project
        );
      } else {
        const p: RechargeProject = new RechargeProject();
        p.mergeGeneralInfo(projectData);
        await this._projectService.createRechargeProject(p);
        // TODO check for eventually missing await
        void this.router.navigate([
          '/recharge-project',
          this._projectService.currentRechargeProject.id,
        ]);
      }
    }
    this.checkoutForm.reset();
    this.isBusy = false;
    this.modal?.close();
  }

  open(): void {
    this.checkoutForm.reset();
    if (!this.createProject && this.inputProject) {
      this.checkoutForm.controls['name'].setValue(this.inputProject.name);
      this.checkoutForm.controls['brand'].setValue(this.inputProject.brand);
      this.checkoutForm.controls['code'].setValue(this.inputProject.code);
      this.checkoutForm.controls['description'].setValue(
        this.inputProject.description
      );

      if (this.isReadOnly) this.checkoutForm.disable();
    }

    this.modal = this.modalService.open(this.templateModal, this.MODAL_OPTIONS);
  }

  close(): void {
    this.checkoutForm.reset();
    this.modal?.close();
  }

  valuesHaveChanged(projectData: any): boolean {
    if (this.createProject) {
      return true;
    } else if (this.inputProject) {
      return (
        this.inputProject.name != projectData.name ||
        this.inputProject.code != projectData.code ||
        this.inputProject.brand != projectData.brand ||
        this.inputProject.description != projectData.description
      );
    } else return false;
  }
}
