import { ConversionProcess } from './conversion-process';
import { DDL } from './ddl';
import { Type } from 'class-transformer';

export class MaterialTypeGroup extends DDL {
  @Type(() => ConversionProcess)
  conversionProcesses!: Array<ConversionProcess>;

  constructor() {
    super();
  }
}
