import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiUtils } from './api-utils';
import { plainToClass } from 'class-transformer';
import { FinishingProcess } from '../model/packaging-model/finishing-process';

@Injectable({
  providedIn: 'root',
})
export class FinishingProcessApiService {
  buildPath(endPoint: string): string {
    return ApiUtils.BASE_PATH + 'finishing-processes/' + endPoint;
  }

  constructor(private http: HttpClient) {}

  async createFinishingProcess(
    componentId: number,
    processData: FinishingProcess
  ): Promise<FinishingProcess> {
    const url = this.buildPath(componentId.toString());
    const response = await this.http
      .post<FinishingProcess>(url, processData)
      .toPromise();
    return plainToClass(FinishingProcess, response);
  }

  async updateFinishingProcess(
    id: number,
    processData: FinishingProcess
  ): Promise<FinishingProcess> {
    const url = this.buildPath(id.toString());
    const response = await this.http
      .put<FinishingProcess>(url, processData, ApiUtils.HTTP_OPTIONS)
      .toPromise();
    return plainToClass(FinishingProcess, response);
  }

  async deleteFinishingProcess(id: number): Promise<void> {
    const url = this.buildPath(id.toString());
    await this.http
      .delete<FinishingProcess>(url, ApiUtils.HTTP_OPTIONS)
      .toPromise();
  }
}
