<ng-template #openprojectmodal let-modal>
  <div class="modal-content">
    <div class="modal-header modal-spacing">
      <div class="modal-title" id="existing">
        <span *ngIf="!user">{{
          "interface_landing_open_existing" | translationPipe
        }}</span>
        <span *ngIf="user">{{
          "interface_browse_user_project" | translationPipe: getUserFullname()
        }}</span>
      </div>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        aria-label="Close"
        (click)="close()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body break-word modal-spacing">
      <div class="container-fluid">
        <div class="row">
          <div class="top-bar">
            <div class="search-box">
              <input
                type="text"
                class="search-input"
                placeholder="{{ 'interface_search' | translationPipe }} "
                [(ngModel)]="this.searchFilter"
                (ngModelChange)="onSearch($event)"
              />
              <em class="fas fa-search mar-right"> </em>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table" aria-describedby="existing">
              <thead>
                <tr>
                  <th scope="col">
                    {{ "interface_name" | translationPipe }}
                    <app-sort-toggle
                      [sorting]="sorting"
                      sortedBy="name"
                      (toggle)="launchSearch()"
                    ></app-sort-toggle>
                  </th>
                  <th scope="col">
                    {{ "interface_type" | translationPipe }}
                    <app-sort-toggle
                      [sorting]="sorting"
                      sortedBy="type"
                      (toggle)="launchSearch()"
                    ></app-sort-toggle>
                  </th>
                  <th *ngIf="!user" scope="col">
                    {{ "interface_owner" | translationPipe }}
                    <app-sort-toggle
                      [sorting]="sorting"
                      sortedBy="ownerName"
                      (toggle)="launchSearch()"
                    ></app-sort-toggle>
                  </th>
                  <th scope="col">
                    {{ "interface_brand" | translationPipe }}
                    <app-sort-toggle
                      [sorting]="sorting"
                      sortedBy="brand"
                      (toggle)="launchSearch()"
                    ></app-sort-toggle>
                  </th>
                  <th scope="col">
                    {{ "interface_code" | translationPipe }}
                    <app-sort-toggle
                      [sorting]="sorting"
                      sortedBy="code"
                      (toggle)="launchSearch()"
                    ></app-sort-toggle>
                  </th>
                  <th scope="col">
                    {{ "interface_created" | translationPipe }}
                    <app-sort-toggle
                      [sorting]="sorting"
                      sortedBy="createdAt"
                      (toggle)="launchSearch()"
                    ></app-sort-toggle>
                  </th>
                  <th scope="col">
                    {{ "interface_last_opened" | translationPipe }}
                    <app-sort-toggle
                      [sorting]="sorting"
                      sortedBy="lastOpenedAt"
                      (toggle)="launchSearch()"
                    ></app-sort-toggle>
                  </th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  class="project-row clickable"
                  *ngFor="let project of projects"
                  [class.selected]="project == this.selectedProject"
                >
                  <td
                    class="text-left"
                    (click)="setProject(project); openProject()"
                  >
                    <div>
                      <span
                        matTooltip="{{ project.name }} "
                        matTooltipPosition="above"
                        matTooltipClass="custom-tooltip"
                      >
                        {{ project.name }}
                      </span>
                    </div>
                  </td>
                  <td (click)="setProject(project); openProject()">
                    <div>
                      {{
                        project.isSingleUse
                          ? ("interface_single_use" | translationPipe)
                          : ("interface_recharge" | translationPipe)
                      }}
                    </div>
                  </td>
                  <td
                    *ngIf="!user"
                    (click)="setProject(project); openProject()"
                  >
                    <div>{{ project.ownerName }}</div>
                  </td>
                  <td (click)="setProject(project); openProject()">
                    <div>{{ project.brand }}</div>
                  </td>
                  <td (click)="setProject(project); openProject()">
                    <div>{{ project.code }}</div>
                  </td>
                  <td (click)="setProject(project); openProject()">
                    <div>
                      {{
                        project.createdAt.toLocaleString([], {
                          day: "2-digit",
                          month: "2-digit",
                          year: "numeric"
                        })
                      }}
                    </div>
                  </td>
                  <td (click)="setProject(project); openProject()">
                    <div>
                      {{
                        project.lastOpenedAt.toLocaleString([], {
                          day: "2-digit",
                          month: "2-digit",
                          year: "numeric"
                        })
                      }}
                    </div>
                  </td>
                  <td class="actions icons">
                    <em
                      class="fas fa-users"
                      (click)="openCollaboratorsModal(project)"
                    ></em>
                    <em
                      class="fas fa-copy"
                      (click)="duplicateProject(project)"
                    ></em>
                    <em
                      class="fas fa-trash-alt"
                      (click)="deleteProject(project)"
                      *ngIf="project.ownerId == currentUser?.id"
                    ></em>
                  </td>
                </tr>
                <tr *ngIf="projects.length == 0">
                  <td colspan="7" class="text-center">
                    {{ "interface_projects_not_found" | translationPipe }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="">
        <strong></strong>
      </div>
      <div class="paginationContainer">
        <app-pagination-bar
          [pager]="pager"
          (selectedPage)="setPage($event)"
        ></app-pagination-bar>
      </div>
    </div>
    <div class="modal-footer modal-footer-center">
      <button class="btn btn-cancel" (click)="close()">
        {{ "interface_cancel" | translationPipe }}
      </button>
    </div>
  </div>
</ng-template>

<app-confirm-modal
  [id]="'delete-project'"
  [title]="'interface_project_deletion_title' | translationPipe"
  [content]="
    ('interface_project_deletion_body_1' | translationPipe) +
    this.selectedProject?.name +
    ('interface_project_deletion_body_2' | translationPipe) +
    this.selectedProject?.ownerName +
    ' ?'
  "
  (answer)="confirmOrAbortDeletion($event)"
>
</app-confirm-modal>

<app-confirm-modal
  [id]="'duplicate-project-modal'"
  [title]="'interface_project_duplication_title' | translationPipe"
  [content]="
    ('interface_project_duplication_body_1' | translationPipe) +
    this.selectedProject?.name +
    ('interface_project_duplication_body_2' | translationPipe) +
    this.selectedProject?.ownerName +
    ' ?'
  "
  (answer)="confirmOrAbortDuplication($event)"
>
</app-confirm-modal>

<app-project-collaborators-modal
  [project]="this.selectedProject!"
  [filterSelectedOnly]="true"
  [editMode]="false"
>
</app-project-collaborators-modal>
