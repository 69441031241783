import { Component, ViewChild, OnInit } from '@angular/core';
import { CreateCompanyModalComponent } from '@/app/pages/company-management/create-company-modal/create-company-modal.component';
import { Company } from '@/app/model/data/company';
import { CompanyService } from '@/app/services/company.service';
import { Sorting, SortingDirection } from '@/app/model/search/sorting';
import { FilterCriterion } from '@/app/model/search/filter-criterion';
import { Page } from '@/app/model/pagination/page';
import { LoginService } from '@/app/services/login/login.service';
import { User } from '@/app/model/user/user';
import { Router } from '@angular/router';
import { Pager } from '@/app/utils/pager';
import { TranslationService } from '@/app/services/translationService/translation.service';
import { TypedSorting } from '@/app/model/search/typed-sorting';

@Component({
  selector: 'app-company-management',
  templateUrl: './company-management.component.html',
  styleUrls: ['./company-management.component.css'],
})
export class CompanyManagementComponent implements OnInit {
  @ViewChild(CreateCompanyModalComponent)
  // @ts-ignore
  private createCompanyModalComponent: CreateCompanyModalComponent;

  currentCompany: Company | null = null;
  // @ts-ignore
  addCompany: boolean;
  // @ts-ignore
  companies: Array<Company> = [];

  // @ts-ignore
  page: number;
  // @ts-ignore
  selectedCompany: Company;
  // @ts-ignore
  nameFilter: string;
  // @ts-ignore
  sorting: TypedSorting<keyof Company>;
  // @ts-ignore
  pager: Pager;

  constructor(
    public companyService: CompanyService,
    private loginService: LoginService,
    private router: Router,
    public translationService: TranslationService
  ) {}

  openModalToCreateCompany(addingCompany: boolean, company: Company | null) {
    this.addCompany = addingCompany;
    this.currentCompany = company;
    this.createCompanyModalComponent.open();
  }

  async ngOnInit() {
    const userPromise: Promise<User> = this.waitForUser();
    const user: User = await userPromise;

    if (!this.loginService.isSuperAdmin()) {
      // TODO check for eventually missing await
      this.router
        .navigate(['/error', 'not-found'])
        .catch((err) => console.error(err));
    } else {
      this.resetPagination();
      this.launchSearch();
      this.setPage(1);
    }
  }

  async waitForUser(): Promise<User> {
    while (this.loginService.currentUser == null) {
      await new Promise((resolve) => setTimeout(resolve, 100));
    }
    return this.loginService.currentUser;
  }

  resetPagination() {
    this.page = 0;
    this.sorting = new TypedSorting<keyof Company>(
      SortingDirection.ASC,
      'name'
    );
    this.nameFilter = '';
  }

  sort(direction: string, column: string) {
    // @ts-ignore
    this.sorting = new Sorting(SortingDirection[direction], column);
    this.launchSearch();
  }

  setPage(page: number) {
    this.page = page - 1;
    this.launchSearch();
  }

  launchSearch() {
    const filterCriteria: Array<FilterCriterion> = [];
    if (this.nameFilter != '') {
      filterCriteria.push(new FilterCriterion('name', this.nameFilter));
    }
    this.companyService
      .getCompanies(this.page, filterCriteria, this.sorting)
      .subscribe((page: Page<Company>) => {
        this.companies = page.content;
        this.pager = Pager.getPager(
          page.totalElements,
          page.number + 1,
          page.size
        );
      });
  }
}
