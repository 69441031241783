import { Type } from 'class-transformer';
import { PkgComponent } from './component/pkg-component';
import { ComponentLevel } from './component/component-level.enum';
import { PrimaryPackaging } from './packaging/primary-packaging';
import { TertiaryPackagingBox } from './packaging/tertiary-packaging-box';
import { SecondaryPackaging } from './packaging/secondary-packaging';
import { TertiaryPackagingPalletization } from './packaging/tertiary-packaging-palletization';
import { Country } from '@/app/model/data/country';

export class Scenario {
  id!: number;
  name!: string;
  productionCountry!: Country;
  salesCountry!: Country;
  published!: boolean;
  get hasSecondaryPackaging(): boolean {
    return this.firstSecondaryPackaging !== undefined;
  }

  secondaryPackagingIsRecyclable!: boolean;
  get specifyTertiaryPackaging(): boolean {
    return this.firstTertiaryPackagingBox !== undefined;
  }

  get specifyTertiaryPackagingPallet(): boolean {
    return this.firstTertiaryPackagingPalletization !== undefined;
  }

  get components(): Array<PkgComponent> {
    let components: Array<PkgComponent> = new Array<PkgComponent>();
    if (this.firstPrimaryPackaging !== undefined)
      components = components.concat(this.firstPrimaryPackaging.components);
    if (this.firstSecondaryPackaging !== undefined)
      components = components.concat(this.firstSecondaryPackaging.components);
    if (this.firstTertiaryPackagingBox !== undefined)
      components = components.concat(this.firstTertiaryPackagingBox.components);
    if (this.firstTertiaryPackagingPalletization !== undefined)
      components = components.concat(
        this.firstTertiaryPackagingPalletization.components
      );
    return components;
  }

  @Type(() => PrimaryPackaging)
  primaryPackagings!: Array<PrimaryPackaging>;
  get firstPrimaryPackaging(): PrimaryPackaging | undefined {
    return this.primaryPackagings[0];
  }

  @Type(() => SecondaryPackaging)
  secondaryPackagings!: Array<SecondaryPackaging>;
  get firstSecondaryPackaging(): SecondaryPackaging | undefined {
    return this.secondaryPackagings[0];
  }

  @Type(() => TertiaryPackagingBox)
  tertiaryPackagingBoxes!: Array<TertiaryPackagingBox>;
  get firstTertiaryPackagingBox(): TertiaryPackagingBox | undefined {
    return this.tertiaryPackagingBoxes[0];
  }

  @Type(() => TertiaryPackagingPalletization)
  tertiaryPackagingPalletizations!: Array<TertiaryPackagingPalletization>;
  get firstTertiaryPackagingPalletization():
    | TertiaryPackagingPalletization
    | undefined {
    return this.tertiaryPackagingPalletizations[0];
  }

  copy(): Scenario {
    const copy: Scenario = new Scenario();
    copy.id = this.id;
    copy.name = this.name;
    copy.productionCountry = this.productionCountry;
    copy.salesCountry = this.salesCountry;

    copy.primaryPackagings = new Array<PrimaryPackaging>();
    if (this.primaryPackagings != null)
      for (const packaging of this.primaryPackagings) {
        const copyP: PrimaryPackaging = packaging.copy();
        copy.primaryPackagings.push(copyP);
      }

    copy.secondaryPackagings = new Array<SecondaryPackaging>();
    if (this.secondaryPackagings != null)
      for (const packaging of this.secondaryPackagings) {
        const copyP: SecondaryPackaging = packaging.copy();
        copy.secondaryPackagings.push(copyP);
      }

    copy.tertiaryPackagingBoxes = new Array<TertiaryPackagingBox>();
    if (this.tertiaryPackagingBoxes != null)
      for (const packaging of this.tertiaryPackagingBoxes) {
        const copyP: TertiaryPackagingBox = packaging.copy();
        copy.tertiaryPackagingBoxes.push(copyP);
      }

    copy.tertiaryPackagingPalletizations =
      new Array<TertiaryPackagingPalletization>();
    if (this.tertiaryPackagingPalletizations != null)
      for (const packaging of this.tertiaryPackagingPalletizations) {
        const copyP: TertiaryPackagingPalletization = packaging.copy();
        copy.tertiaryPackagingPalletizations.push(copyP);
      }

    copy.secondaryPackagingIsRecyclable = this.secondaryPackagingIsRecyclable;
    return copy;
  }

  mergeEditableFields(scenario: Scenario | undefined): void {
    if (scenario != undefined) {
      this.name = scenario.name;
      this.productionCountry = scenario.productionCountry;
      this.salesCountry = scenario.salesCountry;
    }
  }

  editableFieldsHaveChanged(original: Scenario): boolean {
    return (
      this.name != original.name ||
      this.productionCountry.label != original.productionCountry.label ||
      this.salesCountry.label != original.salesCountry.label
    );
  }

  componentsOfLevel(componentLevel: ComponentLevel): Array<PkgComponent> {
    switch (componentLevel) {
      case ComponentLevel.PRIMARY:
        return this.firstPrimaryPackaging?.components ?? [];
      case ComponentLevel.SECONDARY:
        return this.firstSecondaryPackaging?.components ?? [];
      case ComponentLevel.TERTIARY_BOX:
        return this.firstTertiaryPackagingBox?.components ?? [];
      case ComponentLevel.TERTIARY_PALLETIZATION:
        return this.firstTertiaryPackagingPalletization?.components ?? [];
    }
  }

  addComponentToLevel(
    pkgcomponent: PkgComponent,
    componentLevel: ComponentLevel
  ) {
    switch (componentLevel) {
      case ComponentLevel.SECONDARY:
        this.firstSecondaryPackaging?.components.push(pkgcomponent);
        break;
      case ComponentLevel.TERTIARY_BOX:
        this.firstTertiaryPackagingBox?.components.push(pkgcomponent);
        break;
      case ComponentLevel.TERTIARY_PALLETIZATION:
        this.firstTertiaryPackagingPalletization?.components.push(pkgcomponent);
        break;
      default:
        this.firstPrimaryPackaging?.components.push(pkgcomponent);
        break;
    }
  }

  addPrimaryPackaging(packaging: PrimaryPackaging): void {
    this.primaryPackagings.push(packaging);
  }

  setSecondaryPackaging(packaging: SecondaryPackaging): void {
    this.secondaryPackagings = new Array<SecondaryPackaging>();
    this.secondaryPackagings.push(packaging);
  }

  deleteSecondaryPackaging(): void {
    this.secondaryPackagings = new Array<SecondaryPackaging>();
  }

  setTertiaryPackagingBox(packaging: TertiaryPackagingBox): void {
    this.tertiaryPackagingBoxes = new Array<TertiaryPackagingBox>();
    this.tertiaryPackagingBoxes.push(packaging);
  }

  deleteTertiaryPackagingBox(): void {
    this.tertiaryPackagingBoxes = new Array<TertiaryPackagingBox>();
  }

  setTertiaryPackagingPalletization(
    packaging: TertiaryPackagingPalletization
  ): void {
    this.tertiaryPackagingPalletizations =
      new Array<TertiaryPackagingPalletization>();
    this.tertiaryPackagingPalletizations.push(packaging);
  }

  deleteTertiaryPackagingPalletization(): void {
    this.tertiaryPackagingPalletizations =
      new Array<TertiaryPackagingPalletization>();
  }
}
