/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import {
  Component,
  OnInit,
  HostListener,
  ViewChild,
  AfterViewInit,
  OnDestroy,
} from '@angular/core';
import { Scenario } from '@/app/model/packaging-model/scenario';
import { SingleUseProject } from '@/app/model/packaging-model/project/single-use-project';
import { ScenarioResult } from '@/app/model/results/scenario-result';
import { ProjectService } from '@/app/services/project.service';
import { GlobalService } from '@/app/services/global.service';
import { GuestService } from '@/app/services/guest.service';
import { TranslationService } from '@/app/services/translationService/translation.service';
import { DetailedResult } from '@/app/model/results/detailed-result';
import { ChartResult } from '@/app/model/results/chartResult';
import { RechargeProject } from '@/app/model/packaging-model/project/recharge-project';
import { Router, ActivatedRoute } from '@angular/router';
import { Indicator } from '@/app/model/results/indicator';
import { ResultPerScenario } from '@/app/model/results/result-per-scenario';
import { ResultsDisplayService } from '@/app/services/results-display.service';
import { ChartDataBuilderService } from '@/app/services/charts/chart-data-builder.service';
import { RechargeType } from '@/app/model/results/recharge-type';
import { BreakdownByMaterialModalComponent } from '../../projects/assess/breakdown-by-material-modal/breakdown-by-material-modal.component';

declare function buildOrUpdateChart(
  scenarioName: Array<string>,
  chart: string,
  results: Array<ChartResult>,
  number: number,
  xLabel: string,
  onClickCallback?: (evt: PointerEvent, activeElts: any[]) => void
): any;

declare function resetCanvas(canvasNames: Array<string>): any;

//#endregion

@Component({
  selector: 'app-projects-assessment',
  templateUrl: './projects-assessment.component.html',
  styleUrls: ['./projects-assessment.component.css'],
})
export class ProjectsAssessmentComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  get projects(): any[] {
    return this.projectService.selectedProjectsForComparison;
  }

  incompleteProjects: Array<RechargeProject> = [];

  selectedRechargeOrReferenceScenariosForAssess: Array<
    RechargeProject | Scenario
  > = [];
  selectedObjectsForAssessCopy!: Array<any>;
  error500 = false;
  errorsWhileComputing = false;
  errors: string[] = [];
  computing = true;
  scenarioDir: string | null = null;
  indic1Dir: string | null = null;
  indic2Dir: string | null = null;
  indic3Dir: string | null = null;
  firstSelectedIndic!: Indicator;
  secondSelectedIndic!: Indicator;
  thirdSelectedIndic!: Indicator;
  selectedIndicators: Array<Indicator> = [];
  resultsForSelectedObjects: Array<ScenarioResult> = [];
  resultsPerIndicators: Array<ResultPerScenario> =
    new Array<ResultPerScenario>();
  deepDiveSelectedIndic!: Indicator;
  deepDiveRadioGroup = 'score';

  @ViewChild(BreakdownByMaterialModalComponent)
  private readonly breakdownByMaterialModal!: BreakdownByMaterialModalComponent;

  get numberOfCharDisplayed(): number {
    return this.resultsDisplayService.numberOfCharDisplayed;
  }
  get heightOfIndicatorChart(): number {
    return this.resultsDisplayService.heightOfIndicatorChart;
  }
  get heightOfPackChart(): number {
    return this.resultsDisplayService.heightOfPackChart;
  }
  get heightOfLCAChart(): number {
    return this.resultsDisplayService.heightOfLCAChart;
  }

  get heightOfComponentChart(): number {
    return this.resultsDisplayService.heightOfComponentChart;
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(): void {
    this.resultsDisplayService.computeDisplayVariables(
      window.innerWidth,
      window.innerHeight
    );

    if (!this.computing && !this.errorsWhileComputing && !this.error500) {
      this.clearAllCanvases();
      this.buildOrUpdateAllCharts();
    }
  }

  constructor(
    private route: ActivatedRoute,
    private projectService: ProjectService,
    public globalService: GlobalService,
    private router: Router,
    public guestService: GuestService,
    private translationService: TranslationService,
    private resultsDisplayService: ResultsDisplayService,
    private chartDataBuilderService: ChartDataBuilderService
  ) {
    this.getScreenSize();
  }

  ngOnInit(): void {
    if (this.guestService.isUserGuest())
      void this.router.navigate(['/'], { relativeTo: this.route });
    this.checkProjectsCompleteness();
    this.selectedRechargeOrReferenceScenariosForAssess = new Array<
      RechargeProject | Scenario
    >();
    this.resultsPerIndicators = [];
    for (let i = 0; i < this.projects.length; i++) {
      const project = this.projects[i];
      const resultPerScenario = new ResultPerScenario();
      resultPerScenario.project = project;
      if (project instanceof RechargeProject)
        this.selectedRechargeOrReferenceScenariosForAssess.push(project);
      else {
        const scenario = project.scenarios.find(
          (s: Scenario) => s.id == project.referenceScenarioId
        );
        this.selectedRechargeOrReferenceScenariosForAssess.push(scenario);
        resultPerScenario.scenario = scenario;
      }
      this.resultsPerIndicators.push(resultPerScenario);
    }
    this.initSelectedRechargesOrReferenceScenarioService();
    this.selectedObjectsForAssessCopy =
      this.selectedRechargeOrReferenceScenariosForAssess;

    if (this.incompleteProjects.length == 0) this.computeResults();
  }

  ngOnDestroy(): void {
    this.clearAllCanvases();
  }

  async ngAfterViewInit(): Promise<void> {
    await this.delay(2);
    if (!this.computing && !this.errorsWhileComputing && !this.error500) {
      this.selectedObjectsForAssessCopy = this.resultsForSelectedObjects;

      this.buildOrUpdateAllCharts();
    }
  }

  fillResultPerScenario(s: any): void {
    let resultPerScenario;

    const scenarioAbsoluteResults = this.resultsPerIndicators.find(
      (r) => r.scenario != null && r.scenario.id == s.id
    );
    if (scenarioAbsoluteResults != undefined)
      resultPerScenario = scenarioAbsoluteResults;
    else
      resultPerScenario = this.resultsPerIndicators.find(
        (r) => r.project.id == s.id
      )!;

    resultPerScenario.indicator1 = this.firstSelectedIndic;
    resultPerScenario.resultIndicator1 = this.resultsForSelectedObjects
      .find((sr) => sr.id == s.id)!
      .results.perIndicatorImpacts.find(
        (i) => i.indicator.value == this.firstSelectedIndic.value
      )!.result;
    resultPerScenario.indicator2 = this.secondSelectedIndic;
    resultPerScenario.resultIndicator2 = this.resultsForSelectedObjects
      .find((sr) => sr.id == s.id)!
      .results.perIndicatorImpacts.find(
        (i) => i.indicator.value == this.secondSelectedIndic.value
      )!.result;
    resultPerScenario.indicator3 = this.thirdSelectedIndic;
    resultPerScenario.resultIndicator3 = this.resultsForSelectedObjects
      .find((sr) => sr.id == s.id)!
      .results.perIndicatorImpacts.find(
        (i) => i.indicator.value == this.thirdSelectedIndic.value
      )!.result;
  }

  isRecharge(project: any): boolean {
    return project instanceof RechargeProject;
  }

  indicatorsAsList(): Array<Indicator> {
    let list: Indicator[] = [];
    if (
      this.resultsForSelectedObjects != null &&
      this.resultsForSelectedObjects.length > 0
    ) {
      list = this.resultsForSelectedObjects[0].results.perIndicatorImpacts.map(
        (r) => r.indicator
      );
    }

    list.sort((a, b) => this.sort(a.id, b.id, 'ASC'));
    return list;
  }

  updateSelectedIndicators(): void {
    this.resetAllDir();
    this.selectedIndicators = Array.of(
      this.firstSelectedIndic,
      this.secondSelectedIndic,
      this.thirdSelectedIndic
    );
    this.rebuildScenarioResultsPerSelectedIndicators();
  }

  rebuildScenarioResultsPerSelectedIndicators(): void {
    this.selectedObjectsForAssessCopy.forEach((s) => {
      this.fillResultPerScenario(s);
    });
  }

  clearAllCanvases(): void {
    this.clearOverviewCanvases();
    this.clearDeepDiveCanvases();
  }

  clearOverviewCanvases(): void {
    const packagingKPIChartId = `packagingKPIChart_${String(
      this.selectedRechargeOrReferenceScenariosForAssess[0].id
    )}`;
    resetCanvas([packagingKPIChartId]);
  }

  clearDeepDiveCanvases(): void {
    const projectOrScenarioId =
      this.selectedRechargeOrReferenceScenariosForAssess[0].id;
    const packBreakDownChartId = `packagingBreakdownChart_${String(
      projectOrScenarioId
    )}`;
    const packagingLCAStepChartId = `packagingLCAStepChart_${String(
      projectOrScenarioId
    )}`;
    const rechargeTypeChartId = `rechargeBreakdownChart_${String(
      projectOrScenarioId
    )}`;

    const canvasNames = [packBreakDownChartId, packagingLCAStepChartId];
    if (this.bothProjectsAreRecharge()) {
      canvasNames.push(rechargeTypeChartId);
    }

    this.getScenariosFrom(
      this.selectedRechargeOrReferenceScenariosForAssess
    ).forEach((s: Scenario | RechargeProject) => {
      canvasNames.push('componentChart_' + s.id.toString());
    });

    this.getRechargeFrom(
      this.selectedRechargeOrReferenceScenariosForAssess
    ).forEach((s: Scenario | RechargeProject) => {
      canvasNames.push(
        'componentChart_' +
          s.id.toString() +
          '-' +
          this.getMotherReferenceProjectId(s).toString()
      );
      canvasNames.push(
        'componentChart_' +
          s.id.toString() +
          '-' +
          this.getDaughterReferenceProjectId(s).toString()
      );
    });

    resetCanvas(canvasNames);
  }

  buildOrUpdateOverviewChart(): void {
    const packagingKPIChartId = `packagingKPIChart_${String(
      this.selectedRechargeOrReferenceScenariosForAssess[0].id
    )}`;
    const scenarioNames = this.truncateNamesIfNeeded(
      this.selectedRechargeOrReferenceScenariosForAssess.map((s) => s.name)
    );
    const selectedScenarioResults =
      this.selectedRechargeOrReferenceScenariosForAssess.map(
        (s) => this.resultsForRechargeProjectOrReferenceScenario(s.id)!
      );

    buildOrUpdateChart(
      scenarioNames,
      packagingKPIChartId,
      this.chartDataBuilderService.buildDataPerIndic(selectedScenarioResults),
      this.heightOfIndicatorChart +
        3 * this.projectService.selectedScenariosForAssess.length,
      selectedScenarioResults[0].totalSingleScore.indicator.displayUnit
    );
  }

  buildOrUpdateDeepDiveCharts(): void {
    const packBreakDownChartId = `packagingBreakdownChart_${String(
      this.selectedRechargeOrReferenceScenariosForAssess[0].id
    )}`;
    const packagingLCAStepChartId = `packagingLCAStepChart_${String(
      this.selectedRechargeOrReferenceScenariosForAssess[0].id
    )}`;
    const rechargeTypeChartId = `rechargeBreakdownChart_${String(
      this.selectedRechargeOrReferenceScenariosForAssess[0].id
    )}`;

    const scenarioNames = this.truncateNamesIfNeeded(
      this.selectedRechargeOrReferenceScenariosForAssess.map((s) => s.name)
    );
    const selectedScenarioResults =
      this.selectedRechargeOrReferenceScenariosForAssess.map(
        (s) => this.resultsForRechargeProjectOrReferenceScenario(s.id)!
      );

    const singleScoreIndicator =
      selectedScenarioResults[0].totalSingleScore.indicator;
    const isSingleScoreSelected = this.deepDiveRadioGroup === 'score';
    const chartUnit = isSingleScoreSelected
      ? singleScoreIndicator.displayUnit
      : this.deepDiveSelectedIndic.displayUnit;

    const onComponentClickCallback = (evt: PointerEvent, activeElts: any[]) => {
      if (activeElts.length) {
        const clickedChart = activeElts[0]._chart;
        const clickedBar = clickedChart.getElementAtEvent(evt)[0];
        if (clickedBar != undefined) {
          const clickedComponent: ChartResult =
            clickedChart.data.datasets[clickedBar._datasetIndex];
          if (clickedComponent.elementId! !== 0) {
            this.breakdownByMaterialModal.open(
              clickedComponent.elementId!,
              clickedComponent.label,
              isSingleScoreSelected,
              isSingleScoreSelected
                ? singleScoreIndicator
                : this.deepDiveSelectedIndic,
              clickedComponent.rechargeProjectId
            );
          }
        }
      }
    };

    buildOrUpdateChart(
      scenarioNames,
      packBreakDownChartId,
      this.chartDataBuilderService.buildPackChartData(
        selectedScenarioResults,
        isSingleScoreSelected,
        this.deepDiveSelectedIndic
      ),
      this.heightOfPackChart +
        3 * this.selectedRechargeOrReferenceScenariosForAssess.length,
      chartUnit
    );

    buildOrUpdateChart(
      scenarioNames,
      packagingLCAStepChartId,
      this.chartDataBuilderService.buildLCAChartData(
        selectedScenarioResults,
        isSingleScoreSelected,
        this.deepDiveSelectedIndic
      ),
      this.heightOfLCAChart +
        3 * this.projectService.selectedScenariosForAssess.length,
      chartUnit
    );

    if (this.bothProjectsAreRecharge()) {
      buildOrUpdateChart(
        scenarioNames,
        rechargeTypeChartId,
        this.chartDataBuilderService.buildRechargeChartData(
          selectedScenarioResults,
          isSingleScoreSelected,
          this.deepDiveSelectedIndic
        ),
        this.heightOfPackChart +
          3 * this.projectService.selectedScenariosForAssess.length,
        chartUnit
      );
    }

    this.getScenariosFrom(
      this.selectedRechargeOrReferenceScenariosForAssess
    ).forEach((s: Scenario) => {
      if (s != undefined) {
        const scenarioResult =
          this.resultsForRechargeProjectOrReferenceScenario(s.id);
        if (scenarioResult != undefined) {
          buildOrUpdateChart(
            [s.name],
            this.getGraphId(s.id.toString()),
            this.chartDataBuilderService.buildComponentChartData(
              scenarioResult,
              isSingleScoreSelected,
              this.deepDiveSelectedIndic,
              null
            ),
            this.heightOfComponentChart,
            chartUnit,
            onComponentClickCallback
          );
        }
      }
    });

    this.getRechargeFrom(
      this.selectedRechargeOrReferenceScenariosForAssess
    ).forEach((p: RechargeProject) => {
      if (p != undefined) {
        const rechargeProjResult =
          this.resultsForRechargeProjectOrReferenceScenario(p.id);
        if (rechargeProjResult != undefined) {
          buildOrUpdateChart(
            [this.getMotherReferenceName(p)],
            this.getGraphId(
              p.id.toString() + '-' + this.getMotherReferenceProjectId(p)
            ),
            this.chartDataBuilderService.buildComponentChartData(
              rechargeProjResult,
              isSingleScoreSelected,
              this.deepDiveSelectedIndic,
              RechargeType.MOTHER,
              p.id
            ),
            this.heightOfComponentChart,
            chartUnit,
            onComponentClickCallback
          );
          buildOrUpdateChart(
            [this.getDaughterReferenceName(p)],
            this.getGraphId(
              p.id.toString() + '-' + this.getDaughterReferenceProjectId(p)
            ),
            this.chartDataBuilderService.buildComponentChartData(
              rechargeProjResult,
              isSingleScoreSelected,
              this.deepDiveSelectedIndic,
              RechargeType.DAUGHTER,
              p.id
            ),
            this.heightOfComponentChart,
            chartUnit,
            onComponentClickCallback
          );
        }
      }
    });
  }

  buildOrUpdateAllCharts(): void {
    this.buildOrUpdateOverviewChart();
    this.buildOrUpdateDeepDiveCharts();
  }

  singleScoreOrIndicatorSelectionChanged(): void {
    this.buildOrUpdateDeepDiveCharts();
  }

  truncateNamesIfNeeded(scenarioNames: Array<string>): string[] {
    const scenarioNamesTruncated: string[] = [];
    scenarioNames.forEach((s) => {
      if (s.length > this.numberOfCharDisplayed)
        scenarioNamesTruncated.push(s.slice(0, this.numberOfCharDisplayed - 1));
      else scenarioNamesTruncated.push(s);
    });
    return scenarioNamesTruncated;
  }

  trackByFn(item: Scenario): number {
    return item.id;
  }

  initSelectedRechargesOrReferenceScenarioService(): void {
    this.projectService.updateSelectedScenariosForAssess(
      this.selectedRechargeOrReferenceScenariosForAssess
    );
  }

  computeResults(): void {
    this.computing = true;
    this.projectService
      .computeProjectComparisonResults()
      .then(async (r) => {
        this.resultsForSelectedObjects = r;
        this.computing = false;
        this.synchronizeErrorBooleans();
        if (!this.computing && !this.errorsWhileComputing && !this.error500) {
          await this.delay(2);
          this.buildOrUpdateAllCharts();
          this.initSelectedIndicators();
          this.updateSelectedIndicators();
          this.rebuildScenarioResultsPerSelectedIndicators();
        }
      })
      .catch((err) => {
        this.projectService.handleError(err);
        this.synchronizeErrorBooleans();
        this.computing = false;
      });
  }

  synchronizeErrorBooleans(): void {
    this.error500 = this.projectService.error500;
    this.errors = this.projectService.errors;
    this.errorsWhileComputing = this.projectService.errorsWhileComputing;
  }

  initSelectedIndicators(): void {
    this.firstSelectedIndic = this.indicatorsAsList().find((i) => i.id == 1)!; //Climate change
    this.secondSelectedIndic = this.indicatorsAsList().find((i) => i.id == 13)!; //Land use
    this.thirdSelectedIndic = this.indicatorsAsList().find((i) => i.id == 14)!; //Ressource use fossil
    this.deepDiveSelectedIndic = this.indicatorsAsList().find(
      (i) => i.id == 1
    )!; //Climate change
  }

  resultsForRechargeProjectOrReferenceScenario(
    scenarioId: number
  ): DetailedResult | undefined {
    const scenarioResult = this.resultsForSelectedObjects.find(
      (result) => result != null && result.id == scenarioId
    );
    return scenarioResult?.results;
  }

  singleScoreForProject(project: SingleUseProject | RechargeProject): string {
    if (project instanceof SingleUseProject)
      return this.singleScoreForScenario(project.referenceScenarioId);
    else return this.singleScoreForScenario(project.id);
  }

  singleScoreForScenario(scenarioId: number): string {
    return this.resultsForRechargeProjectOrReferenceScenario(
      scenarioId
    )!.totalSingleScore.result.toFixed(2);
  }

  delay(ms: number): Promise<unknown> {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  getScenarioWithMinSingleScore(): number {
    let min = this.resultsForRechargeProjectOrReferenceScenario(
      this.selectedRechargeOrReferenceScenariosForAssess[0].id
    )!.totalSingleScore.result;
    let minScenario = this.selectedRechargeOrReferenceScenariosForAssess[0].id;
    for (
      let i = 1;
      i < this.selectedRechargeOrReferenceScenariosForAssess.length;
      i++
    ) {
      if (
        this.resultsForRechargeProjectOrReferenceScenario(
          this.selectedRechargeOrReferenceScenariosForAssess[i].id
        )!.totalSingleScore.result < min
      ) {
        min = this.resultsForRechargeProjectOrReferenceScenario(
          this.selectedRechargeOrReferenceScenariosForAssess[i].id
        )!.totalSingleScore.result;
        minScenario = this.selectedRechargeOrReferenceScenariosForAssess[i].id;
      }
    }
    return minScenario;
  }

  getSingleScoreDifference(scenarioMin: any): number {
    const getMinScenarioId =
      scenarioMin instanceof SingleUseProject
        ? scenarioMin.referenceScenarioId
        : scenarioMin.id;
    const findResult = this.selectedRechargeOrReferenceScenariosForAssess.find(
      (s) => s.id != getMinScenarioId
    );
    const scenarioMax = findResult != undefined ? findResult : scenarioMin;
    const scenarioMaxId =
      scenarioMax instanceof SingleUseProject
        ? scenarioMax.referenceScenarioId
        : scenarioMax.id;
    const scenarioMinSS =
      this.resultsForRechargeProjectOrReferenceScenario(getMinScenarioId)!
        .totalSingleScore.result;
    const scenarioMaxSS =
      this.resultsForRechargeProjectOrReferenceScenario(scenarioMaxId)!
        .totalSingleScore.result;
    //if we want absolute: return (scenarioMinSS - scenarioMaxSS) / ((scenarioMinSS + scenarioMaxSS) / 2) * 100;
    return 100 * ((scenarioMinSS - scenarioMaxSS) / scenarioMaxSS);
  }

  resetAllDir(): void {
    this.indic1Dir = null;
    this.indic2Dir = null;
    this.indic3Dir = null;
    this.scenarioDir = null;
  }

  sort(aValue: any, bValue: any, order: string): number {
    if (order == 'ASC') return aValue > bValue ? 1 : -1;
    else return aValue > bValue ? -1 : 1;
  }

  sortPerIndicator(indicator: Indicator, dir: string): void {
    this.resetAllDir();
    if (indicator.id == this.firstSelectedIndic.id) {
      this.indic1Dir = dir;
      this.resultsPerIndicators.sort((a, b) =>
        this.sort(a.resultIndicator1, b.resultIndicator1, dir)
      );
    }

    if (indicator.id == this.secondSelectedIndic.id) {
      this.indic2Dir = dir;
      this.resultsPerIndicators.sort((a, b) =>
        this.sort(a.resultIndicator2, b.resultIndicator2, dir)
      );
    }

    if (indicator.id == this.thirdSelectedIndic.id) {
      this.indic3Dir = dir;
      this.resultsPerIndicators.sort((a, b) =>
        this.sort(a.resultIndicator3, b.resultIndicator3, dir)
      );
    }
  }

  sortPerProjectName(dir: string): void {
    this.resetAllDir();
    this.scenarioDir = dir;
    this.resultsPerIndicators.sort((a, b) =>
      this.sort(a.project.name, b.project.name, dir)
    );
  }

  checkProjectsCompleteness(): void {
    this.incompleteProjects = [];
    this.projects.forEach((p) => {
      if (p instanceof RechargeProject && !p.isComplete()) {
        this.incompleteProjects.push(p);
      }
    });
  }

  bothProjectsAreRecharge(): boolean {
    return (
      this.selectedRechargeOrReferenceScenariosForAssess[0] instanceof
        RechargeProject &&
      this.selectedRechargeOrReferenceScenariosForAssess[1] instanceof
        RechargeProject
    );
  }

  goToExplanationPage(): void {
    void this.router.navigate(['/explanations']);
  }

  hideOrShow(idScenario: string): void {
    const scenarioGraph = document.getElementById('collapse_' + idScenario);
    if (scenarioGraph?.classList.contains('hide'))
      scenarioGraph.classList.remove('hide');
    else scenarioGraph?.classList.add('hide');
  }

  isGraphShown(idScenario: string): boolean {
    const scenarioGraph = document.getElementById('collapse_' + idScenario);
    if (scenarioGraph?.classList.contains('hide')) return false;
    else return true;
  }

  getGraphId(idScenario: string): string {
    return 'componentChart_' + idScenario;
  }

  getCollapseId(idScenario: string): string {
    return 'collapse_' + idScenario;
  }

  getMotherReferenceProjectId(scenario: Scenario | RechargeProject): number {
    return (scenario as RechargeProject).motherProject.referenceScenarioId;
  }

  getDaughterReferenceProjectId(scenario: Scenario | RechargeProject): number {
    return (scenario as RechargeProject).daughterProject.referenceScenarioId;
  }

  getScenariosFrom(
    selectedRechargeOrReferenceScenariosForAssess: Array<
      RechargeProject | Scenario
    >
  ): Array<Scenario> {
    return <Scenario[]>(
      selectedRechargeOrReferenceScenariosForAssess.filter(
        (s) => s instanceof Scenario
      )
    );
  }

  getRechargeFrom(
    selectedRechargeOrReferenceScenariosForAssess: Array<
      RechargeProject | Scenario
    >
  ): Array<RechargeProject> {
    return <RechargeProject[]>(
      selectedRechargeOrReferenceScenariosForAssess.filter(
        (s) => s instanceof RechargeProject
      )
    );
  }

  getMotherReferenceName(s: RechargeProject): string {
    const motherProject = s.motherProject;
    return motherProject.referenceScenario != undefined
      ? motherProject.referenceScenario.name
      : '';
  }

  getDaughterReferenceName(s: RechargeProject): string {
    const daughterProject = s.daughterProject;
    return daughterProject.referenceScenario != undefined
      ? daughterProject.referenceScenario.name
      : '';
  }

  getFormattedResult(result: number): string {
    return result < 0.1 ? result.toExponential(2) : result.toFixed(2);
  }
}
