import {
  Component,
  ViewChild,
  TemplateRef,
  Output,
  EventEmitter,
  OnInit,
} from '@angular/core';
import {
  NgbModal,
  ModalDismissReasons,
  NgbModalRef,
} from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '@/app/services/user.service';
import { CustomValidators } from '@/app/utils/custom-validators';
import { Observable, of, Subject, concat } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  map,
  tap,
  switchMap,
} from 'rxjs/operators';
import { User } from '@/app/model/user/user';
import { TranslationPipe } from '@/app/intl/translation-pipe';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-delete-user-modal',
  templateUrl: './delete-user-modal.component.html',
  styleUrls: ['./delete-user-modal.component.css'],
})
export class DeleteUserModalComponent implements OnInit {
  @Output() changeMade = new EventEmitter<boolean>();

  // @ts-ignore
  _userLocal: User;

  modal: NgbModalRef | undefined;
  // @ts-ignore
  deleteUserForm: UntypedFormGroup;

  // @ts-ignore
  users$: Observable<User[]>;
  userLoading = false;
  userInput$ = new Subject<string>();
  searchFailed = false;

  loading = false;

  // @ts-ignore
  @ViewChild('deleteusermodal') templateModal: TemplateRef<any>;

  constructor(
    private modalService: NgbModal,
    private formBuilder: UntypedFormBuilder,
    private userService: UserService,
    private translationPipe: TranslationPipe
  ) {}

  ngOnInit() {
    this.deleteUserForm = this.formBuilder.group({
      user: ['', Validators.compose([Validators.required])],
      confirm: ['', Validators.compose([Validators.required])],
    });

    this.loadUsers();
  }

  trackByFn(item: User) {
    return item.id;
  }

  onSubmitDeleteUser() {
    this.loading = true;

    this.userService
      .adminDeleteMemberUser(
        this._userLocal,
        // @ts-ignore
        this.deleteUserForm.get('user').value
      )
      .then((_) => {
        this.changeMade.emit();
        this.close();
      })
      .finally(() => (this.loading = false));
  }

  open(user: User) {
    this._userLocal = user;

    // @ts-ignore
    this.deleteUserForm
      .get('confirm')
      .setValidators(
        Validators.compose([
          Validators.required,
          CustomValidators.equals(
            this.translationPipe.transform('interface_delete')
          ),
        ])
      );

    this.modal = this.modalService.open(this.templateModal);
  }

  close() {
    this.resetFields();
    this.modal?.close();
  }

  resetAfterDismissal(reason: any) {
    if (
      reason === ModalDismissReasons.ESC ||
      reason === ModalDismissReasons.BACKDROP_CLICK
    ) {
      this.resetFields();
    }
  }

  resetFields() {
    this.deleteUserForm.reset();
  }

  private loadUsers() {
    this.users$ = concat(
      of([]), // default items
      this.userInput$.pipe(
        debounceTime(300),
        distinctUntilChanged(),
        tap(() => (this.userLoading = true)),
        switchMap((term) =>
          this.userService.searchUsersByName(term).pipe(
            map((users) =>
              users.filter(
                (user) =>
                  user.id != this._userLocal.id &&
                  user.company.id == this._userLocal.company.id
              )
            ),
            catchError(() => {
              this.searchFailed = true;
              return of([]);
            })
          )
        ),
        tap(() => (this.userLoading = false))
      )
    );
  }
}
