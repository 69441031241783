import { MaterialType } from '../data/material-type';
import { ConversionProcess } from '../data/conversion-process';
import { Type } from 'class-transformer';

export class Material {
  id!: number;
  mass!: number;
  recycledContent!: number;

  @Type(() => MaterialType)
  materialType!: MaterialType;

  @Type(() => ConversionProcess)
  conversionProcess!: ConversionProcess;

  static copy(material: Material): Material {
    const res: Material = new Material();
    res.id = material.id;
    res.mergeAll(material);
    return res;
  }
  mergeAll(updated: Material | undefined) {
    if (updated != undefined) {
      this.mass = updated.mass;
      this.recycledContent = updated.recycledContent;
      this.materialType = updated.materialType;
      this.conversionProcess = updated.conversionProcess;
    }
  }

  public inputDataHaveChanged(original: Material): boolean {
    return (
      this.mass != original.mass ||
      this.recycledContent != original.recycledContent ||
      this.conversionProcess.id != original.conversionProcess.id ||
      this.materialType.id != original.materialType.id
    );
  }
}
