/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { isBlank, normalizeForFilterAndSort } from '@/app/utils/string-utils';
import { SortingDirection } from '@/app/model/search/sorting';
import { TypedSorting } from '@/app/model/search/typed-sorting';
import { Pager } from '@/app/utils/pager';

/**
 * Utility class to perform pure frontend filter, sort and paginate operations on a list of items.
 */
export class Paginator {
  /**
   * The fixed size for the pages.
   */
  static readonly DEFAULT_PAGE_SIZE = 10;

  /**
   * Adds new "normalized" fields to each item, to perform filter and sort operations on.
   *
   * This method is intended to be called once on a list of items, as a preparation step.
   * Once prepared, the items can be passed to `Paginator.filterSortAndPaginate`, for performing the filter and sort operations.
   *
   * @example
   * ```
   * const users = [{name: 'Joël'}, {name: 'Hélène'}];
   * const usersNormalizedFieldsMapping = [{fieldName: 'name', normalizedFieldName: 'normalizedName'}];
   * const normalizedUsers = Paginator.normalizeForFilterAndSort(users, usersNormalizedFieldsMapping);
   * assert(_.isEqual(normalizedUsers, [{name: 'Joël', normalizedName: 'joel'}, {name: 'Hélène', normalizedName: 'helene'}]))
   * ```
   * @param item - The list of items to add new fields on.
   * @param normalizedFieldsMapping - The map of fields applicable for filter and sort operations.
   * @see Paginator.filterSortAndPaginate
   */
  static normalizeForFilterAndSort<
    T,
    TN extends T,
    F extends keyof T,
    FN extends keyof TN
  >(
    item: T,
    normalizedFieldsMapping: {
      fieldName: F;
      normalizedFieldName: FN;
    }[]
  ): TN {
    const normalizedItem = { ...item } as TN;
    for (const normalizedField of normalizedFieldsMapping) {
      const fieldValue = String(item[normalizedField.fieldName]);
      const normalizedFieldValue = normalizeForFilterAndSort(fieldValue);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      normalizedItem[normalizedField.normalizedFieldName] =
        normalizedFieldValue as any;
    }
    return normalizedItem;
  }

  /**
   * Perform filter, sort and paginate operations on the given list of items.
   *
   * This method is intended to be called after the list of items is prepared with `Paginator.normalizeForFilterAndSort`.
   *
   * If `options.filterSelectedOnly` is set to `true`, then the items must have a `selected` field for this option to take effect.
   *
   * @example
   * ```
   * const normalizedUsers = [{name: 'Joël', normalizedName: 'joel'}, {name: 'Hélène', normalizedName: 'helene'}];
   * const normalizedUsersFields = ['normalizedName'];
   * const searchFilter = 'j';
   * const sorting = new TypedSorting<keyof {name: string, normalizedName: string}>(SortingDirection.ASC, 'normalizedName');
   * const page = 1;
   * const result = Paginator.filterSortAndPaginate(normalizedUsers, normalizedUsersFields, searchFilter, sorting, page);
   * assert(_.isEqual(result, [{name: 'Joël', normalizedName: 'joel'}]))
   * ```
   *
   * @param items - The items to perform the operations on.
   * @param normalizedFields - The items fields applicable for filter and sort operations.
   * @param searchFilter - The search filter to apply on the normalized fields. The empty string means no search filter.
   * @param sorting - The sorting to apply on the normalized fields.
   * @param page - The page to display, starting at 1.
   * @param options - The filter, sort and paginate options.
   * @see Paginator.normalizeForFilterAndSort
   */
  static filterSortAndPaginate<T, F extends keyof T>(
    items: T[],
    normalizedFields: F[],
    searchFilter: string,
    sorting: TypedSorting<F>,
    page: number,
    options = {
      filterSelectedOnly: false,
    }
  ): {
    items: T[];
    pager: Pager;
    page: number;
  } {
    const totalItems = items.length;

    // Filter
    const normalizedSearchFilter = normalizeForFilterAndSort(searchFilter);
    if (!isBlank(normalizedSearchFilter)) {
      items = items.filter((item) =>
        normalizedFields.find(
          (normalizedField) =>
            String(item[normalizedField]).indexOf(normalizedSearchFilter) > -1
        )
      );
    }
    if (options.filterSelectedOnly) {
      items = items.filter(
        (item) =>
          typeof (item as any).selected === 'boolean' && (item as any).selected
      );
    }

    // Sort
    items.sort((ca, cb) => {
      const sortFieldA = String(ca[sorting.sortedBy]);
      const sortFieldB = String(cb[sorting.sortedBy]);
      return sorting.direction === SortingDirection.ASC
        ? sortFieldA.localeCompare(sortFieldB, undefined, { numeric: true })
        : sortFieldB.localeCompare(sortFieldA, undefined, { numeric: true });
    });

    // Paginate
    const totalFilteredItems = items.length;
    const currentPage = page;
    const pageSize = Paginator.DEFAULT_PAGE_SIZE;
    const pager = Pager.getPager(
      totalItems,
      currentPage,
      pageSize,
      totalFilteredItems
    );

    // Display
    const startIndex = (currentPage - 1) * Paginator.DEFAULT_PAGE_SIZE;
    items = items.slice(startIndex, startIndex + Paginator.DEFAULT_PAGE_SIZE);
    if (items.length === 0 && currentPage > 1) {
      return Paginator.filterSortAndPaginate(
        items,
        normalizedFields,
        searchFilter,
        sorting,
        --page,
        options
      );
    } else {
      return {
        items: items,
        pager: pager,
        page: page,
      };
    }
  }
}
