import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';
import { ConfirmModalComponent } from '@/app/pages/confirm-modal/confirm-modal.component';
import { Sorting, SortingDirection } from '@/app/model/search/sorting';
import { FilterCriterion } from '@/app/model/search/filter-criterion';
import { Page } from '@/app/model/pagination/page';
import { LoginService } from '@/app/services/login/login.service';
import { GuestUser } from '@/app/model/user/user';
import { GuestUserService } from '@/app/services/guest-user.service';
import { Router } from '@angular/router';
import { UntypedFormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbdSortableHeaderDirective } from '@/app/directive/sortable.directive';
import { TranslationService } from '@/app/services/translationService/translation.service';
import { FeatureService } from '@/app/services/featureService/feature.service';
import { FeatureStatus } from '@/app/services/featureService/feature-status';
import { UsualFeaturesAndRules } from '@/app/services/featureService/usual-features-and-rules';
import { Pager } from '@/app/utils/pager';

@Component({
  selector: 'app-guest-user',
  templateUrl: './guest-user.component.html',
  styleUrls: ['./guest-user.component.css'],
})
export class GuestUserComponent implements OnInit {
  @ViewChildren(ConfirmModalComponent)
  // @ts-ignore
  private confirmModals: QueryList<ConfirmModalComponent>;

  users: Array<GuestUser> = [];

  // @ts-ignore
  page: number;
  // @ts-ignore
  searchFilter: string;
  // @ts-ignore
  sorting: Sorting;
  // @ts-ignore
  pager: Pager;

  // @ts-ignore
  search: UntypedFormControl;
  // @ts-ignore
  searching: boolean;

  componentReady = true;

  // @ts-ignore
  selectedUser: GuestUser;

  @ViewChildren(NgbdSortableHeaderDirective)
  // @ts-ignore
  headers: QueryList<NgbdSortableHeaderDirective>;

  constructor(
    private loginService: LoginService,
    public translationService: TranslationService,
    private guestUserService: GuestUserService,
    private router: Router,
    private modalService: NgbModal,
    private featureService: FeatureService
  ) {}

  ngOnInit() {
    this.featureService.dispatcher$.subscribe((fs: FeatureStatus) => {
      if (fs.feature == UsualFeaturesAndRules.LOGGED_FEATURE && fs.isActive) {
        this.onComponentReady();
      }
    });

    if (this.featureService.isActive(UsualFeaturesAndRules.LOGGED_FEATURE)) {
      this.onComponentReady();
    }
  }

  onComponentReady() {
    this.search = new UntypedFormControl();
    this.componentReady = true;
    this.onSearchChange();

    if (!this.loginService.isSuperAdmin()) {
      void this.router.navigate(['/error', 'not-found']);
    } else {
      this.resetPagination();
      this.launchSearch();
    }
  }

  private onSearchChange() {
    this.search.valueChanges
      .pipe(debounceTime(300))
      .subscribe(() => this.launchSearch());
  }

  resetPagination() {
    this.page = 0;
    this.sorting = new Sorting(SortingDirection.ASC, 'firstname');
    this.searchFilter = '';
  }

  setPage(page: number) {
    this.page = page - 1;
    this.launchSearch();
  }

  launchSearch() {
    const filterCriteria: Array<FilterCriterion> = [];

    if (this.search.value) {
      filterCriteria.push(new FilterCriterion('search', this.search.value));
    }

    this.guestUserService
      .getUsers(this.page, filterCriteria, this.sorting)
      .then((page: Page<GuestUser>) => {
        this.users = page.content;
        this.pager = Pager.getPager(
          page.totalElements,
          page.number + 1,
          page.size
        );
      })
      .catch((err) => console.error(err));
  }

  async confirmDeleteUser(confirm: boolean) {
    this.modalService.dismissAll();

    if (confirm) {
      await this.guestUserService.adminDeleteGuestUser(this.selectedUser.id);
      this.launchSearch();
    }
  }

  getUserFullname() {
    return new Map().set(
      '$1',
      this.selectedUser ? this.selectedUser.displayedName : ''
    );
  }

  openModalToDeleteGuestUser(user: GuestUser) {
    this.selectedUser = user;
    this.confirmModals
      .filter((item) => item.id == 'guest-user-delete')[0]
      .open();
  }

  exportList() {
    void this.guestUserService.downloadExcelExport();
  }
}
